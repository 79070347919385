import { Injectable, inject } from '@angular/core';
import { REG_EXP } from '@common/constants/regexp.const';
import { ELanguages } from '@common/enums';
import { environment } from '@web-builder-env/environment';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private readonly transferStateService: TransferStateService = inject(TransferStateService);

    private languageMap = {
        [ELanguages.EN]: { code: 'en_US', lang: 'English', region: 'United States' },
        [ELanguages.ES_MX]: { code: 'es_MX', lang: 'Spanish', region: 'Mexico' },
        [ELanguages.FR]: { code: 'fr_FR', lang: 'French', region: 'France' },
        [ELanguages.PT_BR]: { code: 'pt_BR', lang: 'Portuguese', region: 'Brazil' },
        [ELanguages.TR]: { code: 'tr_TR', lang: 'Turkish', region: 'Turkey' },
        [ELanguages.RU]: { code: 'ru_RU', lang: 'Russian', region: 'Russia' },
        [ELanguages.UA]: { code: 'uk_UA', lang: 'Ukrainian', region: 'Ukraine' },
        [ELanguages.IT]: { code: 'it_IT', lang: 'Italian', region: 'Italy' },
    };

    public formatImgSrc(src: string): string {
        return src.replace(REG_EXP.IMG_SRC, this.imgSrc(this.transferStateService.resourceUrl));
    }

    private imgSrc(resourceUrl: string): string {
        return `${resourceUrl}${environment.mlsConstructorHref}assets/img`;
    }

    public getLanguageCode(value: string): string {
        return this.languageMap[value].code;
    }
}
