import type { SafeHtml } from '@angular/platform-browser';
import type { IAccordionElementData, IAccordionElementItem } from '@app/features/accordion/interface/IAccordion';

import { Component } from '@angular/core';

import { getStyles } from '@app/features/accordion/components/accordion-view/accordion-view.component.styles';
import { commonIconTemplates } from '@app/shared/templates/svg/common-icons';
import { EAccordionIconType } from '@app/features/accordion/enum/accordion.enums';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-accordion-feature',
    templateUrl: './accordion-feature.component.html',
    styleUrls: ['./accordion-feature.component.less'],
})
export default class AccordionFeatureComponent extends BaseFeatureComponent<IAccordionElementData> {
    protected getStyles = getStyles;

    public EAccordionIconType = EAccordionIconType;

    public set accordionList(list: IAccordionElementItem[]) {
        this.elementData.list = list;
    }

    public get accordionList(): IAccordionElementItem[] {
        return this.elementData.list;
    }

    public getIcon(iconName): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(commonIconTemplates[iconName]);
    }

    public toggle(index: number): void {
        this.accordionList = this.accordionList.reduce((acc: IAccordionElementItem[], item: IAccordionElementItem, i: number) => {
            acc.push({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            });

            return acc;
        }, []);
    }

    public formatContent(content: string): string {
        if (this.preventTrackingLinkTransformations) {
            return content;
        }

        return this.trackingLinkConverterService.convertAllTrackingLinksFromText(content);
    }
}
