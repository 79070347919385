<div class="tabs">
    <div *ngFor="let type of BUTTON_TYPES" class="tab"
         [class.active]="currentBtnType === type"
         (click)="currentBtnType = type">
        {{ buttonStyleTypesTranslations[type] | translate }}
    </div>
</div>
<div class="theme-settings">
    <div *ngFor="let type of BUTTON_TYPES; index as i">
        <div class="form form-horizontal" *ngIf="currentBtnType === type">
            <sp-themes-button-tab
                [buttonMode]="null"
                [base]="getThemeButtonBase(themeButtons[i])"
                [styles]="themeButtons[i].styles"
                [colorsPreset]="themeColors$ | async"
                (valueChangeEvent)="valueChangeHandler(type, $event)">
            </sp-themes-button-tab>
        </div>
    </div>


    <div class="form-footer">
        <button type="button" class="btn btn-default mr-10" (click)="onCancel()">{{ 'cancel' | translate }}</button>
        <button type="button" class="btn btn-success" (click)="onSave()">{{ 'save' | translate }}</button>
    </div>
</div>
