<div [ngSwitch]="type">
    <ng-container *ngSwitchCase="EGalleryTypes.image">
        <!--        TODO: REFACTOR -->
        <ng-container *ngIf="image?.url; else noUrl">
            <ng-container *ngIf="image?.link; else noLink">
                <a [class]="classes?.imgWrapper" [attr.href]="imageLink" target="_blank">
                    <ng-container *ngIf="image?.hasResize; else displayDefault">
                        <picture>
                            <source *ngFor="let source of imageSrcSets" [srcset]="source.url" [media]="source.media" />
                            <img [class]="classes?.image" [src]="imgSrc" [alt]="image?.alt" />
                        </picture>
                    </ng-container>
                    <ng-template #displayDefault>
                        <img [class]="classes?.image" [src]="imgSrc" [alt]="image?.alt" />
                    </ng-template>
                    <div *ngIf="description" [class]="classes?.description">
                        <div [class]="classes?.wrapLinkIcon">
                            <svg
                                [class]="classes?.linkIcon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 42 42"
                                fill="none"
                            >
                                <path
                                    [class]="classes?.linkIcon"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9729 4.10811C24.4509 -0.369875 31.7112 -0.369875 36.1892 4.10811C40.6672 8.5861 40.6672 15.8463 36.1892 20.3243L30.7838 25.7297C26.3058 30.2077 19.0455 30.2077 14.5675 25.7297L13.8919 25.0541C13.1455 24.3077 13.1455 23.0977 13.8919 22.3514C14.6382 21.605 15.8482 21.605 16.5946 22.3514L17.2702 23.027C20.2556 26.0124 25.0957 26.0124 28.0811 23.027L33.4865 17.6216C36.4718 14.6363 36.4718 9.79614 33.4865 6.81081C30.5011 3.82549 25.661 3.82549 22.6756 6.81081L19.2973 10.1892C18.5509 10.9355 17.3409 10.9355 16.5946 10.1892C15.8482 9.44286 15.8482 8.23282 16.5946 7.48649L19.9729 4.10811Z"
                                    fill="white"
                                />
                                <path
                                    [class]="classes?.linkIcon"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M24.0275 18.9729C21.0422 15.9876 16.202 15.9876 13.2167 18.9729L7.81126 24.3783C4.82593 27.3637 4.82593 32.2038 7.81126 35.1891C10.7966 38.1745 15.6367 38.1745 18.6221 35.1891L22.0004 31.8108C22.7468 31.0644 23.9568 31.0644 24.7032 31.8108C25.4495 32.5571 25.4495 33.7671 24.7032 34.5135L21.3248 37.8918C16.8468 42.3698 9.58654 42.3698 5.10855 37.8918C0.630567 33.4139 0.630567 26.1536 5.10855 21.6756L10.514 16.2702C14.9919 11.7922 22.2522 11.7922 26.7302 16.2702L27.4059 16.9459C28.1522 17.6922 28.1522 18.9023 27.4059 19.6486C26.6595 20.3949 25.4495 20.3949 24.7032 19.6486L24.0275 18.9729Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div [class]="classes?.text">
                            <span>{{ element.data.image.description }}</span>
                        </div>
                    </div>
                </a>
            </ng-container>
            <ng-template #noLink>
                <div [class]="classes?.imgWrapper">
                    <ng-container *ngIf="image?.hasResize; else displayDefault">
                        <picture>
                            <source *ngFor="let source of imageSrcSets" [srcset]="source.url" [media]="source.media" />
                            <img [class]="classes?.image" [src]="imgSrc" [alt]="image?.alt" />
                        </picture>
                    </ng-container>
                    <ng-template #displayDefault>
                        <img [class]="classes?.image" [src]="imgSrc" [alt]="image?.alt" />
                    </ng-template>
                    <div *ngIf="description" [class]="classes?.description">
                        <div [class]="classes?.wrapLinkIcon">
                            <svg
                                [class]="classes?.linkIcon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 42 42"
                                fill="none"
                            >
                                <path
                                    [class]="classes?.linkIcon"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9729 4.10811C24.4509 -0.369875 31.7112 -0.369875 36.1892 4.10811C40.6672 8.5861 40.6672 15.8463 36.1892 20.3243L30.7838 25.7297C26.3058 30.2077 19.0455 30.2077 14.5675 25.7297L13.8919 25.0541C13.1455 24.3077 13.1455 23.0977 13.8919 22.3514C14.6382 21.605 15.8482 21.605 16.5946 22.3514L17.2702 23.027C20.2556 26.0124 25.0957 26.0124 28.0811 23.027L33.4865 17.6216C36.4718 14.6363 36.4718 9.79614 33.4865 6.81081C30.5011 3.82549 25.661 3.82549 22.6756 6.81081L19.2973 10.1892C18.5509 10.9355 17.3409 10.9355 16.5946 10.1892C15.8482 9.44286 15.8482 8.23282 16.5946 7.48649L19.9729 4.10811Z"
                                    fill="white"
                                />
                                <path
                                    [class]="classes?.linkIcon"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M24.0275 18.9729C21.0422 15.9876 16.202 15.9876 13.2167 18.9729L7.81126 24.3783C4.82593 27.3637 4.82593 32.2038 7.81126 35.1891C10.7966 38.1745 15.6367 38.1745 18.6221 35.1891L22.0004 31.8108C22.7468 31.0644 23.9568 31.0644 24.7032 31.8108C25.4495 32.5571 25.4495 33.7671 24.7032 34.5135L21.3248 37.8918C16.8468 42.3698 9.58654 42.3698 5.10855 37.8918C0.630567 33.4139 0.630567 26.1536 5.10855 21.6756L10.514 16.2702C14.9919 11.7922 22.2522 11.7922 26.7302 16.2702L27.4059 16.9459C28.1522 17.6922 28.1522 18.9023 27.4059 19.6486C26.6595 20.3949 25.4495 20.3949 24.7032 19.6486L24.0275 18.9729Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div [class]="classes?.text">
                            <span>{{ element.data.image.description }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #noUrl>
            <div [ngClass]="image?.url ? classes?.imgWrapper : classes?.blankImageWrapper">
                <img [ngClass]="image?.url ? classes?.image : classes?.blankImage" [src]="imgSrc" [alt]="image?.alt" />
                <div [class]="classes?.description">
                    <div [class]="classes?.wrapLinkIcon">
                        <svg
                            [class]="classes?.linkIcon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 42 42"
                            fill="none"
                        >
                            <path
                                [class]="classes?.linkIcon"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.9729 4.10811C24.4509 -0.369875 31.7112 -0.369875 36.1892 4.10811C40.6672 8.5861 40.6672 15.8463 36.1892 20.3243L30.7838 25.7297C26.3058 30.2077 19.0455 30.2077 14.5675 25.7297L13.8919 25.0541C13.1455 24.3077 13.1455 23.0977 13.8919 22.3514C14.6382 21.605 15.8482 21.605 16.5946 22.3514L17.2702 23.027C20.2556 26.0124 25.0957 26.0124 28.0811 23.027L33.4865 17.6216C36.4718 14.6363 36.4718 9.79614 33.4865 6.81081C30.5011 3.82549 25.661 3.82549 22.6756 6.81081L19.2973 10.1892C18.5509 10.9355 17.3409 10.9355 16.5946 10.1892C15.8482 9.44286 15.8482 8.23282 16.5946 7.48649L19.9729 4.10811Z"
                                fill="white"
                            />
                            <path
                                [class]="classes?.linkIcon"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24.0275 18.9729C21.0422 15.9876 16.202 15.9876 13.2167 18.9729L7.81126 24.3783C4.82593 27.3637 4.82593 32.2038 7.81126 35.1891C10.7966 38.1745 15.6367 38.1745 18.6221 35.1891L22.0004 31.8108C22.7468 31.0644 23.9568 31.0644 24.7032 31.8108C25.4495 32.5571 25.4495 33.7671 24.7032 34.5135L21.3248 37.8918C16.8468 42.3698 9.58654 42.3698 5.10855 37.8918C0.630567 33.4139 0.630567 26.1536 5.10855 21.6756L10.514 16.2702C14.9919 11.7922 22.2522 11.7922 26.7302 16.2702L27.4059 16.9459C28.1522 17.6922 28.1522 18.9023 27.4059 19.6486C26.6595 20.3949 25.4495 20.3949 24.7032 19.6486L24.0275 18.9729Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div [class]="classes?.text">
                        <span>{{ element.data.image.description }}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="EGalleryTypes.video">
        <div *ngIf="video.url" [class]="classes?.imgWrapper">
            <div *ngIf="isBrowser" spPlayer [playerSources]="videoSource" style="width: 100%"></div>

            <img *ngIf="isServer" [class]="classes?.image" [src]="video?.previewUrl" [alt]="video?.type" />
        </div>

        <div *ngIf="!video.url" [class]="classes?.blankImageWrapper">
            <img [class]="classes?.blankImage" [src]="galleryBlankPicture" alt="gallery_blank" />
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="EGalleryTypes.slider">
        <swiper [autoplay]="autoplay" *ngIf="isBrowser">
            <ng-template swiperSlide *ngFor="let file of sliderFiles; index as i; trackBy: instanceId">
                <a class="swiper-slide" [attr.href]="getSliderLink(file)" [class]="classes?.sliderImgWrapper">
                    <ng-container *ngIf="file.hasResize; else displayDefault">
                        <picture>
                            <source *ngFor="let source of imagesSrcSets[i]" [srcset]="source.url" [media]="source.media" />
                            <img [class]="classes.gridImage" [src]="sliderImgSrc(file)" [alt]="file.alt" />
                        </picture>
                    </ng-container>
                    <ng-template #displayDefault>
                        <img [class]="classes?.sliderImage" [src]="sliderImgSrc(file)" [alt]="file.alt" />
                    </ng-template>
                    <div [class]="classes['description' + i]">
                        <div [class]="classes['wrapLinkIcon' + i]">
                            <svg
                                [class]="classes['linkIcon' + i]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 42 42"
                                fill="none"
                            >
                                <path
                                    [class]="classes['linkIcon' + i]"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9729 4.10811C24.4509 -0.369875 31.7112 -0.369875 36.1892 4.10811C40.6672 8.5861 40.6672 15.8463 36.1892 20.3243L30.7838 25.7297C26.3058 30.2077 19.0455 30.2077 14.5675 25.7297L13.8919 25.0541C13.1455 24.3077 13.1455 23.0977 13.8919 22.3514C14.6382 21.605 15.8482 21.605 16.5946 22.3514L17.2702 23.027C20.2556 26.0124 25.0957 26.0124 28.0811 23.027L33.4865 17.6216C36.4718 14.6363 36.4718 9.79614 33.4865 6.81081C30.5011 3.82549 25.661 3.82549 22.6756 6.81081L19.2973 10.1892C18.5509 10.9355 17.3409 10.9355 16.5946 10.1892C15.8482 9.44286 15.8482 8.23282 16.5946 7.48649L19.9729 4.10811Z"
                                    fill="white"
                                />
                                <path
                                    [class]="classes['linkIcon' + i]"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M24.0275 18.9729C21.0422 15.9876 16.202 15.9876 13.2167 18.9729L7.81126 24.3783C4.82593 27.3637 4.82593 32.2038 7.81126 35.1891C10.7966 38.1745 15.6367 38.1745 18.6221 35.1891L22.0004 31.8108C22.7468 31.0644 23.9568 31.0644 24.7032 31.8108C25.4495 32.5571 25.4495 33.7671 24.7032 34.5135L21.3248 37.8918C16.8468 42.3698 9.58654 42.3698 5.10855 37.8918C0.630567 33.4139 0.630567 26.1536 5.10855 21.6756L10.514 16.2702C14.9919 11.7922 22.2522 11.7922 26.7302 16.2702L27.4059 16.9459C28.1522 17.6922 28.1522 18.9023 27.4059 19.6486C26.6595 20.3949 25.4495 20.3949 24.7032 19.6486L24.0275 18.9729Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div [class]="classes['text' + i]">
                            <span>{{ file.description }}</span>
                        </div>
                    </div>
                </a>
            </ng-template>
        </swiper>
    </ng-container>
</div>
