export enum EWebsiteThemeButtonProperties {
    borderRadius = 'borderRadius',
    borderStyle = 'borderStyle',
    borderWidth = 'borderWidth',
    borderColor = 'borderColor',
    color = 'color',
    background = 'background',
}

export enum EWebsiteThemeButtonHoverProperties {
    opacity = 'opacity',
    shadow = 'shadow',
    color = 'color',
    background = 'background',
    borderColor = 'borderColor',
}
