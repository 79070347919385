import { EThemeFontFamilies, EThemeFontWeights } from '../enums/font-families.enum';

export const FONT_FAMILY_RULES: Readonly<Record<EThemeFontFamilies, string>> = {
    [EThemeFontFamilies.pacifico]: 'Pacifico, cursive',
    [EThemeFontFamilies.openSans]: 'Open Sans, sans-serif',
    [EThemeFontFamilies.lora]: 'Lora, serif',
    [EThemeFontFamilies.roboto]: 'Roboto, sans-serif',
    [EThemeFontFamilies.ptSerif]: 'PT Serif, serif',
    [EThemeFontFamilies.ptSans]: 'PT Sans, sans-serif',
    [EThemeFontFamilies.bitter]: 'Bitter, serif',
    [EThemeFontFamilies.raleWay]: 'Raleway, sans-serif',
    [EThemeFontFamilies.merriweather]: 'Merriweather, serif',
    [EThemeFontFamilies.firaSans]: 'Fira Sans, sans-serif',
    [EThemeFontFamilies.jost]: 'Jost, sans-serif',
    [EThemeFontFamilies.ubuntu]: 'Ubuntu, sans-serif',
    [EThemeFontFamilies.ibmPlexSans]: 'IBM Plex Sans, sans-serif',
    [EThemeFontFamilies.ibmPlexSerif]: 'IBM Plex Serif, serif',
    [EThemeFontFamilies.oswald]: 'Oswald, sans-serif',
    [EThemeFontFamilies.playFairDisplay]: ' Playfair Display, serif',
    [EThemeFontFamilies.comfortaa]: 'Comfortaa, cursive',
    [EThemeFontFamilies.sourceCodeProp]: 'Source Code Pro, monospace',
    [EThemeFontFamilies.manrope]: 'Manrope, sans-serif',
    [EThemeFontFamilies.anonymousPro]: 'Anonymous Pro, monospace',
    [EThemeFontFamilies.balsamiqSans]: 'Balsamiq Sans, cursive',
    [EThemeFontFamilies.lato]: 'Lato, sans-serif',
    [EThemeFontFamilies.montserrat]: 'Montserrat, sans-serif',
    [EThemeFontFamilies.inter]: 'Inter, sans-serif',
    [EThemeFontFamilies.commissioner]: 'Commissioner, sans-serif',
    [EThemeFontFamilies.mulish]: 'Mulish, sans-serif',
    [EThemeFontFamilies.yanoneKaffeesatz]: 'Yanone Kaffeesatz, sans-serif',
    [EThemeFontFamilies.philosopher]: 'Philosopher, sans-serif',
    [EThemeFontFamilies.jura]: 'Jura, sans-serif',
    [EThemeFontFamilies.caveat]: 'Caveat, cursive',
    [EThemeFontFamilies.literata]: 'Literata, serif',
    [EThemeFontFamilies.unbounded]: 'Unbounded, sans-serif',
    [EThemeFontFamilies.cormorantGaramond]: 'Cormorant Garamond, serif',
    [EThemeFontFamilies.tektur]: 'Tektur, sans-serif',
    [EThemeFontFamilies.exo2]: '"Exo 2", sans-serif',
};

export const FONT_FAMILY_NAMES: Readonly<Record<EThemeFontFamilies, string>> = {
    [EThemeFontFamilies.pacifico]: 'Pacifico',
    [EThemeFontFamilies.openSans]: 'Open Sans',
    [EThemeFontFamilies.lora]: 'Lora',
    [EThemeFontFamilies.roboto]: 'Roboto',
    [EThemeFontFamilies.ptSerif]: 'PT Serif',
    [EThemeFontFamilies.ptSans]: 'PT Sans',
    [EThemeFontFamilies.bitter]: 'Bitter',
    [EThemeFontFamilies.raleWay]: 'Raleway',
    [EThemeFontFamilies.merriweather]: 'Merriweather',
    [EThemeFontFamilies.firaSans]: 'Fira Sans',
    [EThemeFontFamilies.jost]: 'Jost',
    [EThemeFontFamilies.ubuntu]: 'Ubuntu',
    [EThemeFontFamilies.ibmPlexSans]: 'IBM Plex Sans',
    [EThemeFontFamilies.ibmPlexSerif]: 'IBM Plex Serif',
    [EThemeFontFamilies.oswald]: 'Oswald',
    [EThemeFontFamilies.playFairDisplay]: 'Playfair Display',
    [EThemeFontFamilies.comfortaa]: 'Comfortaa',
    [EThemeFontFamilies.sourceCodeProp]: 'Source Code Pro',
    [EThemeFontFamilies.manrope]: 'Manrope',
    [EThemeFontFamilies.anonymousPro]: 'Anonymous Pro',
    [EThemeFontFamilies.balsamiqSans]: 'Balsamiq Sans',
    [EThemeFontFamilies.lato]: 'Lato',
    [EThemeFontFamilies.montserrat]: 'Montserrat',
    [EThemeFontFamilies.inter]: 'Inter',
    [EThemeFontFamilies.commissioner]: 'Commissioner',
    [EThemeFontFamilies.mulish]: 'Mulish',
    [EThemeFontFamilies.yanoneKaffeesatz]: 'Yanone Kaffeesatz',
    [EThemeFontFamilies.philosopher]: 'Philosopher',
    [EThemeFontFamilies.jura]: 'Jura',
    [EThemeFontFamilies.caveat]: 'Caveat',
    [EThemeFontFamilies.literata]: 'Literata',
    [EThemeFontFamilies.unbounded]: 'Unbounded',
    [EThemeFontFamilies.cormorantGaramond]: 'Cormorant Garamond',
    [EThemeFontFamilies.tektur]: 'Tektur',
    [EThemeFontFamilies.exo2]: 'Exo 2',
};

export const FONT_WEIGHT_VALUES: Readonly<Record<EThemeFontWeights, number>> = {
    [EThemeFontWeights.light]: 300,
    [EThemeFontWeights.normal]: 400,
    [EThemeFontWeights.bold]: 700,
};

export const FONT_FAMILY_WEIGHTS: Readonly<Record<EThemeFontFamilies, EThemeFontWeights[]>> = {
    [EThemeFontFamilies.pacifico]: [EThemeFontWeights.normal],
    [EThemeFontFamilies.openSans]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.lora]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.roboto]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.ptSerif]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.ptSans]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.bitter]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.raleWay]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.merriweather]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.firaSans]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.jost]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.ubuntu]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.ibmPlexSans]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.ibmPlexSerif]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.oswald]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.playFairDisplay]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.comfortaa]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.sourceCodeProp]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.manrope]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.anonymousPro]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.balsamiqSans]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.lato]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.montserrat]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.inter]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.commissioner]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.mulish]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.yanoneKaffeesatz]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.philosopher]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.jura]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.caveat]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.literata]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.unbounded]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.cormorantGaramond]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.tektur]: [EThemeFontWeights.normal, EThemeFontWeights.bold],
    [EThemeFontFamilies.exo2]: [EThemeFontWeights.light, EThemeFontWeights.normal, EThemeFontWeights.bold],
};
