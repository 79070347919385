<div class="settings-block">
    <a class="settings-block-link"
       [class.active]="toggleOptions"
       (click)="toggleOptions = !toggleOptions">
        <span class="dotted">
            {{ 'analytics_form_settings' | translate }}
        </span> <span class="caret"></span>
    </a>
    <div class="settings-wrap" [hidden]="!toggleOptions">
        <div class="form-horizontal">
            <div class="panel-options-body overflow-wrapper">
                <ng-container *ngrxLet="siteSettings$; let settings">
                    <div class="mb-20">
                        <div class="form-group mb-10 form-horizontal row">
                            <ng-container *ngIf="settings?.google_analytics; else withoutGoogleSwitcher">
                                <div class="col-xs-9">
                                    <label>
                                        {{ 'google_analytic_label' | translate }}
                                    </label>
                                    <p class="analytic-hint small text-muted mb-0">
                                        {{ 'how_setting' | translate }}
                                        <a [href]="helpGALink" target="_blank">
                                            {{ 'info_google_link' | translate }}
                                            <img class="font-icon-light" src="./assets/img/lpc-new-window-icon.svg" alt="new-window-icon"/>
                                        </a>
                                    </p>
                                </div>
                                <div class="col-xs-3 control-switch text-right">
                                    <sp-switch
                                        [active]="googleAnalyticControl.value"
                                        (toggleActiveEvent)="toggleGoogleAnalyticControl($event)"
                                    ></sp-switch>
                                </div>
                            </ng-container>

                            <ng-template #withoutGoogleSwitcher>
                                <div class="col-xs-12">
                                    <label>
                                        {{ 'google_analytic_label' | translate }}
                                    </label>
                                    <p class="analytic-hint small text-muted mb-0">
                                        {{ 'google_analytic_description' | translate }}
                                        <a [href]="helpGALink" target="_blank">
                                            {{ 'how_setting_product' | translate }}
                                            <img class="font-icon-light" src="./assets/img/lpc-new-window-icon.svg" alt="new-window-icon"/>
                                        </a>
                                    </p>
                                </div>
                            </ng-template>
                        </div>
                        <app-google-settings
                            *ngIf="googleAnalyticControl.value"
                            [googleSettings]="googleGoogleSettingsGroup"></app-google-settings>
                    </div>

                    <div class="mb-0">
                        <div class="form-group mb-10 form-horizontal row">
                            <ng-container *ngIf="settings?.facebook_pixel; else withoutFacebookSwitcher">
                                <div class="col-xs-9">
                                    <label>
                                        {{ 'facebook_analytic_label' | translate }}
                                    </label>
                                    <p class="analytic-hint small text-muted mb-0">
                                        {{ 'how_setting' | translate }}
                                        <a [href]="helpPixelLink" target="_blank">
                                            {{ 'info_facebook_link' | translate }}
                                            <img class="font-icon-light" src="./assets/img/lpc-new-window-icon.svg" alt="new-window-icon"/>
                                        </a>
                                    </p>
                                </div>
                                <div class="col-xs-3 control-switch text-right">
                                    <sp-switch
                                        [active]="pixelAnalyticControl.value"
                                        (toggleActiveEvent)="togglePixelAnalyticControl($event)"
                                    ></sp-switch>
                                </div>
                            </ng-container>
                            <ng-template #withoutFacebookSwitcher>
                                <div class="col-xs-12">
                                    <label>
                                        {{ 'facebook_analytic_label' | translate }}
                                    </label>
                                    <p class="analytic-hint small text-muted mb-0">
                                        {{ 'facebook_analytic_description' | translate }}
                                        <a [href]="helpPixelLink" target="_blank">
                                            {{ 'how_setting_product' | translate }}
                                            <img class="font-icon-light" src="./assets/img/lpc-new-window-icon.svg" alt="new-window-icon"/>
                                        </a>
                                    </p>
                                </div>
                            </ng-template>
                        </div>
                        <app-pixel-settings *ngIf="pixelAnalyticControl.value" [pixelSettings]="pixelSettingsGroup">
                        </app-pixel-settings>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

