<div [class.input-group]="prefixImgUrl || suffixText || prefixText || suffixList">
    <div class="input-group-addon" *ngIf="prefixImgUrl">
        <img [src]="prefixImgUrl" alt="prefix" />
    </div>

    <span class="input-group-addon" *ngIf="prefixText">
        {{ prefixText }}
    </span>

    <input
        class="form-control"
        type="number"
        [class.invalid-input]="!control.valid"
        [class.disabled]="disabled"
        [min]="min"
        [max]="max"
        [step]="step"
        [formControl]="control"
        (focus)="focusEventHandler()"
        (blur)="blurEventHandler()"
    />

    <div class="input-group-addon" *ngIf="suffixText">
        {{ suffixText }}
    </div>
    <div dropdown class="input-group-btn" *ngIf="suffixList">
        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ suffixValue }}
            <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <li *ngFor="let option of suffixList" [class.selected]="suffixValue === option">
                <a (click)="suffixChangeEvent.emit(option)">
                    {{ option }}
                </a>
            </li>
        </ul>
    </div>
</div>
