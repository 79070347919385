import { type JssStyle } from 'jss';

import { EButtonType } from '@common/enums';
import { type IElement } from '@app/features/common/interfaces/IElement';
import { textButtonStyles, iconButtonStyles, getButtonsMargins } from '@app/features/common/styles/button.styles';

export function getStyles(element: IElement): JssStyle {
    const margins = getButtonsMargins(element.data.type);

    let buttonClasses = textButtonStyles(element);

    if (element.data.type === EButtonType.icon) {
        buttonClasses = iconButtonStyles(element);
    }

    return {
        ...buttonClasses,
        messenger: {
            ...margins,
        },
    };
}
