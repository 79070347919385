import { createActionGroup, props } from '@ngrx/store';

import { type SiteSettings } from '@common/types/site-settings.type';

export const SettingsActions = createActionGroup({
    source: 'Settings',
    events: {
        'Load site settings request': props<{ siteId: string }>(),
        'Load site settings success': props<{ settings: SiteSettings }>(),
        'Load site settings failure': props<{ error: string }>(),
    },
});

export default SettingsActions;
