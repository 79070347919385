import { type ShareBlocks } from '@common/types/site.type';
import { type BlockStructureEntity } from '@web/types';

export function formatStructure(
    structure: BlockStructureEntity[],
    sharedBlocks: string,
    pageId: number,
    isNotFoundPage: boolean = false,
): BlockStructureEntity[] {
    const normalizedStructure: BlockStructureEntity[] = structure || [];

    if (isNotFoundPage) {
        return normalizedStructure;
    }

    if (!sharedBlocks) {
        return structure;
    }

    const normalizedSharedBlocks = (JSON.parse(sharedBlocks) || {}) as ShareBlocks;

    if (normalizedSharedBlocks?.header && !normalizedSharedBlocks.header.sharedBlockProps?.hideOnPage[pageId]) {
        normalizedStructure.unshift(normalizedSharedBlocks.header);
    }

    if (normalizedSharedBlocks?.footer && !normalizedSharedBlocks.footer.sharedBlockProps?.hideOnPage[pageId]) {
        normalizedStructure.push(normalizedSharedBlocks.footer);
    }

    return normalizedStructure;
}
