import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@web-builder/store/cart/cart.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class CartEffect {
    setCartLocalStore$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.getCartLocalStore),
            map(() => CartActions.cartLoaded()),
        ),
    );

    addItemToCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.addItemToCart),
            map(() => CartActions.itemAddedToCartSuccessfully()),
        ),
    );

    removeItemFromCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.removeItemFromCart),
            map(() => CartActions.itemRemovedFromCartSuccessfully()),
        ),
    );

    setNumberOfItemInCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.setNumberOfItemInCart),
            map(() => CartActions.itemNumberSetSuccessfully()),
        ),
    );

    constructor(private actions$: Actions) {}
}
