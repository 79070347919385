import type { ThemeConfig } from '@common/libs/themes/src/types/theme-config.type';
import type { ThemeSettingsState } from '@libs/themes/src/store/theme-settings/theme-settings.reducer';
import type {
    ThemeButton,
    ThemeButtonPreset,
    ThemeColor,
    ThemeColorPreset,
    ThemeFontPreset,
    ThemeSelectedButtons,
    ThemeSelectedColors,
    ThemeSelectedFonts,
} from '@common/libs/themes/src/types';
import type { EThemeVariants } from '@libs/themes/src/enums/theme-variants.enum';
import type { AbstractThemeFormatter } from './theme-formatter/abstract-theme-formatter';

export abstract class AbstractBaseThemeStrategy {
    public abstract type: EThemeVariants;
    public abstract config: ThemeConfig;
    public abstract formatter: AbstractThemeFormatter;

    public abstract get buttonPresets(): ThemeButtonPreset[];
    public abstract get colorPresets(): ThemeColorPreset[];
    public abstract get fontPresets(): ThemeFontPreset[];

    public formatTheme(theme: ThemeSettingsState): Record<string, string> {
        return this.formatter.formatTheme(theme);
    }

    public formatNextButtonSettings(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[] {
        return this.formatter.formatNextButtonSettings(colors, buttons);
    }

    public setSelectedButton(buttons: ThemeSelectedButtons): ThemeSelectedButtons {
        if (!buttons?.selected) {
            const { values, index }: ThemeButtonPreset = this.buttonPresets[0];
            return {
                selected: values,
                selectedIndex: index,
            };
        }

        const { selected, selectedIndex } = buttons;
        return {
            selected,
            selectedIndex,
        };
    }

    public setSelectedColor(colors: ThemeSelectedColors): ThemeSelectedColors {
        if (!colors?.selected) {
            const { values, index }: ThemeColorPreset = this.colorPresets[0];
            return {
                selected: values,
                selectedIndex: index,
            };
        }

        const { selected, selectedIndex } = colors;
        return {
            selected,
            selectedIndex,
        };
    }

    public setSelectedFont(fonts: ThemeSelectedFonts): ThemeSelectedFonts {
        if (!fonts?.selected) {
            const { values, index }: ThemeFontPreset = this.fontPresets[0];
            return {
                selected: values,
                selectedIndex: index,
                isSynchronized: fonts?.isSynchronized ?? true,
            };
        }

        const { selected, selectedIndex } = fonts;
        return {
            selected,
            selectedIndex,
            isSynchronized: fonts?.isSynchronized ?? true,
        };
    }

    public setButtonPresets(buttons: ThemeSelectedButtons): ThemeButtonPreset[] {
        const presets: ThemeButtonPreset[] = this.buttonPresets;
        const currentIndex: number = presets.findIndex((preset: ThemeButtonPreset) => preset.index === buttons.selectedIndex);

        return currentIndex === -1 ? [{ index: null, values: buttons.selected }, ...presets] : presets;
    }

    public setColorPresets(colors: ThemeSelectedColors): ThemeColorPreset[] {
        const presets: ThemeColorPreset[] = this.colorPresets;
        const currentIndex: number = presets.findIndex((preset: ThemeColorPreset) => preset.index === colors.selectedIndex);

        return currentIndex === -1 ? [{ index: null, values: colors.selected }, ...presets] : presets;
    }

    public setFontPresets(fonts: ThemeSelectedFonts): ThemeFontPreset[] {
        const presets: ThemeFontPreset[] = this.fontPresets;
        const currentIndex: number = presets.findIndex((preset: ThemeFontPreset) => preset.index === fonts.selectedIndex);

        return currentIndex === -1 ? [{ index: null, values: fonts.selected }, ...presets] : presets;
    }

    public adaptThemeSettings(settings: ThemeSettingsState): ThemeSettingsState {
        return settings;
    }

    public get isConfigButtons(): boolean {
        return this.config.buttons;
    }

    public get isConfigColors(): boolean {
        return this.config.colors;
    }

    public get isConfigFonts(): boolean {
        return this.config.fonts;
    }
}
