/* eslint-disable @typescript-eslint/no-use-before-define */
import { type JssStyle } from 'jss';
import { EButtonStyleType, EButtonType } from '@common/enums';
import { type IElement } from '@app/features/common/interfaces/IElement';
import { Widgets } from '@common/enums';
import { commonButtonIconStyles, commonButtonTextStyles } from '@app/features/common/constants/button-sizes.const';
import { commonPositionStyles } from '@app/features/common/constants/position.const';
import { commonFontSizeCoefficient } from '@app/features/common/styles/text.styles';

import { simpleButtonCssProperty } from '@libs/themes/src/variants/simple/utils/variables';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { ESimpleThemeButtonProperties } from '@libs/themes/src/variants/simple/enums/button.enum';

export const customButtonStyles: JssStyle = {
    fontSize: '16px',
    lineHeight: '140%',
    color: 'rgb(255,255,255)',
    letterSpacing: '0px',
    background: 'rgb(0,0,0)',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    borderRadius: '0px',
    borderWidth: '2px',
    borderColor: 'rgb(0,0,0)',
    borderStyle: 'solid',
};

// WRAP CLASS
export const commonButtonWrapStyles = ({ data }: IElement): JssStyle => {
    const styles: JssStyle = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    };

    if (data.type === EButtonType.icon) {
        const { textAlign, justifyContent } = commonPositionStyles[data.position];

        styles.textAlign = textAlign;
        styles.justifyContent = justifyContent;
        styles.flexDirection = 'row';
    }

    return styles;
};
// export const commonButtonWrapStyles = ({ buttonType, p }: TextButtonProps): JssStyle => {
//     const styles: JssStyle = {
//         display: 'flex',
//         flexDirection: 'column',
//         flexWrap: 'wrap',
//     };

//     if (buttonType === EButtonType.icon) {
//         const { textAlign, justifyContent } = commonPositionStyles[data.position];

//         styles.textAlign = textAlign;
//         styles.justifyContent = justifyContent;
//         styles.flexDirection = 'row';
//     }

//     return styles;
// };

// type TextButtonProps = {
//     buttonSize: ESize;
//     buttonType: EButtonType;
//     buttonStyleType: EButtonStyleType;
//     hasIcon: boolean;
//     position: EHorizontalPosition;
// };
// const hasIcon = element.name === Widgets.BUTTON || element.name === Widgets.SOCIAL_NETWORKS || element.name === Widgets.CHAT_BOTS;

// export const textButtonStyles1 = (props: TextButtonProps, element: IElement): JssStyle => {
//     const fontSizeCoefficient = commonFontSizeCoefficient[props.buttonSize];
//     const fontSize =
//         props.buttonStyleType === EButtonStyleType.classic
//             ? simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontSize)
//             : getCssProperty(element, ESimpleThemeFontProperties.fontSize);

//     const scaledFontSize = `calc(${fontSize} * ${fontSizeCoefficient})`;
//     const descriptionFontSize = `calc((${fontSize} * ${fontSizeCoefficient}) * 0.75)`;
//     const descriptionMarginTop = `calc(${descriptionFontSize} / 4)`;

//     return {
//         wrap: commonButtonWrapStyles(props),
//         element: commonButtonElementStyles(props, element),
//         label: textButtonLabelStyles(element, scaledFontSize),
//         description: textButtonDescriptionStyles(element, scaledFontSize, descriptionFontSize, descriptionMarginTop),
//         wrapIcon: buttonTextWrapIconStyles(element, scaledFontSize),
//         wrapContent: {
//             flex: 1,
//             flexDirection: 'column',
//             display: 'flex',
//             justifyContent: 'center',
//             marginLeft: props.hasIcon ? '16px' : 0,
//             marginRight: props.hasIcon ? `calc(${fontSize} * ${fontSizeCoefficient} + 16px)` : 0,
//         },
//     };
// };

export const textButtonStyles = (element: IElement): JssStyle => {
    const fontSizeCoefficient = commonFontSizeCoefficient[element.data.buttonSize];
    const fontSize =
        element.data.buttonType === EButtonStyleType.classic
            ? simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontSize)
            : getCssProperty(element, ESimpleThemeFontProperties.fontSize);
    const hasIcon = element.name === Widgets.BUTTON || element.name === Widgets.SOCIAL_NETWORKS || element.name === Widgets.CHAT_BOTS;

    const scaledFontSize = `calc(${fontSize} * ${fontSizeCoefficient})`;
    const descriptionFontSize = `calc((${fontSize} * ${fontSizeCoefficient}) * 0.75)`;
    const descriptionMarginTop = `calc(${descriptionFontSize} / 4)`;

    return {
        wrap: commonButtonWrapStyles(element),
        element: commonButtonElementStyles(element),
        label: textButtonLabelStyles(element, scaledFontSize),
        description: textButtonDescriptionStyles(element, scaledFontSize, descriptionFontSize, descriptionMarginTop),
        wrapIcon: buttonTextWrapIconStyles(element, scaledFontSize),
        wrapContent: {
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: hasIcon ? '16px' : 0,
            marginRight: hasIcon ? `calc(${fontSize} * ${fontSizeCoefficient} + 16px)` : 0,
        },
    };
};

const commonButtonElementStyles = (element: IElement): JssStyle => {
    const styles: JssStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
        },
    };

    if (element.data.type === EButtonType.icon) {
        return styles;
    }

    styles.padding = commonButtonTextStyles[element.data.buttonSize].padding;

    switch (element.data.buttonType) {
        case EButtonStyleType.classic:
            styles.borderRadius = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderRadius);
            styles.borderStyle = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderStyle);
            styles.borderWidth = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderWidth);
            break;
        case EButtonStyleType.primary:
        case EButtonStyleType.secondary:
        case EButtonStyleType.custom:
            styles.background = getCssProperty(element, ESimpleThemeButtonProperties.background);
            styles.borderColor = getCssProperty(element, ESimpleThemeButtonProperties.borderColor);
            styles.borderRadius = getCssProperty(element, ESimpleThemeButtonProperties.borderRadius);
            styles.borderStyle = getCssProperty(element, ESimpleThemeButtonProperties.borderStyle);
            styles.borderWidth = getCssProperty(element, ESimpleThemeButtonProperties.borderWidth);
            break;
        default:
            break;
    }

    return styles;
};
// const commonButtonElementStyles = (props: TextButtonProps, element: IElement): JssStyle => {
//     const styles: JssStyle = {
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'center',
//         textAlign: 'center',
//         cursor: 'pointer',
//         '&:hover': {
//             textDecoration: 'none',
//         },
//     };

//     if (props.buttonType === EButtonType.icon) {
//         return styles;
//     }

//     styles.padding = commonButtonTextStyles[props.buttonSize].padding;

//     switch (props.buttonStyleType) {
//         case EButtonStyleType.classic:
//             styles.borderRadius = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderRadius);
//             styles.borderStyle = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderStyle);
//             styles.borderWidth = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderWidth);
//             break;
//         case EButtonStyleType.primary:
//         case EButtonStyleType.secondary:
//         case EButtonStyleType.custom:
//             styles.background = getCssProperty(element, ESimpleThemeButtonProperties.background);
//             styles.borderColor = getCssProperty(element, ESimpleThemeButtonProperties.borderColor);
//             styles.borderRadius = getCssProperty(element, ESimpleThemeButtonProperties.borderRadius);
//             styles.borderStyle = getCssProperty(element, ESimpleThemeButtonProperties.borderStyle);
//             styles.borderWidth = getCssProperty(element, ESimpleThemeButtonProperties.borderWidth);
//             break;
//         default:
//             break;
//     }

//     return styles;
// };

const textButtonLabelStyles = (element: IElement, scaledFontSize: string): JssStyle => {
    const styles: JssStyle = {};

    switch (element.data.buttonType) {
        case EButtonStyleType.classic:
            styles.fontSize = scaledFontSize;
            styles.fontFamily = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontFamily);
            styles.textTransform = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.textTransform);
            styles.fontStyle = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontStyle);
            styles.fontWeight = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontWeight);
            styles.lineHeight = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.lineHeight);
            styles.wordBreak = 'break-word';
            break;
        case EButtonStyleType.primary:
        case EButtonStyleType.secondary:
            styles.fontSize = scaledFontSize;
            styles.color = getCssProperty(element, ESimpleThemeButtonProperties.color);
            styles.lineHeight = getCssProperty(element, ESimpleThemeFontProperties.lineHeight);
            styles.letterSpacing = getCssProperty(element, ESimpleThemeFontProperties.letterSpacing);
            styles.textTransform = getCssProperty(element, ESimpleThemeFontProperties.textTransform);
            styles.fontFamily = getCssProperty(element, ESimpleThemeFontProperties.fontFamily);
            styles.fontStyle = getCssProperty(element, ESimpleThemeFontProperties.fontStyle);
            styles.fontWeight = getCssProperty(element, ESimpleThemeFontProperties.fontWeight);
            styles.wordBreak = 'break-word';
            break;
        case EButtonStyleType.custom:
            styles.fontSize = scaledFontSize;
            styles.color = element.styles[ESimpleThemeButtonProperties.color];
            styles.lineHeight = element.styles[ESimpleThemeFontProperties.lineHeight];
            styles.letterSpacing = element.styles[ESimpleThemeFontProperties.letterSpacing];
            styles.textTransform = element.styles[ESimpleThemeFontProperties.textTransform];
            styles.fontFamily = simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontFamily);
            styles.fontStyle = element.styles[ESimpleThemeFontProperties.fontStyle];
            styles.fontWeight = element.styles[ESimpleThemeFontProperties.fontWeight];
            styles.wordBreak = 'break-word';
            break;
        default:
            break;
    }

    return styles;
};

// WRAP_ICON CLASS
const buttonTextWrapIconStyles = (element: IElement, scaledFontSize: string): JssStyle => {
    let color;

    switch (element.data.buttonType) {
        case EButtonStyleType.classic:
            color = '#FFFFFF';
            break;
        case EButtonStyleType.primary:
        case EButtonStyleType.secondary:
        case EButtonStyleType.custom:
            color = getCssProperty(element, ESimpleThemeButtonProperties.color);
            break;
    }

    return {
        display: 'flex',
        alignItems: 'center',
        '& .icon': {
            display: 'flex',
            alignItems: 'center',
            color,
            '& svg': {
                height: scaledFontSize,
                width: scaledFontSize,
                '& path': {
                    fill: color,
                },
                '& path.stroke': {
                    fill: 'none',
                    stroke: color,
                },
            },
        },
    };
};

const textButtonDescriptionStyles = (
    element: IElement,
    scaledFontSize: string,
    descriptionFontSize: string,
    descriptionMarginTop: string,
): JssStyle => {
    const labelStyles = textButtonLabelStyles(element, scaledFontSize);

    return {
        ...labelStyles,
        fontSize: descriptionFontSize,
        fontWeight: 'normal',
        textTransform: 'none',
        marginTop: descriptionMarginTop,
    };
};
// END BUTTON TEXT CLASSES

// START BUTTON ICON STYLES

export const iconButtonStyles = (element: IElement): JssStyle => ({
    wrap: commonButtonWrapStyles(element),
    element: commonButtonElementStyles(element),
    wrapIcon: {
        display: 'flex',
        alignItems: 'center',
        '& .icon': commonButtonIconStyles[element.data.buttonSize],
    },
});

// END BUTTON ICON STYLES

export const getButtonsMargins = (buttonType: EButtonType): JssStyle => {
    let margin = '';

    if (buttonType === EButtonType.icon) {
        margin = '0 5px 10px 5px';
    } else if (buttonType === EButtonType.iconText) {
        margin = '0 0 10px';
    }

    return {
        margin,
    };
};

function getCssProperty(element: IElement, property: string): string {
    return element.data.buttonType === EButtonStyleType.custom
        ? element.styles[property]
        : simpleButtonCssProperty(element.data.buttonType, property);
}
