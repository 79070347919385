import type { Observable } from 'rxjs';
import type { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';

import { Injectable } from '@angular/core';

import { BaseFacade } from '@common/services/base-facade.service';

import ThemeConfigActions from '@common/libs/themes/src/store/theme-config/theme-config.actions';
import * as ThemeConfigSelectors from '@common/libs/themes/src/store/theme-config/theme-config.selectors';

@Injectable()
export class ThemeConfigFacade extends BaseFacade {
    public readonly themeConfigLoaded$: Observable<boolean> = this.store$.select(ThemeConfigSelectors.selectThemeConfigLoaded);
    public readonly activeThemeSetting$: Observable<EThemeSettings> = this.store$.select(ThemeConfigSelectors.selectActiveThemeSetting);

    public setActiveThemeSettingAction(value: EThemeSettings): void {
        this.store$.dispatch(ThemeConfigActions.setActiveThemeSetting({ value }));
    }

    public unselectActiveThemeSettingAction(): void {
        this.store$.dispatch(ThemeConfigActions.unselectActiveThemeSetting());
    }
}
