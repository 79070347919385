import { createReducer, on } from '@ngrx/store';

import ThemeValuesActions from '@common/libs/themes/src/store/theme-values/theme-values.actions';

export type ThemeValuesState = Record<string, string>;

export const THEME_VALUES_STORE_KEY = 'values';

const initialThemeValuesState: ThemeValuesState = {};

export const themeValuesReducer = createReducer<ThemeValuesState>(
    initialThemeValuesState,
    on(ThemeValuesActions.setThemeValues, (state, { values }) => ({ ...state, ...values })),
);
