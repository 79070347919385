export const environment = {
    production: true,
    constructorHref: '/websites/builder/',
    mlsConstructorHref: '/constructor/',
    fileManagerCdnUrl: 'https://1860267202.rsc.cdn77.org',
    memberAreaUrl: 'https://pre.sendpulse.com',
    proxyUrl: '/sp-auth-pre',
    eduStudentUrl: '/coursespre',
    landingPagesHref: '/landing-pages/',
    type: 'pre',
    buildTimestamp: new Date().getTime(),
};
