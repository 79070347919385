import { type JssStyle } from 'jss';

import { simpleColorCss, simpleFontCss } from '@libs/themes/src/variants/simple/utils/variables';

import { type ISubscriptionFormElementData } from '@app/features/subscription-form/interface/ISubscriptionFormElement';
import { type IElement } from '@app/features/common/interfaces/IElement';
import { EThemeFontTags } from '@libs/themes';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { ESimpleThemeColorNames } from '@libs/themes/src/variants/simple/enums/color.enum';
import { h2Styles, h4Styles, pStyles } from '@app/features/common/styles/text.styles';
import { textButtonStyles } from '@app/features/common/styles/button.styles';
import { formElementsStyles } from '@app/features/common/styles/form-elements.styles';

export function getStyles(element: IElement): JssStyle {
    const elementData: ISubscriptionFormElementData = element.data;
    const buttonClasses = textButtonStyles(element);

    return {
        ...formElementsStyles(elementData),
        ...buttonClasses,
        modal: {
            width: '100%',
            maxWidth: '420px',
            padding: '30px',
            background: '#FFFFFF',
            filter: 'drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.18))',
            borderRadius: '10px',
        },
        modalHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            '& h2': {
                ...h2Styles,
                color: simpleColorCss(element.data.textColor),
            },
            '& button': {
                position: 'absolute',
                top: '0',
                right: '0',
                fontSize: h2Styles.fontSize,
                lineHeight: h2Styles.fontSize,
                color: '#CBCBCB',
                background: 'none',
                border: 'none',
                '&:hover': {
                    color: '#9d9d9d',
                    cursor: 'pointer',
                },
            },
        },
        modalBody: {
            margin: '10px 0',
            '& h4': {
                ...h4Styles,
                color: simpleColorCss(element.data.textColor),
            },
            '& p': {
                ...pStyles,
                color: simpleColorCss(element.data.textColor),
            },
            '& .price-value': {
                ...pStyles,
                fontWeight: 'bold',
                color: simpleColorCss(element.data.textColor),
            },
            '& .modal-divider': {
                display: 'block',
                width: '100%',
                height: '1px',
                margin: '25px 0',
                background: simpleColorCss(ESimpleThemeColorNames.primary),
            },
            '& .min-amount-control': {
                '& .input-group': {
                    width: '240px',
                    position: 'relative',
                    display: 'table',
                    borderCollapse: 'separate',
                    '& input': {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                    '& .input-group-addon': {
                        padding: '8px 12px',
                        fontSize: '15px',
                        fontWeight: 400,
                        lineHeight: 1,
                        borderWidth: '1px',
                        boxSizing: 'border-box',
                        borderStyle: 'solid',
                        whiteSpace: 'nowrap',
                        borderTopWidth: '1px',
                        borderRadius: '4px',
                        borderColor: '#cccccc',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        display: 'table-cell',
                        width: '1%',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                    },
                },
            },
            '& .form-content': {
                paddingBottom: '15px',
                // '& label': pStyles.fontSize,
            },
            '& .payments': {
                '& label': {
                    fontSize: pStyles.fontSize,
                    fontFamily: pStyles.fontFamily,
                    color: simpleColorCss(element.data.textColor),
                },
                '& .payment-elements': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    marginRight: '-5px',
                    marginLeft: '-5px',
                    '& .payment-element': {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '82px',
                        height: '50px',
                        border: '1px solid #E6ECEC',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginRight: '5px',
                        marginLeft: '5px',
                        marginBottom: '10px',
                        padding: '0 12px',
                        '& img.payment-logo': {
                            width: '70px',
                        },
                    },
                },
                '& .selected': {
                    boxShadow: '0 0 0 1px #31c178, 0 0 0px 3px rgba(49, 193, 120, 0.3)',
                },
                '& .alert': {
                    marginBottom: '0',
                },
            },
            '& .disabled': {
                opacity: '0.6',
                pointerEvents: 'none',
            },
        },
        form: {
            color: simpleColorCss(element.data.textColor),
            fontFamily: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontFamily),
            fontSize: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontSize),
        },
        pointerEventsNone: {
            pointerEvents: 'none',
        },
    };
}
