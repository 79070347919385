<div class="form-group mb-20">
    <label class="col-xs-10 control-label text-left">
        {{ 'control_text_color' | translate }}
    </label>
    <div class="col-xs-2">
        <sp-color-picker
            hideGradient
            [color]="colorValue"
            [colorsPreset]="colorsPreset"
            (colorChangeEvent)="setColor($event)"
        ></sp-color-picker>
    </div>
</div>
<div class="form-group mb-20">
    <label class="col-xs-10 control-label text-left">
        {{ 'control_background_color' | translate }}
    </label>
    <div class="col-xs-2">
        <sp-color-picker
            hideGradient
            [color]="backgroundValue"
            [colorsPreset]="colorsPreset"
            (colorChangeEvent)="setBackground($event)"
        ></sp-color-picker>
    </div>
</div>
<div class="form-group">
    <label class="col-xs-12">
        {{ 'control_contour' | translate }}
    </label>
    <div class="col-xs-4">
        <sp-input-num
            suffixText="px"
            [min]="BORDER_WIDTH_MIN"
            [max]="BORDER_WIDTH_MAX"
            [value]="borderWidth.value"
            (valueChange)="setBorderWidth($event)"
            (blurEvent)="setBorderWidth($event)"
        ></sp-input-num>
    </div>
    <div class="col-xs-6">
        <sp-input-num
            prefixImgUrl="./assets/img/border-radius-icon.svg"
            suffixText="px"
            [min]="BORDER_RADIUS_MIN"
            [max]="BORDER_RADIUS_MAX"
            [value]="borderRadius.value"
            (valueChange)="setBorderRadius($event)"
            (blurEvent)="setBorderRadius($event)"
        ></sp-input-num>
    </div>
    <div class="col-xs-2">
        <sp-color-picker
            hideGradient
            [positionLeft]="true"
            [color]="borderColorValue"
            [colorsPreset]="colorsPreset"
            (colorChangeEvent)="setBorderColor($event)"
        ></sp-color-picker>
    </div>
</div>
<ng-container *ngIf="showHoverSettings">
    <div class="form-group">
        <label class="col-xs-12">
            {{ 'control_hover_effect' | translate }}
        </label>
        <div [ngClass]="{'col-xs-8': hoverType.value === EButtonHoverType.opacity, 'col-xs-12': hoverType.value !== EButtonHoverType.opacity}">
            <select class="form-control" (change)="changeHoverOptions($event)">
                <option
                    *ngFor="let option of buttonHoverOptions"
                    [value]="option.value"
                    [selected]="option.value === hoverType.value">
                    {{ option.label | translate }}
                </option>
            </select>
        </div>
        <div
            *ngIf="hoverType.value === EButtonHoverType.opacity"
            class="col-xs-4">
            <sp-input-num
                suffixText="%"
                [min]="OPACITY_MIN"
                [max]="OPACITY_MAX"
                [value]="opacity.value"
                (valueChange)="setOpacity($event)"
                (blurEvent)="setOpacity($event)"
            ></sp-input-num>
        </div>
    </div>

    <div
        *ngIf="hoverType.value === EButtonHoverType.color"
        class="alternative-color-settings">
        <div class="form-group mb-10">
            <label class="col-xs-9 control-label">
                {{ 'control_text_color' | translate }}
            </label>
            <div class="col-xs-3">
                <sp-color-picker
                    hideGradient
                    [color]="hoverColorValue"
                    [colorsPreset]="colorsPreset"
                    (colorChangeEvent)="setHoverColor($event)"
                ></sp-color-picker>
            </div>
        </div>
        <div class="form-group mb-10">
            <label class="col-xs-9 control-label">
                {{ 'control_background_color' | translate }}
            </label>
            <div class="col-xs-3">
                <sp-color-picker
                    hideGradient
                    [color]="hoverBackgroundValue"
                    [colorsPreset]="colorsPreset"
                    (colorChangeEvent)="setHoverBackground($event)"
                ></sp-color-picker>
            </div>
        </div>
        <div class="form-group">
            <label class="col-xs-9 control-label">
                {{ 'control_contour_color' | translate }}
            </label>
            <div class="col-xs-3">
                <sp-color-picker
                    hideGradient
                    [color]="hoverBorderColorValue"
                    [colorsPreset]="colorsPreset"
                    (colorChangeEvent)="setHoverBorderColor($event)"
                ></sp-color-picker>
            </div>
        </div>
    </div>
</ng-container>

