import { Input, Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { DeviceMode } from '@common/types/layout.type';

@Component({
    selector: 'sp-device-toggle',
    templateUrl: './sp-device-toggle.component.html',
    styleUrls: ['./sp-device-toggle.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpDeviceToggleComponent {
    @Input() public isDesktop: boolean = false;
    @Input() public isMobile: boolean = false;

    @Output() public changeDeviceMode: EventEmitter<DeviceMode> = new EventEmitter();

    public readonly deviceMode = DeviceMode;
}
