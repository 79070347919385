<div class="sp-timer-group" [class]="classes?.element">
    <div *ngIf="!isExpiredTime" [class]="classes?.wrapColumns" [class.mobileWidgetViewWrap]="isDeviceMobile">
        <div *ngIf="hasColumn('days')" class="sp-timer-item sp-timer-days" [class]="classes?.wrapColumn">
            <div [class]="classes?.column">
                <span [class]="classes?.value" [class.mobileWidgetViewElement]="isDeviceMobile">{{ remainingTime?.days }}</span>
                <span [class]="classes?.label" [class.mobileWidgetViewElement]="isDeviceMobile">{{ label(ETime.days) }}</span>
            </div>
            <div class="separator" [class]="classes?.separator"></div>
        </div>
        <div *ngIf="hasColumn('hours')" class="sp-timer-item sp-timer-hours" [class]="classes?.wrapColumn">
            <div [class]="classes?.column">
                <span [class]="classes?.value" [class.mobileWidgetViewElement]="isDeviceMobile">{{ remainingTime?.hours}}</span>
                <span [class]="classes?.label" [class.mobileWidgetViewElement]="isDeviceMobile">{{ label(ETime.hours) }}</span>
            </div>
            <div class="separator" [class]="classes?.separator"></div>
        </div>
        <div *ngIf="hasColumn('minutes')" class="sp-timer-item sp-timer-minutes" [class]="classes?.wrapColumn">
            <div [class]="classes?.column">
                <span [class]="classes?.value" [class.mobileWidgetViewElement]="isDeviceMobile">{{ remainingTime?.minutes }}</span>
                <span [class]="classes?.label" [class.mobileWidgetViewElement]="isDeviceMobile">{{ label(ETime.minutes) }}</span>
            </div>
            <div class="separator" [class]="classes?.separator"></div>
        </div>
        <div *ngIf="hasColumn('seconds')" class="sp-timer-item sp-timer-seconds" [class]="classes?.wrapColumn">
            <div [class]="classes?.column">
                <span [class]="classes?.value" [class.mobileWidgetViewElement]="isDeviceMobile">{{ remainingTime?.seconds }}</span>
                <span [class]="classes?.label" [class.mobileWidgetViewElement]="isDeviceMobile">{{ label(ETime.seconds) }}</span>
            </div>
        </div>
    </div>
<!---->
    <div *ngIf="isExpiredTime"  class="sp-timer-expired" [class]="classes?.wrapColumns">
        <span *ngIf="timerData.expiredText" [class]="classes?.expiredText" [class.mobileWidgetViewElement]="isDeviceMobile">{{ timerData.expiredText }}</span>
        <span *ngIf="!timerData.expiredText" [class]="classes?.expiredText" [class.mobileWidgetViewElement]="isDeviceMobile">{{ 'timer_default_expired_text' | translate }}</span>
    </div>
</div>
