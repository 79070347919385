import { Pipe, type PipeTransform } from '@angular/core';
import { DomSanitizer, type SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'spSafeUrl',
})
export class SpSafeUrlPipe implements PipeTransform {
    constructor(private readonly sanitized: DomSanitizer) {}

    public transform(value: string): SafeUrl {
        if (!value) {
            return '';
        }

        return this.sanitized.bypassSecurityTrustUrl(value);
    }
}
