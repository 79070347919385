import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
    selector: 'web-builder-app',
    template: `
        <!-- <nav>
            <a routerLink="/">index</a>
            <br />
            <a routerLink="/321">321</a>
            <br />
            <a (click)="redirectTo('ssr-isr.stage.test')">ssr-isr.stage.test</a>
            <br />
            <a (click)="redirectTo('ssr-isr.stage.test/page')">ssr-isr.stage.test/page</a>
            <br />
            <a (click)="redirectTo('wqefwqef.sendpulse.online')">wqefwqef.sendpulse.online</a>

            <pre>
                {{ params$ | async | json }}
            </pre
            >
        </nav> -->
        <router-outlet />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterOutlet, RouterLink, RouterLinkActive],
})
export class RootComponent implements OnInit {
    private readonly router: Router = inject(Router);

    ngOnInit(): void {
        console.log(this.router.url);
        // console.log(this.activatedRoute);
    }

    redirectTo(uri: string) {
        //     this.router.routeReuseStrategy.shouldReuseRoute = () => {
        //         return false;
        //     };
        //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //         this.router.navigate([uri]);
        //     });
    }
}
