import type { ThemeButton, ThemeColor, ThemeSettingsState } from '@libs/themes';
import {
    formatSimpleButtonValues,
    formatSimpleColorsValues,
    formatSimpleFontsValues,
    formatNextSimpleButtons,
} from '@libs/themes/src/variants/simple/utils/formatter';

import { AbstractThemeFormatter } from './abstract-theme-formatter';

export class SimpleThemeFormatter extends AbstractThemeFormatter {
    public formatThemeColors(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatSimpleColorsValues(values, theme.colors);
    }

    public formatThemeFonts(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatSimpleFontsValues(values, theme.fonts);
    }

    public formatThemeButtons(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatSimpleButtonValues(values, theme.buttons, theme.fonts);
    }

    public formatNextButtonSettings(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[] {
        return formatNextSimpleButtons(colors, buttons);
    }
}
