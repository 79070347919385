import type { ThemeSettingsState } from './theme-settings.reducer';
import type { ReverseThemeColorsKeyValue, ThemeColor, ThemeSelectedColors } from '@libs/themes/src/types/theme-color.type';
import type { ThemeFont, ThemeSelectedFonts } from '@libs/themes/src/types/theme-font.type';
import type { ThemeButton, ThemeSelectedButtons } from '@libs/themes/src/types/theme-button.type';
import type { ThemeFeatureState } from '@libs/themes/src/store/theme-feature.reducer';

import { createSelector } from '@ngrx/store';

import { selectThemeState } from '../theme-state.selector';
import { THEME_SETTINGS_STORE_KEY } from './theme-settings.reducer';

export const selectThemeSettingsState = createSelector(
    selectThemeState,
    (state: ThemeFeatureState): ThemeSettingsState => state[THEME_SETTINGS_STORE_KEY],
);

// START BUTTONS
export const selectThemeButtons = createSelector(
    selectThemeSettingsState,
    (state: ThemeSettingsState): ThemeSelectedButtons => state.buttons,
);

export const selectSelectedThemeButtons = createSelector(
    selectThemeButtons,
    (buttons: ThemeSelectedButtons): ThemeButton[] => buttons.selected,
);

export const selectSelectedThemeButtonIndex = createSelector(
    selectThemeButtons,
    (buttons: ThemeSelectedButtons): string => buttons.selectedIndex,
);
// END BUTTONS

// START COLORS
export const selectThemeColors = createSelector(selectThemeSettingsState, (state: ThemeSettingsState): ThemeSelectedColors => state.colors);

export const selectSelectedThemeColors = createSelector(
    selectThemeColors,
    (colors: ThemeSelectedColors): Array<ThemeColor> => colors.selected,
);

export const selectSelectedThemeColorIndex = createSelector(
    selectThemeColors,
    (colors: ThemeSelectedColors): string => colors.selectedIndex,
);

export const selectSelectedThemeColorsValues = createSelector(
    selectSelectedThemeColors,
    (colors: ThemeColor[]): Array<string> => colors.map((color) => color.value.toLowerCase()),
);

export const selectSelectedThemeColorsNames = createSelector(
    selectSelectedThemeColors,
    (colors: ThemeColor[]): Array<string> => colors.map((color) => color.name),
);

export const selectReversedSelectedThemeColorsKeyValue = createSelector(
    selectSelectedThemeColors,
    (colors: ThemeColor[]): ReverseThemeColorsKeyValue => {
        const result: ReverseThemeColorsKeyValue = {};
        colors.forEach((color: ThemeColor) => (result[color.value.toLowerCase()] = color.name));
        return result;
    },
);
// END COLORS

// START FONTS
export const selectThemeFonts = createSelector(selectThemeSettingsState, (state: ThemeSettingsState): ThemeSelectedFonts => state.fonts);

export const selectSelectedThemeFonts = createSelector(selectThemeFonts, (fonts: ThemeSelectedFonts): ThemeFont[] => fonts.selected);

export const selectSelectedThemeFontIndex = createSelector(selectThemeFonts, (fonts: ThemeSelectedFonts): string => fonts.selectedIndex);
// END FONTS

export const selectThemeRoot = createSelector(selectThemeSettingsState, (state: any): any => state?.root ?? null);
