import '@total-typescript/ts-reset';
import type { ThemeFont, ThemeSelectedFonts } from '@libs/themes/src/types/theme-font.type';
import type { ThemeColor, ThemeSelectedColors } from '@libs/themes/src/types/theme-color.type';
import type { ThemeButton, ThemeSelectedButtons } from '@libs/themes/src/types/theme-button.type';

import {
    WEBSITE_THEME_FONTS_VARIABLES,
    WEBSITE_THEME_BUTTONS_VARIABLES,
    WEBSITE_THEME_COLORS_VARIABLES,
    WEBSITE_THEME_BUTTONS_HOVER_VARIABLES,
} from '../constants/css-variables';
import type { EWebsiteThemeColorNames, EWebsiteThemeFontProperties } from '@common/libs/themes/public-api';
import type {
    EWebsiteThemeButtonHoverProperties,
    EWebsiteThemeButtonProperties,
} from '@common/libs/themes/src/variants/website/enums/button.enum';

export function formatWebsiteColorsValues(values: Record<string, string>, colors: ThemeSelectedColors | undefined): Record<string, string> {
    if (!colors) {
        return values;
    }

    return {
        ...values,
        ...colors.selected.reduce((colorValue: Record<string, string>, color: ThemeColor) => {
            if (!WEBSITE_THEME_COLORS_VARIABLES[color.name as EWebsiteThemeColorNames]) {
                return colorValue;
            }

            return {
                ...colorValue,
                [WEBSITE_THEME_COLORS_VARIABLES[color.name as EWebsiteThemeColorNames]]: color.value,
            };
        }, {}),
    };
}

export function formatWebsiteFontsValues(values: Record<string, string>, fonts: ThemeSelectedFonts | undefined): Record<string, string> {
    if (!fonts) {
        return values;
    }

    return {
        ...values,
        ...fonts.selected.reduce((fontValue: Record<string, string>, font: ThemeFont) => {
            if (!WEBSITE_THEME_FONTS_VARIABLES[font.tag]) {
                return fontValue;
            }
            const fontKeys = Object.keys(font.styles) as EWebsiteThemeFontProperties[];
            fontKeys.forEach((prop) => {
                if (!WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop] || !font.styles[prop]) {
                    return;
                }

                fontValue[WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop]] = font.styles[prop]!.toString();
            });

            if (font.mobileStyles) {
                const mobileFontKeys = Object.keys(font.mobileStyles) as EWebsiteThemeFontProperties[];
                mobileFontKeys.forEach((prop) => {
                    if (!WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop] || !font.mobileStyles?.[prop]) {
                        return;
                    }

                    const key = `${WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop]}-mobile`;
                    fontValue[key] = font.mobileStyles[prop]!?.toString();
                });
            }

            return fontValue;
        }, {}),
    };
}

export function formatWebsiteButtonValues(
    values: Record<string, string>,
    buttons: ThemeSelectedButtons | undefined,
): Record<string, string> {
    if (!buttons) {
        return values;
    }

    return {
        ...values,
        ...buttons.selected.reduce((buttonValues: Record<string, string>, button: ThemeButton) => {
            const buttonStyleTypeVariables = WEBSITE_THEME_BUTTONS_VARIABLES[button.styleType]!;
            const buttonHoverStyleTypeVariables = WEBSITE_THEME_BUTTONS_HOVER_VARIABLES[button.styleType];

            const buttonKeys = Object.keys(buttonStyleTypeVariables) as EWebsiteThemeButtonProperties[];
            buttonKeys.forEach((prop) => {
                const buttonStyleTypeVariable = buttonStyleTypeVariables[prop];
                const buttonBasePropValue = button.base?.[prop]!;

                if (Array.isArray(buttonStyleTypeVariable) && Array.isArray(buttonBasePropValue)) {
                    buttonStyleTypeVariable.forEach((variable: string, index: number) => {
                        const value = buttonBasePropValue[index] || buttonBasePropValue[0]!;

                        if (WEBSITE_THEME_COLORS_VARIABLES[value as EWebsiteThemeColorNames]) {
                            buttonValues[variable] = values[WEBSITE_THEME_COLORS_VARIABLES[value as EWebsiteThemeColorNames]]!;
                            return;
                        }

                        buttonValues[variable] = value;
                    });
                } else if (!Array.isArray(buttonStyleTypeVariable) && !Array.isArray(buttonBasePropValue)) {
                    buttonValues[buttonStyleTypeVariable] = buttonBasePropValue.toString();
                }
            });

            if (!button.hover) {
                return buttonValues;
            }

            const hoverKeys = Object.keys(buttonHoverStyleTypeVariables!) as EWebsiteThemeButtonHoverProperties[];
            hoverKeys.forEach((prop) => {
                const buttonHoverStyleTypeVariable = buttonHoverStyleTypeVariables?.[prop]!;
                const buttonHoverPropValue = button.hover?.[prop]!;

                if (Array.isArray(buttonHoverStyleTypeVariable) && Array.isArray(buttonHoverPropValue)) {
                    buttonHoverStyleTypeVariable.forEach((variable: string, index: number) => {
                        const value = buttonHoverPropValue[index] || buttonHoverPropValue[0]!;

                        if (WEBSITE_THEME_COLORS_VARIABLES[value as EWebsiteThemeColorNames]) {
                            buttonValues[variable] = values[WEBSITE_THEME_COLORS_VARIABLES[value as EWebsiteThemeColorNames]]!;
                            return;
                        }

                        buttonValues[variable] = value;
                    });
                } else if (!Array.isArray(buttonHoverStyleTypeVariable) && !Array.isArray(buttonHoverPropValue)) {
                    buttonValues[buttonHoverStyleTypeVariable] = buttonHoverPropValue.toString();
                }
            });

            return buttonValues;
        }, {}),
    };
}

export function formatNextWebsiteButtons(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[] {
    // const colorsObj: ThemeColorsKeyValue = colors.reduce((result: ThemeColorsKeyValue, color: ThemeColor) => ({ ...result, [color.name]: color.value }), {});

    return buttons.map((button: ThemeButton) => {
        const styles = Object.keys(button.base!).reduce((res, prop: string) => {
            return res;
            // const replacedProp = prop.replace(/Light|Dark/, '');
            // return {
            //     ...res,
            //     [replacedProp]: colorsObj[button.base[prop]],
            // };
        }, {});

        return {
            ...button,
            styles: {
                ...button.styles,
                ...styles,
            },
        };
    });
}
