<div class="well">
    <div class="form-group">
        <label>
            {{ 'category_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="categoryControl" />
    </div>
    <div class="form-group">
        <label>
            {{ 'action_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="actionControl" />
    </div>
    <div class="form-group">
        <label>
            {{ 'label_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="labelControl" />
    </div>
</div>
