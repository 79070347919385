export enum EFormInputType {
    input = 'input',
    name = 'name',
    email = 'email',
    phone = 'phone',
    address = 'address',
    date = 'date',
    url = 'url',
    question = 'question',
    hidden = 'hidden',
    file = 'file',
    number = 'number',
}

export enum EFormControlType {
    checkbox = 'checkbox',
    radio = 'radio',
    select = 'select',
    textarea = 'textarea',
}

export enum EHtmlInputType {
    email = 'email',
    tel = 'tel',
    url = 'url',
    date = 'date',
    text = 'text',
    checkbox = 'checkbox',
    radio = 'radio',
    select = 'select',
    hidden = 'hidden',
    textarea = 'textarea',
    file = 'file',
    number = 'number',
}

export enum EHtmlAutocompleteAttr {
    email = 'email',
    name = 'name',
    streetAddress = 'street-address',
    tel = 'tel',
    url = 'url',
    on = 'on',
    off = 'off',
}

export enum ESystemAddressBookVariables {
    email = 'email',
    phone = 'phone',
    gdprConfirm = 'gdprConfirm',
    gdprTerms = 'gdprTerms',
}

export enum EAttributeTypesCrm {
    text = 0,
    number = 1,
    date = 2,
    link = 3,
    select = 4,
    multiselect = 5,
    checkbox = 6,
    textarea = 7,
}

export enum EContactFieldsCrm {
    firstName = 'firstName',
    lastName = 'lastName',
}

export enum EVariablesTypes {
    crmDealAttribute = 'crmDealAttribute',
    crmContactAttribute = 'crmContactAttribute',
    emailAddressBook = 'emailAddressBook',
}
