export enum EThemeFontTags {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    p = 'p',
    button = 'button',
    leading = 'leading',
    big = 'big',
    small = 'small',
    quote = 'quote',
    signature = 'signature',
    pre = 'pre',
}
