import type { ThemeSelectedFonts } from '@common/libs/themes/src/types/theme-font.type';

import { FONT_FAMILY_NAMES } from '@common/libs/themes/src/constants/font-families.conts';

function formatFontsNames(fonts: ThemeSelectedFonts): string[] {
    if (!fonts?.selected) {
        return [];
    }

    const uniqueFontNames = new Set<string>();
    Object.keys(fonts.selected).forEach((key: string) => {
        const fontName = FONT_FAMILY_NAMES[fonts.selected[key].font];
        if (fontName) {
            uniqueFontNames.add(fontName);
        }
    });

    return [...uniqueFontNames];
}

function formatFontsLink(fonts: ThemeSelectedFonts): string {
    return formatFontsNames(fonts)
        .map((fontName: string) => {
            const name = fontName.replace(' ', '+');
            return `family=${name}:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&`;
        })
        .join('');
}

export function formatFontsLinkAsImport(fonts: ThemeSelectedFonts): string {
    return `@import url('https://fonts.googleapis.com/css2?${formatFontsLink(fonts)}display=swap');\n`;
}

export function formatFontsLinkAsLink(fonts: ThemeSelectedFonts): string {
    return `https://fonts.googleapis.com/css2?${formatFontsLink(fonts)}display=swap`;
}
