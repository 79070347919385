import { Widgets } from '@common/enums';
import { type BlockStructureEntity, type WebWidgetEntity } from '@web/types';
import { type MapWidgetData } from '@web/widgets/map';

export function getMapsApiKey(structure: BlockStructureEntity[]): string {
    const mapWidget = structure
        .flatMap((block) => block.sections)
        .flatMap((section) => section.columns)
        .flatMap((column) => column.widgets)
        .find((widget: WebWidgetEntity) => widget.name === Widgets.MAP) as WebWidgetEntity<MapWidgetData>;

    return mapWidget?.data.apiKey || '';
}
