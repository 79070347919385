import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseFacade } from '@common/services/base-facade.service';

import * as ThemeValuesSelectors from '@common/libs/themes/src/store/theme-values/theme-values.selectors';

@Injectable()
export class ThemeValuesFacade extends BaseFacade {
    public readonly themeValues$: Observable<Record<string, string>> = this.store$.select(ThemeValuesSelectors.selectThemeValuesState);
    public readonly themeValueByKey$ = (key: string): Observable<string> => this.store$.select(ThemeValuesSelectors.selectThemeValueByKey(key));
}
