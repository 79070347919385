import { type Route } from '@angular/router';

import { canMatchMLP, canMatchWeb } from '@web-builder/core/resolvers/website-resolver.router';

export const routes: Route[] = [
    {
        path: '**',
        canMatch: [canMatchWeb],
        loadComponent: () => import('./app.component').then((m) => m.AppComponent),
        data: { revalidate: 0 },
    },
    {
        path: '**',
        canMatch: [canMatchMLP],
        loadComponent: () => import('./components/mls/root/root.component').then((m) => m.MlsRootComponent),
        data: { revalidate: 0 },
    },
    // {
    //     path: '**',
    //     loadComponent: () => import('./components/not-found/not-found.component').then((m) => m.NotFoundComponent),
    // },
];
