export enum ERepeatOptions {
    noRepeat = 'no-repeat',
    repeatX = 'repeat-x',
    repeatY = 'repeat-y',
    repeat = 'repeat',
}

export interface IRepeatOptions {
    label: string;
    value: ERepeatOptions;
}

export const repeatOptions: IRepeatOptions[] = [
    {
        label: 'background_settings_repeat_no_repeat',
        value: ERepeatOptions.noRepeat,
    },
    {
        label: 'background_settings_repeat_horizontally',
        value: ERepeatOptions.repeatX,
    },
    {
        label: 'background_settings_repeat_vertically',
        value: ERepeatOptions.repeatY,
    },
    {
        label: 'background_settings_repeat_both',
        value: ERepeatOptions.repeat,
    },
];
