import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { SpTransparentIconModule } from '@ui/sp-transparent-icon';

import { RgbaToHexPipe } from './pipes/rgba-to-hex.pipe';

import { SpColorPickerComponent } from './sp-color-picker.component';
import { SpColorPickerDropdownComponent } from './sp-color-picker-dropdown/sp-color-picker-dropdown.component';
import { SpColorPickerPresetsComponent } from './sp-color-picker-presets/sp-color-picker-presets.component';
import { SpColorPickerCustomColorComponent } from './sp-color-picker-custom-color/sp-color-picker-custom-color.component';

const MODULES = [ColorPickerModule, CommonModule, FormsModule, ReactiveFormsModule, BsDropdownModule, TranslateModule, SpTransparentIconModule];

const COMPONENTS = [SpColorPickerComponent, SpColorPickerDropdownComponent, SpColorPickerPresetsComponent, SpColorPickerCustomColorComponent];

const PIPES = [RgbaToHexPipe];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS, ...PIPES],
    exports: [...COMPONENTS, ...PIPES],
})
export class SpColorPickerModule {}
