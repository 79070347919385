import type { EThemeSettings } from '@libs/themes/src/enums/theme-settings.enum';
import type { ThemeFeatureState } from '@libs/themes/src/store/theme-feature.reducer';
import type { ThemeConfigState } from './theme-config.reducer';

import { createSelector } from '@ngrx/store';
import { selectThemeState } from '../theme-state.selector';
import { THEME_CONFIG_STORE_KEY } from './theme-config.reducer';

const selectThemeConfigState = createSelector(
    selectThemeState,
    (state: ThemeFeatureState): ThemeConfigState => state[THEME_CONFIG_STORE_KEY],
);

export const selectThemeConfigLoaded = createSelector(
    selectThemeConfigState,
    (config: ThemeConfigState): boolean => config?.loaded ?? false,
);

export const selectActiveThemeSetting = createSelector(
    selectThemeConfigState,
    (config: ThemeConfigState): EThemeSettings => config?.activeThemeSetting ?? null,
);
