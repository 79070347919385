export const REG_EXP = {
    HEX_COLOR: /^#([0-9A-F]{6}|[0-9A-F]{3})$/i,
    COLOR: /(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi,
    URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,20}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    PHONE: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
    YOUTUBE_LINKS: /(?:youtu\.be\/|youtube\.com\/(?:embed\/|watch\?v=|v\/|shorts\/|live\/)|youtu\.be\/)([^\?\&\s]+)/,
    VIMEO_LINKS: /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/,
    FILE_EXTENSION: /\.[^/.]+$/,
    NUMBER: /^\d*$/,
    // mls contants
    IMG_SRC: /.\/assets\/img/i,
    YOUTUBE_LINK_ID: /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/,
    VIMEO_LINK_ID: /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,

    // ml contants

    YOUTUBE_LINKS_ML: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
};
