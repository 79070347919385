import { ChangeDetectionStrategy, Component } from '@angular/core';

import { getStyles } from '@app/features/line/components/line-view/line-view.component.styles';
import { ILineElementData } from '@app/features/line/interface/ILineElement';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-line-feature',
    templateUrl: './line-feature.component.html',
    styleUrls: ['./line-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class LineFeatureComponent extends BaseFeatureComponent<ILineElementData> {
    protected getStyles = getStyles;
}
