export enum ModalStates {
    signUp = 'signUp',
    courseSignUp = 'courseSignUp',
    verifyEmail = 'verifyEmail',
    signIn = 'signIn',
    signInSuccess = 'signInSuccess',
    passwordRecovery = 'passwordRecovery',
    passwordRecoverySuccess = 'passwordRecoverySuccess',
    changePassword = 'changePassword',
}
