export enum EChatBotsMessengers {
    instagram = 'instagram',
    telegram = 'telegram',
    messenger = 'messenger',
    facebook = 'facebook',
    snapchat = 'snapchat',
    whatsapp = 'whatsapp',
    viber = 'viber',
    skype = 'skype',
    vk = 'vk',
    ok = 'ok',
    line = 'line',
    kik = 'kik',
    email = 'email',
    phone = 'phone',
}
