import { combineReducers } from '@ngrx/store';

import { type ThemeConfigState, THEME_CONFIG_STORE_KEY, themeConfigReducer } from './theme-config/theme-config.reducer';
import { type ThemePresetsState, THEME_PRESETS_STORE_KEY, themePresetsReducer } from './theme-presets/theme-presets.reducer';
import { type ThemeSettingsState, THEME_SETTINGS_STORE_KEY, themeSettingsReducer } from './theme-settings/theme-settings.reducer';
import { type ThemeValuesState, THEME_VALUES_STORE_KEY, themeValuesReducer } from './theme-values/theme-values.reducer';

export interface ThemeFeatureState {
    [THEME_CONFIG_STORE_KEY]: ThemeConfigState;
    [THEME_PRESETS_STORE_KEY]: ThemePresetsState;
    [THEME_SETTINGS_STORE_KEY]: ThemeSettingsState;
    [THEME_VALUES_STORE_KEY]: ThemeValuesState;
}

export const THEME_FEATURE_STORE_KEY = 'theme';

export const themeFeatureReducer = combineReducers<ThemeFeatureState>({
    [THEME_CONFIG_STORE_KEY]: themeConfigReducer,
    [THEME_PRESETS_STORE_KEY]: themePresetsReducer,
    [THEME_SETTINGS_STORE_KEY]: themeSettingsReducer,
    [THEME_VALUES_STORE_KEY]: themeValuesReducer,
});
