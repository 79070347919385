<div
    dropdown
    #dropdown="bs-dropdown"
    [insideClick]="true"
    class="spc-color-picker-button form-control pull-right"
>
    <span
        dropdownToggle
        class="spc-color-picker-button-area"
        [style.background]="colorValue"
    >
        <sp-transparent-icon
            [color]="colorValue"
        ></sp-transparent-icon>
    </span>

    <div
        *dropdownMenu
        class="dropdown-menu color-picker-dropdown"
        [class.dropdown-menu-right]="!positionLeft"
    >
        <sp-color-picker-dropdown
            *ngIf="dropdown.isOpen"
            [color]="colorValue?.toLowerCase()"
            [colorsPreset]="colorsPreset"
            [hideGradient]="hideGradient"
            (setColorEvent)="setColorHandler($event)"
            (setColorPresetEvent)="setColorPresetHandler($event)"
            (close)="dropdown.hide()"
        ></sp-color-picker-dropdown>
    </div>
</div>
