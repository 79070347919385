import { type JssStyle } from 'jss';
import { indentSizeStyles } from '@app/features/indent/constants/indent.constants';
import { type IElement } from '@app/features/common/interfaces/IElement';
import { type IIndentElementData } from '@app/features/indent/interface/IIndentElement';

export function getStyles(element: IElement): JssStyle {
    const data: IIndentElementData = element.data;

    const { height } = indentSizeStyles[data.size];
    return {
        element: {
            height,
        },
    };
}
