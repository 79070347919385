<div class="theme-settings">
    <form [formGroup]="form" class="form form-horizontal form-row">
        <div class="form-group" *ngFor="let colorName of colorNames" >
            <label class="col-xs-10 control-label">{{ COLOR_NAME_TRANSLATIONS[colorName] | translate }}</label>
            <div class="col-xs-2">
                <sp-color-picker
                    [color]="form.get(colorName).value"
                    (colorChangeEvent)="onChangeColor(colorName, $event)">
                </sp-color-picker>
            </div>
        </div>
    </form>
    <div class="form-footer">
        <button
            type="button"
            class="btn btn-default mr-10"
            (click)="onCancel()"
        >
            {{ 'cancel' | translate }}
        </button>
        <button
            type="button"
            class="btn btn-success"
            (click)="onSave()"
        >
            {{ 'save' | translate }}
        </button>
    </div>
</div>

