import { bootstrapApplication } from '@angular/platform-browser';

import { appConfig } from '@web-builder/app.config';
import { RootComponent } from './app/root.component';

function bootstrap() {
    bootstrapApplication(RootComponent, appConfig).catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
