import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpTransparentIconComponent } from './sp-transparent-icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [SpTransparentIconComponent],
    exports: [SpTransparentIconComponent],
})
export class SpTransparentIconModule {}
