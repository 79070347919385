import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { JssStyle } from 'jss';

import { ThemeButton } from '@common/libs/themes/src/types/theme-button.type';
import { EButtonStyleType } from '@common/enums';

import { getStyles } from './simple-button-preset.component.styles';

@Component({
    selector: 'sp-simple-button-preset',
    templateUrl: './simple-button-preset.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSimpleButtonPresetComponent implements OnInit {
    @Input() type: string;
    @Input() button: ThemeButton;
    @Input() selected: boolean;

    public styles: JssStyle;
    public readonly buttonStyleTypeTranslations: Readonly<Record<EButtonStyleType, string>> = {
        [EButtonStyleType.primary]: 'control_appearance_primary',
        [EButtonStyleType.secondary]: 'control_appearance_secondary',
        [EButtonStyleType.custom]: 'control_appearance_custom',
        [EButtonStyleType.classic]: 'control_appearance_classic',
    };

    public ngOnInit(): void {
        this.styles = getStyles(this.button.styles, this.selected, this.type, this.button.data);
    }
}
