import type { Observable } from 'rxjs';
import type { ThemeColor, ThemeColorPreset, ThemeSelectedColors } from '@common/libs/themes/src/types/theme-color.type';
import type { ThemeButton, ThemeButtonPreset, ThemeSelectedButtons } from '@common/libs/themes/src/types/theme-button.type';
import type { ThemeFont, ThemeFontPreset, ThemeSelectedFonts } from '@common/libs/themes/src/types/theme-font.type';
import type { ThemeSettingsState } from '@libs/themes/src/store/theme-settings/theme-settings.reducer';

import { Injectable } from '@angular/core';
import { BaseFacade } from '@common/services/base-facade.service';

import ThemeSettingsActions from './theme-settings.actions';
import * as ThemeSettingsSelectors from './theme-settings.selectors';

@Injectable()
export class ThemeSettingsFacade extends BaseFacade {
    public readonly themeSettings$: Observable<ThemeSettingsState> = this.store$.select(ThemeSettingsSelectors.selectThemeSettingsState);
    public readonly selectedThemeColors$: Observable<ThemeColor[]> = this.store$.select(ThemeSettingsSelectors.selectSelectedThemeColors);
    public readonly selectedThemeColorIndex$: Observable<string> = this.store$.select(ThemeSettingsSelectors.selectSelectedThemeColorIndex);
    public readonly selectedThemeButtons$: Observable<ThemeButton[]> = this.store$.select(
        ThemeSettingsSelectors.selectSelectedThemeButtons,
    );
    public readonly selectedThemeButtonIndex$: Observable<string> = this.store$.select(
        ThemeSettingsSelectors.selectSelectedThemeButtonIndex,
    );
    public readonly selectedThemeFonts$: Observable<ThemeFont[]> = this.store$.select(ThemeSettingsSelectors.selectSelectedThemeFonts);
    public readonly selectedThemeFontIndex$: Observable<string> = this.store$.select(ThemeSettingsSelectors.selectSelectedThemeFontIndex);
    public readonly themeButtons$: Observable<ThemeSelectedButtons> = this.store$.select(ThemeSettingsSelectors.selectThemeButtons);
    public readonly themeFonts$: Observable<ThemeSelectedFonts> = this.store$.select(ThemeSettingsSelectors.selectThemeFonts);
    public readonly themeColors$: Observable<ThemeSelectedColors> = this.store$.select(ThemeSettingsSelectors.selectThemeColors);

    /*
    // @deprecated
     */
    public readonly themeRoot$: Observable<any> = this.store$.select(ThemeSettingsSelectors.selectThemeRoot);

    public changeColorsAction(colors: ThemeColorPreset): void {
        this.store$.dispatch(
            ThemeSettingsActions.changeColors({
                colors: {
                    selected: colors.values,
                    selectedIndex: colors.index,
                },
            }),
        );
    }

    public setColorsAction(colors: ThemeSelectedColors): void {
        this.store$.dispatch(ThemeSettingsActions.setColors({ colors }));
    }

    public changeButtonsAction(buttons: ThemeButtonPreset): void {
        this.store$.dispatch(
            ThemeSettingsActions.changeButtons({
                buttons: {
                    selected: buttons.values,
                    selectedIndex: buttons.index,
                },
            }),
        );
    }

    public setButtonsAction(buttons: ThemeSelectedButtons): void {
        this.store$.dispatch(ThemeSettingsActions.setButtons({ buttons }));
    }

    public changeFontsAction(fonts: ThemeFontPreset, isSynchronized: boolean = true): void {
        this.store$.dispatch(
            ThemeSettingsActions.changeFonts({
                fonts: {
                    selected: fonts.values,
                    selectedIndex: fonts.index,
                    isSynchronized,
                },
            }),
        );
    }

    public changeFontsSynchronizationAction(isSynchronized: boolean): void {
        this.store$.dispatch(ThemeSettingsActions.changeFontsSynchronization({ isSynchronized }));
    }

    public setFontsAction(fonts: ThemeSelectedFonts): void {
        this.store$.dispatch(ThemeSettingsActions.setFonts({ fonts }));
    }

    public updateRoot(root: any): void {
        this.store$.dispatch(ThemeSettingsActions.updateRoot({ root }));
    }
}
