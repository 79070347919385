import { EGallerySliderSpeeds, EGalleryTypes } from '../enum/gallery.enum';
import { type IGalleryElementData } from '../interface/IGalleryElement';

// DEFAULT COMPONENT SETTINGS
export const galleryData: IGalleryElementData = {
    type: EGalleryTypes.image,
    image: {
        name: null,
        url: null,
        alt: null,
        link: null,
        description: null,
        status: null,
        imageRound: null,
        textSize: null,
        textPosition: null,
        textColor: null,
        textBackground: null,
        defaultCdnUrl: null,
        sendpulseCdn: null,
        imagePosition: null,
    },
    video: {
        previewUrl: null,
        type: null,
        url: null,
    },
    slider: {
        sliderSpeed: null,
        uploadedFiles: null,
        sliderRound: null,
        sliderHeightType: null,
        height: null,
    },
};

export const galleryBlankPicture = './assets/img/elements/gallery-blank.svg';
// END DEFAULT SETTINGS

export const gallerySliderSpeeds = {
    [EGallerySliderSpeeds.manual]: null,
    [EGallerySliderSpeeds.slow]: 3000, // INTERVAL TIME IN MS
    [EGallerySliderSpeeds.middle]: 2000,
    [EGallerySliderSpeeds.high]: 1000,
};
