import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type FormControl } from '@angular/forms';
import { EPixelTypes, PixelSettingsFormGroupType } from '@app/features/common/components/analytics-settings';

@Component({
    selector: 'app-pixel-settings',
    templateUrl: './pixel-settings.component.html',
    styleUrls: ['./pixel-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PixelSettingsComponent {
    @Input() public pixelSettings: PixelSettingsFormGroupType;

    options = Object.values(EPixelTypes);

    public get type(): FormControl<EPixelTypes> {
        return this.pixelSettings.get('type') as FormControl<EPixelTypes>;
    }

    protected readonly EPixelTypes = EPixelTypes;
}
