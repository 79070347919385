import type { ThemeSelectedColors } from '@libs/themes/src/types/theme-color.type';
import type { ThemeSelectedFonts } from '@libs/themes/src/types/theme-font.type';
import type { ThemeSelectedButtons } from '@libs/themes/src/types/theme-button.type';

import { createActionGroup, props } from '@ngrx/store';

const ThemeSettingsActions = createActionGroup({
    source: 'ThemeSettings',
    events: {
        'Set Initial Theme': props<{ buttons: ThemeSelectedButtons; colors: ThemeSelectedColors; fonts: ThemeSelectedFonts }>(),
        'Change Colors': props<{ colors: ThemeSelectedColors }>(),
        'Update Colors': props<{ colors: ThemeSelectedColors }>(),
        'Set Colors': props<{ colors: ThemeSelectedColors }>(),
        'Change Buttons': props<{ buttons: ThemeSelectedButtons }>(),
        'Update Buttons': props<{ buttons: ThemeSelectedButtons }>(),
        'Set Buttons': props<{ buttons: ThemeSelectedButtons }>(),
        'Change Fonts': props<{ fonts: ThemeSelectedFonts }>(),
        'Update Fonts': props<{ fonts: ThemeSelectedFonts }>(),
        'Change Fonts Synchronization': props<{ isSynchronized: ThemeSelectedFonts['isSynchronized'] }>(),
        'Set Fonts': props<{ fonts: ThemeSelectedFonts }>(),
        'Update Root': props<{ root: any }>(),
    },
});

export default ThemeSettingsActions;
