import type { ThemeColorPreset } from '@common/libs/themes/src/types/theme-color.type';
import type { ThemeFontPreset } from '@common/libs/themes/src/types/theme-font.type';
import type { ThemeButtonPreset } from '@common/libs/themes/src/types/theme-button.type';

import { createActionGroup, props } from '@ngrx/store';

const ThemePresetsActions = createActionGroup({
    source: 'ThemePresets',
    events: {
        'Set Initial Theme Presets': props<{ buttons: ThemeButtonPreset[]; colors: ThemeColorPreset[]; fonts: ThemeFontPreset[] }>(),
        'Change Colors Presets': props<{ colors: ThemeColorPreset[] }>(),
        'Change Buttons Presets': props<{ buttons: ThemeButtonPreset[] }>(),
        'Change Fonts Presets': props<{ fonts: ThemeFontPreset[] }>(),
    },
});

export default ThemePresetsActions;
