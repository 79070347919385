import { ThemeFont } from '@common/libs/themes';
import { getValidFontSize, getValidLetterSpacing, getValidLineHeight } from '@common/helpers';

export function convertFontValuesFromStrToInt(font: ThemeFont): ThemeFont {
    const convertedFont = {
        ...font,
        styles: {
            ...font.styles,
            fontSize: parseInt(font.styles.fontSize as string, 10),
            lineHeight: parseInt(font.styles.lineHeight as string, 10),
            letterSpacing: parseInt(font.styles.letterSpacing as string, 10),
        },
    };

    if (font.mobileStyles) {
        convertedFont.mobileStyles = {
            ...font.mobileStyles,
            fontSize: parseInt(font.mobileStyles.fontSize as string, 10),
            lineHeight: parseInt(font.mobileStyles.lineHeight as string, 10),
            letterSpacing: parseInt(font.mobileStyles.letterSpacing as string, 10),
        };
    };

    return convertedFont;
}

export function convertFontValuesFromIntToStr(font: ThemeFont): ThemeFont {
    const convertedFont = {
        ...font,
        styles: {
            ...font.styles,
            fontSize: `${getValidFontSize(font.styles.fontSize as number)}px`,
            lineHeight: `${getValidLineHeight(font.styles.lineHeight as number)}%`,
            letterSpacing: `${getValidLetterSpacing(font.styles.letterSpacing as number)}px`,
        },
    };

    if (font.mobileStyles) {
        convertedFont.mobileStyles = {
            ...font.mobileStyles,
            fontSize: `${getValidFontSize(font.mobileStyles.fontSize as number)}px`,
            lineHeight: `${getValidLineHeight(font.mobileStyles.lineHeight as number)}%`,
            letterSpacing: `${getValidLetterSpacing(font.mobileStyles.letterSpacing as number)}px`,
        };
    }

    return convertedFont;
}
