import { Injectable, inject } from '@angular/core';
import { type HttpEvent, type HttpInterceptor, HttpClient, type HttpHandler, type HttpRequest, HttpContext } from '@angular/common/http';

import { from, lastValueFrom, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SOA_ID, SOA_SECRET, SOA_URL } from '@common/tokens';
import { SKIP_AUTH } from '@web-builder/core/interceptors/jwt.interceptor';

let soaToken = '';
@Injectable()
export class SoaInterceptor implements HttpInterceptor {
    private readonly http: HttpClient = inject(HttpClient);
    private readonly oauthSoaId: string = inject(SOA_ID, { optional: true }) || '';
    private readonly oauthSoaSecret: string = inject(SOA_SECRET, { optional: true }) || '';
    private readonly oauthSoaUrl: string = inject(SOA_URL, { optional: true }) || '';

    private readonly skipAuthUrls: string[] = [`${this.oauthSoaUrl}/authorization/client`];

    private date = new Date();

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next));
    }

    private async handle(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.includes('/soa/') && !this.urlIsInSkipAuthUrls(request.url)) {
            console.log('intercepting request');
            const token = await lastValueFrom(this.getToken(request.url));
            console.log('token inside interceptor', token);
            request = request.clone({
                setHeaders: {
                    Authorization: token,
                },
            });
        }

        return lastValueFrom(next.handle(request));
    }

    private getToken(url: string) {
        console.log(url);
        if (!soaToken) {
            console.log('no token so fetching');
            return this.fetchToken();
        }

        const [, payload, _] = soaToken.split('.');
        const decodedPayload = JSON.parse(atob(payload)) as { exp: number };

        if (Date.now() <= decodedPayload.exp * 1000) {
            console.log('validated token return');
            return of(soaToken);
        }
        console.log(Date.now(), decodedPayload.exp * 1000);
        console.log('failed to validate');
        return this.fetchToken();
    }

    private fetchToken() {
        console.log('fetching token');
        return this.http
            .post(
                `http://${this.oauthSoaUrl}/authorization/client`,
                {
                    grant_type: 'client_credentials',
                    client_id: this.oauthSoaId,
                    client_secret: this.oauthSoaSecret,
                    scope: 'soa',
                },
                {
                    context: new HttpContext().set(SKIP_AUTH, true),
                },
            )
            .pipe(
                map((res: any) => {
                    console.log(res);
                    console.log('token was fetched');
                    soaToken = res.token_type + ' ' + res.access_token;
                    return soaToken;
                }),
            );
    }

    private urlIsInSkipAuthUrls(url: string): boolean {
        for (let i = 0; i < this.skipAuthUrls.length; i++) {
            if (url.includes(this.skipAuthUrls[i])) {
                console.log('url is in skip auth urls');
                return true;
            }
        }

        console.log('url is not in skip auth urls');
        return false;
    }
}
