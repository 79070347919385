export enum ESocialNetworks {
    facebook = 'facebook',
    twitter = 'twitter',
    instagram = 'instagram',
    youtube = 'youtube',
    telegram = 'telegram',
    snapchat = 'snapchat',
    vk = 'vk',
    ok = 'ok',
    viber = 'viber',
    linkedin = 'linkedin',
    pinterest = 'pinterest',
    vimeo = 'vimeo',
    slideshare = 'slideshare',
    tumblr = 'tumblr',
    medium = 'medium',
    tiktok = 'tiktok',
    yandexzen = 'yandexzen',
    whatsapp = 'whatsapp',
    spotify = 'spotify',
    twitch = 'twitch',
    messenger = 'messenger',
    skype = 'skype',
    onlyfans = 'onlyfans',
    patreon = 'patreon',
    buymeacoffee = 'buymeacoffee',
    soundcloud = 'soundcloud',
    email = 'email',
    phone = 'phone',
}
