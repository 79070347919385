import { createActionGroup, props } from '@ngrx/store';

import type { Domain } from '@common/types/domain.type';

export const DomainsActions = createActionGroup({
    source: 'Domains',
    events: {
        'Load domains request': props<{ siteId: string }>(),
        'Load domains success': props<{ domains: Domain[] }>(),
        'Load domains failure': props<{ error: string }>(),
    },
});

export default DomainsActions;
