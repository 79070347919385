import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Classes } from 'jss';

import { ELanguages } from '@common/enums';
import { PlatformService } from '@common/services/platform.service';

import { ContainerModel } from '@app/core/models/container-model';

import { TranslationsService } from '@web-builder/mls-core/services/translations.service';
import { EduServiceHttp } from '@web-builder/mls-core/services/edu-api.service';
import { UtilsService } from '@web-builder/mls-core/services/utils.service';

import { ModalStates } from '@web-builder/mls-widgets/edu-banner-feature/edu-banner-modal-feature/modal-states.enum';

import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { environment } from '@web-builder-env/environment';

type SiteInfo = {
    userId: number;
    siteId: number;
    pageId: number;
    domainId: number;
};
@Component({
    selector: 'mls-edu-banner-modal-feature',
    templateUrl: './edu-banner-modal-feature.component.html',
    styleUrls: ['./edu-banner-modal-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EduBannerModalFeatureComponent implements OnInit, OnDestroy {
    @HostListener('window:keydown.enter', ['$event'])
    handleEnterKeyDown() {
        this.onClickPrimaryBtn();
    }
    public container: ContainerModel;
    public classes: Classes;
    public form: UntypedFormGroup;
    public modalState: ModalStates;
    public modalStates = ModalStates;
    public serverErrorCode: string;
    public lang: ELanguages = this.mlpTranslationsService.getLanguageAsValue();
    public disableSendAgain: boolean;
    public showPassword: boolean = false;
    public showPoweredBy: boolean = this.transferStateService.get('showPoweredBy');
    public isPreview: boolean = this.transferStateService.isPreview;

    public passwordErrors: string[] = null;
    public loginError = null;

    private subscription: Subscription = new Subscription();
    private courseId: number = this.transferStateService.get('courseId');
    public resetPasswordData: Record<string, string>;

    public siteInfo: SiteInfo = {
        userId: this.transferStateService.get('userId'),
        siteId: this.transferStateService.get('siteId'),
        pageId: this.transferStateService.get('pageId'),
        domainId: this.transferStateService.get('domainId'),
    };

    public get verifyEmailError() {
        return this.loginError === 'error_email_is_not_verified';
    }

    public serverErrorCodes = {
        EMAIL_ALREADY_EXIST: 'EMAIL_ALREADY_EXIST',
        INVALID_PASSWORD: 'INVALID_PASSWORD',
        INVALID_NEW_PASSWORD: 'INVALID_NEW_PASSWORD',
        FORBIDDEN_ACCESS: 'FORBIDDEN_ACCESS',
        NEW_STUDENT_TARIFF_RESTRICTION: 'NEW_STUDENT_TARIFF_RESTRICTION',
        CONFIRM_NEW_PASSWORD_NOT_MATCH: 'CONFIRM_NEW_PASSWORD_NOT_MATCH',
        NOT_FOUND: 'NOT_FOUND',
    };

    public translations = {
        [ModalStates.signUp]: {
            modalHeaderTitle: 'sign_up_title',
            modalFooterSecondaryBtn: 'sign_in',
            modalFooterPrimaryBtn: 'sign_up_action',
        },
        [ModalStates.verifyEmail]: {
            modalHeaderTitle: 'verify_email',
            modalFooterPrimaryBtn: 'change_user',
        },
        [ModalStates.signIn]: {
            modalHeaderTitle: 'sign_in',
            modalFooterSecondaryBtn: 'forgot_password_question',
            modalFooterPrimaryBtn: 'sign_in',
        },
        [ModalStates.signInSuccess]: {
            modalHeaderTitle: 'sign_up_course',
            modalFooterSecondaryBtn: 'change_user',
            modalFooterPrimaryBtn: 'enroll',
        },
        [ModalStates.passwordRecovery]: {
            modalHeaderTitle: 'password_recovery',
            modalFooterPrimaryBtn: 'send',
        },
        [ModalStates.passwordRecoverySuccess]: {
            modalHeaderTitle: 'password_recovery',
            modalFooterPrimaryBtn: 'sign_in',
        },
        [ModalStates.changePassword]: {
            modalHeaderTitle: 'change_password',
            modalFooterPrimaryBtn: 'save',
        },
    };

    constructor(
        protected readonly platformService: PlatformService,
        private readonly bsModalRef: BsModalRef,
        private readonly fb: UntypedFormBuilder,
        private readonly eduServiceHttp: EduServiceHttp,
        private readonly mlpTranslationsService: TranslationsService,
        private readonly utilsService: UtilsService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        protected readonly transferStateService: TransferStateService,
        private readonly cookieService: CookieService,
    ) {}

    public ngOnInit(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        this.initForm();

        this.subscription.add(this.form.valueChanges.subscribe(() => this.resetServerErrors()));

        if (this.modalState === ModalStates.signInSuccess) {
            this.eduServiceHttp.getStudentInfo().subscribe((res: any) => {
                this.name.patchValue(`${res.firstName} ${res.lastName}`);
                this.email.patchValue(res.email);
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    protected initForm(): void {
        this.form = this.fb.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.email, Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
        });
    }

    public get name(): AbstractControl {
        return this.form.get('name');
    }

    public get email(): AbstractControl {
        return this.form.get('email');
    }

    public get password(): AbstractControl {
        return this.form.get('password');
    }

    public get confirmPassword(): AbstractControl {
        return this.form.get('confirmPassword');
    }

    public get isVisibleSecondaryBtn(): boolean {
        return [ModalStates.signUp, ModalStates.signInSuccess, ModalStates.signIn].includes(this.modalState);
    }

    public get isDisabledPrimaryBtn(): boolean {
        switch (this.modalState) {
            case ModalStates.signUp:
                return !this.form.valid;
            case ModalStates.signIn:
                return !this.form.controls.email.valid && !this.form.controls.password.valid;
            default:
                return false;
        }
    }

    public clearLocalStorage() {}

    public changeModalState(state: ModalStates): void {
        if (state === ModalStates.signIn || state === ModalStates.passwordRecovery) {
            this.form.reset();
        }

        if (state === ModalStates.signInSuccess) {
            this.eduServiceHttp.getStudentInfo().subscribe((res: any) => {
                this.name.patchValue(`${res.firstName} ${res.lastName}`);
                this.email.patchValue(res.email);
                this.changeDetectorRef.detectChanges();
            });
        }

        this.modalState = state;
    }

    public onClickSecondaryBtn(): void {
        switch (this.modalState) {
            case ModalStates.signUp:
                this.changeModalState(ModalStates.signIn);
                break;
            case ModalStates.signIn:
                this.changeModalState(ModalStates.passwordRecovery);
                break;
            case ModalStates.signInSuccess:
                // TODO: make logout
                this.changeModalState(ModalStates.signIn);
                break;
            default:
                this.changeModalState(ModalStates.signUp);
                break;
        }
    }

    public onClickPrimaryBtn(): void {
        switch (this.modalState) {
            case ModalStates.signUp:
                this.signUp();
                break;
            case ModalStates.signInSuccess:
                this.signInSuccess();
                break;
            case ModalStates.signIn:
                this.signIn();
                break;
            case ModalStates.passwordRecovery:
                this.sendRecoveryPassword();
                break;
            case ModalStates.passwordRecoverySuccess:
                this.changeModalState(ModalStates.signIn);
                break;
            case ModalStates.verifyEmail:
                this.clearLocalStorage();
                this.changeModalState(ModalStates.signIn);
                break;
            case ModalStates.changePassword:
                this.resetPassword();
                break;
            default:
                this.changeModalState(ModalStates.signUp);
                break;
        }
    }

    public togglePassword(): void {
        this.showPassword = !this.showPassword;
    }

    private signUp(): void {
        if (this.name.invalid || this.password.invalid || this.email.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.eduServiceHttp
            .register({
                firstName: this.form.value.name,
                email: this.form.value.email,
                password: this.form.value.password,
                confirmPassword: this.form.value.password,
                language: this.utilsService.getLanguageCode(this.lang),
                domainId: this.siteInfo.domainId,
                responsibleId: this.siteInfo.userId,
                courseId: this.courseId,
            })
            .subscribe(
                (response: any) => {
                    console.log('signUp response: ', response);
                    this.changeModalState(ModalStates.verifyEmail);
                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    console.log('signUp error: ', error);
                    this.resolveServerErrorsCode(error.error.errors, error.status);
                    this.changeDetectorRef.detectChanges();
                },
            );
    }

    private signIn(): void {
        this.eduServiceHttp
            .login({
                email: this.form.value.email,
                password: this.form.value.password,
                domainId: this.siteInfo.domainId,
            })
            .pipe(
                mergeMap((response) => {
                    const accessToken = response.headers.get('access_token');
                    const refreshToken = response.headers.get('refresh_token');

                    this.cookieService.set('oauth_access_token', accessToken, { path: '/' });
                    this.cookieService.set('oauth_refresh_token', refreshToken, { path: '/' });

                    if (!this.courseId) {
                        window.location.href = environment.eduStudentUrl;
                        return;
                    }

                    return this.eduServiceHttp.studentCourseStatus(this.courseId).pipe(
                        map((res) => {
                            if (res === 'deletedFromCourse') {
                                this.changeModalState(ModalStates.signInSuccess);
                                this.changeDetectorRef.detectChanges();
                                return;
                            }

                            window.location.href = environment.eduStudentUrl;
                        }),
                        catchError((err) => {
                            console.log('studentStatus error: ', err);
                            // if unregistered
                            this.changeModalState(ModalStates.signInSuccess);
                            this.changeDetectorRef.detectChanges();
                            return EMPTY;
                        }),
                    );
                }),
                catchError((error) => {
                    if (error.status === 401) {
                        this.form.setErrors({ invalidCredentials: true });
                    }

                    if (error.status === 403) {
                        const message = error?.error?.error?.message;

                        if (message === 'Student does not exist.') {
                            this.form.setErrors({ userNotFound: true });
                        } else {
                            this.form.setErrors({ emailVerified: true });
                        }
                    }

                    this.changeDetectorRef.detectChanges();
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    // sign in success method
    private signInSuccess(): void {
        this.eduServiceHttp.registerOnCourse(this.courseId).subscribe(() => {
            window.location.href = environment.eduStudentUrl;
        });
    }

    private sendRecoveryPassword(): void {
        this.eduServiceHttp
            .forgotPassword({
                email: this.form.value.email,
                domainId: this.siteInfo.domainId,
                courseId: this.courseId,
            })
            .subscribe(
                () => {
                    this.changeModalState(ModalStates.passwordRecoverySuccess);
                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    if (error.status === 404) {
                        this.email.setErrors({ notFound: true });
                    }

                    if (error.status === 429) {
                        this.form.setErrors({ resetAlreadySent: true });
                    }
                    // this.resolveServerErrorsCode(error.error.error, error.status);
                    this.changeDetectorRef.detectChanges();
                },
            );
    }

    private resetPassword(): void {
        this.eduServiceHttp
            .resetPassword(
                {
                    token: this.resetPasswordData.token,
                    newPassword: this.password.value,
                    confirmNewPassword: this.confirmPassword.value,
                },
                this.resetPasswordData,
            )
            .subscribe(
                (response) => {
                    console.log('response: ', response);
                    this.changeModalState(ModalStates.signIn);
                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    console.log('error: ', error);
                    if (error.status === 403) {
                        this.form.setErrors({ invalidResetLink: true });
                        return;
                    }

                    if (error.status === 404) {
                        this.form.setErrors({ usedResetLink: true });
                    }
                    this.changeDetectorRef.detectChanges();
                },
            );
    }

    public sendVerifyEmail(): void {
        this.eduServiceHttp
            .resendVerifyLink({
                email: this.email.value,
                domainId: this.siteInfo.domainId,
                courseId: this.courseId,
            })
            .pipe(
                tap(() => {
                    this.disableSendAgain = true;
                    this.changeDetectorRef.detectChanges();
                }),
                delay(1 * 1000 * 30),
            )
            .subscribe(
                () => {
                    this.disableSendAgain = false;
                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    if (error.status === 429) {
                        this.form.setErrors({ verificationAlreadySent: true });
                    }
                    this.changeDetectorRef.detectChanges();
                },
            );
    }

    private resetServerErrors(): void {
        if (this.serverErrorCode) {
            this.serverErrorCode = null;
        }

        if (this.loginError) {
            this.loginError = null;
        }

        if (this.passwordErrors) {
            this.passwordErrors = null;
        }
    }

    public showErrorNotification(): boolean {
        return (
            this.serverErrorCode === this.serverErrorCodes.FORBIDDEN_ACCESS ||
            this.serverErrorCode === this.serverErrorCodes.NOT_FOUND ||
            this.serverErrorCode === this.serverErrorCodes.INVALID_NEW_PASSWORD ||
            this.serverErrorCode === this.serverErrorCodes.CONFIRM_NEW_PASSWORD_NOT_MATCH ||
            this.serverErrorCode === this.serverErrorCodes.NEW_STUDENT_TARIFF_RESTRICTION ||
            this.form.hasError('emailVerified') ||
            this.form.hasError('userNotFound') ||
            this.form.hasError('invalidResetLink') ||
            this.form.hasError('usedResetLink') ||
            this.form.hasError('invalidCredentials') ||
            this.form.hasError('resetAlreadySent') ||
            this.form.hasError('verificationAlreadySent')
        );
    }

    private resolveServerErrorsCode(errors, status: number, message?: string): void {
        if (status === 422) {
            if ('email' in errors) {
                if (errors.email.includes('Email must be unique in pair with domainId')) {
                    this.serverErrorCode = this.serverErrorCodes.EMAIL_ALREADY_EXIST;
                }
            }
            if ('password' in errors) {
                if (
                    errors.password.includes('The password must be at least 8 characters.') ||
                    errors.password.includes('The password must contain at least one number.') ||
                    errors.password.includes('The password must contain at least one uppercase and one lowercase letter.')
                ) {
                    const errorsMap = {
                        'The password must be at least 8 characters.': 'password_error_1',
                        'The password must contain at least one uppercase and one lowercase letter.': 'password_error_2',
                        'The password must contain at least one number.': 'password_error_3',
                    };
                    this.serverErrorCode = this.serverErrorCodes.INVALID_PASSWORD;
                    this.passwordErrors = errors.password.map((error) => errorsMap[error]).filter(Boolean);
                }
            }
            if ('newPassword' in errors) {
                if (
                    errors.newPassword.includes('The new password must be at least 8 characters.') ||
                    errors.newPassword.includes('The new password must contain at least one number.') ||
                    errors.newPassword.includes('The new password must contain at least one uppercase and one lowercase letter.')
                ) {
                    this.serverErrorCode = this.serverErrorCodes.INVALID_NEW_PASSWORD;
                }
            }

            if ('New Student' in errors) {
                if (errors['New Student'].includes('Student can not be registered due to tariff restrictions')) {
                    this.serverErrorCode = this.serverErrorCodes.NEW_STUDENT_TARIFF_RESTRICTION;
                }
            }

            if ('confirmNewPassword' in errors) {
                if (errors.confirmNewPassword.includes('The confirm new password and new password must match.')) {
                    this.serverErrorCode = this.serverErrorCodes.CONFIRM_NEW_PASSWORD_NOT_MATCH;
                }
            }
        }

        if (status === 403 || status === 401) {
            const errorsMap = {
                'Your email address is not verified': 'error_email_is_not_verified',
            };
            this.serverErrorCode = this.serverErrorCodes.FORBIDDEN_ACCESS;
            if (!message) return;
            this.loginError = errorsMap[message] || 'error_forbidden_access';
        }

        if (status === 404) {
            this.serverErrorCode = this.serverErrorCodes.NOT_FOUND;
        }
    }

    public onCancel(): void {
        this.bsModalRef.hide();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
