import type { ThemeColor } from '@libs/themes';

import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { COLOR_PICKER_PRESETS, COLOR_PICKER_GRADIENT_PRESETS, TRANSPARENT_VALUE } from '../constants/color-picker-presets.const';

@Component({
    selector: 'sp-color-picker-presets',
    templateUrl: './sp-color-picker-presets.component.html',
    styleUrls: ['./sp-color-picker-presets.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpColorPickerPresetsComponent {
    @Input() public color!: string;
    @Input() public colorsPreset: ThemeColor[];
    @Input() public showCustom: boolean;
    @Input() public hideGradient: boolean;

    @Output() public showCustomChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public setColorEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() public setColorPresetEvent: EventEmitter<ThemeColor> = new EventEmitter<ThemeColor>();

    public readonly COLOR_PICKER_PRESETS: string[][] = COLOR_PICKER_PRESETS;
    public readonly COLOR_PICKER_GRADIENT_PRESETS: string[][] = COLOR_PICKER_GRADIENT_PRESETS;
    public readonly TRANSPARENT_VALUE = TRANSPARENT_VALUE;

    public toggleShowCustom(event: Event): void {
        event.preventDefault();
        this.showCustomChange.emit(true);
    }

    public setColorHandler(color: string): void {
        this.setColorEvent.emit(color);
    }

    public setColorPresetHandler(preset: ThemeColor): void {
        this.setColorPresetEvent.emit(preset);
    }
}
