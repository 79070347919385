import { ESocialNetworks } from '../enum/social-networks.enum';
import { EButtonStyleType, EButtonPositions } from '@common/enums';
import { buttonStyleTypeTranslations } from '@app/core/translations';

export const translations = {
    icon: 'control_appearance_icon',
    iconText: 'control_appearance_icon_text',
    classic: 'control_appearance_classic',
    primary: 'control_appearance_primary',
    secondary: 'control_appearance_secondary',
    custom: 'control_appearance_custom',
} as const;

export const buttonStyleTypeOptions = [
    {  label: buttonStyleTypeTranslations[EButtonStyleType.classic], type: EButtonStyleType.classic },
    {  label: buttonStyleTypeTranslations[EButtonStyleType.primary], type: EButtonStyleType.primary },
    {  label: buttonStyleTypeTranslations[EButtonStyleType.secondary], type: EButtonStyleType.secondary },
    {  label: buttonStyleTypeTranslations[EButtonStyleType.custom], type: EButtonStyleType.custom },
];

export const positionStyles = {
    [EButtonPositions.L]: {textAlign: 'left', justifyContent: 'flex-start'},
    [EButtonPositions.M]: {textAlign: 'center', justifyContent: 'center'},
    [EButtonPositions.R]: {textAlign: 'right', justifyContent: 'flex-end'}
};

export const socialNetworksNames = {
    [ESocialNetworks.facebook]: 'Facebook',
    [ESocialNetworks.twitter]: 'Twitter',
    [ESocialNetworks.instagram]: 'Instagram',
    [ESocialNetworks.vk]: 'VKontakte',
    [ESocialNetworks.ok]: 'Ok',
    [ESocialNetworks.youtube]: 'YouTube',
    [ESocialNetworks.telegram]: 'Telegram',
    [ESocialNetworks.snapchat]: 'Snapchat',
    [ESocialNetworks.viber]: 'Viber',
    [ESocialNetworks.linkedin]: 'Linkedin',
    [ESocialNetworks.pinterest]: 'Pinterest',
    [ESocialNetworks.vimeo]: 'Vimeo',
    [ESocialNetworks.slideshare]: 'SlideShare',
    [ESocialNetworks.tumblr]: 'Tumblr',
    [ESocialNetworks.medium]: 'Medium',
    [ESocialNetworks.tiktok]: 'TikTok',
    [ESocialNetworks.yandexzen]: 'Yandex Zen',
    [ESocialNetworks.whatsapp]: 'WhatsApp',
    [ESocialNetworks.spotify]: 'Spotify',
    [ESocialNetworks.twitch]: 'Twitch',
    [ESocialNetworks.messenger]: 'Messenger',
    [ESocialNetworks.skype]: 'Skype',
    [ESocialNetworks.onlyfans]: 'OnlyFans',
    [ESocialNetworks.patreon]: 'Patreon',
    [ESocialNetworks.buymeacoffee]: 'Buy me a coffee',
    [ESocialNetworks.soundcloud]: 'SoundCloud',
    [ESocialNetworks.email]: 'Email',
    [ESocialNetworks.phone]: 'Phone',
};

export const socialNetworksUrl = {
    [ESocialNetworks.facebook]: ['https://facebook.com', 'https://www.facebook.com', 'https://fb.com', 'https://touch.facebook.com'],
    [ESocialNetworks.twitter]: ['https://twitter.com', 'https://www.twitter.com'],
    [ESocialNetworks.instagram]: ['https://instagram.com', 'https://www.instagram.com', 'https://ig.me', 'https://www.ig.me'],
    [ESocialNetworks.vk]: ['https://vk.me', 'https://www.vk.me', 'https://vk.com', 'https://www.vk.com'],
    [ESocialNetworks.ok]: ['https://ok.ru', 'https://www.ok.ru'],
    [ESocialNetworks.youtube]: ['https://www.youtube.com', 'https://youtube.com', 'https://youtu.be', 'https://www.youtu.be'],
    [ESocialNetworks.telegram]: ['https://t.me', 'https://www.t.me', 'https://telegram.me', 'https://www.telegram.me', 'tg://'],
    [ESocialNetworks.snapchat]: ['https://snapchat.com', 'https://www.snapchat.com'],
    [ESocialNetworks.viber]: ['https://viber.me', 'https://www.viber.me', 'https://viber.com', 'https://www.viber.com', 'https://viber.click', 'https://www.viber.click', 'https://invite.viber.com', 'https://www.invite.viber.com', 'viber://'],
    [ESocialNetworks.linkedin]: ['https://www.linkedin.com', 'https://linkedin.com'],
    [ESocialNetworks.pinterest]: ['https://www.pinterest.com', 'https://pinterest.com'],
    [ESocialNetworks.vimeo]: ['https://vimeo.com', 'https://www.vimeo.com'],
    [ESocialNetworks.slideshare]: ['https://www.slideshare.net', 'https://slideshare.net'],
    [ESocialNetworks.tumblr]: ['https://www.tumblr.com', 'https://tumblr.com'],
    [ESocialNetworks.medium]: ['https://medium.com', 'https://www.medium.com'],
    [ESocialNetworks.tiktok]: ['https://www.tiktok.com', 'https://tiktok.com', 'https://vm.tiktok.com' ],
    [ESocialNetworks.yandexzen]: ['https://zen.yandex.ru', 'https://www.zen.yandex.ru'],
    [ESocialNetworks.whatsapp]: ['https://www.whatsapp.com', 'https://whatsapp.com', 'https://chat.whatsapp.com', 'https://web.whatsapp.com', 'https://wa.me', 'https://www.wa.me', 'https://api.whatsapp.com/send', 'https://www.api.whatsapp.com/send', 'whatsapp://'],
    [ESocialNetworks.spotify]: ['https://www.spotify.com', 'https://spotify.com', 'https://open.spotify.com/playlist'],
    [ESocialNetworks.twitch]: ['https://www.twitch.tv', 'https://twitch.tv'],
    [ESocialNetworks.messenger]: ['https://www.messenger.com'],
    [ESocialNetworks.skype]: ['skype'],
    [ESocialNetworks.soundcloud]: ['https://soundcloud.com'],
    [ESocialNetworks.onlyfans]: ['https://onlyfans.com'],
    [ESocialNetworks.patreon]: ['https://www.patreon.com'],
    [ESocialNetworks.buymeacoffee]: ['https://buymeacoffee.com'],
    [ESocialNetworks.email]: [''],
    [ESocialNetworks.phone]: [''],
};

export const socialNetworksBrandColors = {
    [ESocialNetworks.facebook]: '#1877F2',
    [ESocialNetworks.twitter]: '#1da1f2',
    [ESocialNetworks.instagram]: '#D62976',
    [ESocialNetworks.vk]: '#2787F5',
    [ESocialNetworks.ok]: '#ed812b',
    [ESocialNetworks.youtube]: '#ff0000',
    [ESocialNetworks.telegram]: '#32AADD',
    [ESocialNetworks.snapchat]: '#FFE400',
    [ESocialNetworks.viber]: '#675DA9',
    [ESocialNetworks.linkedin]: '#2867B2',
    [ESocialNetworks.pinterest]: '#e60023',
    [ESocialNetworks.vimeo]: '#162221',
    [ESocialNetworks.slideshare]: '#0077b5',
    [ESocialNetworks.tumblr]: '#35465c',
    [ESocialNetworks.medium]: '#00ab6c',
    [ESocialNetworks.tiktok]: '#010101',
    [ESocialNetworks.yandexzen]: '#2c3036',
    [ESocialNetworks.whatsapp]: '#25D366',
    [ESocialNetworks.spotify]: '#1ED760',
    [ESocialNetworks.twitch]: '#5A3E85',
    [ESocialNetworks.messenger]: '#8e15ff',
    [ESocialNetworks.skype]: '#00aff0',
    [ESocialNetworks.soundcloud]: '#FF5500',
    [ESocialNetworks.onlyfans]: '#00AEEF',
    [ESocialNetworks.patreon]: '#000000',
    [ESocialNetworks.buymeacoffee]: '#FFDD06',
    [ESocialNetworks.email]: '#2576D4',
    [ESocialNetworks.phone]: '#D44F25',
};
export const socialNetworksTextColors = {
    [ESocialNetworks.facebook]: '#ffffff',
    [ESocialNetworks.twitter]: '#ffffff',
    [ESocialNetworks.instagram]: '#ffffff',
    [ESocialNetworks.vk]: '#ffffff',
    [ESocialNetworks.ok]: '#ffffff',
    [ESocialNetworks.youtube]: '#ffffff',
    [ESocialNetworks.telegram]: '#ffffff',
    [ESocialNetworks.snapchat]: '#000000',
    [ESocialNetworks.viber]: '#ffffff',
    [ESocialNetworks.linkedin]: '#ffffff',
    [ESocialNetworks.pinterest]: '#ffffff',
    [ESocialNetworks.vimeo]: '#ffffff',
    [ESocialNetworks.slideshare]: '#ffffff',
    [ESocialNetworks.tumblr]: '#ffffff',
    [ESocialNetworks.medium]: '#ffffff',
    [ESocialNetworks.tiktok]: '#ffffff',
    [ESocialNetworks.yandexzen]: '#ffffff',
    [ESocialNetworks.whatsapp]: '#ffffff',
    [ESocialNetworks.spotify]: '#ffffff',
    [ESocialNetworks.twitch]: '#ffffff',
    [ESocialNetworks.skype]: '#ffffff',
    [ESocialNetworks.soundcloud]: '#ffffff',
    [ESocialNetworks.onlyfans]: '#ffffff',
    [ESocialNetworks.patreon]: '#ffffff',
    [ESocialNetworks.buymeacoffee]: '#000000',
    [ESocialNetworks.email]: '#ffffff',
    [ESocialNetworks.phone]: '#ffffff',
};
