<!-- <div [class]="classes?.element">
    <div *ngIf="elementData.showLogo">
        <svg [class]="classes?.logo" width="108" height="42"  fill="none" viewBox="0 0 108 42" xmlns="http://www.w3.org/2000/svg">
            <circle [class]="classes?.logo" cx="20" cy="20" r="18.5" stroke="white" stroke-width="3"/>
            <path [class]="classes?.logo" d="M2 25.5L12.3137 15.6568L23.6274 15.6568L23.981 27.324L14 37.5" stroke="white" stroke-width="3"/>
            <path [class]="classes?.logo" d="M11.9602 16.0103L23.2739 27.324M17.2635 22.0207L6.65685 32.6273" stroke="white" stroke-width="3"/>
            <path [class]="classes?.logo" d="M19.7383 16.0103L23.2738 19.5458" stroke="white" stroke-width="3"/>
            <path [class]="classes?.logo" d="M54.44 33V7.8H58.4V29.4H64.916V33H54.44ZM70.2752 13.596V27.204C70.2752 28.86 70.9592 29.688 72.3272 29.688C73.6952 29.688 74.3792 28.86 74.3792 27.204V13.596C74.3792 11.94 73.6952 11.112 72.3272 11.112C70.9592 11.112 70.2752 11.94 70.2752 13.596ZM66.3152 26.952V13.848C66.3152 11.832 66.8312 10.272 67.8632 9.168C68.8952 8.064 70.3832 7.512 72.3272 7.512C74.2712 7.512 75.7592 8.064 76.7912 9.168C77.8232 10.272 78.3392 11.832 78.3392 13.848V26.952C78.3392 28.968 77.8232 30.528 76.7912 31.632C75.7592 32.736 74.2712 33.288 72.3272 33.288C70.3832 33.288 68.8952 32.736 67.8632 31.632C66.8312 30.528 66.3152 28.968 66.3152 26.952ZM86.8527 22.56V18.96H92.3967V26.952C92.3967 28.992 91.8927 30.564 90.8847 31.668C89.8767 32.748 88.4127 33.288 86.4927 33.288C84.5727 33.288 83.1087 32.748 82.1007 31.668C81.0927 30.564 80.5887 28.992 80.5887 26.952V13.848C80.5887 11.808 81.0927 10.248 82.1007 9.168C83.1087 8.064 84.5727 7.512 86.4927 7.512C88.4127 7.512 89.8767 8.064 90.8847 9.168C91.8927 10.248 92.3967 11.808 92.3967 13.848V16.296H88.6527V13.596C88.6527 11.94 87.9687 11.112 86.6007 11.112C85.2327 11.112 84.5487 11.94 84.5487 13.596V27.204C84.5487 28.836 85.2327 29.652 86.6007 29.652C87.9687 29.652 88.6527 28.836 88.6527 27.204V22.56H86.8527ZM98.576 13.596V27.204C98.576 28.86 99.26 29.688 100.628 29.688C101.996 29.688 102.68 28.86 102.68 27.204V13.596C102.68 11.94 101.996 11.112 100.628 11.112C99.26 11.112 98.576 11.94 98.576 13.596ZM94.616 26.952V13.848C94.616 11.832 95.132 10.272 96.164 9.168C97.196 8.064 98.684 7.512 100.628 7.512C102.572 7.512 104.06 8.064 105.092 9.168C106.124 10.272 106.64 11.832 106.64 13.848V26.952C106.64 28.968 106.124 30.528 105.092 31.632C104.06 32.736 102.572 33.288 100.628 33.288C98.684 33.288 97.196 32.736 96.164 31.632C95.132 30.528 94.616 28.968 94.616 26.952Z" fill="white"/>
        </svg>
    </div>
    <div [class]="classes?.bannerText">
        <div [class]="classes?.textContent"
             [innerHTML]="elementData.bannerHeader | spSafeHtml">
        </div>
        <div [class]="classes?.textContent"
            [innerHTML]="elementData.bannerDescription | spSafeHtml">
        </div>
    </div>
    <div [class]="classes.courseButtons" >
        <button [class]="classes.coursePrimaryBtn" (click)="openPrimaryModal()">{{ elementData.primaryButtonText }}</button>
        <button [class]="classes.courseSecondaryBtn" *ngIf="elementData.secondaryButtonEnabled">{{ elementData.secondaryButtonText }}</button>
    </div>
</div> -->


<div [class]="classes?.teachersHeading">
    <h2>{{ 'teachers' | translate}}</h2>
</div>

<div>
    <div [class]="classes?.teachersList">
        <div [class]="classes.teacherProfile" *ngFor="let teacher of elementData.list">
            <ng-container *ngIf="teacher.enabled">
                <img *ngIf="teacherHasUploadedImage(teacher)"
                     [src]="teacher.image"
                     [attr.alt]="teacher.name"
                     [class]="classes.teacherAvatar"
                     width="100px"
                     height="100px">

                <img *ngIf="!teacherHasUploadedImage(teacher)"
                     [class]="classes.teacherAvatar"
                     [src]="getBlancImage" [attr.alt]="teacher.name"
                     width="100px"
                     height="100px">

                <div [class]="classes.teacherName">{{ teacher.name }}</div>

                <p [class]="classes.teacherDescription">{{ teacher.description }}</p>
            </ng-container>
        </div>
    </div>
</div>
