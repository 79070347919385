<img *ngIf="alpha === MAX_OPACITY_VALUE"
     width="100%"
     src="./assets/img/transparent-icon/opacity-icon.svg"
     alt="opacity-icon">

<img *ngIf="alpha < MAX_OPACITY_VALUE && alpha > MIN_OPACITY_VALUE"
     width="100%"
     [style.opacity]="alpha"
     src="./assets/img/transparent-icon/half-opacity-icon.svg"
     alt="half-opacity-icon">
