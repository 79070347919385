import { createSelector } from '@ngrx/store';
import type { ProductCard } from '@common/types/product-card.type';

export interface CartFeatureState {
    cartItems: ProductCard[];
    numberOfItems: number;
    totalPrice: number;
    loaded: boolean;
}

export const selectCartState = (state: CartFeatureState) => state;

export const selectCartItems = createSelector(selectCartState, (state: any | undefined): ProductCard[] => {
    return state?.cartFeature?.cartItems;
});

export const selectCartNumberOfItems = createSelector(selectCartState, (state: any | undefined): number => {
    return state?.cartFeature?.numberOfItems;
});

export const selectCartTotalPrice = createSelector(selectCartState, (state: any | undefined): number => {
    return state?.cartFeature?.totalPrice;
});

export const selectCartLoaded = createSelector(selectCartState, (state: any | undefined): boolean => {
    return state?.cartFeature?.loaded;
});
