import { JssStyle } from 'jss';
import { ESize } from '@common/enums';

export const commonButtonTextStyles: Record<ESize, JssStyle> = {
    [ESize.S]: { padding: '4px 10px' },
    [ESize.M]: { padding: '10px 16px' },
    [ESize.L]: { padding: '16px 22px' },
};

export const commonWebsitesFormButtonTextStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        height: '35px',
        padding: '10px 10px !important',
        fontSize: '13px !important',
        lineHeight: '15px !important',
    },
    [ESize.M]: {
        height: '41px',
        padding: '12px 16px !important',
        fontSize: '15px !important',
        lineHeight: '17px !important',
    },
    [ESize.L]: {
        height: '57px',
        padding: '18px 22px !important',
        fontSize: '19px !important',
        lineHeight: '21px !important',
    },
};

export const commonButtonIconStyles: Record<ESize, JssStyle> = {
    [ESize.S]: { width: '24px', height: '24px' },
    [ESize.M]: { width: '36px', height: '36px' },
    [ESize.L]: { width: '58px', height: '58px' },
};
