import { type JssStyle } from 'jss';

import { simpleColorCss, simpleFontCss } from '@libs/themes/src/variants/simple/utils/variables';

import { type IElement } from '@app/features/common/interfaces/IElement';
import { EThemeFontTags } from '@libs/themes';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { type ISubscriptionFormElementData } from '@app/features/subscription-form/interface/ISubscriptionFormElement';
import { textButtonStyles } from '@app/features/common/styles/button.styles';
import { formElementsStyles } from '@app/features/common/styles/form-elements.styles';
import { h1Styles, h2Styles, h3Styles, h4Styles, pStyles } from '@app/features/common/styles/text.styles';

export function getStyles(element: IElement): JssStyle {
    const elementData: ISubscriptionFormElementData = element.data;
    const buttonClasses = textButtonStyles(element);

    return {
        ...formElementsStyles(elementData),
        ...buttonClasses,
        form: {
            fontFamily: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontFamily),
            '& label': {
                color: simpleColorCss(element.data.textColor),
            },
        },
        pointerEventsNone: {
            pointerEvents: 'none',
        },
        notificationContent: {
            '& h1': { ...h1Styles, color: simpleColorCss(elementData.textColor) },
            '& h2': { ...h2Styles, color: simpleColorCss(elementData.textColor) },
            '& h3': { ...h3Styles, color: simpleColorCss(elementData.textColor) },
            '& h4': { ...h4Styles, color: simpleColorCss(elementData.textColor) },
            '& p': { ...pStyles, color: simpleColorCss(elementData.textColor) },
        },
    };
}
