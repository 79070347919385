import { type SubscriptionFormElement } from '@app/features/subscription-form/interface/ISubscriptionFormElement';
import { EFormControlType, EFormInputType } from '@app/features/common/enums/form.enum';
import { IFormCheckbox, IFormInput, IFormInputDate, IFormRadio, IFormSelect, IFormTextarea, IFormNumber } from '@app/features/common/interfaces/form-control.interface';

export const isInput = (control: SubscriptionFormElement): control is IFormInput => {
    const controlType = (control as IFormInput).type;
    return Object.values(EFormInputType).includes(controlType);
};

export const isInputDate = (control: SubscriptionFormElement): control is IFormInputDate => {
    const controlType = (control as IFormInputDate).type;
    return controlType === EFormInputType.date;
};

export const isCheckbox = (control: SubscriptionFormElement): control is IFormCheckbox => {
    const controlType = (control as IFormCheckbox).type;
    return controlType === EFormControlType.checkbox;
};

export const isRadio = (control: SubscriptionFormElement): control is IFormRadio => {
    const controlType = (control as IFormRadio).type;
    return controlType === EFormControlType.radio;
};

export const isSelect = (control: SubscriptionFormElement): control is IFormSelect => {
    const controlType = (control as IFormSelect).type;
    return controlType === EFormControlType.select;
};

export const isSystemElement = (element: SubscriptionFormElement) => {
    const { type } = element;
    return type === EFormInputType.email || type === EFormInputType.phone || type === EFormInputType.file;
};

export const isTextarea = (control: SubscriptionFormElement): control is IFormTextarea => {
    const controlType = (control as IFormTextarea).type;
    return controlType === EFormControlType.textarea;
};

export const isNumber = (control: SubscriptionFormElement): control is IFormNumber => {
    const controlType = (control as IFormNumber).type;
    return Object.values(EFormInputType).includes(controlType);
};
