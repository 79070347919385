export enum EFilerOverlay {
    B_W = 'grayscale(100%)',
    DARKEN = 'brightness(25%)',
    BLURRY = 'blur(3px)',
    RADIANCE = 'contrast(160%)',
    BLOOM = 'saturate(300%)',
    SEPIA = 'sepia(100%)',
    TINT = 'hue-rotate(180deg)',
    OPACITY = 'opacity: 0.75',
}
