import { InjectionToken } from '@angular/core';
import { type EContentSources } from '@common/enums';
import { type Request as ExpressRequest, type Response as ExpressResponse } from 'express';

export const REQUEST = new InjectionToken<ExpressRequest>('REQUEST');
export const RESPONSE = new InjectionToken<ExpressResponse>('RESPONSE');

export const IS_PREVIEW = new InjectionToken<boolean>('IS_PREVIEW');

export const CONTENT_SOURCE = new InjectionToken<EContentSources>('CONTENT_SOURCE');

export const USER_DOMAIN_URL = new InjectionToken<string>('USER_DOMAIN_URL');

export const API_LANDINGS_SERVICE_URL = new InjectionToken<string>('API_LANDING_SERVICE_URL');

export const SOA_ID = new InjectionToken<string>('SOA_ID');

export const SOA_SECRET = new InjectionToken<string>('SOA_SECRET');

export const SOA_URL = new InjectionToken<string>('SOA_URL');

export const X_REAL_IP = new InjectionToken<string>('X_REAL_IP');
