import type { ThemeColorPreset } from '@libs/themes/src/types/theme-color.type';
import type { ThemeButtonPreset } from '@libs/themes/src/types/theme-button.type';
import type { ThemeFontPreset } from '@libs/themes/src/types/theme-font.type';

import { createReducer, on } from '@ngrx/store';

import ThemeActions from '@libs/themes/src/store/theme/theme.actions';
import ThemePresetsActions from '@libs/themes/src/store/theme-presets/theme-presets.actions';

export type ThemePresetsState = {
    colors?: ThemeColorPreset[];
    buttons?: ThemeButtonPreset[];
    fonts?: ThemeFontPreset[];
};

export const THEME_PRESETS_STORE_KEY = 'presets';

const initialThemePresetsState: ThemePresetsState = {};

export const themePresetsReducer = createReducer<ThemePresetsState>(
    initialThemePresetsState,
    on(ThemeActions.setTheme, (state, { presets }) => ({ ...state, ...presets })),

    on(ThemePresetsActions.setInitialThemePresets, (state, { fonts, colors, buttons }) => ({ ...state, fonts, colors, buttons })),
    on(ThemePresetsActions.changeColorsPresets, (state, { colors }) => ({ ...state, colors })),
    on(ThemePresetsActions.changeButtonsPresets, (state, { buttons }) => ({ ...state, buttons })),
    on(ThemePresetsActions.changeFontsPresets, (state, { fonts }) => ({ ...state, fonts })),
);
