import { inject, Injectable } from '@angular/core';
import { BaseHttpService } from './base.http.service';
import { type Observable } from 'rxjs';
import { X_REAL_IP } from '@common/tokens';

@Injectable({
    providedIn: 'root',
})
export class CountryISOService extends BaseHttpService {
    private readonly xRealIp: string = inject(X_REAL_IP, { optional: true });

    public getCountryISO(): Observable<any> {
        return this.http.get(`https://geoip.sendpulse.com${this.xRealIp ? '/' + this.xRealIp : ''}`);
    }
}
