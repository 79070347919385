import { createActionGroup, props } from '@ngrx/store';

const ThemeValuesActions = createActionGroup({
    source: 'ThemeValues',
    events: {
        'Set Theme Values': props<{ values: Record<string, string> }>(),
    },
});

export default ThemeValuesActions;
