import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SpTimerComponent } from './sp-timer.component';

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [SpTimerComponent],
    exports: [SpTimerComponent],
})
export class SpTimerModule {}
