import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { SpPlayerModule } from '@components/sp-player';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { SpDirectivesModule } from '@common/directives/directives.module';

import { SpSliderModule } from '@components/sp-slider';
import { SpTimerModule } from '@components/sp-timer';
import { SwiperModule } from 'swiper/angular';

import MenuFeatureComponent from './menu/menu-feature.component';
import EduTeachersFeatureComponent from './edu-teachers-feature/edu-teachers-feature.component';
import AccordionFeatureComponent from './accordion-feature/accordion-feature.component';
import AvatarFeatureComponent from './avatar-feature/avatar-feature.component';
import IndentFeatureComponent from './indent-feature/indent-feature.component';
import GalleryFeatureComponent from './gallery-feature/gallery-feature.component';
import LineFeatureComponent from './line-feature/line-feature.component';
import ButtonFeatureComponent from './button-feature/button-feature.component';
import ChatBotsFeatureComponent from './chat-bots-feature/chat-bots-feature.component';
import SocialNetworksFeatureComponent from './social-networks-feature/social-networks-feature.component';
import SubscriptionFormFeatureComponent from './subscription-form-feature/subscription-form-feature.component';
import TextFeatureComponent from './text-feature/text-feature.component';
import TimerFeatureComponent from './timer-feature/timer-feature.component';
import PaymentFormFeatureComponent from './payment-form-feature/payment-form-feature.component';
import EduBannerFeatureComponent from './edu-banner-feature/edu-banner-feature.component';
import { PaymentFormModalFeatureComponent } from './payment-form-feature/payment-form-modal-feature/payment-form-modal-feature.component';
import { EduBannerModalFeatureComponent } from './edu-banner-feature/edu-banner-modal-feature/edu-banner-modal-feature.component';
import { SharedModule } from '@web-builder/mls-core/shared/shared.module';
import { SubscriptionMessageModalComponent } from './subscription-form-feature/subscription-message-modal/subscription-message-modal.component';
import { NgxIntlTelInputModule } from '@moddi3/ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDropzoneModule } from 'ngx-dropzone';

const FEATURES = [
    AccordionFeatureComponent,
    AvatarFeatureComponent,
    ButtonFeatureComponent,
    ChatBotsFeatureComponent,
    GalleryFeatureComponent,
    IndentFeatureComponent,
    LineFeatureComponent,
    SocialNetworksFeatureComponent,
    SubscriptionFormFeatureComponent,
    TextFeatureComponent,
    TimerFeatureComponent,
    PaymentFormFeatureComponent,
    EduBannerFeatureComponent,
    EduTeachersFeatureComponent,
    MenuFeatureComponent,
    SubscriptionMessageModalComponent,
];

const FEATURE_MODALS = [PaymentFormModalFeatureComponent, EduBannerModalFeatureComponent];

const MODULES = [
    CommonModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TranslateModule,
    SpPlayerModule,
    SpPipesModule,
    SpDirectivesModule,
    ReactiveFormsModule,
    SpSliderModule,
    SpTimerModule,
    SharedModule,
    SwiperModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
];

@NgModule({
    imports: [...MODULES],
    declarations: [...FEATURES, ...FEATURE_MODALS],
    exports: [],
})
export class FeaturesModule {}
