import type { Observable } from 'rxjs';
import type { SiteSettings } from '@common/types/site-settings.type';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as SettingsSelectors from './settings.selectors';

@Injectable()
export class SettingsFacade {
    public readonly siteSettings$: Observable<SiteSettings> = this.store.select(SettingsSelectors.selectSiteSettings);
    public readonly showPoweredBy$: Observable<boolean> = this.store.select(SettingsSelectors.selectShowPoweredBy);

    constructor(private readonly store: Store) {}

    public isPage404$(pageId: string): Observable<boolean> {
        return this.store.select(SettingsSelectors.selectIsPage404(pageId));
    }
}
