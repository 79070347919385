import type { SiteContentEntity } from '@common/types/site-content.type';
import type { ShareBlocks } from '@common/types/site.type';
import type { Template } from '@common/types/template.type';
import type { Id, Uuid } from '@common/types';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ContentActions = createActionGroup({
    source: 'Content',
    events: {
        'Load content': props<{ siteId?: Id | Uuid; pageId?: Id | Uuid; template?: Template }>(),
        'Load site content success': props<{ content: SiteContentEntity }>(),
        'Load site content failure': props<{ error: string }>(),
        'Load page content request': props<{ siteId: Id | Uuid; pageId: Id | Uuid; template?: Template }>(),
        'Load pages content success': props<{ content: SiteContentEntity }>(),
        'Load pages content failure': props<{ error: string }>(),
        'Set content': props<{ siteContent: SiteContentEntity; pageContent: SiteContentEntity }>(),
        'Set is dirty': props<{ isDirty: boolean }>(),
        'Update page structure content': props<{ structure: string }>(),
        'Update site theme settings content': props<{ themeSettings: string }>(),
        'Update site shared blocks content': props<{ sharedBlocks: ShareBlocks }>(),
        'Reset content to published': emptyProps(),
        'Trigger save content': emptyProps(),
    },
});

export default ContentActions;
