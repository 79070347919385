import type { SitePageEntity } from '@common/types/site-page.type';

import { createImmerReducer } from 'ngrx-immer/store';
import { on } from '@ngrx/store';

import PagesActions from './pages.actions';

export const PAGES_FEATURE_KEY = 'pages';

export type PagesState = {
    pages: SitePageEntity[];
    publishing: boolean;
    error?: string;
};

export const initialPagesState: PagesState = {
    pages: [],
    publishing: false,
};

export const pagesReducer = createImmerReducer<PagesState>(
    initialPagesState,
    on(PagesActions.loadPagesSuccess, PagesActions.setPages, (state: PagesState, { pages }) => ({ ...state, pages })),
    on(PagesActions.loadPagesFailure, (state: PagesState, { error }) => ({ ...state, error })),
    on(PagesActions.createNewPageSuccess, (state: PagesState, { page }) => {
        state.pages.push(page);
        return state;
    }),
    on(PagesActions.createPageSuccess, (state: PagesState, { page, oldPageId }) => {
        const currentPage = state.pages.find((p) => p.id.toString() === oldPageId.toString());

        if (currentPage) {
            Object.assign(currentPage, page);
        }

        return state;
    }),
    on(PagesActions.updatePageSuccess, (state: PagesState, { page }) => {
        const currentPage = state.pages.find((p) => p.id.toString() === page.id.toString());

        if (currentPage) {
            Object.assign(currentPage, page);
        }

        return state;
    }),
    on(
        PagesActions.activatePageRequest,
        PagesActions.disablePageRequest,
        PagesActions.deletePageRequest,
        PagesActions.copyPageRequest,
        (state: PagesState, { pageId }) => {
            if (!state.pages?.length) {
                return state;
            }

            state.pages.forEach((page) => {
                if (page.id.toString() === pageId.toString()) {
                    page.is_action_pending = true;
                }
            });
            state.publishing = true;

            return state;
        },
    ),
    on(PagesActions.activatePageSuccess, PagesActions.disablePageSuccess, (state: PagesState, { pageId, page }) => {
        state.pages = state.pages.map((p) => {
            if (p.id.toString() === pageId.toString()) {
                return page;
            }

            return p;
        });
        state.publishing = false;

        return state;
    }),
    on(PagesActions.activatePageFailure, (state: PagesState, { pageId }) => {
        state.pages.forEach((page) => {
            if (page.id.toString() === pageId.toString()) {
                page.is_action_pending = false;
            }
        });
        state.publishing = false;

        return state;
    }),
    on(PagesActions.deletePageSuccess, (state: PagesState, { pageId }) => {
        state.pages = state.pages.filter((page) => page.id.toString() !== pageId.toString());
        return state;
    }),
    on(PagesActions.copyPageSuccess, (state: PagesState, { pageId, page }) => {
        state.pages = state.pages.map((item) => {
            if (item.id.toString() === pageId.toString()) {
                return { ...item, is_action_pending: false };
            }

            return item;
        });
        state.pages.push(page);
        return state;
    }),
    on(PagesActions.patchPage, (state: PagesState, { pageId, data }) => {
        const currentPage = state.pages.find((p) => p.id.toString() === pageId.toString());
        const newData = { ...data };
        try {
            // draft already created, so url is equal to https://domain.com/page-url

            const url = new URL(currentPage.url);
            url.pathname = newData.url;
            newData.url = url.toString();
        } catch (error) {
            console.log(error);
            // url is not set or is not a valid url but a pathname - page-url
            // in this case new URL will throw an error
            // this is possible when publishing page before draft is created
            // in this situation we don't need to do anything, passing url as is
        }

        if (currentPage) {
            Object.assign(currentPage, newData);
        } else {
            throw new Error(`Page with id ${pageId} not found`);
        }
        return state;
    }),
);
