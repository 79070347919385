import { ChangeDetectorRef, Component, Input, type OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { type JssStyle, type Classes } from 'jss';
import { CookieService } from 'ngx-cookie-service';

import { TRACKING_LINK_USERS_WHITELIST } from '@common/constants/tracking-links-users-whitelist.const';

import { ServerStylesService } from '@common/services/server-styles.service';
import { PlatformService } from '@common/services/platform.service';
import { TrackingLinkConverterService } from '@common/services/tracking-link-converter.service';
import { UtmTagsService } from '@web-builder/mls-core/services/utm-tags.service';

import { type ContainerModel } from '@app/core/models/container-model';
import { type IElement } from '@app/features/common/interfaces/IElement';

import { UtilsService } from '@web-builder/mls-core/services/utils.service';
import { EduServiceHttp } from '@web-builder/mls-core/services/edu-api.service';
import { ETemplateSource } from '@app/core/enums/template-source.enum';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

@Component({ template: '' })
export abstract class BaseFeatureComponent<T> implements OnInit {
    @Input() public container: ContainerModel;

    public classes: Classes;
    public templateId: string = this.transferStateService.get('templateId');
    private userId: number = this.transferStateService.get('userId');
    private siteId: number = this.transferStateService.get('siteId');
    public pageId: number = this.transferStateService.get('pageId');
    public isPreview: boolean = this.transferStateService.isPreview;
    public resourceUrl: string = this.transferStateService.resourceUrl;
    public projectType: ETemplateSource;

    public projectTypes = ETemplateSource;

    protected abstract getStyles: (element: IElement) => JssStyle;
    protected widgetInit(): void {}

    constructor(
        protected readonly trackingLinkConverterService: TrackingLinkConverterService,
        protected readonly platformService: PlatformService,
        protected readonly stylesService: ServerStylesService,
        protected readonly sanitizer: DomSanitizer,
        protected readonly transferStateService: TransferStateService,
        protected readonly eduServiceHttp: EduServiceHttp,
        protected readonly utilsService: UtilsService,
        protected readonly modalService: BsModalService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        protected readonly cookieService: CookieService,
        protected readonly utmTagsService: UtmTagsService,
    ) {
        this.trackingLinkConverterService.init(
            this.transferStateService.get('siteId'),
            this.transferStateService.get('pageId'),
            this.transferStateService.get('domainUrl'),
        );
    }

    public ngOnInit(): void {
        this.classes = this.stylesService.getStyles(this.container.element.id, this.getStyles(this.container.element));
        this.widgetInit();
    }

    protected formatImgSrc(src: string): string {
        return this.utilsService.formatImgSrc(src);
    }

    public get element(): IElement {
        return this.container.element;
    }

    public get elementData(): T {
        return this.element.data;
    }

    public get isServer(): boolean {
        return this.platformService.isPlatformServer();
    }

    public get isBrowser(): boolean {
        return this.platformService.isPlatformBrowser();
    }

    public get preventTrackingLinkTransformations(): boolean {
        return TRACKING_LINK_USERS_WHITELIST.includes(this.userId);
    }
}
