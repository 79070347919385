<div
    *ngIf="strategyType === EThemeVariants.SIMPLE"
    class="scroll-wrapper"
    [class.theme-font-settings]="(activeThemeSetting$ | async) === EThemeSettings.fontsSettings"
>
    <sp-simple-theme-control-resolver
        *ngIf="themeConfigLoaded$ | async"
        [activeThemeSetting]="activeThemeSetting$ | async"
    ></sp-simple-theme-control-resolver>
</div>

<div *ngIf="strategyType === EThemeVariants.WEBSITE">
    <sp-website-theme-control-resolver
        *ngIf="themeConfigLoaded$ | async"
        [activeThemeSetting]="activeThemeSetting$ | async"
        [deviceMode]="deviceMode"
        (changeDeviceMode)="changeDeviceMode.emit($event)"
    ></sp-website-theme-control-resolver>
</div>
