import { EChatBotsMessengers } from '@app/features/chat-bots/enum/chat-bots.enum';
import { EButtonStyleType } from '@common/enums';
import { buttonStyleTypeTranslations } from '@app/core/translations';

export const buttonStyleTypeOptions = [
    { label: buttonStyleTypeTranslations[EButtonStyleType.classic], type: EButtonStyleType.classic },
    { label: buttonStyleTypeTranslations[EButtonStyleType.primary], type: EButtonStyleType.primary },
    { label: buttonStyleTypeTranslations[EButtonStyleType.secondary], type: EButtonStyleType.secondary },
    { label: buttonStyleTypeTranslations[EButtonStyleType.custom], type: EButtonStyleType.custom },
];

export const chatBotsMessengerName = {
    [EChatBotsMessengers.telegram]: 'Telegram',
    [EChatBotsMessengers.vk]: 'VKontakte',
    [EChatBotsMessengers.messenger]: 'Messenger',
    [EChatBotsMessengers.instagram]: 'Instagram',
    [EChatBotsMessengers.whatsapp]: 'WhatsApp',
    [EChatBotsMessengers.facebook]: 'Facebook',
    [EChatBotsMessengers.viber]: 'Viber',
    [EChatBotsMessengers.snapchat]: 'Snapchat',
    [EChatBotsMessengers.skype]: 'Skype',
    [EChatBotsMessengers.ok]: 'Ok',
    [EChatBotsMessengers.line]: 'Line',
    [EChatBotsMessengers.kik]: 'Kik',
    [EChatBotsMessengers.email]: 'Email',
    [EChatBotsMessengers.phone]: 'Phone',
};

export const chatBotsUrl = {
    [EChatBotsMessengers.telegram]: [
        'https://t.me',
        'https://telegram.me',
        'http://t.me',
        'http://telegram.me',
        'https://www.t.me',
        'https://www.telegram.me',
        'http://www.t.me',
        'http://www.telegram.me',
        'tg://',
    ],
    [EChatBotsMessengers.vk]: [
        'https://vk.me',
        'https://vk.com',
        'http://vk.me',
        'http://vk.com',
        'https://www.vk.me',
        'https://www.vk.com',
        'http://www.vk.me',
        'http://www.vk.com',
    ],
    [EChatBotsMessengers.messenger]: ['https://m.me', 'https://www.messenger.com', 'http://m.me', 'http://www.messenger.com'],
    [EChatBotsMessengers.instagram]: [
        'https://instagram.com',
        'http://instagram.com',
        'https://www.instagram.com',
        'http://www.instagram.com',
        'https://ig.me',
        'https://www.ig.me',
    ],
    [EChatBotsMessengers.whatsapp]: [
        'https://wa.me',
        'https://web.whatsapp.com',
        'http://wa.me',
        'http://web.whatsapp.com',
        'https://www.wa.me',
        'https://www.web.whatsapp.com',
        'http://www.wa.me',
        'http://www.web.whatsapp.com',
        'https://api.whatsapp.com/send',
        'https://www.api.whatsapp.com/send',
        'whatsapp://',
    ],
    [EChatBotsMessengers.facebook]: [
        'https://facebook.com',
        'https://www.messenger.com',
        'http://facebook.com',
        'http://www.messenger.com',
        'https://www.facebook.com',
        'https://messenger.com',
        'http://www.facebook.com',
        'http://messenger.com',
    ],
    [EChatBotsMessengers.viber]: [
        'https://vb.me',
        'https://chats.viber.com',
        'http://vb.me',
        'http://chats.viber.com',
        'https://www.vb.me',
        'https://www.chats.viber.com',
        'http://www.vb.me',
        'http://www.chats.viber.com',
        'https://viber.click',
        'https://www.viber.click',
        'https://invite.viber.com',
        'https://www.invite.viber.com',
        'viber://',
    ],
    [EChatBotsMessengers.snapchat]: ['https://snapchat.com', 'http://snapchat.com', 'https://www.snapchat.com', 'http://www.snapchat.com'],
    [EChatBotsMessengers.skype]: [
        'https://join.skype.com',
        'http://join.skype.com',
        'https://www.join.skype.com',
        'http://www.join.skype.com',
    ],
    [EChatBotsMessengers.ok]: [
        'https://ok.me',
        'https://ok.ru',
        'http://ok.me',
        'http://ok.ru',
        'https://www.ok.me',
        'https://www.ok.ru',
        'http://www.ok.me',
        'http://www.ok.ru',
    ],
    [EChatBotsMessengers.line]: [
        'https://clova.line.me',
        'https://line.me',
        'http://clova.line.me',
        'http://line.me',
        'https://www.clova.line.me',
        'https://www.line.me',
        'http://www.clova.line.me',
        'www.http://line.me',
    ],
    [EChatBotsMessengers.kik]: [
        'https://www.kik.com',
        'https://www.kik.co',
        'http://www.kik.com',
        'http://www.kik.co',
        'https://kik.com',
        'https://kik.co',
        'http://kik.com',
        'http://kik.co',
    ],
    [EChatBotsMessengers.email]: [''],
    [EChatBotsMessengers.phone]: [''],
};

export const chatBotsBrandColors = {
    [EChatBotsMessengers.telegram]: '#32AADD',
    [EChatBotsMessengers.vk]: '#2787F5',
    [EChatBotsMessengers.messenger]: '#8F3EFF',
    [EChatBotsMessengers.instagram]: '#D62976',
    [EChatBotsMessengers.whatsapp]: '#0DC143',
    [EChatBotsMessengers.facebook]: '#1877F2',
    [EChatBotsMessengers.viber]: '#675DA9',
    [EChatBotsMessengers.snapchat]: '#FFE400',
    [EChatBotsMessengers.skype]: '#00aff0',
    [EChatBotsMessengers.ok]: '#ed812b',
    [EChatBotsMessengers.line]: '#00B900',
    [EChatBotsMessengers.kik]: '#5DCD11',
    [EChatBotsMessengers.email]: '#2576D4',
    [EChatBotsMessengers.phone]: '#D44F25',
};

export const chatBotsTextColors = {
    [EChatBotsMessengers.telegram]: '#ffffff',
    [EChatBotsMessengers.vk]: '#ffffff',
    [EChatBotsMessengers.messenger]: '#ffffff',
    [EChatBotsMessengers.instagram]: '#ffffff',
    [EChatBotsMessengers.whatsapp]: '#ffffff',
    [EChatBotsMessengers.facebook]: '#ffffff',
    [EChatBotsMessengers.viber]: '#ffffff',
    [EChatBotsMessengers.snapchat]: '#000000',
    [EChatBotsMessengers.skype]: '#ffffff',
    [EChatBotsMessengers.ok]: '#ffffff',
    [EChatBotsMessengers.line]: '#ffffff',
    [EChatBotsMessengers.kik]: '#ffffff',
    [EChatBotsMessengers.email]: '#ffffff',
    [EChatBotsMessengers.phone]: '#ffffff',
};

export const DEFAULT_CHAIN_TYPE = 1;
