import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { getStyles } from '@app/features/payment-form/components/payment-form-view/payment-form-view.component.styles';
import { IPaymentFormElementData } from '@app/features/payment-form/interface/IPaymentFormElement';

import { PaymentFormModalFeatureComponent } from '@web-builder/mls-widgets/payment-form-feature/payment-form-modal-feature/payment-form-modal-feature.component';
import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-payment-form-feature',
    templateUrl: './payment-form-feature.component.html',
    styleUrls: ['./payment-form-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class PaymentFormFeatureComponent extends BaseFeatureComponent<IPaymentFormElementData> {
    protected getStyles = getStyles;
    public modalRef: BsModalRef;

    public openModal(): void {
        this.modalRef = this.modalService.show(PaymentFormModalFeatureComponent, {
            initialState: {
                classes: this.classes,
                container: this.container,
                isPreview: this.isPreview,
                resourceUrl: this.resourceUrl,
                templateId: this.templateId,
                pageId: this.pageId,
            },
            class: 'ml-modal-sm',
        });
    }
}
