import type { Domain } from '@common/types/domain.type';

import { createImmerReducer } from 'ngrx-immer/store';
import { on } from '@ngrx/store';

import DomainsActions from './domains.actions';

export const DOMAINS_FEATURE_KEY = 'domains';

export type DomainsState = {
    domains: Domain[];
    error?: string;
};

export const initialDomainsState: DomainsState = {
    domains: [],
};

export const domainsReducer = createImmerReducer<DomainsState>(
    initialDomainsState,
    on(DomainsActions.loadDomainsSuccess, (state: DomainsState, { domains }) => ({ ...state, domains })),
    on(DomainsActions.loadDomainsFailure, (state: DomainsState, { error }) => ({ ...state, error })),
);
