import type { JssStyle } from 'jss';
import type { ContainerModel } from '@app/core/models/container-model';

import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';

export function getContainerStyles(container: ContainerModel, cdnUrl: string): JssStyle {
    const { data, containerStyle } = container;

    const containerWrap: JssStyle = {
        position: 'relative',
        ...container.containerStyle,
        background: simpleColorCss((data.themeBackground ?? containerStyle?.background) as any),
        overflow: 'hidden',
    };

    if (typeof containerStyle?.background === 'string') {
        if (containerStyle?.background.includes('linear') && data.backgroundFile) {
            containerWrap.background = `url("${data.backgroundFile.defaultCdnUrl ? cdnUrl : ''}${data.backgroundFile.url}"), ${
                containerStyle?.background
            }`;

            return {
                containerWrap,
            };
        }
    }

    if (data.backgroundFile) {
        containerWrap.backgroundImage = `url("${data.backgroundFile.defaultCdnUrl ? cdnUrl : ''}${data.backgroundFile.url}")`;
    }

    return {
        containerWrap,
    };
}
