import { type JssStyle } from 'jss';

export function formatStylesToPx(data: JssStyle): JssStyle {
    // TODO: REMOVE THIS METHOD
    const formattedData = { ...data };

    Object.keys(formattedData).forEach((item) => {
        if (typeof formattedData[item] === 'number') {
            // TODO: implement rules and logic to change and control css units;
            if (item === 'lineHeight') {
                return (formattedData[item] = `${formattedData[item]}%`);
            }

            return (formattedData[item] = `${formattedData[item]}px`);
        }
    });

    return formattedData;
}

export function hashCode(...values: any[]): string {
    let hash = 0;
    let i;
    let chr;
    let str: string;

    values.forEach((value) => {
        str += typeof value === 'string' ? value : JSON.stringify(value);
    });

    const len = str.length;

    if (len === 0) {
        return hash.toString();
    }

    for (i = 0; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // convert to 32bit integer
    }

    return hash.toString();
}

export function getValuesAsString(values: Record<string, string>): string {
    return Object.entries(values).reduce((str: string, [key, value]) => `${str}${key}: ${value};\n`, '');
}

export function formattedDateTimeNow(): string {
    const d = new Date();

    return `${[d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/')}_${[d.getHours(), d.getMinutes(), d.getSeconds()].join(':')}`;
}
