import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, type OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { type Classes } from 'jss';

import { type ContainerModel } from '@app/core/models/container-model';

import {
    type IPaymentElement,
    type IPaymentFormElementData,
    type PaymentFormElement,
} from '@app/features/payment-form/interface/IPaymentFormElement';
import { type IElement } from '@app/features/common/interfaces/IElement';

import { paymentTypeNames } from '@app/features/payment-form/constants/payment-form.const';
import * as typeGuards from '@app/features/common/type-guards/form-elements.type-guards';
import { EContactFieldsCrm, EFormInputType, ESystemAddressBookVariables } from '@app/features/common/enums/form.enum';

import {
    type AbstractControl,
    type UntypedFormArray,
    UntypedFormBuilder,
    type UntypedFormGroup,
    type ValidatorFn,
    Validators,
} from '@angular/forms';
import { type EPaymentType } from '@app/features/payment-form/enums/payment-form.enum';
import { UtilsService } from '@web-builder/mls-core/services/utils.service';
import { removeWhiteSpaces } from '@web-builder/mls-core/shared/validators/removeWhiteSpaces';
import { type Option } from '@common/types/form-element.type';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@moddi3/ngx-intl-tel-input';
import { CountryISOService } from '@common/services/REST/country-iso.http.service';
import { ECrmCommentPlace, EFormControlType } from '@common/enums';
import { AnalyticsService } from '@web-builder/mls-core/services/analytics.service';

interface PaymentPayload {
    site_hash: string;
    pageId?: number;
    createAddressBook: boolean;
    paymentType: EPaymentType;
    currency: string;
    description: string;
    name: string;
    dealName: string;
    isDeal: boolean;
    elementId: string;
    addressBookId?: number;
    pipelineId?: number;
    pipelineStepId?: number;
    paymentMethodId?: string;
    isEditableTotalCost: boolean;

    totalCost: AbstractControl;
    email: AbstractControl;
    variables: UntypedFormGroup;
    contactFields: UntypedFormGroup;
    contactVariables: UntypedFormGroup;
    dealVariables: UntypedFormGroup;
    utm: string;
    contactComments?: string[];
    dealComments?: string[];
}

@Component({
    selector: 'mls-payment-form-modal-feature',
    templateUrl: './payment-form-modal-feature.component.html',
    styleUrls: ['./payment-form-modal-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFormModalFeatureComponent implements OnInit {
    private readonly countryISOService: CountryISOService = inject(CountryISOService);
    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);

    public readonly SearchCountryField = SearchCountryField;
    public readonly PhoneNumberFormat = PhoneNumberFormat;

    public container: ContainerModel;
    public classes: Classes;
    public isPreview: boolean;
    public resourceUrl: string;
    public templateId: string;
    public pageId: number;
    public validPayments: IPaymentElement[] = [];
    public selectedElement: IPaymentElement;
    public paymentTypeNames = paymentTypeNames;
    public typeGuards = typeGuards;
    public formInputType = EFormInputType;
    public paymentPayload: UntypedFormGroup;
    public elementsFormGroup = this.fb.group({
        elementsFormArray: this.fb.array([]),
    });
    public submitted: boolean = false;
    public CountryISO = CountryISO.Ukraine;
    public excludeCountries = [CountryISO.Russia];
    public isDynamicPhoneError = false;

    constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly http: HttpClient,
        private readonly fb: UntypedFormBuilder,
        private readonly utilsService: UtilsService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        if (!this.elementData.paymentElements?.length) {
            return;
        }
        this.selectedElement = this.elementData.paymentElements.find((payment) => {
            return this.selectedElement?.type === payment.type && payment.amount;
        });

        if (!this.selectedElement) {
            this.setDefaultSelectedType();
        }

        this.validPayments = this.elementData.paymentElements.filter((payment) => this.checkPaymentMethodValidity(payment));
        this.setFormData();
        this.getCountryISO();
    }

    private setFormData() {
        const { description, productName, dealName, createDeal, pipelineId, pipelineStepId, options } = this.elementData;
        const { amount, type, currency, payment_method_id } = this.selectedElement;
        const dealNameChanged = options?.dealNameChanged;
        const payload: PaymentPayload = {
            site_hash: this.templateId,
            createAddressBook: this.createAddressBook,
            totalCost: this.fb.control(amount?.toString(), [Validators.min(this.selectedElement.amount)]),
            isEditableTotalCost: false,
            paymentType: type,
            currency,
            description,
            name: productName,
            dealName: dealNameChanged ? dealName : `${dealName} ${productName}`,
            isDeal: createDeal,
            elementId: this.container.element.id,

            email: this.fb.control('', [Validators.email, Validators.required]),
            variables: this.fb.group({}),
            contactFields: this.fb.group({}),
            contactVariables: this.fb.group({}),
            dealVariables: this.fb.group({}),
            utm: this.getURLParams(),
            contactComments: [],
            dealComments: [],
        };

        if (this.pageId) {
            payload.pageId = this.pageId;
        }

        if (this.addressBookId) {
            payload.addressBookId = this.addressBookId;
        }

        if (pipelineId) {
            payload.pipelineId = pipelineId;
            payload.pipelineStepId = pipelineStepId;
        }

        if (payment_method_id) {
            payload.paymentMethodId = payment_method_id;
        }

        this.elementData.elements.forEach((el) => {
            const { variable, crmContactVariable, crmDealVariable } = el;

            const control = this.makeControl(el);
            this.elementsFormArray.push(control);

            const config = { onlyValueControl: true };
            if (variable && el.type !== EFormInputType.email) {
                payload.variables.addControl(variable, this.makeControl(el, config));
            }

            if (this.crmContactField(el)) {
                payload.contactFields.addControl(crmContactVariable.toString(), this.makeControl(el, config));
            }

            if (this.crmContactAttribute(el)) {
                payload.contactVariables.addControl(crmContactVariable.toString(), this.makeControl(el, config));
            }

            if (crmDealVariable) {
                payload.dealVariables.addControl(crmDealVariable.toString(), this.makeControl(el, config));
            }

            control.valueChanges.subscribe((elem) => {
                if (el.type === EFormInputType.email) {
                    payload.email.patchValue(elem.value);
                    return;
                }

                payload.variables.get(variable)?.patchValue(elem.value);
                payload.contactFields.get([crmContactVariable])?.patchValue(elem.value);
                payload.contactVariables.get([crmContactVariable])?.patchValue(elem.value);
                payload.dealVariables.get([crmDealVariable])?.patchValue(elem.value);
            });
        });

        this.paymentPayload = this.fb.group(payload);

        this.elementsFormGroup.updateValueAndValidity();
    }

    private makeControl(
        element: PaymentFormElement,
        options: { onlyValueControl: boolean } = { onlyValueControl: false },
    ): AbstractControl {
        const { required } = element;
        const validators: ValidatorFn[] = [];
        const group = this.fb.group({ ...element });

        if (element.type === EFormInputType.email) {
            validators.push(Validators.email);
        }

        if (element.type === EFormInputType.phone && !element.isDynamicPlaceholderEnabled) {
            validators.push(Validators.pattern('[- +()0-9]{6,}'));
        }

        if (typeGuards.isInput(element) || typeGuards.isTextarea(element)) {
            validators.push(removeWhiteSpaces);
        }

        let val: string | boolean = '';
        if (typeGuards.isCheckbox(element)) {
            val = false;
        }

        if (element.type === EFormInputType.hidden) {
            val = element.value;
        }

        if (typeGuards.isRadio(element) || typeGuards.isSelect(element)) {
            const opts = element.options.map((option) => this.fb.control(option));
            val = element.options.find((option) => option.selected)?.value || '';
            group.setControl('options', this.fb.array(opts));
        }

        if (required && typeGuards.isCheckbox(element)) {
            validators.push(Validators.requiredTrue);
        } else if (required) {
            validators.push(Validators.required);
        }

        const valueControl = this.fb.control(val, validators);
        group.addControl('value', valueControl);
        group.controls.value.setValidators(validators);

        if (options.onlyValueControl) {
            return valueControl;
        }

        return group;
    }

    public get isDeal(): boolean {
        return !!this.element.data.createDeal;
    }

    public crmContactField(control): boolean {
        return Object.values(EContactFieldsCrm).includes(control.crmContactVariable);
    }

    public crmContactAttribute(control): boolean {
        return control.crmContactVariable && !this.crmContactField(control);
    }

    private checkPaymentMethodValidity(paymentMethod: IPaymentElement) {
        const { dealName, pipelineId, pipelineStepId, productName } = this.elementData;
        const { amount, type } = paymentMethod;

        const validCrm: boolean = !!(dealName?.trim() && pipelineId && pipelineStepId);
        const validPayment: boolean = !!(productName?.trim() && amount && type);

        return validPayment && validCrm;
    }

    public setDefaultSelectedType(): void {
        this.selectedElement = this.elementData.paymentElements.find((payment) => payment.amount) || ({} as IPaymentElement);
    }

    private getURLParams(): string {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.toString();
    }

    public onConfirm(): void {
        this.bsModalRef.hide();
    }

    public changeSelectValue(control: AbstractControl, event) {
        const option = event.target.value;
        const opt = control.value.options.map((el) => {
            return {
                ...el,
                ...{ selected: option === el.value },
            };
        });
        control.get('options').patchValue(opt);
        control.get('value').patchValue(option);
    }

    public onCancel(): void {
        this.bsModalRef.hide();
    }

    public get element(): IElement {
        return this.container.element;
    }

    public get elementData(): IPaymentFormElementData {
        return this.container.element.data;
    }

    public selectPaymentType(element: IPaymentElement): void {
        this.selectedElement = element;
    }

    public getPaymentLogo(paymentName: string): string {
        return this.utilsService.formatImgSrc(`./assets/img/payment-icons/lg/${paymentName}-lg.svg`);
    }

    //======================================================================//

    public get action(): string {
        return `${this.resourceUrl}/api/landings-service/public/payment/user-url`;
    }

    public change(control: AbstractControl, event) {
        control.get('value').patchValue(event.target.checked);
    }

    public changeRadio(control: UntypedFormGroup, index) {
        control.get('value').patchValue(control.value.options[index].value);
    }

    public getControlNameAttr(control: AbstractControl): string {
        if (control.value.variable === ESystemAddressBookVariables.email) {
            return 'email';
        }

        return `variables[${control.value.variable}]`;
    }

    public get addressBookId(): number {
        return this.elementData.addressBookId;
    }

    public get createAddressBook(): boolean {
        return this.elementData.createAddressBook;
    }

    public get elementsFormArray(): UntypedFormArray {
        return this.elementsFormGroup.get('elementsFormArray') as UntypedFormArray;
    }

    public submitForm(): void {
        let contactComments: string[] = [];
        let dealComments: string[] = [];

        this.elementsFormArray.value.forEach((el) => {
            if (el.type === EFormControlType.textarea) {
                if (el.crmCommentPlace === ECrmCommentPlace.crmAllComment) {
                    contactComments.push(el.value);
                    dealComments.push(el.value);
                }
                if (el.crmCommentPlace === ECrmCommentPlace.crmContactComment) {
                    contactComments.push(el.value);
                }
                if (el.crmCommentPlace === ECrmCommentPlace.crmDealComment) {
                    dealComments.push(el.value);
                }
            }
        });

        this.isDynamicPhoneError = false;

        const phoneElement = document.querySelectorAll(`.modal-content #phone`)[0] as any;
        if (phoneElement) {
            const phone = phoneElement?.value.trim().replace(/\s/g, '');
            const mask = phoneElement?.placeholder.trim().replace(/\s/g, '');
            const code = (phoneElement as any)?.offsetParent?.innerText?.trim();
            const phoneNumber = `${code}${phone}`.trim();

            this.isDynamicPhoneError = phone.length < mask.length || phone.length > mask.length + 4;

            if (this.isDynamicPhoneError) return;

            this.paymentPayload.patchValue({ variables: { phone: phoneNumber } });
        }

        this.submitted = true;
        if (this.paymentPayload.invalid) return;
        const paymentPayload = this.paymentPayload.value;

        for (let key in paymentPayload.dealVariables) {
            if (!paymentPayload.dealVariables[key]) delete paymentPayload.dealVariables[key];
        }

        const payload = {
            ...paymentPayload,
            contactComments,
            dealComments,
            totalCost: this.totalCost.value.toString(),
        };
        this.http.post(this.action, payload).subscribe(
            (response: any) => {
                if (response?.data) {
                    this.sendGaEvent();
                    this.sendPixelFbEvent(payload.currency, payload.totalCost);

                    this.onCancel();
                        window.location.href = response.data.url;
                    return;
                }
            },
        );
    }

    public changeAmount(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.totalCost.setValue(Number(value));
    }

    public validateAmount(): void {
        if (this.totalCost.value < this.selectedElement.amount) {
            this.totalCost.setValue(this.selectedElement.amount);
        }
    }

    public get totalCost(): AbstractControl {
        return this.paymentPayload.get('totalCost') as AbstractControl;
    }

    public getSelected(options: Option[]) {
        return !options.some((el) => el.selected);
    }

    public getInputIdForSelenium(control: any): string {
        return `${this.element.id}${control.value?.variable ? '-variable-' + control.value?.variable : ''}${
            control.value?.crmContactVariable ? '-crmContactVariable-' + control.value?.crmContactVariable : ''
        }${control.value?.crmDealVariable ? '-crmDealVariable-' + control.value?.crmDealVariable : ''}`;
    }

    public getPhoneErrorMsg() {
        return (document.getElementById('phone') as any).placeholder;
    }

    private getCountryISO() {
        this.countryISOService.getCountryISO().subscribe((data) => {
            this.CountryISO = data.country_code.toLowerCase();
            this.changeDetectorRef.detectChanges();
        });
    }

    private sendGaEvent() {
        if (this.elementData?.analyticsSettings?.googleAnalytic) {
            this.analyticsService.gaEventRequest(this.elementData?.analyticsSettings?.googleSettings);
        }
    }

    private sendPixelFbEvent(currency: string, value: string) {
        if (this.elementData?.analyticsSettings?.pixelAnalytic) {
            this.analyticsService.pixelFbEventRequest({ ...this.elementData?.analyticsSettings?.pixelSettings, currency, value });
        }
    }
}
