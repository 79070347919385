import { type JssStyle } from 'jss';

import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';

import { type IElement } from '@app/features/common/interfaces/IElement';
import { commonFontSizeCoefficient, pStyles } from '@app/features/common/styles/text.styles';
import { ETimerAppearanceSeparator } from '@app/features/timer/enum/timer.enum';
import { ESimpleThemeColorNames } from '@libs/themes/src/variants/simple/enums/color.enum';
import { ESize } from '@common/enums';

const commonColonSeparator = (element: IElement): JssStyle => {
    return {
        content: '""',
        position: 'absolute',
        display: 'block',
        background: element.data.dividerColor ? simpleColorCss(element.data.dividerColor) : simpleColorCss(ESimpleThemeColorNames.text),
    };
};

const commonLineSeparator = (element: IElement): JssStyle => {
    return {
        content: '""',
        position: 'absolute',
        top: '0',
        width: '1px',
        height: '100%',
        display: 'block',
        background: element.data.dividerColor ? simpleColorCss(element.data.dividerColor) : simpleColorCss(ESimpleThemeColorNames.primary),
    };
};

const separatorS = (element: IElement): JssStyle => {
    const commonSeparatorStyles = {
        position: 'relative',
        margin: '0 2px',
    };

    switch (element.data.appearance) {
        case ETimerAppearanceSeparator.none:
            return { ...commonSeparatorStyles };
        case ETimerAppearanceSeparator.colon:
            return {
                ...commonSeparatorStyles,
                '&:before': {
                    ...commonColonSeparator(element),
                    top: '10px',
                    width: '2px',
                    height: '2px',
                },
                '&:after': {
                    ...commonColonSeparator(element),
                    top: '16px',
                    width: '2px',
                    height: '2px',
                },
            };
        case ETimerAppearanceSeparator.line:
            return {
                ...commonSeparatorStyles,
                '&:before': { ...commonLineSeparator(element) },
            };
        default:
            return { ...commonSeparatorStyles };
    }
};

const separatorM = (element: IElement): JssStyle => {
    const commonSeparatorStyles = {
        position: 'relative',
        margin: '0 4px',
    };

    switch (element.data.appearance) {
        case ETimerAppearanceSeparator.none:
            return { ...commonSeparatorStyles };
        case ETimerAppearanceSeparator.colon:
            return {
                ...commonSeparatorStyles,
                '&:before': {
                    ...commonColonSeparator(element),
                    top: '13px',
                    width: '3px',
                    height: '3px',
                },
                '&:after': {
                    ...commonColonSeparator(element),
                    top: '20px',
                    width: '3px',
                    height: '3px',
                },
            };
        case ETimerAppearanceSeparator.line:
            return {
                ...commonSeparatorStyles,
                '&:before': { ...commonLineSeparator(element) },
            };
        default:
            return { ...commonSeparatorStyles };
    }
};

const separatorL = (element: IElement): JssStyle => {
    const commonSeparatorStyles = {
        position: 'relative',
        margin: '0 6px',
    };

    switch (element.data.appearance) {
        case ETimerAppearanceSeparator.none:
            return { ...commonSeparatorStyles };
        case ETimerAppearanceSeparator.colon:
            return {
                ...commonSeparatorStyles,
                '&:before': {
                    ...commonColonSeparator(element),
                    top: '12px',
                    width: '4px',
                    height: '4px',
                },
                '&:after': {
                    ...commonColonSeparator(element),
                    top: '21px',
                    width: '4px',
                    height: '4px',
                },
            };
        case ETimerAppearanceSeparator.line:
            return {
                ...commonSeparatorStyles,
                '&:before': { ...commonLineSeparator(element) },
            };
        default:
            return { ...commonSeparatorStyles };
    }
};

const separatorStyles = (element: IElement): JssStyle => {
    switch (element.data.size) {
        case ESize.S:
            return separatorS(element);
        case ESize.M:
            return separatorM(element);
        case ESize.L:
            return separatorL(element);
        default:
            return separatorM(element);
    }
};

export function getStyles(element: IElement): JssStyle {
    const fontSizeCoefficient = commonFontSizeCoefficient[element.data.size];

    return {
        element: {
            // padding: '0 0 27px',
        },
        wrapColumns: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        wrapColumn: {
            display: 'flex',
            '&:last-child .separator': {
                display: 'none',
            },
        },
        column: {
            width: '80px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        value: {
            ...pStyles,
            minWidth: '40px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: `calc(24px * ${fontSizeCoefficient})`,
            color: simpleColorCss(element.data.textColor),
        },
        label: {
            ...pStyles,
            overflow: 'hidden',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: `calc(15px * ${fontSizeCoefficient})`,
            color: simpleColorCss(element.data.textColor),
        },
        expiredText: {
            ...pStyles,
            fontSize: `calc(${pStyles.fontSize} * ${fontSizeCoefficient})`,
            color: simpleColorCss(element.data.textColor),
        },
        separator: separatorStyles(element),
    };
}
