import { type PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'spSafeHtml',
})
export class SpSafeHtmlPipe implements PipeTransform {
    constructor(private readonly sanitized: DomSanitizer) {}

    public transform(value: string): SafeHtml {
        if (!value) {
            return '';
        }

        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
