import { Injectable } from '@angular/core';

import { PlatformService } from '@common/services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class TrackingLinkConverterService {
    private readonly HTTP_PROTOCOL: string = 'http://';
    private readonly HTTPS_PROTOCOL: string = 'https://';
    private readonly VIBER_PROTOCOL: string = 'viber://';
    private readonly EMAIL_PROTOCOL: string = 'mailto:';
    private readonly PHONE_PROTOCOL: string = 'tel:';
    private readonly TRACKING_DOMAIN: string = 'https://click.pulse.is/public';

    private siteId!: string;
    private pageId!: string;
    private domainUrl!: string;
    private isInitialized: boolean = false;

    constructor(private readonly platformService: PlatformService) {}

    public init(siteId: number, pageId: number, domainUrl: string): void {
        if (this.isInitialized) {
            return;
        }

        this.siteId = siteId?.toString();
        this.pageId = pageId?.toString();
        this.domainUrl = domainUrl;
        this.isInitialized = true;
    }

    public convertAllTrackingLinksFromText(text: string): string {
        // TODO: refactor, find better solution
        const links = text.match(/<a[^>]*href="([^"]*)"[^>]*>/g);
        if (!links) {
            return text;
        }

        links.forEach((link: string) => {
            if (link.includes('href=""')) {
                return;
            }

            const linkHref: string = link.replace('href="', '').replace('"', '');
            const trackingLink: string = this.convertToTrackingLink(this.normalizeLink(linkHref));
            text = text.replace(linkHref, trackingLink);
        });

        return text;
    }

    public convertToTrackingLink(normalizedLink: string): string {
        if (this.platformService.isPlatformServer() || !this.siteId || !this.pageId) {
            return normalizedLink;
        }

        try {
            return `${this.TRACKING_DOMAIN}/s/${this.toBase64(this.siteId)}/p/${this.toBase64(this.pageId)}/l/${this.toBase64(
                normalizedLink,
            )}`;
        } catch (e) {
            console.error(e);
            return normalizedLink;
        }
    }

    private toBase64(str: string): string {
        if (this.platformService.isPlatformBrowser()) {
            return window?.btoa(str);
        }

        return Buffer.from(str).toString('base64');
    }

    public normalizeLink(link: string): string {
        if (!link) {
            return link;
        }

        if (link.startsWith('/')) {
            return this.domainUrl + link;
        }

        if (link.startsWith(this.HTTPS_PROTOCOL) || link.startsWith(this.VIBER_PROTOCOL)) {
            return link;
        }

        if (link.startsWith(this.HTTP_PROTOCOL)) {
            return link.replace(this.HTTP_PROTOCOL, this.HTTPS_PROTOCOL);
        }

        return this.HTTPS_PROTOCOL + link;
    }

    public normalizeEmailLink(link: string): string {
        if (link.startsWith(this.EMAIL_PROTOCOL)) {
            return link;
        }

        return this.EMAIL_PROTOCOL + link;
    }

    public normalizePhoneLink(link: string): string {
        if (link.startsWith(this.PHONE_PROTOCOL)) {
            return link;
        }

        return this.PHONE_PROTOCOL + link;
    }
}
