import type { JssStyle } from 'jss';
import { simpleColorCss, simpleFontCss } from '@libs/themes/src/variants/simple/utils/variables';
import { ESize } from '@common/enums';

import { ESimpleThemeColorNames } from '@libs/themes/src/variants/simple/enums/color.enum';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { EThemeFontTags } from '@libs/themes';

// TEXT STYLES
const textCommon: JssStyle = {
    padding: 0,
    color: simpleColorCss(ESimpleThemeColorNames.text),
};

export const h1Styles: JssStyle = {
    ...textCommon,
    fontFamily: simpleFontCss(EThemeFontTags.h1, ESimpleThemeFontProperties.fontFamily),
    fontSize: simpleFontCss(EThemeFontTags.h1, ESimpleThemeFontProperties.fontSize),
    fontWeight: simpleFontCss(EThemeFontTags.h1, ESimpleThemeFontProperties.fontWeight),
    fontStyle: simpleFontCss(EThemeFontTags.h1, ESimpleThemeFontProperties.fontStyle),
    lineHeight: simpleFontCss(EThemeFontTags.h1, ESimpleThemeFontProperties.lineHeight),
    textTransform: simpleFontCss(EThemeFontTags.h1, ESimpleThemeFontProperties.textTransform),
};

export const h2Styles: JssStyle = {
    ...textCommon,
    fontFamily: simpleFontCss(EThemeFontTags.h2, ESimpleThemeFontProperties.fontFamily),
    fontSize: simpleFontCss(EThemeFontTags.h2, ESimpleThemeFontProperties.fontSize),
    fontWeight: simpleFontCss(EThemeFontTags.h2, ESimpleThemeFontProperties.fontWeight),
    fontStyle: simpleFontCss(EThemeFontTags.h2, ESimpleThemeFontProperties.fontStyle),
    lineHeight: simpleFontCss(EThemeFontTags.h2, ESimpleThemeFontProperties.lineHeight),
    textTransform: simpleFontCss(EThemeFontTags.h2, ESimpleThemeFontProperties.textTransform),
};

export const h3Styles: JssStyle = {
    ...textCommon,
    fontFamily: simpleFontCss(EThemeFontTags.h3, ESimpleThemeFontProperties.fontFamily),
    fontSize: simpleFontCss(EThemeFontTags.h3, ESimpleThemeFontProperties.fontSize),
    fontWeight: simpleFontCss(EThemeFontTags.h3, ESimpleThemeFontProperties.fontWeight),
    fontStyle: simpleFontCss(EThemeFontTags.h3, ESimpleThemeFontProperties.fontStyle),
    lineHeight: simpleFontCss(EThemeFontTags.h3, ESimpleThemeFontProperties.lineHeight),
    textTransform: simpleFontCss(EThemeFontTags.h3, ESimpleThemeFontProperties.textTransform),
};

export const h4Styles: JssStyle = {
    ...textCommon,
    fontFamily: simpleFontCss(EThemeFontTags.h4, ESimpleThemeFontProperties.fontFamily),
    fontSize: simpleFontCss(EThemeFontTags.h4, ESimpleThemeFontProperties.fontSize),
    fontWeight: simpleFontCss(EThemeFontTags.h4, ESimpleThemeFontProperties.fontWeight),
    fontStyle: simpleFontCss(EThemeFontTags.h4, ESimpleThemeFontProperties.fontStyle),
    lineHeight: simpleFontCss(EThemeFontTags.h4, ESimpleThemeFontProperties.lineHeight),
    textTransform: simpleFontCss(EThemeFontTags.h4, ESimpleThemeFontProperties.textTransform),
};

export const pStyles: JssStyle = {
    ...textCommon,
    fontFamily: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontFamily),
    fontSize: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontSize),
    fontWeight: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontWeight),
    fontStyle: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.fontStyle),
    lineHeight: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.lineHeight),
    textTransform: simpleFontCss(EThemeFontTags.p, ESimpleThemeFontProperties.textTransform),
};
// END TEXT STYLES

export const commonFontSizeCoefficient = {
    [ESize.S]: 0.8,
    [ESize.M]: 1,
    [ESize.L]: 1.2,
};
