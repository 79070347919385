import { ChangeDetectionStrategy, Component, Input, type OnChanges, type SimpleChanges } from '@angular/core';

@Component({
    selector: 'sp-transparent-icon',
    templateUrl: './sp-transparent-icon.component.html',
    styleUrls: ['./sp-transparent-icon.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpTransparentIconComponent implements OnChanges {
    @Input() public color!: string;

    private readonly regExp: RegExp = /^rgba\((?:\d{1,3}%?,\s?){3}(1|0?(?:\.\d+)?)\)/;
    public readonly MAX_OPACITY_VALUE = 1;
    public readonly MIN_OPACITY_VALUE = 0;
    public alpha: number = 0;

    public ngOnChanges(changes: SimpleChanges): void {
        const alphaValue = parseFloat(changes['color']?.currentValue?.match(this.regExp)?.[1]);

        if (alphaValue <= this.MAX_OPACITY_VALUE) {
            this.alpha = this.MAX_OPACITY_VALUE - alphaValue;
            return;
        }

        this.alpha = 0;
    }
}
