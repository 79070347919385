import type { ELanguages } from '@common/enums';

import { on, createReducer } from '@ngrx/store';
import AppActions from './app.actions';

export const APP_FEATURE_KEY = 'app';

export type AppState = {
    isReady: boolean;
    isAdmin: boolean;
    error?: string;
    locale: ELanguages;
};

export const initialAppState: AppState = {
    isReady: false,
    isAdmin: false,
    error: null,
    locale: null,
};

export const appReducer = createReducer<AppState>(
    initialAppState,
    on(AppActions.initAppSuccess, (state: AppState) => ({ ...state, isReady: true })),
    on(AppActions.initAppFailure, (state: AppState, { error }) => ({ ...state, error })),
    on(AppActions.setIsAdmin, (state: AppState, { isAdmin }) => ({ ...state, isAdmin })),
    on(AppActions.setLocale, (state: AppState, { locale }) => ({ ...state, locale })),
);
