import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { IBannerElementData } from '@app/features/edu-banner/interface/IBanner';
import { getStyles } from '@app/features/edu-banner/components/edu-banner-view/edu-banner-view.component.styles';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';
import { EduBannerModalFeatureComponent } from '@web-builder/mls-widgets/edu-banner-feature/edu-banner-modal-feature/edu-banner-modal-feature.component';
import { ModalStates } from '@web-builder/mls-widgets/edu-banner-feature/edu-banner-modal-feature/modal-states.enum';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'mls-edu-banner-feature',
    templateUrl: './edu-banner-feature.component.html',
    styleUrls: ['./edu-banner-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class EduBannerFeatureComponent extends BaseFeatureComponent<IBannerElementData> implements AfterViewInit {
    protected getStyles = getStyles;

    public modalRef: BsModalRef;
    public modalStates = ModalStates;
    public isLoggedIn: boolean = false;

    private modalState: ModalStates = ModalStates.signUp;
    private resetPasswordData: Record<string, string> = {};

    public ngAfterViewInit(): void {
        if (this.isServer) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const { pathname } = window.location;

        const courseId = urlParams.get('courseId');
        const expires = urlParams.get('expires');
        const signature = urlParams.get('signature');
        const verifyEmailAction = pathname.includes('/email/verify') && courseId && signature && expires;

        const token = urlParams.get('token');
        const resetHash = urlParams.get('hash');
        const resetUuid = urlParams.get('uuid');
        const resetPasswordAction =
            pathname.includes('/reset-password') && courseId && signature && expires && token && resetUuid && resetHash;

        const action = urlParams.get('action');

        if (verifyEmailAction) {
            const [uuid, hash] = window.location.pathname
                .split('/')
                .filter((s) => s)
                .slice(-2);

            this.eduServiceHttp.verify({ uuid, hash, expires, signature, courseId }).subscribe(() => {
                this.modalState = ModalStates.signIn;
                this.openPrimaryModal();
            });
        } else if (resetPasswordAction) {
            this.resetPasswordData = { uuid: resetUuid, hash: resetHash, expires, signature, token, courseId };
            this.modalState = ModalStates.changePassword;
            this.openPrimaryModal();
        } else if (action === 'login') {
            this.modalState = ModalStates.signIn;
            this.openPrimaryModal();
        } else if (action === 'signup') {
            this.modalState = ModalStates.signUp;
            this.openPrimaryModal();
        }

        if (this.cookieService.check('oauth_access_token')) {
            this.eduServiceHttp.studentCourseStatus(this.transferStateService.get('courseId')).subscribe(
                (res: string) => {
                    this.isLoggedIn = res !== 'deletedFromCourse';

                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.isLoggedIn = false;
                    this.changeDetectorRef.detectChanges();
                },
            );
        }
    }

    public openPrimaryModal(modalState = this.modalState): void {
        this.modalRef = this.modalService.show(EduBannerModalFeatureComponent, {
            initialState: {
                classes: this.classes,
                container: this.container,
                isPreview: this.isPreview,
                modalState,
                resetPasswordData: this.resetPasswordData,
            },
            class: 'ml-modal-sm',
        });
    }

    public secondaryButtonAction() {
        document.querySelector('mls-subscriptions-form-feature').scrollIntoView({ behavior: 'smooth' });
    }

    public loginToAccount(): void {
        const courseId = this.transferStateService.get('courseId');
        this.eduServiceHttp.studentCourseStatus(courseId).subscribe(
            (res) => {
                if (res === 'deletedFromCourse') {
                    this.openPrimaryModal(ModalStates.signInSuccess);
                    return;
                }

                window.location.href = environment.eduStudentUrl;
            },
            (err) => {
                if (err.status === 403) {
                    this.openPrimaryModal(ModalStates.signInSuccess);
                } else {
                    this.openPrimaryModal(ModalStates.signUp);
                }
                this.changeDetectorRef.detectChanges();
            },
        );
    }
}
