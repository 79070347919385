<div class="device-toggle-controls btn-group">
    <button
        class="btn btn-sm btn-action device-toggle-btn"
        [class.selected]="isDesktop"
        (click)="changeDeviceMode.emit(deviceMode.desktop)"
    >
        <i class="sp-icon icon-desktop"></i>
    </button>
    <button
        class="btn btn-sm btn-action device-toggle-btn"
        [class.selected]="isMobile"
        (click)="changeDeviceMode.emit(deviceMode.mobile)"
    >
        <i class="sp-icon icon-mobile"></i>
    </button>
</div>
