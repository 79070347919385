<div [class]="classes?.wrap">
    <div [class]="classes?.messenger" *ngFor="let chatBot of elementData.chatBots">
        <a *ngIf="isChatBotsTypeIcon"
           [class]="classes?.element"
           [attr.href]="getChatBotLink(chatBot)"
           target="_blank">
            <div [class]="classes?.wrapIcon">
                <img class="icon" [alt]="chatBot.messenger" [src]="getIconSrc(chatBot.messenger)">
            </div>
        </a>

        <a *ngIf="!isChatBotsTypeIcon"
           class="btn-hover"
           [class]="classes?.element"
           [attr.href]="getChatBotLink(chatBot)"
           [ngStyle]="isButtonTypeClassic && { borderColor: getBrandColors(chatBot.messenger), background: getBrandColors(chatBot.messenger) }"
           target="_blank">
            <div [class]="classes?.wrapIcon">
                <div [innerHTML]="getIcon(chatBot.messenger)" class="icon"></div>
            </div>

            <div [class]="classes?.wrapContent">
                <span [class]="classes?.label"
                      [ngStyle]="isButtonTypeClassic && { color: chatBotsTextColors(chatBot.messenger) }"
                >{{ chatBot.label }}</span>
                <span *ngIf="!!chatBot.description && chatBot.showDescription"
                      [class]="classes?.description"
                      [ngStyle]="isButtonTypeClassic && { color: chatBotsTextColors(chatBot.messenger) }"
                >{{ chatBot.description }}</span>
            </div>
        </a>
    </div>
</div>
