<a [class]="classes?.element" class="btn-hover" [attr.href]="getHref(elementData.buttonLink)" [target]="target" (click)="trySendAnalytic()">
    <div [class]="classes?.wrapIcon">
        <div [innerHTML]="getIcon(elementData.buttonLink.type)" class="icon"></div>
    </div>

    <div [class]="classes?.wrapContent">
        <span [class]="classes?.label">{{ elementData.content }}</span>
        <span *ngIf="!!elementData.description && elementData.showDescription"
              [class]="classes?.description"
        >{{ elementData.description }}</span>
    </div>
</a>
