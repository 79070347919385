import { type JssStyle } from 'jss';

import { type IElement } from '@app/features/common/interfaces/IElement';
import { type IMenuElementData } from '@app/features/menu/interface/IMenu';
import { EDirection } from '@app/features/common/enums/direction.enum';
import { EHorizontalPosition } from '@common/enums';
import { h2Styles, pStyles } from '@app/features/common/styles/text.styles';
import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';

function getJustifyContent(elementData: IMenuElementData) {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { justifyContent: 'flex-start' };
        case EHorizontalPosition.M:
            return { justifyContent: 'center' };
        case EHorizontalPosition.R:
            return { justifyContent: 'flex-end' };
        default:
            return { justifyContent: 'normal' };
    }
}

function getAlignItems(elementData: IMenuElementData) {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { alignItems: 'flex-start' };
        case EHorizontalPosition.M:
            return { alignItems: 'center' };
        case EHorizontalPosition.R:
            return { alignItems: 'flex-end' };
        default:
            return { alignItems: 'normal' };
    }
}

function getPosition(elementData: IMenuElementData) {
    if (elementData.showAsIcon) {
        return {
            justifyContent: 'flex-start',
            alignItems: 'center',
        };
    }

    if (elementData.layoutDirection === EDirection.R) {
        return getJustifyContent(elementData);
    }

    return getAlignItems(elementData);
}

function getDirection(elementData: IMenuElementData) {
    if (elementData.showAsIcon) {
        return { flexDirection: 'column' };
    }
    return { flexDirection: elementData.layoutDirection === EDirection.R ? 'row' : 'column' };
}

export function getStyles(element: IElement): JssStyle {
    const elementData = element.data;
    return {
        element: {},
        menuList: {
            display: 'flex',
            flexWrap: 'wrap',
            ...getDirection(elementData),
            ...getPosition(elementData),
        },
        menuItem: {
            padding: '5px',
            '& a': {
                ...pStyles,
                textDecoration: 'none',
                color: simpleColorCss(element.data.textColor),
                '&:hover': {
                    opacity: '0.75',
                },
            },
        },
        wrapMenuIcon: {
            display: 'flex',
            flexDirection: 'row',
            alignedItems: 'center',
            ...getJustifyContent(elementData),
            '& svg': {
                fill: simpleColorCss(element.data.iconColor),
                cursor: 'pointer',
            },
        },
        closeBtn: {
            position: 'absolute',
            top: '0',
            right: '0',
            fontSize: h2Styles.fontSize,
            lineHeight: h2Styles.fontSize,
            color: '#CBCBCB',
            background: 'none',
            border: 'none',
            '&:hover': {
                color: '#9d9d9d',
            },
        },
    };
}
