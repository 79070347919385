import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EWebsiteThemeColorNames } from '@libs/themes/src/variants/website/enums/color.enum';
import { SpThemeColorsSettingsComponent } from '@libs/themes/src/abstract/abstract-colors-settings.component';

@Component({
    selector: 'sp-website-colors-settings',
    styleUrls: ['./website-colors-settings.component.less'],
    templateUrl: './website-colors-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpWebsiteColorsSettingsComponent extends SpThemeColorsSettingsComponent {
    public colorNames = Object.values(EWebsiteThemeColorNames); // keep order
    public readonly COLOR_NAME_TRANSLATIONS: Readonly<Record<EWebsiteThemeColorNames, string>> = {
        [EWebsiteThemeColorNames.light]: 'theme_settings_color_light',
        [EWebsiteThemeColorNames.dark]: 'theme_settings_color_dark',
        [EWebsiteThemeColorNames.primary]: 'theme_settings_color_primary',
        [EWebsiteThemeColorNames.secondary]: 'theme_settings_color_secondary',
        [EWebsiteThemeColorNames.accent]: 'theme_settings_color_accent',
    };

    public readonly COLOR_DESCRIPTION_TRANSLATIONS: Readonly<Record<EWebsiteThemeColorNames, string>> = {
        [EWebsiteThemeColorNames.light]: 'theme_settings_color_light_description',
        [EWebsiteThemeColorNames.dark]: 'theme_settings_color_dark_description',
        [EWebsiteThemeColorNames.primary]: 'theme_settings_color_primary_description',
        [EWebsiteThemeColorNames.secondary]: 'theme_settings_color_secondary_description',
        [EWebsiteThemeColorNames.accent]: 'theme_settings_color_accent_description',
    };

    protected initForm(): void {
        this.form = this.fb.group({
            [EWebsiteThemeColorNames.light]: null,
            [EWebsiteThemeColorNames.dark]: null,
            [EWebsiteThemeColorNames.primary]: null,
            [EWebsiteThemeColorNames.secondary]: null,
            [EWebsiteThemeColorNames.accent]: null,
        });
    }
}
