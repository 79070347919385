export enum EButtonStyleType {
    classic = 'classic',
    primary = 'primary',
    secondary = 'secondary',
    custom = 'custom',
}

export enum EButtonHoverType {
    opacity = 'opacity',
    color = 'color',
    shadow = 'shadow',
}

export enum EButtonType {
    iconText = 'iconText',
    icon = 'icon',
    button = 'button',
}

export enum ESocialButtonType {
    iconText = 'iconText',
    icon = 'icon',
}

export enum EButtonPositions {
    L = 'L', // left
    M = 'M', // center
    R = 'R', // right
}

export enum EButtonSocialIconTypes {
    logos = 'logos',
    round = 'round',
    square = 'square',
    flat = 'flat',
    grey = 'grey',
    outlined = 'outlined',
    outlined_white = 'outlined_white',
    white = 'white',
}

export enum EButtonLinkType {
    email = 'email',
    phone = 'phone',
    link = 'link',
    anchor = 'anchor',
}

export enum EButtonWidthType {
    auto = 'auto',
    full = 'full',
    fixed = 'fixed',
}

export enum EButtonAdditionalEffectsCondition {
    hover = 'hover',
    static = 'static',
}

export enum EButtonAdditionalHoverEffectsTypes {
    decrease = 'decrease',
    increase = 'increase',
    bounce = 'bounce',
    shake = 'shake',
}

export enum EButtonAdditionalStaticEffectsTypes {
    twinkle = 'twinkle',
    shine = 'shine',
}
