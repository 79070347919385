import { createActionGroup, emptyProps, props } from '@ngrx/store';
import type { ProductCard } from '@common/types/product-card.type';

export const CartActions = createActionGroup({
    source: 'Cart',
    events: {
        'Get cart local store': emptyProps(),
        'Cart loading': emptyProps(),
        'Cart loaded': emptyProps(),
        'Add item to cart': props<{ cartItem: ProductCard }>(),
        'Remove item from cart': props<{ cartItem: ProductCard }>(),
        'Item added to cart successfully': emptyProps(),
        'Item removed from cart successfully': emptyProps(),
        'Set number of item in cart': props<{ cartItem: ProductCard; selectedQuantity: number }>(),
        'Item number set successfully': emptyProps(),
    },
});
