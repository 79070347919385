import type { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

const ThemeConfigActions = createActionGroup({
    source: 'ThemeConfig',
    events: {
        'Set Active Theme Setting': props<{ value: EThemeSettings }>(),
        'Unselect Active Theme Setting': emptyProps(),
    },
});

export default ThemeConfigActions;
