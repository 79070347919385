import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { cartReducer } from './cart.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CartEffect } from './cart.effect';

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule, EffectsModule.forFeature([CartEffect]), StoreModule.forFeature('cartFeature', cartReducer)],
})
export class CartModule {}
