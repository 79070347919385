import { createActionGroup, props } from '@ngrx/store';

import { type PageSettings, type PageSettingsUpdateRequest } from '@common/types/page-settings.type';

export const PageSettingsActions = createActionGroup({
    source: 'Settings',
    events: {
        'Load page settings request': props<{ siteId: string; pageId: string }>(),
        'Load page settings success': props<{ settings: PageSettings }>(),
        'Load page settings failure': props<{ error: string }>(),
        'Update page settings request': props<{ siteId: number; pageId: number; settings: PageSettingsUpdateRequest }>(),
        'Update page settings success': props<{ siteId: number; pageId: number; pageSettings: PageSettings }>(),
        'Update page settings failure': props<{ siteId: number; pageId: number; error: any }>(),
    },
});

export default PageSettingsActions;
