import { createReducer, on } from '@ngrx/store';

import type { ThemeSelectedColors } from '@common/libs/themes/src/types/theme-color.type';
import type { ThemeSelectedButtons } from '@common/libs/themes/src/types/theme-button.type';
import type { ThemeSelectedFonts } from '@common/libs/themes/src/types/theme-font.type';

import ThemeActions from '@common/libs/themes/src/store/theme/theme.actions';
import ThemeSettingsActions from './theme-settings.actions';

export type ThemeSettingsState = {
    colors?: ThemeSelectedColors;
    buttons?: ThemeSelectedButtons;
    fonts?: ThemeSelectedFonts;
    root?: any;
};

export const THEME_SETTINGS_STORE_KEY = 'settings';

const initialThemeSettingsState: ThemeSettingsState = {};

export const themeSettingsReducer = createReducer<ThemeSettingsState>(
    initialThemeSettingsState,
    on(ThemeActions.setTheme, (state, { settings }) => ({ ...state, ...settings })),

    on(ThemeSettingsActions.setInitialTheme, (state, { fonts, colors, buttons }) => ({ ...state, fonts, colors, buttons })),
    on(ThemeSettingsActions.updateFonts, ThemeSettingsActions.setFonts, (state, { fonts }) => ({ ...state, fonts })),
    on(ThemeSettingsActions.changeFontsSynchronization, (state, { isSynchronized }) => ({
        ...state,
        fonts: { ...state.fonts!, isSynchronized },
    })),
    on(ThemeSettingsActions.updateButtons, ThemeSettingsActions.setButtons, (state, { buttons }) => ({ ...state, buttons })),
    on(ThemeSettingsActions.updateColors, ThemeSettingsActions.setColors, (state, { colors }) => ({ ...state, colors })),
    on(ThemeSettingsActions.updateRoot, (state, { root }) => ({ ...state, root })),
);
