//@ts-ignore
import Plyr from '@sendpulse/plyr';

interface PlyrDriverCreateParams {
    options: Plyr.Options;
    videoElement: HTMLVideoElement;
}

interface PlyrDriverUpdateSourceParams {
    player: Plyr;
    source: Plyr.SourceInfo;
    videoElement: HTMLVideoElement;
}

interface PlyrDriverDestroyParams {
    player: Plyr;
}

export interface PlyrDriver {
    create(params: PlyrDriverCreateParams): Plyr;
    updateSource(params: PlyrDriverUpdateSourceParams): void;
    destroy(params: PlyrDriverDestroyParams): void;
}

export class DefaultPlyrDriver implements PlyrDriver {
    public create(params: PlyrDriverCreateParams) {
        return new Plyr(params.videoElement, params.options);
    }

    public updateSource(params: PlyrDriverUpdateSourceParams) {
        params.player.source = params.source;
    }

    public destroy(params: PlyrDriverDestroyParams) {
        params.player.destroy();
    }
}
