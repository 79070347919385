import type { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { HttpContextToken, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';

const ACCESS_TOKEN_KEY = 'oauth_access_token';
const REFRESH_TOKEN_KEY = 'oauth_refresh_token';

export const SKIP_AUTH = new HttpContextToken<boolean>(() => false);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    private accessToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private readonly cookieService: CookieService, private readonly eduHttpService: EduHttpService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.cookieService.get(ACCESS_TOKEN_KEY);
        let newRequest = request;

        if (token && !request.context.get(SKIP_AUTH)) {
            newRequest = this.addToken(request, token);
        }

        if (request.url.includes('/public/subscribe')) {
            newRequest = request.clone({ headers: request.headers.set('X-Requested-With', `XMLHttpRequest`) });
        }

        return next.handle(newRequest).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 401 && !request.context.get(SKIP_AUTH)) {
                    return this.handle401Error(request, next);
                }
                return throwError(err);
            }),
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const refreshTokenFromCookie = this.cookieService.get(REFRESH_TOKEN_KEY);

        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.accessToken.next(null);

            return this.eduHttpService.refreshToken(refreshTokenFromCookie).pipe(
                switchMap((res: any) => {
                    const accessToken = res.headers.get('access_token');
                    const refreshToken = res.headers.get('refresh_token');

                    this.isRefreshing = false;

                    this.cookieService.set(ACCESS_TOKEN_KEY, accessToken, { path: '/' });
                    this.cookieService.set(REFRESH_TOKEN_KEY, refreshToken, { path: '/' });

                    this.accessToken.next(accessToken);
                    return next.handle(this.addToken(request, accessToken));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    console.log('removing tokens');
                    this.cookieService.delete(ACCESS_TOKEN_KEY);
                    this.cookieService.delete(REFRESH_TOKEN_KEY);
                    return throwError(err);
                }),
            );
        }
        return this.accessToken.pipe(
            filter((token) => token != null),
            take(1),
            switchMap((token) => {
                return next.handle(this.addToken(request, token));
            }),
        );
    }
}
