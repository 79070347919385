import { type JssStyle } from 'jss';
import type { IElement } from '@app/features/common/interfaces/IElement';
import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';

export function getStyles(element: IElement): JssStyle {
    return {
        wrap: {
            minHeight: '1px',
            display: 'flex',
            alignItems: 'center',
        },
        line: {
            width: '100%',
            borderTopColor: simpleColorCss(element.styles.borderTopColor as string),
            borderTopStyle: element.styles.borderTopStyle,
            borderTopWidth: element.styles.borderTopWidth,
        },
    };
}
