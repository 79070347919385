import type { BooleanInput } from '@angular/cdk/coercion';
import type { ThemeColor, ThemeColorsKeyValue } from '@libs/themes/src/types/theme-color.type';
import type { ColorPickerEventPayload } from './types/color-picker.type';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { InputBoolean } from '@common/helpers/convert';

@Component({
    selector: 'sp-color-picker',
    templateUrl: './sp-color-picker.component.html',
    styleUrls: ['./sp-color-picker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpColorPickerComponent implements OnInit, OnChanges {
    @Input() public color!: string;
    @Input() public colorsPreset: ThemeColor[] = [];
    @Input() @InputBoolean() public positionLeft: boolean;
    @Input() @InputBoolean() public hideGradient: boolean;

    @Output() public colorChangeEvent: EventEmitter<ColorPickerEventPayload> = new EventEmitter<ColorPickerEventPayload>();

    public colorValue: string;
    private colorsPresetKeyValue: ThemeColorsKeyValue = {};

    public ngOnInit(): void {
        if (this.colorsPreset?.length) {
            this.colorsPresetKeyValue = this.colorsPreset.reduce((acc: ThemeColorsKeyValue, { name, value }) => {
                acc[name] = value;
                return acc;
            }, {});
        }

        if (this.color?.includes('var')) {
            const matches: RegExpMatchArray = this.color.match(/\((.*?)\)/);
            const matchedVariable = matches[1].slice(2);
            const variableKey = Object.keys(this.colorsPresetKeyValue).find((key) => matchedVariable.includes(key));

            this.colorValue = this.colorsPresetKeyValue[variableKey];
            return;
        }

        this.colorValue = this.colorsPresetKeyValue[this.color] || this.color;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.color) {
            this.colorValue = this.colorsPresetKeyValue[changes.color.currentValue] || changes.color.currentValue;
        }
    }

    public setColorHandler(value: string): void {
        this.color = value;
        this.colorChangeEvent.emit({
            value: value.toLowerCase(),
            presetName: null,
        });
    }

    public setColorPresetHandler({ value, name }: ThemeColor): void {
        this.color = value;
        this.colorChangeEvent.emit({
            value: value.toLowerCase(),
            presetName: name,
        });
    }

    static ngAcceptInputType_positionLeft: BooleanInput;
    static ngAcceptInputType_hideGradient: BooleanInput;
}
