import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { ContainerComponent } from '@web-builder/mls-core/shared/components/container/container.component';
import { LogoFooterComponent } from '@web-builder/mls-core/shared/components/logo-footer/logo-footer.component';
import { EmptyCourseComponent } from '@web-builder/mls-core/shared/components/empty-course/empty-course.component';

const MODULES = [TranslateModule, CommonModule];

const COMPONENTS = [ContainerComponent, LogoFooterComponent, EmptyCourseComponent];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
})
export class SharedModule {}
