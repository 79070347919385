export enum EGalleryTypes {
    image = 'image',
    slider = 'slider',
    video = 'video',
    carousel = 'carousel',
    grid = 'grid',
    collage = 'collage',
}

export enum EGallerySliderSpeeds {
    manual = 'manual',
    slow = 'slow',
    middle = 'middle',
    high = 'high',
}

export enum EGallerySliderHeightType {
    origin = 'origin',
    custom = 'custom',
}

export enum EVideoTypes {
    youtube = 'youtube',
    vimeo = 'vimeo',
    fileManager = 'fileManager',
}
