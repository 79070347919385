import { combineReducers } from '@ngrx/store';

import { type SiteState, SITE_FEATURE_KEY, siteReducer } from './site/site.reducer';
import { type PagesState, PAGES_FEATURE_KEY, pagesReducer } from './pages/pages.reducer';
import { type ContentState, CONTENT_FEATURE_KEY, contentReducer } from './content/content.reducer';
import { type SettingsState, SETTINGS_FEATURE_KEY, settingsReducer } from './settings/settings.reducer';
import { type PageSettingsState, PAGE_SETTINGS_FEATURE_KEY, pageSettingsReducer } from './page-settings/page-settings.reducer';
import { type DomainsState, DOMAINS_FEATURE_KEY, domainsReducer } from './domains/domains.reducer';

export type SiteFeatureState = {
    [SITE_FEATURE_KEY]: SiteState;
    [PAGES_FEATURE_KEY]: PagesState;
    [CONTENT_FEATURE_KEY]: ContentState;
    [SETTINGS_FEATURE_KEY]: SettingsState;
    [PAGE_SETTINGS_FEATURE_KEY]: PageSettingsState;
    [DOMAINS_FEATURE_KEY]: DomainsState;
};

export const SITE_FEATURE_ROOT_KEY = 'site';

export const siteFeatureReducer = combineReducers<SiteFeatureState>({
    [SITE_FEATURE_KEY]: siteReducer,
    [PAGES_FEATURE_KEY]: pagesReducer,
    [CONTENT_FEATURE_KEY]: contentReducer,
    [SETTINGS_FEATURE_KEY]: settingsReducer,
    [PAGE_SETTINGS_FEATURE_KEY]: pageSettingsReducer,
    [DOMAINS_FEATURE_KEY]: domainsReducer,
});
