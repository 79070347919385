import type { JssStyle } from 'jss';
import type { ThemeButton, ThemeSelectedButtons } from '@libs/themes';
import type { EWebsiteThemeButtonProperties } from '@libs/themes/src/variants/website/enums/button.enum';
import type { EWebsiteThemeFontProperties } from '@libs/themes/src/variants/website/enums/font.enum';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EButtonStyleType } from '@common/enums';

import { SpThemeButtonSettingsComponent } from '@libs/themes/src/abstract/abstract-buttons-settings.component';
import { WEBSITE_THEME_BUTTONS_VARIABLES, WEBSITE_THEME_FONTS_VARIABLES } from '@libs/themes/src/variants/website/constants/css-variables';
import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';

@Component({
    selector: 'sp-website-button-settings',
    styleUrls: ['./website-button-settings.component.less'],
    templateUrl: './website-button-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpWebsiteButtonSettingsComponent extends SpThemeButtonSettingsComponent {
    public override readonly BUTTON_STYLE_TYPES_TRANSLATIONS = {
        [EButtonStyleType.primary]: 'control_appearance_primary',
        [EButtonStyleType.secondary]: 'control_appearance_secondary',
    };

    public buttonStyleTypeTranslation(type: EButtonStyleType.primary | EButtonStyleType.secondary) {
        return this.BUTTON_STYLE_TYPES_TRANSLATIONS[type];
    }

    protected formatThemeButtons(buttons: ThemeSelectedButtons, themeValues: Record<string, string>): ThemeButton[] {
        if (!buttons.selectedIndex) {
            return buttons.selected;
        }

        const buttonVariablesKeys = Object.keys(WEBSITE_THEME_BUTTONS_VARIABLES) as (
            | EButtonStyleType.primary
            | EButtonStyleType.secondary
        )[];

        const buttonThemeValues = buttonVariablesKeys.reduce(
            (buttonTypeResult: Record<string, JssStyle | undefined>, key: EButtonStyleType) => {
                const buttonStyleTypeKeys = Object.keys(WEBSITE_THEME_BUTTONS_VARIABLES[key]!) as EWebsiteThemeButtonProperties[];

                buttonTypeResult[key] = buttonStyleTypeKeys.reduce(
                    (result, prop: EWebsiteThemeButtonProperties) => ({
                        // @ts-ignore
                        [prop]: themeValues[WEBSITE_THEME_BUTTONS_VARIABLES[key]![prop]],
                        ...result,
                    }),
                    {} as JssStyle,
                );

                const fontVariablesKeys = Object.keys(
                    WEBSITE_THEME_FONTS_VARIABLES[EThemeFontTags.button],
                ) as EWebsiteThemeFontProperties[];

                buttonTypeResult[key] = fontVariablesKeys.reduce(
                    (result, prop) => ({
                        [prop]: themeValues[WEBSITE_THEME_FONTS_VARIABLES[EThemeFontTags.button][prop]],
                        ...result,
                    }),
                    buttonTypeResult[key],
                );

                return buttonTypeResult;
            },
            {},
        );

        return buttons.selected.map((theme: ThemeButton) => ({ ...theme, styles: buttonThemeValues[theme.styleType] }) as ThemeButton);
    }
}
