<div [class]="classes?.element">
    <div *ngIf="elementData.showAsIcon">
        <div [class]="classes?.wrapMenuIcon" *ngIf="!isOpen">
            <img [src]="imgSrc" alt="menu-icon" (click)="open()">
        </div>

        <button *ngIf="isOpen" [class]="classes?.closeBtn"  type="button" (click)="close()">
            <span>&times;</span>
        </button>

        <div [class]="classes?.menuList" *ngIf="isOpen">
            <div *ngFor="let menuItem of menuList; let i = index;">
                <ng-container *ngIf="menuItem.isVisible">
                    <div [class]="classes?.menuItem">
                        <a [href]="getMenuHref(menuItem)" [target]="getTarget(menuItem)">{{ menuItem.name }}</a>
                        <!-- <a href="{{ menuItem.value }}" [target]="getTarget(menuItem)">{{ menuItem.name }}</a> -->
                        <!-- <a href="control_{{ menuItem.value }}" [target]="getTarget(menuItem)">{{ menuItem.name }}</a> -->
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="!elementData.showAsIcon">
        <div [class]="classes?.menuList">
            <div *ngFor="let menuItem of menuList; let i = index;">
                <ng-container *ngIf="menuItem.isVisible">
                    <div [class]="classes?.menuItem">
                        <a [href]="getMenuHref(menuItem)" [target]="getTarget(menuItem)">{{ menuItem.name }}</a>
                        <!-- <a href="{{ menuItem.value }}" [target]="getTarget(menuItem)">{{ menuItem.name }}</a> -->
                        <!-- <a href="control_{{ menuItem.value }}" [target]="getTarget(menuItem)">{{ menuItem.name }}</a> -->
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
