import type { ThemeButton } from '@libs/themes/src/types/theme-button.type';

import { EButtonHoverType, EButtonStyleType } from '@common/enums';
import { TRANSPARENT_VALUE } from '@common/components/sp-color-picker';

import { EWebsiteThemeColorNames } from '@libs/themes/src/variants/website/enums/color.enum';

export const DEFAULT_WEBSITE_BUTTON_PRESETS: Array<Array<ThemeButton>> = [
    // -------------------Group 1------------------------------//
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.secondary],
                borderColor: [EWebsiteThemeColorNames.secondary],
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.secondary],
                borderColor: [EWebsiteThemeColorNames.secondary],
            },
        },
    ],
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
                borderRadius: '0',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.secondary],
                borderColor: [EWebsiteThemeColorNames.secondary],
                borderRadius: '0',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.secondary],
                borderColor: [EWebsiteThemeColorNames.secondary],
            },
        },
    ],
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
                borderRadius: '50px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.secondary],
                borderColor: [EWebsiteThemeColorNames.secondary],
                borderRadius: '50px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.secondary],
                borderColor: [EWebsiteThemeColorNames.secondary],
            },
        },
    ],
    // -------------------Group 2------------------------------//
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
            },
        },
    ],
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
                borderRadius: '0',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
                borderRadius: '0',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
            },
        },
    ],
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
                borderRadius: '50px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.light],
                background: [EWebsiteThemeColorNames.primary],
                borderColor: [TRANSPARENT_VALUE],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
                borderRadius: '50px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
            },
        },
    ],
    // -------------------Group 3------------------------------//
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.secondary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.secondary],
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.secondary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.secondary],
            },
        },
    ],
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
                borderRadius: '0',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.secondary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.secondary],
                borderRadius: '0',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.secondary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.secondary],
            },
        },
    ],
    [
        {
            // primary
            styleType: EButtonStyleType.primary,
            base: {
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
                borderRadius: '50px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.primary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.primary],
            },
        },
        {
            // secondary
            styleType: EButtonStyleType.secondary,
            base: {
                color: [EWebsiteThemeColorNames.secondary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.secondary],
                borderRadius: '50px',
                borderStyle: 'solid',
                borderWidth: '2px',
            },
            hover: {
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: [EWebsiteThemeColorNames.secondary],
                background: [TRANSPARENT_VALUE],
                borderColor: [EWebsiteThemeColorNames.secondary],
            },
        },
    ],
];
