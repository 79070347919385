import { coerceBooleanProperty } from '@angular/cdk/coercion';

export function toBoolean(value: boolean | string): boolean {
    return coerceBooleanProperty(value);
}

function propDecoratorFactory<T, D>(name: string, fallback: (v: T) => D): (target: any, propName: string) => void {
    function propDecorator(target: any, propName: string): any {
        const privatePropName = `_${propName}`;

        Object.defineProperty(target, privatePropName, {
            configurable: true,
            writable: true,
        });

        return {
            get(): string {
                return this[privatePropName];
            },
            set(value: T): void {
                this[privatePropName] = fallback(value);
            },
        };
    }

    return propDecorator;
}

export function InputBoolean(): any {
    return propDecoratorFactory('InputBoolean', toBoolean);
}
