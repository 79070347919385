import { Injectable } from '@angular/core';
import { EPixelTypes, type GoogleSettings, type PixelSettings } from '@app/features/common/components/analytics-settings';

declare var gtag;
declare var fbq;

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public gaEventRequest(settings: GoogleSettings) {
        gtag('event', settings.action, { event_category: settings.category, event_label: settings.label });
    }

    public pixelFbEventRequest(settings: PixelSettings) {
        fbq('track', settings.type, settings.type === EPixelTypes.purchase ? { value: settings.value, currency: settings.currency } : null);
    }
}
