import type { SiteContentEntity } from '@common/types/site-content.type';

import { createImmerReducer } from 'ngrx-immer/store';
import { on } from '@ngrx/store';

import ContentActions from './content.actions';

export const CONTENT_FEATURE_KEY = 'content';

export type ContentState = {
    site: SiteContentEntity;
    page: SiteContentEntity;
    error?: string;
    isDirty?: boolean;
    isLoading?: boolean;
    isReady?: boolean;
};

export const initialContentState: ContentState = {
    site: null,
    page: null,
    isDirty: false,
    isLoading: true,
    isReady: false,
};

export const contentReducer = createImmerReducer<ContentState>(
    initialContentState,
    on(ContentActions.loadContent, ContentActions.loadPageContentRequest, (state: ContentState) => ({ ...state, isLoading: true })),
    on(ContentActions.loadSiteContentSuccess, (state: ContentState, { content }) => ({
        ...state,
        site: content,
        isLoading: false,
        isReady: true,
    })),
    on(ContentActions.loadSiteContentFailure, (state: ContentState, { error }) => ({ ...state, error })),
    on(ContentActions.loadPagesContentSuccess, (state: ContentState, { content }) => ({
        ...state,
        page: content,
        isLoading: false,
        isReady: true,
    })),
    on(ContentActions.loadPagesContentFailure, (state: ContentState, { error }) => ({ ...state, error })),

    on(ContentActions.setIsDirty, (state: ContentState, { isDirty }) => ({ ...state, isDirty })),
    on(ContentActions.updatePageStructureContent, (state: ContentState, { structure }) => {
        state.page.draft_structure = structure;
        return state;
    }),
    on(ContentActions.updateSiteThemeSettingsContent, (state: ContentState, { themeSettings }) => {
        state.site.draft_theme_settings = themeSettings;
        return state;
    }),
    on(ContentActions.updateSiteSharedBlocksContent, (state: ContentState, { sharedBlocks }) => {
        state.site.draft_shared_blocks = sharedBlocks;
        return state;
    }),
    on(ContentActions.resetContentToPublished, (state: ContentState) => {
        state.page.structure = state.page.draft_structure;
        state.site.theme_settings = state.site.draft_theme_settings;
        state.site.shared_blocks = state.site.draft_shared_blocks;
        return state;
    }),
);
