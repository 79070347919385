import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EThemeFontTags } from '@common/libs/themes/src/enums/font-tags.enum';
import { EThemeFontWeights } from '@common/libs/themes/src/enums/font-families.enum';

import { SpThemeFontSettingsComponent } from '@libs/themes/src/abstract/abstract-fonts-settings.component';

@Component({
    selector: 'sp-simple-font-settings',
    styleUrls: ['./simple-font-settings.component.less'],
    templateUrl: './simple-font-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSimpleFontSettingsComponent extends SpThemeFontSettingsComponent {
    // TODO: remove this
    // @ts-ignore
    public readonly FONT_TAG_TRANSLATIONS: { [K in EThemeFontTags]?: string } = {
        [EThemeFontTags.p]: 'theme_font_settings_paragraph',
        [EThemeFontTags.h1]: 'theme_font_settings_heading_1',
        [EThemeFontTags.h2]: 'theme_font_settings_heading_2',
        [EThemeFontTags.h3]: 'theme_font_settings_heading_3',
        [EThemeFontTags.h4]: 'theme_font_settings_heading_4',
        [EThemeFontTags.pre]: 'theme_font_settings_pre',
        [EThemeFontTags.button]: 'theme_font_settings_button',
    };
    public readonly FONT_WEIGHT_TRANSLATIONS: Readonly<Record<EThemeFontWeights, string>> = {
        [EThemeFontWeights.light]: 'theme_settings_font_weight_light',
        [EThemeFontWeights.normal]: 'theme_settings_font_weight_normal',
        [EThemeFontWeights.bold]: 'theme_settings_font_weight_bold',
    };
    public override readonly HIDDEN_FONT_TAGS: EThemeFontTags[] = [EThemeFontTags.button, EThemeFontTags.pre];

    protected beforeInitForm(): void {
        this.FONTS_TAGS = [
            EThemeFontTags.p,
            EThemeFontTags.h1,
            EThemeFontTags.h2,
            EThemeFontTags.h3,
            EThemeFontTags.h4,
            EThemeFontTags.button,
            EThemeFontTags.pre,
        ];
    }
}
