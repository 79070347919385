import { EAnimationType, EAnimationPosition, EAnimationSpeed } from '@common/components/sp-animation-settings/type/animation-settings.type';
import type { AnimationSettings } from './type/animation-settings.type';
import gsap from 'gsap';

type GsapSettings = {
    stagger?: number;
    duration?: number;
    opacity?: number;
    scale?: number;
    x?: number;
    y?: number;
};

const speed: Record<EAnimationSpeed, number> = {
    [EAnimationSpeed.slow]: 0.8,
    [EAnimationSpeed.medium]: 0.4,
    [EAnimationSpeed.fast]: 0.2,
};

function getGsapAnimationSettings(animation: AnimationSettings): GsapSettings {
    let animationSettings: GsapSettings = {
        stagger: 0.15,
        duration: speed[animation.animationSpeed],
    };

    switch (animation?.animationType) {
        case EAnimationType.fade:
            animationSettings.opacity = 0;
            break;
        case EAnimationType.scale:
            animationSettings.opacity = 1;
            animationSettings.scale = 0;
            break;
        case EAnimationType.slide:
            break;
        case EAnimationType.runningStripe:
            break;
        default:
            animationSettings.opacity = 1;
    }

    switch (animation?.animationPosition) {
        case EAnimationPosition.left:
            animationSettings.x = -100;
            break;
        case EAnimationPosition.top:
            animationSettings.y = -100;
            break;
        case EAnimationPosition.right:
            animationSettings.x = 100;
            break;
        case EAnimationPosition.bottom:
            animationSettings.y = 100;
            break;
        default:
            animationSettings.x = 100;
    }

    if (animation.animationType === EAnimationType.runningStripe) {
        animationSettings.x = 0;
        animationSettings.y = 0;
    }

    return animationSettings;
}

function to(animation: AnimationSettings) {
    let settings: GsapSettings = {
        stagger: 0.15,
        duration: speed[animation.animationSpeed],
        opacity: 1,
        x: 0,
        y: 0,
    };

    if (animation.animationType === EAnimationType.scale) settings.scale = 1;

    return settings;
}

function from(animation: AnimationSettings) {
    let settings: GsapSettings = {
        opacity: 0,
    };

    if (animation.animationType === EAnimationType.scale) settings.scale = 0;

    switch (animation?.animationPosition) {
        case EAnimationPosition.left:
            settings.x = -100;
            break;
        case EAnimationPosition.top:
            settings.y = -100;
            break;
        case EAnimationPosition.right:
            settings.x = 100;
            break;
        case EAnimationPosition.bottom:
            settings.y = 100;
            break;
        default:
            settings.x = 100;
    }

    return settings;
}

export function getGsapScrollTrigger(animation: AnimationSettings, animatedEl: HTMLDivElement, scrollTrigger?: HTMLDivElement): any {
    const scrollBox = gsap.timeline({
        scrollTrigger: {
            trigger: scrollTrigger ? scrollTrigger : animatedEl,
            pin: false,
            start: `top 90%`,
            markers: false,
        },
    });

    scrollBox.fromTo(animatedEl, from(animation), to(animation));
}

export function getGsapAnimation(animation: AnimationSettings, animatedEl: any): void {
    gsap.from(animatedEl, getGsapAnimationSettings(animation));
}
