import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseFacade } from '@common/services/base-facade.service';

import { ThemeColorPreset } from '@common/libs/themes/src/types/theme-color.type';
import { ThemeButtonPreset } from '@common/libs/themes/src/types/theme-button.type';
import { ThemeFontPreset } from '@common/libs/themes/src/types/theme-font.type';

import * as ThemePresetsSelectors from '@common/libs/themes/src/store/theme-presets/theme-presets.selectors';

@Injectable()
export class ThemePresetsFacade extends BaseFacade {
    public readonly colorsPresets$: Observable<ThemeColorPreset[]> = this.store$.select(ThemePresetsSelectors.selectColorsPresets);
    public readonly fontsPresets$: Observable<ThemeFontPreset[]> = this.store$.select(ThemePresetsSelectors.selectFontsPresets);
    public readonly buttonsPresets$: Observable<ThemeButtonPreset[]> = this.store$.select(ThemePresetsSelectors.selectButtonsPresets);
}
