import type { Renderer2 } from '@angular/core';
import type { ThemeSelectedFonts } from '@libs/themes';

import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { formatFontsLinkAsLink } from '@libs/themes';
import { PlatformService } from '@common/services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class LinksService {
    private readonly renderer: Renderer2;
    private readonly linkFontsElement: HTMLLinkElement;
    private readonly linkThemeSettingsElement: HTMLLinkElement;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly platformService: PlatformService,
        private readonly rendererFactory2: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);

        if (this.platformService.isPlatformBrowser()) {
            this.linkThemeSettingsElement = this.renderer.createElement('link');
            this.renderer.appendChild(this.document.querySelector('head'), this.linkThemeSettingsElement);
            this.renderer.setAttribute(this.linkThemeSettingsElement, 'rel', 'stylesheet');
            return;
        }

        this.linkFontsElement = this.renderer.createElement('link');
        this.renderer.appendChild(this.document.querySelector('head'), this.linkFontsElement);
        this.renderer.setAttribute(this.linkFontsElement, 'rel', 'stylesheet');
    }

    public setFontsLinks(fonts: ThemeSelectedFonts): void {
        if (this.platformService.isPlatformBrowser()) {
            return;
        }

        this.renderer.setAttribute(this.linkFontsElement, 'href', formatFontsLinkAsLink(fonts));
    }

    public setThemeSettingsLink(): void {
        this.renderer.setAttribute(this.linkThemeSettingsElement, 'href', 'css/theme_settings_styles.css');
    }
}
