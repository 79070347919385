import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { SKIP_AUTH } from '@web-builder/core/interceptors/jwt.interceptor';
import { environment } from '@web-builder-env/environment';

type Data<T> = Record<'data', T>;

@Injectable({
    providedIn: 'root',
})
export class EduServiceHttp {
    private readonly host = environment.proxyUrl;
    private readonly baseApiUrl = '/api/edu-service';

    constructor(
        private readonly http: HttpClient,
        private readonly cookieService: CookieService,
    ) {}

    private get context() {
        return new HttpContext().set(SKIP_AUTH, true);
    }

    public refreshToken(token) {
        const headers = new HttpHeaders().append('Refreshtoken', token);

        return this.http.get<Data<any>>(`${this.host}${this.baseApiUrl}/refresh-token`, {
            observe: 'response',
            headers,
            context: this.context,
        });
    }

    public register(props) {
        return this.http.post<Data<any>>(`${this.host}${this.baseApiUrl}/register`, props, { context: this.context });
    }

    public verify({ uuid, hash, expires, signature, courseId }) {
        return this.http.get<Data<any>>(`${this.host}${this.baseApiUrl}/email/verify/${uuid}/${hash}`, {
            params: new HttpParams().set('courseId', courseId).set('expires', expires).set('signature', signature),
            context: this.context,
        });
    }

    public resendVerifyLink({ email, domainId, courseId }) {
        return this.http.post<Data<any>>(
            `${this.host}${this.baseApiUrl}/resend-email-verification`,
            {
                email,
                domainId,
                courseId,
            },
            { context: this.context },
        );
    }

    public login(props) {
        return this.http.post<Data<any>>(`${this.host}${this.baseApiUrl}/login`, props, { observe: 'response', context: this.context });
    }

    public forgotPassword(props) {
        return this.http.post<Data<any>>(`${this.host}${this.baseApiUrl}/forgot-password`, props, { context: this.context });
    }

    public resetPassword(props: Record<string, string>, query: Record<string, string>) {
        return this.http.post<Data<any>>(`${this.host}${this.baseApiUrl}/reset-password`, props, {
            params: new HttpParams()
                .set('courseId', query.courseId)
                .set('expires', query.expires)
                .set('hash', query.hash)
                .set('token', query.token)
                .set('uuid', query.uuid)
                .set('signature', query.signature),
            context: this.context,
        });
    }

    // students

    public getStudentInfo() {
        return this.http.get(`${this.host}${this.baseApiUrl}/students/student`);
    }

    // courses

    public studentCourseStatus(courseId: number) {
        return this.http
            .get<Data<any>>(`${this.host}${this.baseApiUrl}/students/courses/${courseId}/status`)
            .pipe(map((data) => data.data));
    }

    public registerOnCourse(courseId: number) {
        return this.http.post<Data<any>>(`${this.host}${this.baseApiUrl}/students/courses/${courseId}/register`, {});
    }
}
