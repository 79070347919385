import { type ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SpTransparentIconModule } from '@common/ui/sp-transparent-icon';
import { SpColorPickerModule } from '@common/components/sp-color-picker';
import { SpCustomButtonModule } from '@common/components/sp-custom-button';
import { SpFormControlsModule } from '@common/components/sp-form-controls';
import { SpAnimationSettingsModule } from '@common/components/sp-animation-settings';
import { SpButtonAdditionalEffectsSettingsModule } from '@common/components/sp-button-additional-effects-settings';
import { SpDeviceToggleModule } from '@components/sp-device-toggle';
import { SpSynchronizeStyleToggleModule } from '@components/sp-synchronize-style-toggle';
import { SpDirectivesModule } from '@common/directives/directives.module';

import { AbstractBaseThemeStrategy } from './strategies/abstract-base-theme.strategy';
import { ThemeStoreModule } from './store';

import { SpThemesComponent } from './components/themes.component';
import { SpThemesButtonTabComponent } from './components/button-tab/button-tab.component';
import {
    SpSimpleButtonSettingsComponent,
    SpSimpleColorsSettingsComponent,
    SpSimpleFontSettingsComponent,
    SpSimplePresetSettingsComponent,
    SpSimpleButtonPresetComponent,
    SpSimpleThemeControlResolverComponent,
} from './variants/simple';
import {
    SpWebsiteButtonSettingsComponent,
    SpWebsiteColorsSettingsComponent,
    SpWebsiteFontSettingsComponent,
    SpWebsitePresetSettingsComponent,
    SpWebsiteButtonPresetComponent,
    SpWebsiteThemeControlResolverComponent,
} from './variants/website';

import { THEME_CONFIG_TOKEN, THEME_STRATEGY_TOKEN } from './tokens';

const MODULES = [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ThemeStoreModule,
    SpTransparentIconModule,
    SpColorPickerModule,
    SpCustomButtonModule,
    SpFormControlsModule,
    SpDirectivesModule,
    BsDropdownModule,
    TooltipModule,
    SpAnimationSettingsModule,
    SpDeviceToggleModule,
    SpButtonAdditionalEffectsSettingsModule,
    SpSynchronizeStyleToggleModule,
];

const COMPONENTS = [
    SpThemesComponent,
    SpThemesButtonTabComponent,

    SpSimpleButtonSettingsComponent,
    SpSimpleColorsSettingsComponent,
    SpSimpleFontSettingsComponent,
    SpSimplePresetSettingsComponent,
    SpSimpleButtonPresetComponent,
    SpSimpleThemeControlResolverComponent,

    SpWebsiteButtonSettingsComponent,
    SpWebsiteColorsSettingsComponent,
    SpWebsiteFontSettingsComponent,
    SpWebsitePresetSettingsComponent,
    SpWebsiteButtonPresetComponent,
    SpWebsiteThemeControlResolverComponent,
];

@NgModule({
    imports: [...MODULES],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
})
export class ThemesModule {
    static forRoot(strategy: typeof AbstractBaseThemeStrategy): ModuleWithProviders<ThemesModule> {
        if (!strategy || typeof strategy !== 'function') {
            return;
        }

        // @ts-ignore
        const test = new strategy();
        return {
            ngModule: ThemesModule,
            providers: [
                {
                    provide: THEME_CONFIG_TOKEN,
                    useValue: test.config,
                },
                {
                    provide: THEME_STRATEGY_TOKEN,
                    useFactory: () => test,
                },
            ],
        };
    }
}
