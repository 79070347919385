<div class="well">
    <div class="form-group">
        <label>
            {{ 'pixel_type_label' | translate }}
        </label>
        <select class="form-control" [formControl]="type">
            <option *ngFor="let option of options" [value]="option" [selected]="option === type.value">{{ option }}</option>
        </select>
        <span class="small text-muted d-block mt-5" *ngIf="type.value === EPixelTypes.purchase">
            {{ 'pixel_purchase_analytics' | translate }}
        </span>
    </div>
</div>
