<div [class]="classes.modal">
    <div [class]="classes.modalHeader">
        <h2>{{ translations[modalState].modalHeaderTitle | translate }}</h2>
        <button type="button" (click)="onCancel()">
            <span>&times;</span>
        </button>
    </div>

    <div [class]="classes.modalBody">
        <div *ngIf="showErrorNotification()" class="error-notification">
            <p *ngIf="this.serverErrorCode === this.serverErrorCodes.INVALID_NEW_PASSWORD">{{ "error_invalid_new_password" | translate }}</p>
            <p *ngIf="this.serverErrorCode === this.serverErrorCodes.CONFIRM_NEW_PASSWORD_NOT_MATCH">{{ "error_confirm_new_password" | translate }}</p>
            <p *ngIf="this.serverErrorCode === this.serverErrorCodes.NEW_STUDENT_TARIFF_RESTRICTION">{{ "new_student_tariff_restriction" | translate }}</p>
            <p *ngIf="this.serverErrorCode === this.serverErrorCodes.NOT_FOUND">ERROR: NOT_FOUND</p>

            <p *ngIf="form.hasError('invalidCredentials')">
                {{ 'error_forbidden_access' | translate }}
            </p>

            <p *ngIf="form.hasError('emailVerified')">
                {{ 'error_email_is_not_verified' | translate }}
                <span style="font-weight: bold; cursor: pointer" [class.disabled]="disableSendAgain" (click)="sendVerifyEmail()">
                    {{ 'sending_the_email' | translate }}
                </span>
                {{ 'again' | translate }}
            </p>

            <p *ngIf="form.hasError('userNotFound')">
                {{ 'error_user_not_found' | translate }}
            </p>

            <p *ngIf="form.hasError('invalidResetLink')">
                {{ 'error_reset_password_link_invalid' | translate }}
            </p>

            <p *ngIf="form.hasError('usedResetLink')">
                {{ 'error_reset_password_link_used' | translate }}
            </p>

            <p *ngIf="form.hasError('resetAlreadySent')">
                {{ 'error_reset_password_link_already_sent' | translate }}
            </p>

            <p *ngIf="form.hasError('verificationAlreadySent')">
                {{ 'error_verification_email_already_sent' | translate }}
            </p>
        </div>

        <!-- SIGN UP STATE -->
        <div *ngIf="modalState === modalStates.signUp">
            <form [class]="classes.modalForm" [formGroup]="form">
                <div class="form-control-row">
                    <label>{{ "name" | translate }}</label>
                    <input
                        [ngClass]="{ 'invalid-input': !name.valid && name.touched }"
                        [placeholder]="'input_name_placeholder' | translate"
                        [formControl]="name"
                    />
                    <div *ngIf="!name.valid && name.touched" class="error-reason text-danger">
                        <span *ngIf="name.errors.required">{{ "input_error_required" | translate }}</span>
                    </div>
                </div>

                <div class="form-control-row">
                    <label>Email</label>
                    <input
                        [ngClass]="{ 'invalid-input': (!email.valid && email.touched) || this.serverErrorCode === this.serverErrorCodes.EMAIL_ALREADY_EXIST }"
                        placeholder="example@mail.com"
                        [formControl]="email"
                    />
                    <div *ngIf="!email.valid && email.touched" class="error-reason text-danger">
                        <span *ngIf="email.errors.required">{{ "input_error_required" | translate }}</span>
                        <span *ngIf="email.errors.email">{{ "input_error_invalid_email" | translate }}</span>
                    </div>
                    <div *ngIf="this.serverErrorCode === this.serverErrorCodes.EMAIL_ALREADY_EXIST" class="error-reason">
                        <span>
                            <span>{{ "error_user_already_exist" | translate }}</span
                            >&nbsp; <span>{{ "you_can" | translate }}</span
                            >&nbsp; <span [class]="classes.linkBtn" (click)="changeModalState(modalStates.signIn)">{{ "sign_in" | translate }}</span
                            >&nbsp; <span>{{ "or" | translate }}</span
                            >&nbsp;
                            <span [class]="classes.linkBtn" (click)="changeModalState(modalStates.passwordRecovery)">{{
                                "recover_your_password" | translate
                            }}</span>
                        </span>
                    </div>
                </div>

                <div class="form-control-row">
                    <label>{{ "password" | translate }}</label>
                    <input
                        [type]="showPassword ? 'text' : 'password'"
                        [ngClass]="{
                            'invalid-input': (!password.valid && password.touched) || this.serverErrorCode === this.serverErrorCodes.INVALID_PASSWORD
                        }"
                        [placeholder]=""
                        [formControl]="password"
                    />
                    <ng-container *ngTemplateOutlet="showPasswordIcon"></ng-container>
                    <div *ngIf="!password.valid && password.touched" class="error-reason text-danger">
                        <span *ngIf="password.errors.required">{{ "input_error_required" | translate }}</span>
                    </div>
                    <div *ngIf="this.serverErrorCode === this.serverErrorCodes.INVALID_PASSWORD" class="error-reason text-danger">
                        <span *ngFor="let error of passwordErrors">
                            {{ error | translate }}
                            <br />
                        </span>
                    </div>
                </div>
            </form>
        </div>

        <!-- SIGN IN STATE -->
        <div *ngIf="modalState === modalStates.signIn">
            <form [class]="classes.modalForm" [formGroup]="form">
                <div class="form-control-row">
                    <label>Email</label>
                    <input [ngClass]="{ 'invalid-input': !email.valid && email.touched }" placeholder="example@mail.com" [formControl]="email" />
                    <div *ngIf="!email.valid && email.touched" class="error-reason text-danger">
                        <span *ngIf="email.errors.required">{{ "input_error_required" | translate }}</span>
                        <span *ngIf="email.errors.email">{{ "input_error_invalid_email" | translate }}</span>
                    </div>
                </div>

                <div class="form-control-row">
                    <label>{{ "password" | translate }}</label>
                    <input
                        [type]="showPassword ? 'text' : 'password'"
                        [ngClass]="{ 'invalid-input': !password.valid && password.touched }"
                        [placeholder]=""
                        [formControl]="password"
                    />
                    <ng-container *ngTemplateOutlet="showPasswordIcon"></ng-container>
                    <div *ngIf="!password.valid && password.touched" class="error-reason text-danger">
                        <span *ngIf="password.errors.required">{{ "input_error_required" | translate }}</span>
                    </div>
                </div>
            </form>
        </div>

        <!-- SIGN IN SUCCESS STATE -->
        <div *ngIf="modalState === modalStates.signInSuccess">
            <p>
                <strong>{{ this.form.value.name }}</strong
                ><br />
                {{ email.value }}
            </p>
        </div>

        <!-- VERIFY EMAIL STATE -->
        <div *ngIf="modalState === modalStates.verifyEmail">
            <p>{{ "confirmation_email_has_been_sent" | translate }}</p>
            <!-- <p>{{'nothing_has_come_question' | translate}}</p> -->
            <p>
                <span>{{ "please_check_your_spam_folder_or_try" | translate }}</span>
                <span style="font-weight: bold" [class]="classes.linkBtn" [class.disabled]="disableSendAgain" (click)="sendVerifyEmail()">
                    {{ "sending_the_email" | translate }}
                </span>
                <span>{{ "again" | translate }}.</span>
            </p>
        </div>

        <!-- PASSWORD RECOVERY STATE -->
        <div *ngIf="modalState === modalStates.passwordRecovery">
            <p>{{ "we_will_send_you_recovery_letter" | translate }}</p>
            <form [class]="classes.modalForm" [formGroup]="form">
                <div class="form-control-row">
                    <label>Email</label>
                    <input
                        [ngClass]="{ 'invalid-input': (!email.valid && email.touched) || this.serverErrorCode === this.serverErrorCodes.EMAIL_ALREADY_EXIST }"
                        placeholder="example@mail.com"
                        [formControl]="email"
                    />
                    <div *ngIf="email.invalid && email.touched" class="error-reason text-danger">
                        <span *ngIf="email.hasError('required')">{{ "input_error_required" | translate }}</span>
                        <span *ngIf="email.hasError('email')">{{ "input_error_invalid_email" | translate }}</span>
                        <span *ngIf="email.hasError('notFound')">{{ 'error_user_not_found' | translate }}</span>
                    </div>
                </div>
            </form>
        </div>

        <!-- PASSWORD RECOVERY SUCCESS STATE -->
        <div *ngIf="modalState === modalStates.passwordRecoverySuccess">
            <p>{{ 'new_password_has_been_sent' | translate }} <strong>{{ email.value}}</strong>.</p>
            <br>
            <p>{{ 'please_check_your_spam_folder' | translate }}</p>
        </div>

        <!-- CHANGE PASSWORD STATE -->
        <div *ngIf="modalState === modalStates.changePassword">
            <form [class]="classes.modalForm" [formGroup]="form">
                <div class="form-control-row">
                    <label>{{ "password" | translate }}</label>
                    <input
                        [ngClass]="{
                            'invalid-input': (!password.valid && password.touched) || this.serverErrorCode === this.serverErrorCodes.INVALID_PASSWORD
                        }"
                        [placeholder]=""
                        [type]="showPassword ? 'text' : 'password'"
                        [formControl]="password"
                    />
                    <ng-container *ngTemplateOutlet="showPasswordIcon"></ng-container>
                    <div *ngIf="!password.valid && password.touched" class="error-reason text-danger">
                        <span *ngIf="password.errors.required">{{ "input_error_required" | translate }}</span>
                    </div>
                    <div *ngIf="this.serverErrorCode === this.serverErrorCodes.INVALID_PASSWORD" class="error-reason text-danger">
                        <span>
                            <span>{{ "error_invalid_password" | translate }}</span>
                        </span>
                    </div>
                </div>

                <div class="form-control-row">
                    <label>{{ "confirm_password" | translate }}</label>
                    <input
                        [ngClass]="{ 'invalid-input': !confirmPassword.valid && confirmPassword.touched }"
                        [placeholder]=""
                        [type]="showPassword ? 'text' : 'password'"
                        [formControl]="confirmPassword"
                    />
                    <div *ngIf="!confirmPassword.valid && confirmPassword.touched" class="error-reason text-danger">
                        <span *ngIf="confirmPassword.errors.required">{{ "input_error_required" | translate }}</span>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div [class]="classes.modalFooter">
        <div class="action-buttons">
            <button
                *ngIf="isVisibleSecondaryBtn"
                class="secondary-btn"
                (click)="onClickSecondaryBtn()">
                {{ translations[modalState].modalFooterSecondaryBtn | translate}}
            </button>
            <button
                class="primary-btn"
                (click)="onClickPrimaryBtn()">
                {{ translations[modalState].modalFooterPrimaryBtn | translate}}
            </button>
        </div>

        <div class="logo">
            <mls-logo-footer
                *ngIf="showPoweredBy && (modalState === modalStates.signUp || modalState === modalStates.signIn || modalState === modalStates.passwordRecovery)"
                [classes]="classes"
                [lang]="lang"
                [isPreview]="isPreview"
                [featuringProduct]="'edu'"
            ></mls-logo-footer>
        </div>
    </div>
</div>

<ng-template #showPasswordIcon>
    <span class="glyphicon showPasswordIcon" [class]="showPassword ? 'glyphicon-eye-close' : 'glyphicon-eye-open'" (click)="togglePassword()"></span>
</ng-template>
