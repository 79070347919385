import { EWebsiteThemeFontProperties } from '@common/libs/themes/src/variants/website/enums/font.enum';
import { EThemeFontTags } from '@common/libs/themes/src/enums/font-tags.enum';

export const WEBSITE_THEME_FONTS_VARIABLES: Record<EThemeFontTags, Record<EWebsiteThemeFontProperties, string>> = {
    [EThemeFontTags.leading]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--leading-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--leading-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--leading-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--leading-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--leading-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--leading-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--leading-letter-spacing',
    },
    [EThemeFontTags.big]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--big-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--big-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--big-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--big-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--big-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--big-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--big-letter-spacing',
    },
    [EThemeFontTags.small]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--small-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--small-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--small-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--small-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--small-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--small-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--small-letter-spacing',
    },
    [EThemeFontTags.quote]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--quote-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--quote-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--quote-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--quote-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--quote-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--quote-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--quote-letter-spacing',
    },
    [EThemeFontTags.signature]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--signature-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--signature-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--signature-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--signature-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--signature-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--signature-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--signature-letter-spacing',
    },
    [EThemeFontTags.p]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--p-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--p-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--p-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--p-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--p-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--p-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--p-letter-spacing',
    },
    [EThemeFontTags.h1]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h1-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--h1-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--h1-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--h1-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--h1-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--h1-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h1-letter-spacing',
    },
    [EThemeFontTags.h2]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h2-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--h2-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--h2-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--h2-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--h2-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--h2-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h2-letter-spacing',
    },
    [EThemeFontTags.h3]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h3-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--h3-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--h3-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--h3-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--h3-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--h3-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h3-letter-spacing',
    },
    [EThemeFontTags.h4]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h4-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--h4-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--h4-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--h4-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--h4-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--h4-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h4-letter-spacing',
    },
    [EThemeFontTags.pre]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--pre-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--pre-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--pre-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--pre-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--pre-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--pre-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--pre-letter-spacing',
    },
    [EThemeFontTags.button]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--button-font-family',
        [EWebsiteThemeFontProperties.fontSize]: '--button-font-size',
        [EWebsiteThemeFontProperties.fontStyle]: '--button-font-style',
        [EWebsiteThemeFontProperties.fontWeight]: '--button-font-weight',
        [EWebsiteThemeFontProperties.lineHeight]: '--button-line-height',
        [EWebsiteThemeFontProperties.textTransform]: '--button-text-transform',
        [EWebsiteThemeFontProperties.letterSpacing]: '--button-letter-spacing',
    },
};

export const WEBSITE_MOBILE_THEME_FONTS_VARIABLES = {
    [EThemeFontTags.leading]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--leading-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--leading-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--leading-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--leading-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--leading-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--leading-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--leading-letter-spacing-mobile',
    },
    [EThemeFontTags.big]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--big-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--big-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--big-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--big-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--big-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--big-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--big-letter-spacing-mobile',
    },
    [EThemeFontTags.small]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--small-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--small-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--small-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--small-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--small-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--small-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--small-letter-spacing-mobile',
    },
    [EThemeFontTags.quote]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--quote-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--quote-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--quote-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--quote-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--quote-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--quote-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--quote-letter-spacing-mobile',
    },
    [EThemeFontTags.signature]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--signature-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--signature-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--signature-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--signature-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--signature-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--signature-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--signature-letter-spacing-mobile',
    },
    [EThemeFontTags.p]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--p-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--p-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--p-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--p-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--p-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--p-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--p-letter-spacing-mobile',
    },
    [EThemeFontTags.h1]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h1-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--h1-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--h1-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--h1-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--h1-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--h1-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h1-letter-spacing-mobile',
    },
    [EThemeFontTags.h2]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h2-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--h2-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--h2-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--h2-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--h2-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--h2-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h2-letter-spacing-mobile',
    },
    [EThemeFontTags.h3]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h3-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--h3-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--h3-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--h3-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--h3-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--h3-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h3-letter-spacing-mobile',
    },
    [EThemeFontTags.h4]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--h4-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--h4-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--h4-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--h4-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--h4-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--h4-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--h4-letter-spacing-mobile',
    },
    [EThemeFontTags.pre]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--pre-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--pre-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--pre-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--pre-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--pre-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--pre-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--pre-letter-spacing-mobile',
    },
    [EThemeFontTags.button]: {
        [EWebsiteThemeFontProperties.fontFamily]: '--button-font-family-mobile',
        [EWebsiteThemeFontProperties.fontSize]: '--button-font-size-mobile',
        [EWebsiteThemeFontProperties.fontStyle]: '--button-font-style-mobile',
        [EWebsiteThemeFontProperties.fontWeight]: '--button-font-weight-mobile',
        [EWebsiteThemeFontProperties.lineHeight]: '--button-line-height-mobile',
        [EWebsiteThemeFontProperties.textTransform]: '--button-text-transform-mobile',
        [EWebsiteThemeFontProperties.letterSpacing]: '--button-letter-spacing-mobile',
    },
};
