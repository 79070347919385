export type AnimationSettings = {
    isActive: boolean;
    animationType: EAnimationType;
    animationPosition: EAnimationPosition;
    animationSpeed: EAnimationSpeed;
};

export enum EAnimationType {
    fade = 'fade',
    slide = 'slide',
    scale = 'scale',
    runningStripe = 'running_stripe',
}

export enum EAnimationPosition {
    left = 'left',
    top = 'top',
    right = 'right',
    bottom = 'bottom',
}

export enum EAnimationSpeed {
    slow = 'slow',
    medium = 'medium',
    fast = 'fast',
}
