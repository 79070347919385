import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'removePTags',
})
export class RemovePTags implements PipeTransform {
    transform(value: string): string {
        let valueWithOutP = value.replace(/^\<p\>/,"").replace(/\<\/p\>$/,"");
        return valueWithOutP;
    }
}