<div [class]="classes?.element">
    <div>
        <img
            *ngIf="image"
            [class]="classes?.image"
            [spFallbackImage]="avatarBlankPicture"
            [src]="image"
            [alt]="file?.alt || file?.name"
        />
        <img
            *ngIf="!image"
            [class]="classes?.image"
            [src]="avatarBlankPicture"
            alt="avatar-blank"
        />
    </div>
    <div
        [class]="classes?.avatarContent"
        [innerHTML]="content | spSafeHtml"
    ></div>
</div>
