export enum ELanguages {
    EN = 'en',
    ES_MX = 'es-mx',
    FR = 'fr',
    PT_BR = 'pt-br',
    RU = 'ru',
    TR = 'tr',
    UA = 'ua',
    IT = 'it',
    ID = 'id',
    FIL = 'fil',
    // edu only
    AZ = 'az',
    CS = 'cs',
    DE = 'de',
    KA = 'ka',
    PL = 'pl',
    HY = 'hy',
    NL = 'nl',
    SH = 'sh',
}
