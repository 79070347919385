export enum ETestimonialLayout {
    carousel = 'carousel',
    grid = 'grid',
}

export enum ETestimonialCount {
    all = 'all',
    last20 = 'last20',
    last50 = 'last50',
    last100 = 'last100',
    last200 = 'last200',
}

export enum ETestimonialType {
    noAvatar = 'noAvatar',
    avatarTop = 'avatarTop',
    avatarLeft = 'avatarLeft',
}
