import type { ThemeButton, ThemeButtonPreset } from '@libs/themes/src/types/theme-button.type';

import { filterEmptyFields, hashCode } from '@common/helpers';

import { DEFAULT_WEBSITE_BUTTON_PRESETS } from '../constants/default-presets';

export function getWebsiteThemeButtonPresets(): ThemeButtonPreset[] {
    return DEFAULT_WEBSITE_BUTTON_PRESETS.map((buttons: ThemeButton[]) => ({
        index: hashCode(buttons),
        values: buttons.map((button: ThemeButton) =>
            filterEmptyFields({
                styleType: button.styleType,
                base: button.base || null,
                hover: button.hover || null,
            }),
        ),
    }));
}
