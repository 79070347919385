import { createActionGroup, props } from '@ngrx/store';
import { type SiteEntity, type UpdateSiteRequest } from '@common/types/site.type';
import { type SitePageEntity } from '@common/types/site-page.type';
import { type Template } from '@common/types/template.type';
import { type FileEditType } from '@common/types/editor-file.type';

type NavigateTo = { pageId?: string; siteId?: string; isNew?: boolean };

export const SiteActions = createActionGroup({
    source: 'Site',
    events: {
        'Load site success': props<{ site: SiteEntity; currentPage: SitePageEntity }>(),
        'Load site failure': props<{ error: string }>(),
        'Load template success': props<{ template: Template }>(),
        'Set new site data': props<{ name: string; subDomain: string; domain: string; systemDomainId: number }>(),
        'Save site': props<{ exit?: boolean; publish?: boolean; navigateTo?: NavigateTo; skipContentChange?: boolean }>(),
        'Save site request': props<{ exit?: boolean; publish: boolean; navigateTo?: NavigateTo; skipContentChange?: boolean }>(),
        'Save site success': props<{ site: SiteEntity }>(),
        'Save site failure': props<{ error: string }>(),
        'Create site request': props<{ exit?: boolean; publish?: boolean; navigateTo?: NavigateTo }>(),
        'Create site success': props<{ site: SiteEntity }>(),
        'Update site': props<{ site: SiteEntity }>(),
        'Update site request': props<{ siteId: number; data: UpdateSiteRequest }>(),
        'Update site success': props<{ site: SiteEntity }>(),
        'Update site failure': props<{ error: string }>(),
        'Update admin site template request': props<{ exit?: boolean }>(),
        'Preview site request': props<{ content: string }>(),
        'Preview site success': props<{ content: string }>(),
        'Preview site failure': props<{ error: string }>(),
        'Navigate template site page': props<{ templateId: string; siteId: string; pageId: string }>(),
        'Upload image': props<{ data: FileEditType }>(),
        'Upload image failure': props<{ error: any }>(),
        'Toggle saving': props<{ saving: boolean }>(),
        'Resize image': props<{ url: string; path: string }>(),
    },
});

export default SiteActions;
