import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EEnvTypes } from '@common/enums/env-types.enym';
import { environment } from '@web-builder-env/environment';

import { CartModule } from '@web-builder/store/cart/cart.module';
import { CartFacade } from '@web-builder/store/cart/cart.facade';

const onlyDevModules = [];

if (environment.type !== EEnvTypes.PROD) {
    onlyDevModules.push(StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }));
}

@NgModule({
    imports: [...onlyDevModules, StoreModule.forRoot(), EffectsModule.forRoot([]), CartModule],
    providers: [CartFacade],
})
export class AppStoreModule {}
