import { type ThemeButtonHover, type ThemeSelectedButtons, type ThemeSelectedFonts, type ThemeSettingsState } from '@libs/themes';
import { getWebsiteThemeFontPresets } from '@libs/themes/src/variants/website/models/font-presets.model';
import { getWebsiteThemeButtonPresets } from '../models/button-presets.model';
import { EButtonHoverType } from '@common/enums';

function adaptThemeFonts(fontSettings: ThemeSettingsState['fonts']): ThemeSettingsState['fonts'] {
    const model = getWebsiteThemeFontPresets()[0];

    const selectedMap = new Map(fontSettings?.selected.map((item) => [item.tag, item]));
    const selected = model?.values.map((modelItem) => {
        const selectedThemeFontItem = selectedMap.get(modelItem.tag);

        if (selectedThemeFontItem) {
            return {
                ...modelItem,
                ...selectedThemeFontItem,
            };
        }

        return modelItem;
    });

    return {
        ...fontSettings,
        selected,
    } as ThemeSelectedFonts;
}

function adaptThemeButtons(buttonSettings: ThemeSettingsState['buttons']): ThemeSettingsState['buttons'] {
    const selectedMap = new Map(buttonSettings?.selected.map((item) => [item.styleType, item]));

    const selected = buttonSettings?.selected.map((modelItem) => {
        const selectedThemeButtonItem = { ...selectedMap.get(modelItem.styleType) };

        if (!selectedThemeButtonItem.hover) {
            selectedThemeButtonItem.hover = {
                background: modelItem.base!.background,
                borderColor: modelItem.base!.borderColor,
                color: modelItem.base!.color,
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
            };
        } else if (selectedThemeButtonItem?.hover.hoverType === EButtonHoverType.opacity) {
            //FIX THEME AFTER FIRST VERSION ADAPTER
            (selectedThemeButtonItem.hover as Partial<ThemeButtonHover>) = {
                ...modelItem.hover,
                background: modelItem.base!.background,
                borderColor: modelItem.base!.borderColor,
                color: modelItem.base!.color,
                opacity: modelItem.hover!.opacity,
                shadow: 'none',
            };
        }

        return selectedThemeButtonItem;
    });

    return {
        ...buttonSettings,
        selected,
    } as ThemeSelectedButtons;
}

export function adaptThemeSettings(settings: ThemeSettingsState): ThemeSettingsState {
    return {
        ...settings,
        fonts: adaptThemeFonts(settings.fonts),
        buttons: adaptThemeButtons(settings.buttons),
    };
}
