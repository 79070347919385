import { JssStyle } from 'jss';
import { EHorizontalPosition } from '@common/enums';

export const commonPositionStyles: Record<EHorizontalPosition, JssStyle> = {
    [EHorizontalPosition.L]: {
        textAlign: 'left',
        justifyContent: 'flex-start',
    },
    [EHorizontalPosition.M]: {
        textAlign: 'center' as 'center',
        justifyContent: 'center',
    },
    [EHorizontalPosition.R]: {
        textAlign: 'right',
        justifyContent: 'flex-end',
    },
};
