import type { ITextElementData } from '@app/features/text/interface/ITextElement';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { getStyles } from '@app/features/text/component/text-view/text-view.component.styles';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-text-feature',
    templateUrl: './text-feature.component.html',
    styleUrls: ['./text-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class TextFeatureComponent extends BaseFeatureComponent<ITextElementData> {
    protected getStyles = getStyles;

    public get content(): string {
        return this.elementData.content;
    }

    public get formattedContent(): string {
        if (this.preventTrackingLinkTransformations) {
            return this.content;
        }

        return this.trackingLinkConverterService.convertAllTrackingLinksFromText(this.content);
    }
}
