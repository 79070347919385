import type { Params } from '@angular/router';
import type { Site, SiteEntity } from '@common/types/site.type';
import type { SitePage } from '@common/types/site-page.type';
import type { Template } from '@common/types/template.type';
import type { ELanguages } from '@common/enums';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

const AppActions = createActionGroup({
    source: 'App',
    events: {
        'Init App': props<{ isAdmin: boolean; routerParams: Params; routerQueryParams: Params }>(),
        'Init App Request': props<{
            site?: SiteEntity;
            template?: Template;
            currentPage?: SitePage;
            pages?: SitePage[];
        }>(),
        'Init App Success': emptyProps(),
        'Init App Failure': props<{ error: string }>(),
        'Init Template': emptyProps(),
        'Init Site': props<{ routerParams: Params }>(),
        'Init New Site': emptyProps(),
        'Init New Page': emptyProps(),
        'Init New Site From Template': emptyProps(),
        'Init New Page From Template': emptyProps(),
        'App Is Ready': props<{ site?: Site; template?: Template; page?: any }>(),
        'App Init Error': props<{ error: string }>(),
        'Init Exit': emptyProps(),
        'Exit App': emptyProps(),
        'Set Is Admin': props<{ isAdmin: boolean }>(),
        'Set Locale': props<{ locale: ELanguages }>(),
        'Intro Finished': emptyProps(),
    },
});

export default AppActions;
