export enum EPositionOptions {
    center = 'center',
    leftTop = 'left top',
    topCenter = 'top center',
    rightTop = 'right top',
    rightCenter = 'right center',
    rightBottom = 'right bottom',
    centerBottom = 'center bottom',
    leftBottom = 'left bottom',
    leftCenter = 'left center',
}

export interface IPositionOptions {
    label: string;
    value: EPositionOptions;
}

export const positionOptions: IPositionOptions[] = [
    {
        label: 'background_settings_position_center',
        value: EPositionOptions.center,
    },
    {
        label: 'background_settings_position_top_left',
        value: EPositionOptions.leftTop,
    },
    {
        label: 'background_settings_position_center_top',
        value: EPositionOptions.topCenter,
    },
    {
        label: 'background_settings_position_top_right',
        value: EPositionOptions.rightTop,
    },
    {
        label: 'background_settings_position_center_right',
        value: EPositionOptions.rightCenter,
    },
    {
        label: 'background_settings_position_bottom_right',
        value: EPositionOptions.rightBottom,
    },
    {
        label: 'background_settings_position_bottom_center',
        value: EPositionOptions.centerBottom,
    },
    {
        label: 'background_settings_position_bottom_left',
        value: EPositionOptions.leftBottom,
    },
    {
        label: 'background_settings_position_left_center',
        value: EPositionOptions.leftCenter,
    },
];
