import type { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';
import type { EWebsiteThemeFontProperties } from '../enums/font.enum';
import type { EWebsiteThemeButtonProperties } from '../enums/button.enum';
import { type EWebsiteThemeColorNames } from '../enums/color.enum';

import { WEBSITE_THEME_BUTTONS_VARIABLES, WEBSITE_THEME_COLORS_VARIABLES, WEBSITE_THEME_FONTS_VARIABLES } from '../constants/css-variables';

const varString = (value: string): string => `var(${value})`;

export function websiteColorCss(property: EWebsiteThemeColorNames | string): string {
    if (!WEBSITE_THEME_COLORS_VARIABLES[property]) {
        return property;
    }

    return varString(WEBSITE_THEME_COLORS_VARIABLES[property]);
}

export function websitePrimaryButtonCss(property: EWebsiteThemeButtonProperties, index: number = 0): string {
    const variable = WEBSITE_THEME_BUTTONS_VARIABLES.primary![property];

    if (Array.isArray(variable)) {
        return varString(variable[index]!);
    }

    return varString(variable);
}

export function websiteSecondaryButtonCss(property: EWebsiteThemeButtonProperties, index: number = 0): string {
    const variable = WEBSITE_THEME_BUTTONS_VARIABLES.secondary![property];

    if (Array.isArray(variable)) {
        return varString(variable[index]!);
    }

    return varString(variable);
}

export function websiteFontCss(tag: EThemeFontTags, property: EWebsiteThemeFontProperties): string {
    return varString(WEBSITE_THEME_FONTS_VARIABLES[tag][property]);
}
