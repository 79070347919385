import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { APP_ID, importProvidersFrom, type ApplicationConfig } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { routes } from '@web-builder/app.routes';
import { JwtInterceptor } from '@web-builder/core/interceptors/jwt.interceptor';
import { SoaInterceptor } from '@web-builder/core/interceptors/soa.interceptor';
import { FeaturesModule } from '@web-builder/mls-widgets/features.module';

import { AppStoreModule } from '@web-builder/store/app-store.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule,
            TranslateModule.forRoot(),
            ModalModule.forRoot(),
            ReactiveFormsModule,
            NgxDropzoneModule,
            FeaturesModule,
            AppStoreModule,
            BsDropdownModule,
        ),
        provideClientHydration(
            withHttpTransferCacheOptions({
                filter: (req) => {
                    return !req.url.includes('geoip.sendpulse.com');
                },
            }),
        ),
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        { provide: APP_ID, useValue: 'webBuilderApp' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SoaInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        provideRouter(routes, withEnabledBlockingInitialNavigation()),
        provideAnimations(),
    ],
};
