import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';

export const DEFAULT_FONT_LINE_HEIGHT = {
    [EThemeFontTags.leading]: '140%',
    [EThemeFontTags.big]: '140%',
    [EThemeFontTags.small]: '140%',
    [EThemeFontTags.quote]: '140%',
    [EThemeFontTags.signature]: '140%',
    [EThemeFontTags.p]: '140%',
    [EThemeFontTags.h1]: '120%',
    [EThemeFontTags.h2]: '120%',
    [EThemeFontTags.h3]: '120%',
    [EThemeFontTags.h4]: '120%',
    [EThemeFontTags.button]: '120%',
    [EThemeFontTags.pre]: '',
};
