import { ChangeDetectionStrategy, Component } from '@angular/core';

import { getStyles } from '@app/features/indent/components/indent-view/indent-view.component.styles';
import { IIndentElementData } from '@app/features/indent/interface/IIndentElement';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-indent-feature',
    templateUrl: './indent-feature.component.html',
    styleUrls: ['./indent-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class IndentFeatureComponent extends BaseFeatureComponent<IIndentElementData> {
    protected getStyles = getStyles;
}
