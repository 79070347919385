import { Pipe, PipeTransform } from '@angular/core';

import { rgbaToHex } from '../helpers';

@Pipe({
    name: 'rgbaToHex',
})
export class RgbaToHexPipe implements PipeTransform {
    public transform(value: string): string {
        return rgbaToHex(value);
    }
}
