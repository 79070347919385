import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { DateTime as DateTimeType } from '@common/types';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ELanguages } from '@common/enums';

import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';

dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
    name: 'spTimeFormat',
})
export class SpTimeFormat implements PipeTransform {
    public transform(date: DateTimeType, locale: ELanguages = ELanguages.EN): string {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return dayjs.utc(date).locale(this.getLocale(locale)).tz(timeZone).format('DD MMMM, YYYY HH:mm:ss');
    }

    private getLocale(locale: ELanguages): string {
        switch (locale) {
            case ELanguages.UA:
                return 'uk';
            case ELanguages.ES_MX:
                return 'es-mx';
            case ELanguages.PT_BR:
                return 'pt-br';
            case ELanguages.IT:
                return 'it';
            case ELanguages.TR:
                return 'tr';
            case ELanguages.FR:
                return 'fr';
            case ELanguages.RU:
                return 'ru';
            case ELanguages.EN:
            default:
                return 'en';
        }
    }
}
