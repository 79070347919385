import type { FormControl } from '@angular/forms';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { type Nullable } from '@common/types';

@Component({
    selector: 'sp-switch',
    templateUrl: './sp-switch.component.html',
    styleUrls: ['./sp-switch.component.less'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSwitchComponent {
    @Input() public control: Nullable<FormControl<boolean>> = null;
    @Input() public active: boolean = false;
    @Input() public disabled: boolean = false;

    @Output() public toggleActiveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public toggleActiveHandler(event: Event): void {
        event.preventDefault();

        const isActive: boolean = !this.isActive;

        if (this.control) {
            this.control.setValue(isActive);
        }

        if (this.toggleActiveEvent.observed) {
            this.toggleActiveEvent.emit(isActive);
        }
    }

    public get isActive(): boolean {
        return this.control?.value ?? this.active;
    }
}
