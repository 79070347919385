import type { SiteSettings } from '@common/types/site-settings.type';
import type { SettingsState } from './settings.reducer';
import type { SiteFeatureState } from '../site-feature.reducer';

import { createSelector } from '@ngrx/store';
import { selectIsAdmin } from '@app/store/app/app.selectors';

import { SETTINGS_FEATURE_KEY } from './settings.reducer';
import { siteRootState } from '../site-state.selectors';

export const selectSettingsState = createSelector(siteRootState, (state: SiteFeatureState): SettingsState => state[SETTINGS_FEATURE_KEY]);

export const selectSiteSettings = createSelector(selectSettingsState, (state: SettingsState): SiteSettings => state?.settings);
export const selectShowPoweredBy = createSelector(selectSiteSettings, (siteSettings: SiteSettings) =>
    Boolean(Number(siteSettings?.is_show_sp_logo ?? '1')),
);
export const selectIsPage404 = (pageId: number | string) =>
    createSelector(selectSiteSettings, selectIsAdmin, (siteSettings: SiteSettings, isAdmin: boolean): boolean =>
        isAdmin ? false : siteSettings?.not_found_page_id?.toString() === pageId?.toString(),
    );
