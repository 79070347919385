import type { ThemePresetsState } from '@libs/themes/src/store/theme-presets/theme-presets.reducer';
import type { ThemeSettingsState } from '@libs/themes/src/store/theme-settings/theme-settings.reducer';

import { createActionGroup, props } from '@ngrx/store';

const ThemeActions = createActionGroup({
    source: 'Theme',
    events: {
        'Init Theme': props<{ themeSettings: ThemeSettingsState; themePresets?: ThemePresetsState }>(),
        'Set Theme': props<{ presets: ThemePresetsState; settings: ThemeSettingsState }>(),
    },
});

export default ThemeActions;
