import { ESimpleThemeFontProperties } from '@common/libs/themes/src/variants/simple/enums/font.enum';
import { EThemeFontTags } from '@common/libs/themes/src/enums/font-tags.enum';

export const SIMPLE_THEME_FONTS_VARIABLES = {
    [EThemeFontTags.p]: {
        [ESimpleThemeFontProperties.fontFamily]: '--p-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--p-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--p-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--p-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--p-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--p-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--p-letter-spacing',
    },
    [EThemeFontTags.h1]: {
        [ESimpleThemeFontProperties.fontFamily]: '--h1-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--h1-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--h1-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--h1-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--h1-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--h1-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--h1-letter-spacing',
    },
    [EThemeFontTags.h2]: {
        [ESimpleThemeFontProperties.fontFamily]: '--h2-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--h2-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--h2-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--h2-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--h2-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--h2-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--h2-letter-spacing',
    },
    [EThemeFontTags.h3]: {
        [ESimpleThemeFontProperties.fontFamily]: '--h3-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--h3-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--h3-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--h3-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--h3-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--h3-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--h3-letter-spacing',
    },
    [EThemeFontTags.h4]: {
        [ESimpleThemeFontProperties.fontFamily]: '--h4-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--h4-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--h4-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--h4-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--h4-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--h4-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--h4-letter-spacing',
    },
    [EThemeFontTags.pre]: {
        [ESimpleThemeFontProperties.fontFamily]: '--pre-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--pre-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--pre-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--pre-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--pre-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--pre-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--pre-letter-spacing',
    },
    [EThemeFontTags.button]: {
        [ESimpleThemeFontProperties.fontFamily]: '--button-font-family',
        [ESimpleThemeFontProperties.fontSize]: '--button-font-size',
        [ESimpleThemeFontProperties.fontStyle]: '--button-font-style',
        [ESimpleThemeFontProperties.fontWeight]: '--button-font-weight',
        [ESimpleThemeFontProperties.lineHeight]: '--button-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--button-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--button-letter-spacing',
    },
};
