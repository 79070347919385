<div [id]="element.id" [class]="classes?.form" style="position: relative">
    <div class="preloader-icon" *ngIf="isLoading">
        <img [src]="loaderSrc" alt=""/>
    </div>
    <form method="POST" ngNoForm #form>
        <div [class]="classes.spField" *ngFor="let control of elementData.elements; index as i" [ngSwitch]="true">
            <ng-container *ngSwitchCase="typeGuards.isInput(control)">
                <label [class.hidden]="control.type === formInputType.hidden" *ngIf="elementData.showLabels"
                >{{ control.label }}{{ control.required ? " *" : "" }}</label
                >
                <ng-container *ngIf="!control.isDynamicPlaceholderEnabled && control.type !== 'file'">
                    <input
                        [id]="getInputIdForSelenium(control)"
                        [class]="classes.spFormControl"
                        [attr.type]="control.htmlInputType"
                        [attr.autocomplete]="control.autocompleteType"
                        [attr.name]="getControlNameAttr(control)"
                        [attr.placeholder]="control.placeholder"
                        [attr.data-contact-var-id]="control.crmContactVariable"
                        [attr.data-deal-var-id]="control.crmDealVariable"
                        [attr.data-form-field]="true"
                        [required]="control.required"
                        [value]="control.type === formInputType.hidden ? control.value : ''"
                    />
                </ng-container>
                <ng-container *ngIf="control.type === 'phone' && control.isDynamicPlaceholderEnabled">
                    <ngx-intl-tel-input
                        [attr.data-form-field]="true"
                        [class]="'intl-size-' + elementData.fieldsSize"
                        [cssClass]="classes?.spFormControl"
                        [enableAutoCountrySelect]="true"
                        [selectFirstCountry]="false"
                        [separateDialCode]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [phoneValidation]="true"
                        [searchCountryField]="[SearchCountryField.All]"
                        [excludeCountries]="excludeCountries"
                        [selectedCountryISO]="CountryISO"
                        [numberFormat]="PhoneNumberFormat.International">
                    </ngx-intl-tel-input>
                    <div class="text-error-msg" *ngIf="isDynamicPhoneError">
                        {{ 'control_errors_phone_format' | translate: { PHONE_FORMAT: phonePlaceholder } }}
                    </div>
                </ng-container>
                <ng-container *ngIf="control.type === 'file'">
                    <ngx-dropzone (change)="onFileUpload($event)" [id]="'file'">
                        <ngx-dropzone-label>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 11C3.55228 11 4 11.4477 4 12V17C4 18.6568 5.34315 20 7 20H17C18.6568 20 20 18.6568 20 17V12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12V17C22 19.7614 19.7614 22 17 22H7C4.23857 22 2 19.7614 2 17V12C2 11.4477 2.44772 11 3 11Z" fill="#3A83A2"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4001 3.2C11.7556 2.93333 12.2445 2.93333 12.6001 3.2L16.6001 6.2C17.0419 6.53137 17.1314 7.15817 16.8001 7.6C16.4687 8.04183 15.8419 8.13137 15.4001 7.8L13.0001 6V15C13.0001 15.5523 12.5523 16 12.0001 16C11.4478 16 11.0001 15.5523 11.0001 15V6L8.60006 7.8C8.15823 8.13137 7.53143 8.04183 7.20006 7.6C6.86869 7.15817 6.95823 6.53137 7.40006 6.2L11.4001 3.2Z" fill="#3A83A2"/>
                            </svg>
                            <p>{{ control.placeholder | translate }}</p>
                        </ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let file of files"
                                              [removable]="true"
                                              (removed)="onFileRemove(file)">
                            <ngx-dropzone-label>{{ file.name }}</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                    <div class="text-error-msg-container" *ngIf="filesSize > 500">
                        <div class="text-error-msg">{{ 'control_file_error' | translate }}</div>
                    </div>
                    <div class="text-error-msg-container" *ngIf="isFilesError">
                        <div class="text-error-msg">{{ 'control_file_error_required' | translate }}</div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="typeGuards.isTextarea(control)">
                <label *ngIf="elementData.showLabels"> {{ control.label }}{{ control.required ? " *" : "" }} </label>
                <textarea
                    [attr.autocomplete]="control.autocompleteType"
                    [attr.data-crm-comment]="control.crmCommentPlace"
                    [attr.name]="getControlNameAttr(control)"
                    [attr.data-form-field]="true"
                    [attr.data-contact-var-id]="control.crmContactVariable"
                    [attr.data-deal-var-id]="control.crmDealVariable"
                    [required]="control.required"
                    [maxLength]="5000"
                    [class]="classes?.spFormCotrolTextarea"
                    [attr.placeholder]="control.placeholder"></textarea>

                    <ng-container *ngIf="control.crmCommentPlace === 'crmAllComment'">
                        <input [id]="'contactComment-' + i" type="hidden" name="contactComments[]">
                        <input [id]="'dealComment-' + i" type="hidden" name="dealComments[]">
                    </ng-container>

                    <ng-container *ngIf="control.crmCommentPlace === 'crmContactComment'">
                        <input [id]="'contactComment-' + i" type="hidden" name="contactComments[]">
                    </ng-container>

                    <ng-container *ngIf="control.crmCommentPlace === 'crmDealComment'">
                        <input [id]="'dealComment-' + i" type="hidden" name="dealComments[]">
                    </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="typeGuards.isCheckbox(control)">
                <label class="sp-checkbox">
                    <input
                        [id]="getInputIdForSelenium(control)"
                        [class]="classes.spCheckbox"
                        [attr.type]="control.htmlInputType"
                        [attr.name]="getControlNameAttr(control)"
                        [attr.data-contact-var-id]="control.crmContactVariable"
                        [attr.data-deal-var-id]="control.crmDealVariable"
                        [attr.data-form-field]="true"
                        [required]="control.required"
                    />
                    <span *ngIf="control.required" class="checkbox-text" [innerHTML]="' ' + (control.label | removePTags) + ' *'"></span>
                    <span *ngIf="!control.required" class="checkbox-text" [innerHTML]="' ' + (control.label | removePTags)"></span>
                    <!-- <span> {{ control.label }}{{ control.required ? " *" : "" }}</span> -->
                </label>
            </ng-container>

            <ng-container *ngSwitchCase="typeGuards.isRadio(control)">
                <label *ngIf="elementData.showLabels">{{ control.label }}{{ control.required ? " *" : "" }}</label>
                <div class="radio-options" *ngFor="let option of control.options">
                    <label class="sp-radio">
                        <input
                            [id]="getInputIdForSelenium(control)"
                            [class]="classes.spRadio"
                            [attr.type]="control.htmlInputType"
                            [attr.name]="getControlNameAttr(control)"
                            [value]="option.value"
                            [checked]="option.selected"
                            [attr.data-contact-var-id]="control.crmContactVariable"
                            [attr.data-deal-var-id]="control.crmDealVariable"
                            [attr.data-form-field]="true"
                            [required]="control.required"
                        />
                        <span> {{ option.label }}</span>
                    </label>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="typeGuards.isSelect(control)">
                <label *ngIf="elementData.showLabels">{{ control.label }}{{ control.required ? " *" : "" }}</label>
                <label class="sp-select">
                    <select
                        [id]="getInputIdForSelenium(control)"
                        class="form-control"
                        [class]="classes.spSelect"
                        [attr.name]="getControlNameAttr(control)"
                        [attr.data-contact-var-id]="control.crmContactVariable"
                        [attr.data-deal-var-id]="control.crmDealVariable"
                        [attr.data-form-field]="true"
                        [required]="control.required"
                    >
                        <option [ngValue]="null" [selected]="getSelected(control.options)" disabled>
                            {{ "select_value" | translate }}
                        </option>
                        <option
                            *ngFor="let option of control.options"
                            [class]="classes.spRadio"
                            [value]="option.value"
                            [selected]="option.selected"
                        >
                            {{ option.label }}
                        </option>
                    </select>
                </label>
            </ng-container>

            <ng-container *ngIf="crmContactField(control)">
                <input type="hidden" name="contactFields[{{ control.crmContactVariable }}]" />
            </ng-container>

            <ng-container *ngIf="crmContactAttribute(control)">
                <input type="hidden" name="contactVariables[{{ control.crmContactVariable }}]" />
            </ng-container>

            <ng-container *ngIf="control.crmDealVariable">
                <input type="hidden" name="dealVariables[{{ control.crmDealVariable }}]" />
            </ng-container>
        </div>
        <input type="hidden" name="elementId" [value]="element.id" />

        <input type="hidden" name="pageId" *ngIf="pageId" [value]="pageId" />

        <input type="hidden" name="isDeal" [value]="isDeal" />

        <input type="hidden" name="updateExistingContact" [value]="isUpdateExistingContact" />

        <input type="hidden" name="utm" [value]="utm" />

        <ng-container *ngIf="isDeal">
            <input type="hidden" name="dealName" [value]="element.data.dealName" />
        </ng-container>

        <ng-container *ngIf="element.data.pipelineId">
            <input type="hidden" name="pipelineId" [value]="element.data.pipelineId" />
            <input type="hidden" name="pipelineStepId" [value]="element.data.pipelineStepId" />
        </ng-container>

        <a (click)="submitForm()" class="btn-hover" [class]="classes.element" [id]="element.id + '-submit'">
            <div [class]="classes.wrapContent">
                <span [class]="classes.label">
                    {{ elementData.buttonText }}
                </span>
            </div>
        </a>
    </form>
</div>
