export enum ECartViewType {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export enum ECartIconType {
    ICON_1 = 'svgIcon1',
    ICON_2 = 'svgIcon2',
    ICON_3 = 'svgIcon3',
}
