import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export abstract class BaseHttpService {
    private readonly baseApiUrlServices = {
        landing: '/api/landings-service',
        email: '/api/email-service',
        crm: '/api/crm-service/api/v1',
        payment: '/api/payment-service',
        edu: '/api/edu-service/api/v1',
        fileManager: '/api/file-manager-service/api/v1',
        ai: '/ai-service',
    };

    constructor(protected readonly http: HttpClient, protected readonly cookieService: CookieService) {}

    protected formatApiUrl(url: string = '', prefix = ''): string {
        return `${prefix}${this.baseApiUrlServices.landing}/${url}`;
    }

    protected formatEmailServiceApiUrl(url: string = ''): string {
        return `${this.baseApiUrlServices.email}/${url}`;
    }

    protected formatCRMServiceApiUrl(url: string = ''): string {
        return `${this.baseApiUrlServices.landing}/crm/${url}`;
    }

    protected formatPaymentServiceApiUrl(url: string = ''): string {
        return `${this.baseApiUrlServices.payment}/${url}`;
    }

    protected formatEduServiceApiUrl(url: string = ''): string {
        return `${this.baseApiUrlServices.landing}/education/${url}`;
    }

    protected formatFileManagerServiceApiUrl(url: string = ''): string {
        return `${this.baseApiUrlServices.fileManager}/${url}`;
    }

    protected formatAiServiceApiUrl(url: string = ''): string {
        return `${this.baseApiUrlServices.landing}${this.baseApiUrlServices.ai}/${url}`;
    }
}
