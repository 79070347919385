import { type JssStyle } from 'jss';
import { type ISubscriptionFormElementData } from '@app/features/subscription-form/interface/ISubscriptionFormElement';
import { type IPaymentFormElementData } from '@app/features/payment-form/interface/IPaymentFormElement';
import { commonInputStyles, commonLabelBottomIndent, commonLabelStyles, commonWebsitesFormTextareaStyles } from '@app/features/common/constants/input-sizes.const';

const baseFormControl: JssStyle = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    whiteSpace: 'nowrap',
};

export const formElementsStyles = (elementData: ISubscriptionFormElementData | IPaymentFormElementData): JssStyle => {
    const spFormControl = {
        ...baseFormControl,
        ...commonInputStyles[elementData.fieldsSize],

        '&:hover': {
            borderColor: '#9a9a9a',
        },
        '&:focus': {
            borderColor: '#5f5f5f',
            outline: '0',
        },
        background: '#ffffff',
        borderColor: '#cccccc',
        width: '100%',
    };

    const spFormCotrolTextarea = {
        ...baseFormControl,
        ...commonWebsitesFormTextareaStyles[elementData.fieldsSize],

        '&:hover': {
            borderColor: '#9a9a9a',
        },
        '&:focus': {
            borderColor: '#5f5f5f',
            outline: '0',
        },
        background: '#ffffff',
        borderColor: '#cccccc',
        width: '100%',
        whiteSpace: 'wrap',
    };

    const spField: JssStyle = {
        marginBottom: '15px',
        '&.has-error input': {
            borderColor: '#bb364d',
            backgroundColor: '#fff',

            '&[type=checkbox] + span': {
                color: '#bb364d',
            },

            '&:focus': {
                boxShadow: '0 0 0 2px rgb(212 105 106 / 30%)',
            },
        },
        '&.has-error textarea': {
            borderColor: '#bb364d',
            backgroundColor: '#fff',

            '&:focus': {
                boxShadow: '0 0 0 2px rgb(212 105 106 / 30%)',
            },
        },
        '&.has-error select': {
            borderColor: '#bb364d',
            backgroundColor: '#fff',
        },
        '& label': {
            ...commonLabelStyles[elementData.fieldsSize],
            ...commonLabelBottomIndent[elementData.fieldsSize],
            fontWeight: 'bold',
            display: 'block',
        },
    };

    const checkboxSpanStyle = {
        '& + span': {
            fontWeight: 'normal',
        },
    };

    const spCheckbox = {
        ...checkboxSpanStyle,
        'input[type=checkbox]&': {
            margin: '4px 2px 0 0',
            padding: 0,
        },
    };

    const spRadio = {
        ...checkboxSpanStyle,
        'input[type=radio]&': {
            margin: '4px 0 0',
            lineHeight: 'normal',
            boxSizing: 'border-box',
            padding: 0,
        },
    };

    const spSelect = {
        ...spFormControl,
        '& select': {
            maxWidth: '100% !important',
            boxShadow: 'none',
            backgroundClip: 'padding-box',
            display: 'block',
            width: '100%',
            height: '39px',
            padding: '8px 12px',
            fontSize: '15px',
            lineHeight: 1.42857143,
            color: '#555',
            backgroundColor: '#fff',
            backgroundImage: 'none',
            border: '1px solid #d6e3e3',
            borderRadius: '4px',
            transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        },
    };

    return { spFormControl, spFormCotrolTextarea, spField, checkboxSpanStyle, spCheckbox, spRadio, spSelect };
};
