import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SpPresetSettingsComponent } from '@libs/themes/src/abstract/abstract-preset-settings.component';

@Component({
    selector: 'sp-simple-preset-settings',
    templateUrl: './simple-preset-settings.component.html',
    styleUrls: ['./simple-preset-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSimplePresetSettingsComponent extends SpPresetSettingsComponent {}
