import { ChangeDetectionStrategy, Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { type Observable } from 'rxjs';

import { DeviceMode } from '@common/types/layout.type';
import { THEME_STRATEGY_TOKEN } from '@common/libs/themes/src/tokens';

import { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';
import { EThemeVariants } from '@libs/themes/src/enums/theme-variants.enum';

import { AbstractBaseThemeStrategy } from '@common/libs/themes/src/strategies/abstract-base-theme.strategy';
import { ThemeConfigFacade } from '@common/libs/themes/src/store/theme-config/theme-config.facade';

@Component({
    selector: 'sp-themes',
    templateUrl: './themes.component.html',
    styleUrls: ['./themes.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpThemesComponent {
    @Input() public deviceMode: DeviceMode = DeviceMode.desktop;
    @Output() public readonly changeDeviceMode: EventEmitter<DeviceMode> = new EventEmitter();

    public readonly EThemeSettings = EThemeSettings;
    public readonly EThemeVariants = EThemeVariants;
    public readonly themeConfigLoaded$: Observable<boolean> = this.themeConfigFacade.themeConfigLoaded$;
    public readonly activeThemeSetting$: Observable<EThemeSettings> = this.themeConfigFacade.activeThemeSetting$;

    constructor(
        @Inject(THEME_STRATEGY_TOKEN) private readonly themeStrategy: AbstractBaseThemeStrategy,
        private readonly themeConfigFacade: ThemeConfigFacade,
    ) {}

    public get strategyType(): EThemeVariants {
        return this.themeStrategy.type;
    }
}
