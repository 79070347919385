import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { CSS_RESTRICTIONS } from '@common/constants';

export function filterEmptyFields(data: any): any {
    const fields = {};
    Object.keys(data).forEach((key) => {
        if (data[key] === '' || data[key] == null) {
            return;
        }

        fields[key] = data[key];
    });
    return fields;
}

export function getValidMinMaxValue(value: number, min: number, max: number): number {
    if (value > max) {
        return max;
    }

    if (value < min) {
        return min;
    }

    return value;
}

export function getValidLineHeight(value: number): number {
    return getValidMinMaxValue(value, CSS_RESTRICTIONS.LINE_HEIGHT_MIN, CSS_RESTRICTIONS.LINE_HEIGHT_MAX);
}

export function getValidFontSize(value: number): number {
    return getValidMinMaxValue(value, CSS_RESTRICTIONS.FONT_SIZE_MIN, CSS_RESTRICTIONS.FONT_SIZE_MAX);
}

export function getValidLetterSpacing(value: number): number {
    return getValidMinMaxValue(value, CSS_RESTRICTIONS.LETTER_SPACING_MIN, CSS_RESTRICTIONS.LETTER_SPACING_MAX);
}

export function getValidBorderWidth(value: number): number {
    return getValidMinMaxValue(value, CSS_RESTRICTIONS.BORDER_WIDTH_MIN, CSS_RESTRICTIONS.BORDER_WIDTH_MAX);
}

export function getValidBorderRadius(value: number): number {
    return getValidMinMaxValue(value, CSS_RESTRICTIONS.BORDER_RADIUS_MIN, CSS_RESTRICTIONS.BORDER_RADIUS_MAX);
}

export function createFormOf<D extends any = any>(data) {
    const form = new FormGroup({});
    Object.keys(data).forEach((key: string) => {
        if (Array.isArray(data[key])) {
            form.addControl(key, new FormArray([]));
            if (typeof data[key][0] === 'object') {
                (form.get(key) as FormArray).push(createFormOf(data[key][0]));
            } else {
                (form.get(key) as FormArray).push(new FormControl(null));
            }
        } else if (typeof data[key] === 'object' && data[key] !== null) {
            form.addControl(key, createFormOf(data[key]));
        } else {
            form.addControl(key, new FormControl(null));
        }
    });

    return form;
}

