import type { ELanguages } from '@common/enums';
import type { AppState } from './app.reducer';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_FEATURE_KEY } from './app.reducer';

export const selectAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const selectIsReady = createSelector(selectAppState, (state: AppState): boolean => state.isReady);

export const selectIsAdmin = createSelector(selectAppState, (state: AppState): boolean => state.isAdmin);

export const selectError = createSelector(selectAppState, (state: AppState): string => state.error);

export const selectLocale = createSelector(selectAppState, (state: AppState): ELanguages => state.locale);
