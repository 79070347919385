import {
    type AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    type EventEmitter,
    Input,
    NgZone,
    type OnChanges,
    type OnDestroy,
    Output,
    Renderer2,
    type SimpleChange,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Observable, type Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import * as Plyr from '@sendpulse/plyr';

import { DefaultPlyrDriver, type PlyrDriver } from './driver/default-plyr.driver';

@Component({
    selector: 'sp-player, [spPlayer]',
    template: '',
    styleUrls: ['./sp-player.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'spPlayer',
})
export class SpPlayerComponent implements AfterViewInit, OnChanges, OnDestroy {
    private playerChange$ = new BehaviorSubject<Plyr>(null);
    private events = new Map();
    private subscriptions: Subscription[] = [];
    private driver: PlyrDriver;
    private videoElement: HTMLVideoElement;

    @Input() playerType: Plyr.MediaType = 'video';
    @Input() playerTitle: string;
    @Input() playerPoster: string;
    @Input() playerSources: Plyr.Source[];
    @Input() playerTracks: Plyr.Track[];
    @Input() playerOptions: Plyr.Options;
    @Input() playerCrossOrigin: boolean;
    @Input() playerPlaysInline: boolean;
    @Input() isShorts?: boolean;

    @ViewChild('v') private vr: ElementRef;

    @Output() playerInit = this.playerChange$.pipe(filter((player: Plyr) => !!player)) as EventEmitter<Plyr>;

    // standard media events
    @Output() playerProgress = this.createLazyEvent('progress');
    @Output() playerPlaying = this.createLazyEvent('playing');
    @Output() playerPlay = this.createLazyEvent('play');
    @Output() playerPause = this.createLazyEvent('pause');
    @Output() playerTimeUpdate = this.createLazyEvent('timeupdate');
    @Output() playerVolumeChange = this.createLazyEvent('volumechange');
    @Output() playerSeeking = this.createLazyEvent('seeking');
    @Output() playerSeeked = this.createLazyEvent('seeked');
    @Output() playerRateChange = this.createLazyEvent('ratechange');
    @Output() playerEnded = this.createLazyEvent('ended');
    @Output() playerEnterFullScreen = this.createLazyEvent('enterfullscreen');
    @Output() playerExitFullScreen = this.createLazyEvent('exitfullscreen');
    @Output() playerCaptionsEnabled = this.createLazyEvent('captionsenabled');
    @Output() playerCaptionsDisabled = this.createLazyEvent('captionsdisabled');
    @Output() playerLanguageChange = this.createLazyEvent('languagechange');
    @Output() playerControlsHidden = this.createLazyEvent('controlshidden');
    @Output() playerControlsShown = this.createLazyEvent('controlsshown');
    @Output() playerReady = this.createLazyEvent('ready');

    // HTML5 events
    @Output() playerLoadStart = this.createLazyEvent('loadstart');
    @Output() playerLoadedData = this.createLazyEvent('loadeddata');
    @Output() playerLoadedMetadata = this.createLazyEvent('loadedmetadata');
    @Output() playerQualityChange = this.createLazyEvent('qualitychange');
    @Output() playerCanPlay = this.createLazyEvent('canplay');
    @Output() playerCanPlayThrough = this.createLazyEvent('canplaythrough');
    @Output() playerStalled = this.createLazyEvent('stalled');
    @Output() playerWaiting = this.createLazyEvent('waiting');
    @Output() playerEmptied = this.createLazyEvent('emptied');
    @Output() playerCueChange = this.createLazyEvent('cuechange');
    @Output() playerError = this.createLazyEvent('error');

    // YouTube events
    @Output() playerStateChange = this.createLazyEvent('statechange');

    constructor(
        private readonly elementRef: ElementRef<HTMLDivElement>,
        private readonly ngZone: NgZone,
        private readonly renderer: Renderer2,
    ) {}

    public ngOnChanges(changes: { [p in keyof SpPlayerComponent]?: SimpleChange }): void {
        this.subscriptions.push(
            this.playerInit.pipe(first()).subscribe((player: Plyr) => {
                const reInitTriggers = [changes.playerOptions, changes.playerPlaysInline, changes.playerCrossOrigin, changes.isShorts].filter(
                    (change: SimpleChange) => !!change,
                );
                if (reInitTriggers.length && reInitTriggers.some((change: SimpleChange) => !change.firstChange)) {
                    this.initPlayer(true);
                    return;
                }

                this.updatePlayerSource(player);
            }),
        );
    }

    public ngOnDestroy(): void {
        this.destroyPlayer();
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public ngAfterViewInit(): void {
        this.initPlayer();
    }

    private initPlayer(force: boolean = false): void {
        if (force || !this.player) {
            this.ngZone.runOutsideAngular(() => {
                this.destroyPlayer();
                this.driver = new DefaultPlyrDriver();
                this.ensureVideoElement();
                const newPlayer = this.driver.create({
                    videoElement: this.videoElement,
                    options: {
                        ...this.playerOptions,
                        ratio: this.isShorts ? '9:16' : '',
                        captions: {
                            active: true,
                            language: 'auto',
                            update: true,
                        },
                        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'fullscreen'],
                    },
                });
                this.updatePlayerSource(newPlayer);
                this.playerChange$.next(newPlayer);
            });
        }
    }

    private updatePlayerSource(player: Plyr): void {
        this.driver.updateSource({
            videoElement: this.videoElement,
            player,
            source: {
                type: this.playerType,
                title: this.playerTitle,
                sources: this.playerSources,
                poster: this.playerPoster,
                tracks: this.playerTracks,
            },
        });

        if (!!this.playerPoster) {
            setTimeout(() => {
                this.player.poster = this.playerPoster;
            }, 200);
        }
    }

    private createLazyEvent<T extends Plyr.PlyrEvent>(name: Plyr.StandardEvent | Plyr.Html5Event | Plyr.YoutubeEvent): EventEmitter<T> {
        return this.playerInit.pipe(
            switchMap(() => new Observable((observer) => this.on(name, (data: T) => this.ngZone.run(() => observer.next(data))))),
        ) as EventEmitter<T>;
    }

    private destroyPlayer(): void {
        if (!this.player) {
            return;
        }

        Array.from(this.events.keys()).forEach((name) => this.off(name));

        this.driver.destroy({
            player: this.player,
        });

        this.videoElement = null;
    }

    private get hostElement(): HTMLDivElement {
        return this.elementRef.nativeElement;
    }

    // this method is required because the player inserts clone of the original element on destroy
    // so we catch the clone element right here and reuse it
    private ensureVideoElement(): void {
        const videoElement = this.hostElement.querySelector('video');

        if (videoElement) {
            this.videoElement = videoElement;
            return;
        }

        this.videoElement = this.renderer.createElement('video');
        this.videoElement.controls = true;

        if (this.playerCrossOrigin) {
            this.videoElement.setAttribute('crossorigin', '');
        }

        if (this.playerPlaysInline) {
            this.videoElement.setAttribute('playsinline', '');
        }

        this.renderer.appendChild(this.hostElement, this.videoElement);
    }

    private on(name: string, handler: any): void {
        this.events.set(name, handler);
        this.player.on(name as any, handler);
    }

    private off(name: string): void {
        this.player.off(name as any, this.events.get(name));
        this.events.delete(name);
    }

    public get player(): Plyr {
        return this.playerChange$.getValue();
    }
}
