import type { SiteEntity, SiteCreateRequest } from '@common/types/site.type';

import { createImmerReducer } from 'ngrx-immer/store';
import { on } from '@ngrx/store';

import SiteActions from './site.actions';

export const SITE_FEATURE_KEY = 'site';

export type SiteState = {
    site: SiteEntity;
    saving: boolean;
    loaded: boolean;
    error?: string;
    newSiteData?: Partial<SiteCreateRequest>;
};

export const initialSiteState: SiteState = {
    site: null,
    saving: false,
    loaded: false,
};

export const siteReducer = createImmerReducer<SiteState>(
    initialSiteState,
    on(SiteActions.loadSiteSuccess, (state: SiteState, { site }) => ({ ...state, site, loaded: true })),
    on(SiteActions.loadTemplateSuccess, (state: SiteState, { template }) => ({ ...state, site: template as any, loaded: true })),
    on(SiteActions.loadSiteFailure, (state: SiteState, { error }) => ({ ...state, error })),
    on(SiteActions.setNewSiteData, (state: SiteState, { name, domain, subDomain, systemDomainId }) => {
        state.newSiteData = {
            name,
            domain,
            sub_domain: subDomain,
            system_domain_id: systemDomainId,
        };

        return state;
    }),
    on(SiteActions.saveSiteRequest, (state: SiteState) => {
        state.saving = true;

        return state;
    }),
    on(SiteActions.saveSiteFailure, (state: SiteState) => {
        state.saving = false;

        return state;
    }),
    on(SiteActions.saveSiteSuccess, (state: SiteState, { site }) => {
        state.site = site;
        state.saving = false;
        state.newSiteData = null;
        delete state.newSiteData;

        return state;
    }),
    on(SiteActions.toggleSaving, (state: SiteState, { saving }) => {
        state.saving = saving;

        return state;
    }),
    on(SiteActions.updateSite, (state: SiteState, { site }) => {
        state.site = site;

        return state;
    }),
);
