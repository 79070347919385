import { JssStyle } from 'jss';

import { ESimpleThemeColorNames } from '@libs/themes/src/variants/simple/enums/color.enum';
import { EAvatarTypes } from '@app/features/avatar/enum/avatar.enum';
import { IAvatarElementData } from '@app/features/avatar/interface/IAvatarElement';
import { defaultImageBackgroundStyles } from '@app/features/common/styles/index.styles';
import { EHorizontalPosition, ESize } from '@common/enums';

// DEFAULT COMPONENT SETTINGS

export const avatarStyles: JssStyle = {};

export const avatarData: IAvatarElementData = {
    content: `<h1>John Stone</h1><p>Designer</p>`,
    avatarType: EAvatarTypes.picture,
    croppedImgSrc: null,
    file: null,
    size: ESize.M,
    position: EHorizontalPosition.M,
};

export const avatarContainerData = {
    themeBackground: ESimpleThemeColorNames.primary,
};

export const avatarContainerStyles: JssStyle = {
    ...defaultImageBackgroundStyles,
};

export const avatarBlankPicture = './assets/img/elements/avatar-blank.svg';

// END DEFAULT SETTINGS
