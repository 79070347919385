import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AnalyticsSettingsComponent } from './analytics-settings.component';

import { SpSwitchModule } from '@ui/sp-switch';
import { GoogleSettingsComponent } from './google-settings/google-settings.component';
import { PixelSettingsComponent } from '@app/features/common/components/analytics-settings/pixel-settings/pixel-settings.component';

@NgModule({
    declarations: [AnalyticsSettingsComponent, GoogleSettingsComponent, PixelSettingsComponent],
    imports: [CommonModule, LetDirective, ReactiveFormsModule, TranslateModule, SpSwitchModule],
    exports: [AnalyticsSettingsComponent],
})
export class AnalyticsSettingsModule {}
