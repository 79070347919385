import { type AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, type OnInit, ViewChild } from '@angular/core';

import { getStyles } from '@app/features/subscription-form/components/subscription-form-view/subscription-form-view.component.styles';
import {
    type ISubscriptionFormElementData,
    type SubscriptionFormElement,
} from '@app/features/subscription-form/interface/ISubscriptionFormElement';
import * as typeGuards from '@app/features/common/type-guards/form-elements.type-guards';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';
import { EContactFieldsCrm, EFormInputType, ESystemAddressBookVariables } from '@app/features/common/enums/form.enum';
import { type IRadioOption, type ISelectOption } from '@app/features/common/interfaces/form-control.interface';
import { SubscriptionMessageModalComponent } from './subscription-message-modal/subscription-message-modal.component';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@moddi3/ngx-intl-tel-input';
import { CountryISOService } from '@common/services/REST/country-iso.http.service';
import { EContentSources, ECrmCommentPlace } from '@common/enums';
import { SubscriptionHttpService } from '@web-builder/mls-core/services/subscription.http.service';
import { AnalyticsService } from '@web-builder/mls-core/services/analytics.service';

@Component({
    selector: 'mls-subscriptions-form-feature',
    templateUrl: './subscription-form-feature.component.html',
    styleUrls: ['./subscription-form-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class SubscriptionFormFeatureComponent
    extends BaseFeatureComponent<ISubscriptionFormElementData>
    implements OnInit, AfterViewInit
{
    @ViewChild('form') form: ElementRef<HTMLFormElement>;
    @ViewChild('notificationContent') notificationContent: ElementRef<HTMLDivElement>;

    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
    private readonly countryISOService: CountryISOService = inject(CountryISOService);
    private readonly subscriptionHttpService: SubscriptionHttpService = inject(SubscriptionHttpService);

    protected getStyles = getStyles;
    public typeGuards = typeGuards;
    public formInputType = EFormInputType;
    public CountryISO = CountryISO.Ukraine;
    public excludeCountries = [CountryISO.Russia];
    public isDynamicPhoneError = false;
    public isFilesError = false;
    public isFilesSizeError = false;
    public files: File[] = [];
    public isLoading = false;

    public readonly SearchCountryField = SearchCountryField;
    public readonly PhoneNumberFormat = PhoneNumberFormat;
    public readonly ESystemAddressBookVariables = ESystemAddressBookVariables;

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        if (this.isServer) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const formId = urlParams.get('subscribed_form_id');

        if (formId && this.element.id === formId) {
            this.showSubscriptionMessageModal();
        }

        this.getCountryISO();
    }

    public showSubscriptionMessageModal() {
        this.modalService
            .show(SubscriptionMessageModalComponent, {
                initialState: { subscriptionMessage: this.elementData.notification },
            })
            .onHide.subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
    }

    public submitForm(): void {
        this.isDynamicPhoneError = false;

        const formEl = this.form.nativeElement;
        const formFields = formEl.querySelectorAll(
            'input[data-form-field=true], textarea[data-form-field=true], select, [data-form-field=true] input#phone, input#file',
        );

        formFields.forEach((field: HTMLInputElement, index) => {
            if (!field.getAttribute('data-form-field') && field.type === 'tel') {
                const flagContainer = document.getElementsByClassName('iti__flag-container')[0] as HTMLElement;
                const phone = field?.value.trim().replace(/\s/g, '');
                const mask = field?.placeholder.trim().replace(/\s/g, '');
                const code = (field as any)?.offsetParent?.innerText;

                this.isDynamicPhoneError = phone.length < mask.length || phone.length > mask.length + 4;

                if (this.isDynamicPhoneError) return;

                flagContainer.style.opacity = '0';
                field.style.paddingLeft = '16px';
                field.name = 'phone';
                field.value = `${code}${phone}`;
            }

            if (field.type === 'radio' && !field.checked) {
                return;
            }

            const dealVarId = field.getAttribute('data-deal-var-id');
            if (dealVarId) {
                const dealField: HTMLInputElement = formEl.querySelector(`input[name="dealVariables[${dealVarId}]"]`);
                dealField.value = field.type === 'checkbox' ? String(+field.checked) : field.value;
            }

            const contactVarId = field.getAttribute('data-contact-var-id');

            if (contactVarId === 'firstName' || contactVarId === 'lastName') {
                const contactField: HTMLInputElement = formEl.querySelector(`input[name="contactFields[${contactVarId}]"]`);
                contactField.value = field.type === 'checkbox' ? String(+field.checked) : field.value;
            } else if (contactVarId) {
                const contactField: HTMLInputElement = formEl.querySelector(`input[name="contactVariables[${contactVarId}]"]`);
                contactField.value = field.type === 'checkbox' ? String(+field.checked) : field.value;
            }

            const crmCommentPlace = field.getAttribute('data-crm-comment');

            if (crmCommentPlace) {
                const contactComments: HTMLInputElement = formEl.querySelector(`#contactComment-` + index);
                const dealComments: HTMLInputElement = formEl.querySelector(`#dealComment-` + index);

                if (crmCommentPlace === ECrmCommentPlace.crmAllComment) {
                    contactComments.value = field.value;
                    dealComments.value = field.value;
                }

                if (crmCommentPlace === ECrmCommentPlace.crmContactComment) {
                    contactComments.value = field.value;
                }

                if (crmCommentPlace === ECrmCommentPlace.crmDealComment) {
                    dealComments.value = field.value;
                }
            }
        });

        const formData = new FormData(formEl);
        const fileField = this.elementData.elements.filter((el) => el.type === EFormInputType.file);

        if (fileField.length) {
            this.isFilesError = !this.files.length && fileField[0].required;
            this.isFilesSizeError = this.filesSize > 500;

            if (!this.isFilesError && !this.isFilesSizeError) {
                this.files.forEach((file) => formData.append('attachments[]', file));
            }
        }

        if (this.isDynamicPhoneError || this.isFilesError || this.isFilesSizeError || !formEl.reportValidity()) return;

        this.requestSubmit(formData);
    }

    public getControlNameAttr(control: SubscriptionFormElement): string {
        const isHasEmail = this.elementData.elements.find((el) => el.variable === ESystemAddressBookVariables.email);
        if (control.variable === ESystemAddressBookVariables.email) {
            return 'email';
        }
        if (control.variable === ESystemAddressBookVariables.phone && !isHasEmail) {
            return 'phone';
        }
        if (control.variable === 'question') {
            return 'question';
        }
        return `variables[${control.variable}]`;
    }

    public crmContactField(control) {
        return Object.values(EContactFieldsCrm).includes(control.crmContactVariable);
    }

    public crmContactAttribute(control) {
        return control.crmContactVariable && !Object.values(EContactFieldsCrm).includes(control.crmContactVariable);
    }

    public get isDeal() {
        return +!!this.element.data.createDeal;
    }

    public get isUpdateExistingContact() {
        return +!!(this.element.data.createDeal && this.element.data.updateExistingContact);
    }

    public get isDoi(): string {
        return this.elementData.doubleOptIn ? '&confirmation=true' : '';
    }

    public get addressBookId(): number {
        return this.elementData.addressBookId;
    }

    public requestSubmit(formData: any): void {
        this.isLoading = true;
        const baseUrl = `${this.resourceUrl}/api/landings-service/`;

        const url =
            this.transferStateService.get('page').contentSourceId === EContentSources.Education
                ? baseUrl + 'public/education/question'
                : baseUrl + `public/subscribe?book_id=${this.addressBookId}&site_hash=${this.templateId}${this.isDoi}`;

        this.subscriptionHttpService.submitSubscription(url, formData).subscribe(() => {
            if (this.elementData?.analyticsSettings?.googleAnalytic) {
                this.analyticsService.gaEventRequest(this.elementData?.analyticsSettings?.googleSettings);
            }

            if (this.elementData?.analyticsSettings?.pixelAnalytic) {
                this.analyticsService.pixelFbEventRequest(this.elementData?.analyticsSettings?.pixelSettings);
            }
            this.showSubscriptionMessageModal();
            this.form.nativeElement.reset();
            this.files = [];
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    public getSelected(options: IRadioOption[] | ISelectOption[]) {
        return !options.some((el) => el.selected);
    }

    private getURLParams(): string {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.toString();
    }

    public get utm(): string {
        if (this.isServer) {
            return '';
        }

        return this.getURLParams();
    }

    public getInputIdForSelenium(control: SubscriptionFormElement): string {
        return `${this.element.id}${control?.variable ? '-variable-' + control?.variable : ''}${
            control?.crmContactVariable ? '-crmContactVariable-' + control?.crmContactVariable : ''
        }${control?.crmDealVariable ? '-crmDealVariable-' + control?.crmDealVariable : ''}`;
    }

    public get phonePlaceholder() {
        return (document.getElementById('phone') as any).placeholder;
    }

    private getCountryISO() {
        this.countryISOService.getCountryISO().subscribe((data) => {
            this.CountryISO = data.country_code.toLowerCase();
            this.changeDetectorRef.detectChanges();
        });
    }

    public onFileUpload(event: { addedFiles: File[] }) {
        this.files.push(...event.addedFiles);
    }

    public onFileRemove(event: File) {
        this.files.splice(this.files.indexOf(event), 1);
    }

    public get filesSize(): number {
        let size: number = 0;
        this.files.forEach((file) => {
            size += file.size;
        });

        return Math.ceil(size / 1024 / 1024);
    }

    public get loaderSrc(): string {
        return this.utilsService.formatImgSrc('./assets/img/preloader-dots-white.svg');
    }
}
