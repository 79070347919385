import type { JssStyle } from 'jss';
import type { ThemeButtonData } from '@libs/themes/src/types';

import { EButtonStyleType } from '@common/enums';

import { EWebsiteThemeButtonProperties } from '@libs/themes/src/variants/website/enums/button.enum';
import { EWebsiteThemeFontProperties } from '@libs/themes/src/variants/website/enums/font.enum';
import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';
import {
    websiteColorCss,
    websiteFontCss,
    websitePrimaryButtonCss,
    websiteSecondaryButtonCss,
} from '@libs/themes/src/variants/website/utils/variables';

function selectedPreset(): Record<EButtonStyleType.primary | EButtonStyleType.secondary, JssStyle> {
    return {
        [EButtonStyleType.primary]: {
            lineHeight: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.lineHeight),
            textTransform: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.textTransform),
            fontFamily: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.fontFamily),
            fontStyle: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.fontStyle),
            fontWeight: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.fontWeight),
            background: websitePrimaryButtonCss(EWebsiteThemeButtonProperties.background),
            borderColor: websitePrimaryButtonCss(EWebsiteThemeButtonProperties.borderColor),
            color: websitePrimaryButtonCss(EWebsiteThemeButtonProperties.color),
            borderRadius: websitePrimaryButtonCss(EWebsiteThemeButtonProperties.borderRadius),
            borderStyle: websitePrimaryButtonCss(EWebsiteThemeButtonProperties.borderStyle),
            borderWidth: websitePrimaryButtonCss(EWebsiteThemeButtonProperties.borderWidth),
        },
        [EButtonStyleType.secondary]: {
            lineHeight: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.lineHeight),
            textTransform: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.textTransform),
            fontFamily: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.fontFamily),
            fontStyle: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.fontStyle),
            fontWeight: websiteFontCss(EThemeFontTags.button, EWebsiteThemeFontProperties.fontWeight),
            background: websiteSecondaryButtonCss(EWebsiteThemeButtonProperties.background),
            borderColor: websiteSecondaryButtonCss(EWebsiteThemeButtonProperties.borderColor),
            color: websiteSecondaryButtonCss(EWebsiteThemeButtonProperties.color),
            borderRadius: websiteSecondaryButtonCss(EWebsiteThemeButtonProperties.borderRadius),
            borderStyle: websiteSecondaryButtonCss(EWebsiteThemeButtonProperties.borderStyle),
            borderWidth: websiteSecondaryButtonCss(EWebsiteThemeButtonProperties.borderWidth),
        },
    };
}

function listPreset(base: ThemeButtonData): Record<EButtonStyleType.primary | EButtonStyleType.secondary, JssStyle> {
    return {
        [EButtonStyleType.primary]: {
            ...base,
            background: websiteColorCss(base.background[0] as any),
            borderColor: websiteColorCss(base.borderColor[0] as any),
            color: websiteColorCss(base.color[0] as any),
        },
        [EButtonStyleType.secondary]: {
            ...base,
            background: websiteColorCss(base.background[0] as any),
            borderColor: websiteColorCss(base.borderColor[0] as any),
            color: websiteColorCss(base.color[0] as any),
        },
    };
}

export function getStyles(
    selected: boolean,
    type: EButtonStyleType.primary | EButtonStyleType.secondary | undefined,
    base: ThemeButtonData,
): JssStyle {
    if (!type) {
        return {};
    }

    if (selected) {
        return {
            ...selectedPreset()[type],
        };
    }
    return {
        ...listPreset(base)[type],
    };
}
