import type { ThemePresetsState } from '@libs/themes/src/store/theme-presets/theme-presets.reducer';
import type { ThemeColorPreset } from '@libs/themes/src/types/theme-color.type';
import type { ThemeFontPreset } from '@libs/themes/src/types/theme-font.type';
import type { ThemeButtonPreset } from '@libs/themes/src/types/theme-button.type';
import type { ThemeFeatureState } from '@libs/themes/src/store/theme-feature.reducer';

import { createSelector } from '@ngrx/store';
import { selectThemeState } from '../theme-state.selector';
import { THEME_PRESETS_STORE_KEY } from './theme-presets.reducer';

const selectThemePresetsState = createSelector(
    selectThemeState,
    (state: ThemeFeatureState): ThemePresetsState => state[THEME_PRESETS_STORE_KEY],
);

// COLORS
export const selectColorsPresets = createSelector(selectThemePresetsState, (state: ThemePresetsState): ThemeColorPreset[] => state.colors);
// END COLORS

// FONTS
export const selectFontsPresets = createSelector(selectThemePresetsState, (state: ThemePresetsState): ThemeFontPreset[] => state.fonts);
// END FONTS

// BUTTONS
export const selectButtonsPresets = createSelector(
    selectThemePresetsState,
    (state: ThemePresetsState): ThemeButtonPreset[] => state.buttons,
);
// END BUTTONS
