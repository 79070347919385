import type { ThemeFont, ThemeSelectedFonts } from '@libs/themes/src/types/theme-font.type';
import type { ThemeColor, ThemeColorsKeyValue, ThemeSelectedColors } from '@libs/themes/src/types/theme-color.type';
import type { ThemeButton, ThemeSelectedButtons } from '@libs/themes/src/types/theme-button.type';

import { formatStylesToPx } from '@common/helpers';

import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';

import { SIMPLE_THEME_FONTS_VARIABLES, SIMPLE_THEME_BUTTONS_VARIABLES, SIMPLE_THEME_COLORS_VARIABLES } from '../constants/css-variables';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';

export function formatSimpleColorsValues(values: Record<string, string>, colors: ThemeSelectedColors): Record<string, string> {
    if (!colors) {
        return values;
    }

    const colorValues: Record<string, string> = colors.selected.reduce((colorsValue, color: ThemeColor) => {
        if (!SIMPLE_THEME_COLORS_VARIABLES[color.name]) {
            return colorsValue;
        }

        return {
            ...colorsValue,
            [SIMPLE_THEME_COLORS_VARIABLES[color.name]]: color.value,
        };
    }, {});

    return { ...values, ...colorValues };
}

export function formatSimpleFontsValues(values: Record<string, string>, fonts: ThemeSelectedFonts): Record<string, string> {
    if (!fonts) {
        return values;
    }

    const fontValues: Record<string, string> = fonts.selected.reduce((fontsValue: Record<string, string>, font: ThemeFont) => {
        if (!SIMPLE_THEME_FONTS_VARIABLES[font.tag]) {
            return fontsValue;
        }

        Object.keys(font.styles).forEach((prop: string) => {
            if (!SIMPLE_THEME_FONTS_VARIABLES[font.tag][prop]) {
                return;
            }

            // tmp fix for button line height
            if (prop === ESimpleThemeFontProperties.lineHeight && font.tag === EThemeFontTags.button) {
                if (typeof font.styles[prop] === 'number') {
                    fontsValue[SIMPLE_THEME_FONTS_VARIABLES[font.tag][prop]] = `${font.styles[prop]}%`;
                    return;
                }
            }

            fontsValue[SIMPLE_THEME_FONTS_VARIABLES[font.tag][prop]] = font.styles[prop];
        });

        return fontsValue;
    }, {});

    return { ...values, ...fontValues };
}

export function formatSimpleButtonValues(
    values: Record<string, string>,
    buttons: ThemeSelectedButtons,
    fonts: ThemeSelectedFonts,
): Record<string, string> {
    if (!buttons) {
        return values;
    }

    const buttonValues: Record<string, string> = {};
    const buttonFonts: ThemeFont = fonts.selected.find((font: ThemeFont) => font.tag === EThemeFontTags.button);
    const buttonFontsStyles = buttonFonts && formatStylesToPx(buttonFonts.styles);

    buttons.selected.forEach((button: ThemeButton) => {
        const formattedStyles = formatStylesToPx(button.styles);

        Object.keys(SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType]).forEach((prop: string) => {
            if (button?.base?.[prop]) {
                const buttonBasePropValue = button.base?.[prop];

                if (SIMPLE_THEME_COLORS_VARIABLES[buttonBasePropValue]) {
                    buttonValues[SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]] =
                        values[SIMPLE_THEME_COLORS_VARIABLES[buttonBasePropValue]];
                    return;
                }

                buttonValues[SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]] = button.base[prop];
                return;
            }

            if (button?.data?.[prop]) {
                buttonValues[SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]] =
                    values[SIMPLE_THEME_COLORS_VARIABLES[button.data[prop]]];
                return;
            }

            if (formattedStyles[prop]) {
                buttonValues[SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]] = formattedStyles[prop];
                return;
            }

            if (buttonFontsStyles[prop]) {
                buttonValues[SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]] = buttonFontsStyles[prop];
                return;
            }

            if (SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]) {
                buttonValues[SIMPLE_THEME_BUTTONS_VARIABLES[button.styleType][prop]] =
                    values[SIMPLE_THEME_COLORS_VARIABLES[button.styleType]];
            }
        });
    });

    return { ...values, ...buttonValues };
}

export function formatNextSimpleButtons(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[] {
    const colorsObj: ThemeColorsKeyValue = {};
    colors.forEach((color: ThemeColor) => (colorsObj[color.name] = color.value));

    return buttons.map((button: ThemeButton) => {
        const nextButton: ThemeButton = { ...button, styles: { ...button.styles } };
        Object.keys(nextButton.data).forEach((key) => (nextButton.styles[key] = colorsObj[nextButton.data[key]]));
        return nextButton;
    });
}
