import type { Observable } from 'rxjs';
import type { EThemeSettings } from '@libs/themes/src/enums/theme-settings.enum';
import type { ThemeColor, ThemeSelectedColors, ThemeSelectedButtons, ThemeSelectedFonts } from '@libs/themes/src/types';
import type { ThemeSettingsState } from '@libs/themes/src/store/theme-settings/theme-settings.reducer';

import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { THEME_CONFIG_TOKEN } from '@libs/themes/src/tokens';

import { ThemeConfig } from '@libs/themes/src/types/theme-config.type';

import { ThemeConfigFacade } from '../theme-config/theme-config.facade';
import { ThemeSettingsFacade } from '../theme-settings/theme-settings.facade';
import { ThemeValuesFacade } from '../theme-values/theme-values.facade';

import ThemeActions from './theme.actions';

@Injectable()
export class ThemeFacade {
    public readonly themeSettings$: Observable<ThemeSettingsState> = this.themeSettingsFacade.themeSettings$;
    public readonly activeThemeSetting$: Observable<EThemeSettings> = this.themeConfigFacade.activeThemeSetting$;
    public readonly themeColors$: Observable<ThemeSelectedColors> = this.themeSettingsFacade.themeColors$;
    public readonly themeButtons$: Observable<ThemeSelectedButtons> = this.themeSettingsFacade.themeButtons$;
    public readonly themeFonts$: Observable<ThemeSelectedFonts> = this.themeSettingsFacade.themeFonts$;
    public readonly selectedThemeColors$: Observable<ThemeColor[]> = this.themeSettingsFacade.selectedThemeColors$;
    public readonly themeValues$: Observable<Record<string, string>> = this.themeValuesFacade.themeValues$;

    /*
    // @deprecated
     */
    public readonly themeRoot$: Observable<any> = this.themeSettingsFacade.themeRoot$;

    constructor(
        private readonly store$: Store,
        @Inject(THEME_CONFIG_TOKEN) private themeConfig: ThemeConfig,
        private readonly themeConfigFacade: ThemeConfigFacade,
        private readonly themeSettingsFacade: ThemeSettingsFacade,
        private readonly themeValuesFacade: ThemeValuesFacade,
    ) {}

    public initTheme(themeSettings = {}): void {
        this.store$.dispatch(ThemeActions.initTheme({ themeSettings }));
    }

    public setActiveThemeSettingAction(value: EThemeSettings): void {
        this.themeConfigFacade.setActiveThemeSettingAction(value);
    }

    public unselectActiveThemeSettingAction(): void {
        this.themeConfigFacade.unselectActiveThemeSettingAction();
    }

    public setColors(colors: ThemeSelectedColors): void {
        this.themeSettingsFacade.setColorsAction(colors);
    }

    public setFonts(fonts: ThemeSelectedFonts): void {
        this.themeSettingsFacade.setFontsAction(fonts);
    }

    public setButtons(buttons: ThemeSelectedButtons): void {
        this.themeSettingsFacade.setButtonsAction(buttons);
    }

    public updateRoot(data: any): void {
        this.themeSettingsFacade.updateRoot(data);
    }

    public get isConfigColors(): boolean {
        return this.themeConfig.colors;
    }

    public get isConfigButtons(): boolean {
        return this.themeConfig.buttons;
    }

    public get isConfigFonts(): boolean {
        return this.themeConfig.fonts;
    }
}
