<div>
    <label class="small" *ngIf="colorsPreset.length">
        {{ 'control_site_palette' | translate }}
    </label>

    <div class="preset-area">
        <div class="cp-preset-colors-class mb-15" *ngIf="colorsPreset.length">
            <div class="preset-color"
                 [class.selected]="color === TRANSPARENT_VALUE"
                 (click)="setColorHandler(TRANSPARENT_VALUE)">
                <img src="./assets/img/color-picker/opacity-icon.svg" alt="opacity-icon">
            </div>

            <div *ngFor="let preset of colorsPreset"
                 class="preset-color"
                 [class.selected]="color === preset.value"
                 [style.background]="preset.value"
                 (click)="setColorPresetHandler(preset)">
                <sp-transparent-icon
                    [color]="preset.value"
                ></sp-transparent-icon>
            </div>

            <div class="preset-color" (click)="toggleShowCustom($event)">
                <img src="./assets/img/color-picker/custom-icon.svg" alt="color-picker-icon">
            </div>
        </div>

        <label class="small">{{ 'control_palette' | translate }}</label>
        <div class="cp-preset-colors-class mb-15 justify-content-start" *ngIf="!colorsPreset.length">
            <div class="preset-color mr-5"
                 [class.selected]="color === TRANSPARENT_VALUE"
                 (click)="setColorHandler(TRANSPARENT_VALUE)">
                <img src="./assets/img/color-picker/opacity-icon.svg" alt="opacity-icon">
            </div>

            <div class="preset-color" (click)="toggleShowCustom($event)">
                <img src="./assets/img/color-picker/custom-icon.svg" alt="color-picker-icon">
            </div>
        </div>
        <div class="cp-preset-colors-class" *ngFor="let preset of COLOR_PICKER_PRESETS">
            <div *ngFor="let value of preset"
                 class="preset-color"
                 [class.selected]="color === (value | lowercase)"
                 [style.background]="value"
                 (click)="setColorHandler(value)"
            ></div>
        </div>

        <div class="margin-top-10" *ngIf="!hideGradient">
            <div class="cp-preset-colors-class" *ngFor="let preset of COLOR_PICKER_GRADIENT_PRESETS">
                <div *ngFor="let value of preset"
                     class="preset-color"
                     [class.selected]="color === (value | lowercase)"
                     [style.background]="value"
                     (click)="setColorHandler(value)"
                ></div>
            </div>
        </div>
    </div>
</div>
