<div class="synchronize-style-control">
    <ng-container *ngIf="active; then isSyncIcon; else notSyncIcon">
    </ng-container>

    <ng-template #isSyncIcon>
        <svg
            placement="bottom"
            [tooltip]="'synchronize_disable_tooltip' | translate"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        >
            <circle cx="12" cy="12" r="12" fill="#00b175" fill-opacity="0.3"/>
            <path d="M10 9H6C5.44772 9 5 9.44772 5 10V14C5 14.5523 5.44772 15 6 15H10M14 15H18C18.5523 15 19 14.5523 19 14V10C19 9.44772 18.5523 9 18 9H14M9 12H15" stroke="#00b175" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-template>

    <ng-template #notSyncIcon>
        <svg
            placement="bottom"
            [tooltip]="'synchronize_enable_tooltip' | translate"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        >
            <circle cx="12" cy="12" r="12" fill="#023346" fill-opacity="0.3"/>
            <path d="M10 9H6C5.44772 9 5 9.44772 5 10V14C5 14.5523 5.44772 15 6 15H10M14 15H18C18.5523 15 19 14.5523 19 14V10C19 9.44772 18.5523 9 18 9H14" stroke="#023346" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 5.5L10.5 7M12 7V4.5M13.5 7L15 5.5M13.5 17L15 18.5M12 17V19.5M10.5 17L9 18.5" stroke="#023346" stroke-linecap="round"/>
        </svg>
    </ng-template>
</div>

<div class="synchronize-style-status">
    <ng-container *ngIf="active; then isSync; else notSync">
    </ng-container>
</div>

<ng-template #isSync>
    {{ 'synchronize_active_hint' | translate }}
</ng-template>

<ng-template #notSync>
    {{ 'synchronize_not_active_hint' | translate }}
</ng-template>
