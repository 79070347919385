import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Classes } from 'jss';

import { EContentSources, ELanguages } from '@common/enums';
import { UtilsService } from '@web-builder/mls-core/services//utils.service';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

type ProductType = 'lp' | 'edu';

@Component({
    selector: 'mls-logo-footer',
    templateUrl: './logo-footer.component.html',
    styleUrls: ['./logo-footer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoFooterComponent {
    @Input() public classes: Classes;
    @Input() public lang: ELanguages = ELanguages.EN;
    @Input() public isPreview: boolean;
    @Input() public featuringProduct: ProductType = 'lp';
    @Input() public isOddSiteId: boolean;
    @Input() public isTestGroupSiteId: boolean;

    private readonly utilsService: UtilsService = inject(UtilsService);
    private readonly transferStateService: TransferStateService = inject(TransferStateService);

    private get queryParams() {
        let utmSource = 'sendpulse'; //default for lp product
        let utmMedium = 'poweredby';
        let utmCampaign = 'landing-page';

        switch (this.featuringProduct) {
            case 'edu':
                utmSource = 'edu';
                utmMedium = 'usersite';
                utmCampaign = window.location.hostname;
                break;
        }

        if (this.pageContentSource === EContentSources.ChatBot) {
            utmCampaign = 'chatbot_landing_page';
        }

        return `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
    }

    private get queryParamsTestBannerV1() {
        let utmSource = 'sendpulse'; //default for lp product
        let utmMedium = 'poweredby';
        let utmCampaign = 'landing-page';

        switch (this.featuringProduct) {
            case 'edu':
                utmSource = 'edu';
                utmMedium = 'usersite';
                utmCampaign = window.location.hostname;
                break;
        }

        if (this.pageContentSource === EContentSources.ChatBot) {
            utmCampaign = 'chatbot_landing_page';
            return `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
        }

        return `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}-v1`;
    }

    private get queryParamsTestBannerV3() {
        let utmSource = 'sendpulse'; //default for lp product
        let utmMedium = 'poweredby';
        let utmCampaign = 'landing-page';

        switch (this.featuringProduct) {
            case 'edu':
                utmSource = 'edu';
                utmMedium = 'usersite';
                utmCampaign = window.location.hostname;
                break;
        }

        if (this.pageContentSource === EContentSources.ChatBot) {
            utmCampaign = 'chatbot_landing_page';
            return `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
        }

        return `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}-v3`;
    }

    private get linksMap(): Partial<Record<ELanguages, string>> {
        let productUrlPart = 'landing-page-builder';
        if (this.featuringProduct === 'edu') {
            productUrlPart = 'edu';
        }

        if (this.pageContentSource === EContentSources.ChatBot) {
            productUrlPart = 'chatbot';
        }

        return {
            [ELanguages.EN]: `https://sendpulse.com/features/${productUrlPart}`,
            [ELanguages.UA]: `https://sendpulse.ua/features/${productUrlPart}`,
            [ELanguages.RU]: `https://sendpulse.com/ru/features/${productUrlPart}`,
            [ELanguages.FR]: `https://sendpulse.com/fr/features/${productUrlPart}`,
            [ELanguages.ES_MX]: `https://sendpulse.com/latam/features/${productUrlPart}`,
            [ELanguages.PT_BR]: `https://sendpulse.com/br/features/${productUrlPart}`,
            [ELanguages.TR]: `https://sendpulse.com.tr/features/${productUrlPart}`,
            [ELanguages.IT]: `https://sendpulse.com/features/${productUrlPart}`,
        };
    }

    public get link(): string {
        return this.isPreview ? this.linksMap[this.lang] : `${this.linksMap[this.lang]}${this.queryParams}`;
    }

    public get linkTestBannerV1(): string {
        return this.isPreview ? this.linksMap[this.lang] : `${this.linksMap[this.lang]}${this.queryParamsTestBannerV1}`;
    }

    public get linkTestBannerV3(): string {
        return this.isPreview ? this.linksMap[this.lang] : `${this.linksMap[this.lang]}${this.queryParamsTestBannerV3}`;
    }

    public get logoSrc(): string {
        return this.utilsService.formatImgSrc('./assets/img/sendpulse-logo-symbol.svg');
    }

    public get pageContentSource(): EContentSources {
        return this.transferStateService.get('page').contentSourceId;
    }

    public get labelTranslationKey(): string {
        if (this.pageContentSource === EContentSources.ChatBot) {
            return 'footer_logo_powered_by_chat_bot';
        }

        return 'footer_logo_powered_by';
    }

    public get labelTranslationKeyTestBannerV1(): string {
        if (this.pageContentSource === EContentSources.ChatBot) {
            return 'footer_logo_powered_by_chat_bot';
        }

        return 'footer_logo_powered_by_ab_test';
    }
}
