export enum EMenuType {
    url = 'url',
    email = 'email',
    phone = 'phone',
}

export enum EMenuTarget {
    self = '_self',
    blank = '_blank',
}
