import type { ThemeFeatureState } from '@libs/themes/src/store/theme-feature.reducer';
import type { ThemeValuesState } from './theme-values.reducer';

import { createSelector } from '@ngrx/store';
import { selectThemeState } from '../theme-state.selector';
import { THEME_VALUES_STORE_KEY } from './theme-values.reducer';

export const selectThemeValuesState = createSelector(
    selectThemeState,
    (state: ThemeFeatureState): ThemeValuesState => state[THEME_VALUES_STORE_KEY],
);

export const selectThemeValueByKey = (key: string) =>
    createSelector(selectThemeValuesState, (values: Record<string, string>): string => values[key] || null);
