import { JssStyle } from 'jss';

import { ERepeatOptions } from '@app/features/common/models/background-image-options/repeat-options.model';
import { ESizeOptions } from '@app/features/common/models/background-image-options/size-options.model';
import { EPositionOptions } from '@app/features/common/models/background-image-options/position-options.model';
import { EAttachmentOptions } from '@app/features/common/models/background-image-options/attachment-options.model';

export const defaultImageBackgroundStyles: JssStyle = {
    backgroundRepeat: ERepeatOptions.noRepeat,
    backgroundSize: ESizeOptions.auto,
    backgroundPosition: EPositionOptions.center,
    backgroundAttachment: EAttachmentOptions.scroll,
};
