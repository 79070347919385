import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'web-subscription-message-modal',
    styleUrls: ['./subscription-message-modal.component.less'],
    templateUrl: './subscription-message-modal.component.html',
})
export class SubscriptionMessageModalComponent {
    public subscriptionMessage: string;
    public exit: boolean;

    constructor(private bsModalRef: BsModalRef) {}

    public onConfirm(): void {
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.bsModalRef.hide();
    }
}
