import type { JssStyle } from 'jss';
import type { IElement } from '@app/features/common/interfaces/IElement';

import { EButtonStyleType } from '@common/enums';
import { simpleButtonCssProperty, simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';
import { ESimpleThemeColorNames } from '@libs/themes/src/variants/simple/enums/color.enum';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { ESimpleThemeButtonProperties } from '@libs/themes/src/variants/simple/enums/button.enum';
import { h1Styles, h2Styles, pStyles } from '@app/features/common/styles/text.styles';

const courseBtn: JssStyle = {
    minWidth: '220px',
    padding: '18px',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    fontSize: '15px',
    lineHeight: '21px',
    textTransform: 'uppercase',

    '&:not(:last-child)': {
        marginRight: '20px',
    },

    '&:hover': {
        opacity: '0.8',
        cursor: 'pointer',
    },

    '@media (max-width: 600px)': {
        '&:not(:last-child)': {
            marginRight: 0,
            marginBottom: '20px',
        },
    },
};

export function getStyles(element: IElement): JssStyle {
    // const primaryColor = simpleColorCss(EThemeColorNames.primary);

    return {
        element: {
            paddingTop: 228,
            paddingBottom: 228,
        },
        logo: {
            // stroke: primaryColor,
            marginBottom: '30px',

            '&:hover': {
                opacity: '0.8',
                cursor: 'pointer',
            },
        },
        bannerText: {
            '& *': {
                color: '#333333',
            },
            textAlign: 'center',
            marginBottom: '45px',
        },
        bannerTitle: {
            paddingBottom: 20,
            '& h1': h1Styles,
        },
        bannerParagraph: {
            '& p': pStyles,
        },
        coursePrimaryBtn: {
            ...courseBtn,
            // TODO: REFACTOR IT TO COMMON BUTTON STYLE CREATOR METHODS
            fontSize: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontSize),
            lineHeight: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.lineHeight),
            color: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.color),
            letterSpacing: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.letterSpacing),
            fontFamily: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontFamily),
            fontStyle: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontStyle),
            fontWeight: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontWeight),
            backgroundColor: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.background),
            borderColor: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderColor),
            borderWidth: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderWidth),
            borderStyle: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderStyle),
        },
        courseSecondaryBtn: {
            ...courseBtn,
            // TODO: REFACTOR IT TO COMMON BUTTON STYLE CREATOR METHODS
            fontSize: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontSize),
            lineHeight: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.lineHeight),
            color: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.color),
            letterSpacing: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.letterSpacing),
            fontFamily: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontFamily),
            fontStyle: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontStyle),
            fontWeight: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontWeight),
            background: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.background),
            borderColor: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.borderColor),
            borderWidth: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.borderWidth),
            borderStyle: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.borderStyle),
        },
        courseButtons: {
            display: 'flex',
            justifyContent: 'center',
            '@media (max-width: 600px)': {
                justifyContent: 'center',
                flexDirection: 'column',
            },
        },
        modal: {
            width: '100%',
            maxWidth: '420px',
            padding: '30px',
            background: '#FFFFFF',
            filter: 'drop-shadow(0px 6px 18px rgba(0, 0, 0, 0.18))',
            borderRadius: '10px',
            '& .text-danger': {
                color: '#BD1E1F',
            },
            '& .error-notification': {
                padding: '12px 20px',
                marginBottom: '20px',
                borderRadius: '4px',
                background: 'rgba(212, 105, 106, 0.1)',
                '& p': {
                    ...pStyles,
                    margin: 0,
                    color: '#BD1E1F',
                },
            },
        },
        modalHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // position: 'relative',
            '& h2': {
                ...h2Styles,
                color: element.data.textColor,
            },
            '& button': {
                position: 'absolute',
                top: '12px',
                right: '12px',
                fontSize: h2Styles.fontSize,
                lineHeight: h2Styles.fontSize,
                color: '#CBCBCB',
                background: 'none',
                border: 'none',
                '&:hover': {
                    color: '#9d9d9d',
                },
            },
        },
        modalBody: {
            margin: '10px 0',
        },
        modalForm: {
            '& .form-control-row': {
                marginBottom: '25px',
            },
            '& .error-reason': {
                // ...pStyles,
                lineHeight: '16px',
                paddingTop: '5px',
                fontSize: '12px',
            },
            '& label': {
                ...pStyles,
                display: 'block',
                fontWeight: 'bold',
                marginBottom: 0,
                color: element.data.textColor,
            },
            '& input': {
                width: '100%',
                height: 39,
                padding: '8px 12px',
                fontSize: 15,
                borderRadius: '4px',
                borderWidth: '1px',
                borderStyle: 'solid',
                whiteSpace: 'nowrap',
                background: '#ffffff',
                borderColor: '#d6e3e3',
                '&:hover': {
                    borderColor: '#9a9a9a',
                },
                '&:focus': {
                    borderColor: '#5f5f5f',
                    outline: '0',
                },
            },
            '& .invalid-input': {
                borderColor: '#bb364d!important',
                '&:focus': {
                    boxShadow: '0 0 0 2px rgba(212,105,106,.3)',
                },
            },
        },
        modalFooter: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& .secondary-btn': {
                fontSize: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontSize),
                lineHeight: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.lineHeight),
                color: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.color),
                letterSpacing: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.letterSpacing),
                textTransform: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.textTransform),
                fontFamily: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontFamily),
                fontStyle: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontStyle),
                fontWeight: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeFontProperties.fontWeight),
                background: simpleButtonCssProperty(EButtonStyleType.secondary, ESimpleThemeButtonProperties.background),
                border: 'none',
                cursor: 'pointer',
                padding: 0,
                '&:hover': {
                    opacity: '0.8',
                },
            },
            '& .primary-btn': {
                marginLeft: 'auto',
                fontSize: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontSize),
                lineHeight: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.lineHeight),
                color: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.color),
                letterSpacing: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.letterSpacing),
                textTransform: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.textTransform),
                fontFamily: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontFamily),
                fontStyle: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontStyle),
                fontWeight: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeFontProperties.fontWeight),
                background: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.background),
                borderColor: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderColor),
                borderRadius: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderRadius),
                borderStyle: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderStyle),
                borderWidth: simpleButtonCssProperty(EButtonStyleType.primary, ESimpleThemeButtonProperties.borderWidth),
                cursor: 'pointer',
                // padding: commonButtonTextStyles[ESize.S].padding,
                padding: '9px 12px',
                '&:hover': {
                    opacity: '0.8',
                },
            },
            '& .action-buttons': {
                width: '100%',
                display: 'flex',
            },
            '& .logo': {
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '20px',

                '& .powered-sendpulse': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px 15px',
                    borderRadius: '50px',
                    textDecoration: 'none',
                    '& span': {
                        marginLeft: '10px',
                        fontFamily: '"Nunito Sans", sans-serif',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#333333',
                    },
                    '&:hover': {
                        opacity: '0.8',
                    },
                },
            },
        },
        linkBtn: {
            cursor: 'pointer',
            color: simpleColorCss(ESimpleThemeColorNames.primary),
        },
    };
}
