import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';

import { EButtonLinkType } from '@common/enums';

import { type IButtonElementData, type IButtonLink } from '@app/features/button/interface/IButtonElement';
import { commonIconTemplates } from '@app/shared/templates/svg/common-icons';
import { getStyles } from '@app/features/button/components/button-view/button-view.component.styles';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';
import { AnalyticsService } from '@web-builder/mls-core/services/analytics.service';

@Component({
    selector: 'mls-button-feature',
    templateUrl: './button-feature.component.html',
    styleUrls: ['./button-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class ButtonFeatureComponent extends BaseFeatureComponent<IButtonElementData> {
    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
    protected getStyles = getStyles;

    public getIcon(iconName): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(commonIconTemplates[iconName]);
    }

    private getNormalizedLink(type: EButtonLinkType, link: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (type === EButtonLinkType.email) {
            return this.trackingLinkConverterService.normalizeEmailLink(trimmedLink);
        }

        if (type === EButtonLinkType.phone) {
            return this.trackingLinkConverterService.normalizePhoneLink(trimmedLink);
        }

        return this.trackingLinkConverterService.normalizeLink(trimmedLink);
    }

    public getHref(link: IButtonLink): string {
        const { value, type } = link;
        const normalizedLink = this.getNormalizedLink(type, value);

        if (!normalizedLink) {
            return null;
        }

        if (this.preventTrackingLinkTransformations || type === EButtonLinkType.phone) {
            return normalizedLink;
        }

        return this.trackingLinkConverterService.convertToTrackingLink(normalizedLink);
    }

    public trySendAnalytic(): void {
        this.sendGaEvent();
        this.sendPixelFbEvent();
    }

    public get target(): string {
        return this.elementData.buttonLink.type === EButtonLinkType.phone ? '' : '_blank';
    }

    private sendGaEvent() {
        if (this.elementData?.analyticsSettings?.googleAnalytic) {
            this.analyticsService.gaEventRequest(this.elementData?.analyticsSettings?.googleSettings);
        }
    }

    private sendPixelFbEvent() {
        if (this.elementData?.analyticsSettings?.pixelAnalytic) {
            this.analyticsService.pixelFbEventRequest(this.elementData?.analyticsSettings?.pixelSettings);
        }
    }
}
