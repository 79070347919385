import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular'; // TODO: TRY TO SET IT LAZY

import { SpSliderComponent } from '@common/components/sp-slider/sp-slider.component';

@NgModule({
    imports: [SwiperModule],
    declarations: [SpSliderComponent],
    exports: [SpSliderComponent],
})
export class SpSliderModule {}
