<div class="dropdown">
    <sp-color-picker-presets
        [hidden]="showCustom"
        [color]="color"
        [hideGradient]="hideGradient"
        [(showCustom)]="showCustom"
        [colorsPreset]="colorsPreset"
        (setColorEvent)="setColorHandler($event)"
        (setColorPresetEvent)="setColorPresetHandler($event)"
    ></sp-color-picker-presets>

    <sp-color-picker-custom-color
        [hidden]="!showCustom"
        [color]="color"
        [hideGradient]="hideGradient"
        [(showCustom)]="showCustom"
        (setColorEvent)="setColorHandler($event)"
    ></sp-color-picker-custom-color>
</div>
