import type { SafeHtml } from '@angular/platform-browser';
import type { IUploadedFile } from '@app/features/common/interfaces/IFiles';
import type { IGalleryElementData, IGallerySlider, IGalleryVideo } from '@app/features/gallery/interface/IGalleryElement';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { getStyles } from '@app/features/gallery/components/gallery-view/gallery-view.component.styles';
import { EGalleryTypes } from '@app/features/gallery/enum/gallery.enum';
import { galleryBlankPicture } from '@app/features/gallery/model/gallery.model';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';
import Swiper, { Autoplay, EffectCards, EffectFade, FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import { setChatAt } from '@common/helpers/string';
import { environment } from '@web-builder-env/environment';

Swiper.use([Autoplay, Pagination, Thumbs, Navigation, EffectCards, EffectFade, FreeMode]);

type SourceSet = { size: number; media: string; url: string };

@Component({
    selector: 'mls-gallery-feature',
    templateUrl: './gallery-feature.component.html',
    styleUrls: ['./gallery-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class GalleryFeatureComponent extends BaseFeatureComponent<IGalleryElementData> {
    protected getStyles = getStyles;
    public EGalleryTypes = EGalleryTypes;
    public galleryBlankPicture = this.formatImgSrc(galleryBlankPicture);

    public instanceId = (index: number): number => index;

    public imageSrcSets: SourceSet[] = [];
    public imagesSrcSets: SourceSet[][] = [];

    protected widgetInit() {
        const srcSets = [
            {
                size: 720,
                media: '(max-width: 720px)',
            },
            {
                size: 1080,
                media: '(max-width: 1080px)',
            },
            {
                size: 1920,
                media: '(min-width: 1081px)',
            },
        ];

        if (this.type === EGalleryTypes.image && this.image.hasResize) {
            const splitImgSrc = this.imgSrc.split('/');
            splitImgSrc.splice(4, 0, 'resize_landing');
            const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
            splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

            const res = splitImgSrc.join('/');

            this.imageSrcSets = srcSets.map((srcset) => {
                if (srcset.size >= this.image.imageWidth || this.imgSrc.endsWith('.svg')) {
                    return { ...srcset, url: this.imgSrc };
                }

                return { ...srcset, url: `${res}/${srcset.size}.webp` };
            });
            return;
        }

        if (this.type === EGalleryTypes.slider && this.sliderFiles?.length) {
            this.imagesSrcSets = this.sliderFiles.map((image) => {
                if (!image?.hasResize) {
                    return null;
                }

                const splitImgSrc = this.sliderImgSrc(image).split('/');
                splitImgSrc.splice(4, 0, 'resize_landing');
                const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
                splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

                const res = splitImgSrc.join('/');

                return srcSets.map((srcset) => {
                    if (srcset.size >= image.imageWidth) {
                        return { ...srcset, url: this.sliderImgSrc(image) };
                    }

                    return { ...srcset, url: `${res}/${srcset.size}.webp` };
                });
            });
        }
    }

    public get autoplay() {
        if (!this.elementData?.slider?.sliderSpeed) return false;

        return {
            delay: this.elementData?.slider?.sliderSpeed,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    private getNormalizedLink(link: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        return this.trackingLinkConverterService.normalizeLink(trimmedLink);
    }

    public getSliderLink(file: IUploadedFile): string | SafeHtml {
        const normalizedLink = this.getNormalizedLink(file.link);

        if (!normalizedLink) {
            return null;
        }

        if (this.preventTrackingLinkTransformations) {
            return normalizedLink;
        }

        return this.trackingLinkConverterService.convertToTrackingLink(normalizedLink);
    }

    public get type(): EGalleryTypes {
        return this.elementData.type;
    }

    public get image(): IUploadedFile {
        return this.elementData.image;
    }

    public get description(): string {
        return this.image?.description;
    }

    public get imgSrc(): string {
        if (!this.image.url) {
            return this.galleryBlankPicture;
        }

        return `${this.image.defaultCdnUrl ? environment.fileManagerCdnUrl : ''}${this.image.url}`;
    }

    public get video(): IGalleryVideo {
        return this.elementData.video;
    }

    public get videoSource() {
        const videoCode = this.getVideoId(this.video.url);

        const url = this.video.url.includes('shorts') ? videoCode : this.video.url;

        return [
            {
                src: url,
                provider: this.video.type,
            },
        ];
    }

    private getVideoId(url: string): string {
        if (!url) {
            return;
        }
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/|shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : '';
    }

    public get slider(): IGallerySlider {
        return this.elementData.slider;
    }

    public get sliderFiles(): IUploadedFile[] {
        return this.slider?.uploadedFiles;
    }

    public get sliderSpeed(): number {
        return this.slider.sliderSpeed;
    }

    public sliderImgSrc(file: IUploadedFile): string {
        if (!file.url) {
            return this.galleryBlankPicture;
        }

        return `${file.defaultCdnUrl ? environment.fileManagerCdnUrl : ''}${file.url}`;
    }

    public get url(): string {
        return this.video.url;
    }

    public get videoType(): any {
        return this.video.type;
    }

    public get previewUrl(): string {
        return this.video.previewUrl;
    }

    public get imageLink(): string {
        const normalizedLink = this.getNormalizedLink(this.image?.link);

        if (!normalizedLink) {
            return null;
        }

        return this.trackingLinkConverterService.convertToTrackingLink(normalizedLink);
    }
}
