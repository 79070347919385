import { type JssStyle } from 'jss';
import { h1Styles, h2Styles, h3Styles, h4Styles, pStyles } from '@app/features/common/styles/text.styles';
import { type IElement } from '@app/features/common/interfaces/IElement';
import { commonPositionStyles } from '@app/features/common/constants/position.const';

export function getStyles(element: IElement): JssStyle {
    return {
        element: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: commonPositionStyles[element.data.position].textAlign,
        },
        textContent: {
            '& h1': h1Styles,
            '& h2': h2Styles,
            '& h3': h3Styles,
            '& h4': h4Styles,
            '& p': pStyles,
            '& ul, ol': {
                '& li': pStyles,
            },
        },
    };
}
