import { type JssStyle } from 'jss';
import { h1Styles, h2Styles, h3Styles, h4Styles, pStyles } from '@app/features/common/styles/text.styles';
import { type IElement } from '@app/features/common/interfaces/IElement';
import { commonPositionStyles } from '@app/features/common/constants/position.const';
import { avatarSizeStyles } from '@app/features/avatar/constants/avatar.const';

export function getStyles(element: IElement): JssStyle {
    return {
        element: {
            ...commonPositionStyles[element.data.position],
            display: 'flex',
            flexDirection: 'column',
        },
        image: {
            ...avatarSizeStyles[element.data.size],
            borderRadius: '50%',
        },
        avatarContent: {
            marginTop: '20px',
            '& h1': h1Styles,
            '& h2': h2Styles,
            '& h3': h3Styles,
            '& h4': h4Styles,
            '& p': pStyles,
            '& ul, ol': {
                '& li': pStyles,
            },
        },
    };
}
