import type { NextFunction, Request, Response } from 'express';
import type {
    BuildDto,
    BuildRequest,
    DomainRequestObject,
    EduRequestObject,
    SiteRequestObject,
    PageRequestObject,
    UserRequestObject,
} from '@web-builder-server/types';

import * as Joi from 'joi';

import { createBuildRequestDto } from '@web-builder-server/dto';
import { processRequest } from '@web-builder-server/validation-schemas';

export const buildSchema: Joi.Schema<BuildRequest> = Joi.object({
    structure: Joi.string().required(),
    themeSettings: Joi.string().required(),
    sharedBlocks: Joi.string().allow(null),
    lang: Joi.string(),
    pageId: Joi.number(),
    siteId: Joi.number(),
    domain: Joi.object<DomainRequestObject>({
        id: Joi.number(),
        url: Joi.string(),
    }),
    user: Joi.object<UserRequestObject>({
        id: Joi.number(),
    }),
    edu: Joi.object<EduRequestObject>({
        tariff: Joi.object({
            abbr: Joi.string().allow(null),
        }),
    }).allow(null),
    showPoweredBy: Joi.boolean(),
    site: Joi.object<SiteRequestObject>({
        settings: Joi.object<SiteRequestObject['settings']>({
            not_found_page_id: Joi.number().allow(null),
            shop: Joi.object<any>({
                cart_icon: Joi.object<any>({
                    icon_bg_color: Joi.string().allow(null, ''),
                    icon_color: Joi.string().allow(null, ''),
                    icon_counter_bg_color: Joi.string().allow(null, ''),
                    icon_counter_color: Joi.string().allow(null, ''),
                    icon_size: Joi.string().allow(null, ''),
                    icon_type: Joi.string().allow(null, ''),
                    icon_rounding: Joi.number().allow(null, ''),
                }).allow(null),
                currency: Joi.string().allow(null, ''),
                enabled: Joi.boolean().allow(null, ''),
            }).allow(null),
        }).allow(null),
    }).allow(null),
    page: Joi.object<PageRequestObject>({
        settings: Joi.object<PageRequestObject['settings']>({
            url: Joi.string().allow(null, ''),
        }).allow(null),
    }).allow(null),
});

export function buildRequestSchema(req: Request, res: Response, next: NextFunction): void {
    return processRequest<BuildRequest, BuildDto>(req, res, next, buildSchema, createBuildRequestDto);
}
