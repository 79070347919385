import { Component } from '@angular/core';

import { getStyles } from '@app/features/avatar/components/avatar-view/avatar-view.component.styles';
import { IAvatarElementData } from '@app/features/avatar/interface/IAvatarElement';
import { avatarBlankPicture } from '@app/features/avatar/model/avatar.model';
import { IUploadedFile } from '@app/features/common/interfaces/IFiles';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-avatar-feature',
    templateUrl: './avatar-feature.component.html',
    styleUrls: ['./avatar-feature.component.less'],
})
export default class AvatarFeatureComponent extends BaseFeatureComponent<IAvatarElementData> {
    public avatarBlankPicture = this.formatImgSrc(avatarBlankPicture);

    protected getStyles = getStyles;

    public get image(): string {
        return this.elementData.croppedImgSrc;
    }

    public get file(): IUploadedFile {
        return this.elementData.file;
    }

    public get content(): string {
        return this.elementData.content;
    }
}
