import type { ColorPickerEventPayload } from '@components/sp-color-picker';
import type { ThemeSelectedColors } from '@libs/themes/src/types/theme-color.type';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ThemeFacade } from '@libs/themes/src/store/theme/theme.facade';
import { ThemeSettingsFacade } from '@libs/themes/src/store/theme-settings/theme-settings.facade';

@Component({
    template: '',
})
export abstract class SpThemeColorsSettingsComponent implements OnInit, OnDestroy {
    public abstract readonly COLOR_NAME_TRANSLATIONS: Readonly<Record<any, string>>;
    public abstract colorNames; // keep order

    public form: UntypedFormGroup;
    public initialColorsState: ThemeSelectedColors;
    public isSaved = false;

    protected unsubscribe$ = new Subject<void>();
    protected abstract initForm(): void;

    constructor(
        protected readonly fb: UntypedFormBuilder,
        protected readonly themeFacade: ThemeFacade,
        protected readonly themeSettingsFacade: ThemeSettingsFacade,
    ) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.themeSettingsFacade.themeColors$.pipe(takeUntil(this.unsubscribe$)).subscribe((themeColors: ThemeSelectedColors) => {
            const selectedColors = {};

            for (const color of themeColors.selected) {
                selectedColors[color.name] = color.value;
            }

            this.form.patchValue(selectedColors, { emitEvent: false });

            // save initial
            if (!this.initialColorsState) {
                this.initialColorsState = themeColors;
            }
        });

        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            const selectedColors = [];

            for (const colorKey in data) {
                if (data.hasOwnProperty(colorKey)) {
                    selectedColors.push({ name: colorKey, value: data[colorKey] });
                }
            }

            this.dispatchValues({ selected: selectedColors, selectedIndex: null });
        });
    }

    public dispatchValues(themeColors: ThemeSelectedColors): void {
        this.themeSettingsFacade.changeColorsAction({
            values: themeColors.selected,
            index: themeColors.selectedIndex,
        });
    }

    public onCancel(): void {
        if (this.form.dirty) {
            this.dispatchValues(this.initialColorsState);
        }

        this.themeFacade.unselectActiveThemeSettingAction();
    }

    public onSave(): void {
        this.isSaved = true;
        this.themeFacade.unselectActiveThemeSettingAction();
    }

    public onChangeColor(name: string, { value }: ColorPickerEventPayload): void {
        this.form.markAsDirty();
        this.form.patchValue({ [name]: value });
    }

    public ngOnDestroy(): void {
        if (!this.isSaved && this.form.dirty) {
            this.dispatchValues(this.initialColorsState);
        }

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
