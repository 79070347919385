import type { ThemeSettingsState } from '@libs/themes/src/store/theme-settings/theme-settings.reducer';
import type { ThemeButton, ThemeColor } from '@libs/themes';

export abstract class AbstractThemeFormatter {
    public abstract formatThemeColors(theme: ThemeSettingsState, values: Record<string, string>): Record<string, string>;
    public abstract formatThemeFonts(theme: ThemeSettingsState, values: Record<string, string>): Record<string, string>;
    public abstract formatNextButtonSettings(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[];

    public abstract formatThemeButtons(theme: ThemeSettingsState, values: Record<string, string>): Record<string, string>;

    public formatTheme(theme: ThemeSettingsState): Record<string, string> {
        let values: Record<string, string> = {};

        values = this.formatThemeColors(theme, values);
        values = this.formatThemeFonts(theme, values);
        values = this.formatThemeButtons(theme, values);

        return values;
    }
}
