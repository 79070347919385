import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, type FormControl } from '@angular/forms';
import { GoogleSettingsFormGroupType } from '@app/features/common/components/analytics-settings';

@Component({
    selector: 'app-google-settings',
    templateUrl: './google-settings.component.html',
    styleUrls: ['./google-settings.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleSettingsComponent {
    @Input() public googleSettings: GoogleSettingsFormGroupType;

    constructor(private readonly fb: FormBuilder) {}

    public get categoryControl(): FormControl<string> {
        return this.googleSettings.get('category') as FormControl<string>;
    }

    public get actionControl(): FormControl<string> {
        return this.googleSettings.get('action') as FormControl<string>;
    }

    public get labelControl(): FormControl<string> {
        return this.googleSettings.get('label') as FormControl<string>;
    }
}
