export enum EMenuLayoutType {
    HORIZONTAL = 'Horizontal',
    VERTICAL = 'Vertical',
}

export enum EMenuLogoPosition {
    LEFT = 'Left',
    CENTER = 'Center',
    RIGHT = 'Right',
}
