import type { OnDestroy, OnInit } from '@angular/core';
import type { AbstractControl, UntypedFormGroup } from '@angular/forms';
import type { IButtonHoverTypeOption, ThemeColor, ThemeColorName } from '@libs/themes';
import type { ColorPickerEventPayload } from '@components/sp-color-picker';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { type ThemeButton, ThemeButtonData, ThemeButtonHover, buttonHoverTypeOptions } from '@libs/themes';

import { CSS_RESTRICTIONS } from '@common/constants';
import { InputBoolean } from '@common/helpers/convert';
import { EButtonHoverType } from '@common/enums/button.enum';

import { ButtonMode } from './type/custom-button.type';

@Component({
    selector: 'sp-custom-button',
    templateUrl: './sp-custom-button.component.html',
    styleUrls: ['./sp-custom-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpCustomButtonComponent implements OnInit, OnDestroy {
    @Input() public parentBase?: Partial<ThemeButtonData> = {};
    @Input() public parentHover?: ThemeButtonHover;
    @Input() public colorsPreset: ThemeColor[] = [];
    @Input() public buttonMode: ButtonMode = null;
    @Input() @InputBoolean() public isThemeSettings: boolean = false;
    @Input() @InputBoolean() public showFontSettings: boolean = false;
    @Input() @InputBoolean() public showHoverSettings: boolean = false;

    @Output() public valueChangeEvent: EventEmitter<ThemeButton> = new EventEmitter();

    private form: UntypedFormGroup;
    private readonly unsubscribe$ = new Subject<void>();

    public EButtonHoverType = EButtonHoverType;
    public buttonHoverOptions: IButtonHoverTypeOption[] = buttonHoverTypeOptions;

    constructor(private readonly fb: NonNullableFormBuilder) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((changes) => this.emitChanges(changes));

        this.setFormData();
    }

    private initForm(): void {
        this.form = this.fb.group({
            base: this.fb.group({
                color: null,
                background: null,
                borderColor: null,
                borderWidth: null,
                borderRadius: null,
                borderStyle: null,
            }),
            hover: this.fb.group({
                hoverType: null,
                opacity: null,
                shadow: null,
                color: null,
                background: null,
                borderColor: null,
            }),
        });
    }

    private setFormData(): void {
        const base: ThemeButtonData = {
            color: this.parentBase.color,
            background: this.parentBase.background,
            borderColor: this.parentBase.borderColor,
            borderWidth: parseInt(this.parentBase.borderWidth as string, 10),
            borderRadius: parseInt(this.parentBase.borderRadius as string, 10),
            borderStyle: this.parentBase.borderStyle,
        };

        if (!this.showHoverSettings) {
            this.form.patchValue({ base }, { emitEvent: false });
            return;
        }

        const hover: ThemeButtonHover = {
            hoverType: this.parentHover.hoverType,
            opacity: this.parentHover.opacity,
            shadow: this.parentHover.shadow,
            color: this.parentHover.color,
            background: this.parentHover.background,
            borderColor: this.parentHover.borderColor,
        };

        this.form.patchValue({ base, hover }, { emitEvent: false });
    }

    private emitChanges(changes: ThemeButton): void {
        this.valueChangeEvent.emit(changes);
    }

    private setColorPickerPayload(property: string, value: string, presetName: ThemeColorName): void {
        let formPropertyValue: string | string[];
        const payloadValue: string = presetName || value;

        if (Array.isArray(this.color.value)) {
            formPropertyValue = [...this.base.value[property]];
            formPropertyValue[this.buttonMode] = payloadValue;
            if (formPropertyValue.length > 1 && formPropertyValue.every((item: string) => item === payloadValue)) {
                formPropertyValue = [payloadValue];
            }
        } else {
            formPropertyValue = payloadValue;
        }

        this.base.setValue({ ...this.base.value, [property]: formPropertyValue });
    }

    private setHoverColorPickerPayload(property: string, value: string, presetName: ThemeColorName): void {
        let formPropertyValue: string | string[];
        const payloadValue: string = presetName || value;

        if (Array.isArray(this.color.value)) {
            formPropertyValue = [...this.hover.value[property]];
            formPropertyValue[this.buttonMode] = payloadValue;
            if (formPropertyValue.length > 1 && formPropertyValue.every((item: string) => item === payloadValue)) {
                formPropertyValue = [payloadValue];
            }
        } else {
            formPropertyValue = payloadValue;
        }

        this.hover.setValue({ ...this.hover.value, [property]: formPropertyValue });
    }

    public get base(): AbstractControl {
        return this.form.get('base');
    }

    // COLOR
    public get color(): AbstractControl {
        return this.base.get('color');
    }

    public get colorValue(): string {
        if (this.buttonMode !== null) {
            return this.color.value[this.buttonMode] || this.color.value[0];
        }

        return this.color.value;
    }

    public setColor({ value, presetName }: ColorPickerEventPayload): void {
        this.setColorPickerPayload('color', value, presetName);

        if (!this.showHoverSettings) {
            return;
        }

        if (this.hoverType.value !== EButtonHoverType.color) {
            this.setHoverColor({ value, presetName });
        }
    }

    // BACKGROUND
    public get background(): AbstractControl {
        return this.base.get('background');
    }

    public get backgroundValue(): string {
        if (this.buttonMode !== null) {
            return this.background.value[this.buttonMode] || this.background.value[0];
        }

        return this.background.value;
    }

    public setBackground({ value, presetName }: ColorPickerEventPayload): void {
        this.setColorPickerPayload('background', value, presetName);

        if (!this.showHoverSettings) {
            return;
        }

        if (this.hoverType.value !== EButtonHoverType.color) {
            this.setHoverBackground({ value, presetName });
        }
    }

    // BORDER_WIDTH
    public get borderWidth(): AbstractControl {
        return this.base.get('borderWidth');
    }

    public setBorderWidth(value: number): void {
        this.borderWidth.setValue(value);
    }

    // BORDER_COLOR
    public get borderColor(): AbstractControl {
        return this.base.get('borderColor');
    }

    public get borderColorValue(): string {
        if (this.buttonMode !== null) {
            return this.borderColor.value[this.buttonMode] || this.borderColor.value[0];
        }

        return this.borderColor.value;
    }

    public setBorderColor({ value, presetName }: ColorPickerEventPayload): void {
        this.setColorPickerPayload('borderColor', value, presetName);

        if (!this.showHoverSettings) {
            return;
        }

        if (this.hoverType.value !== EButtonHoverType.color) {
            this.setHoverBorderColor({ value, presetName });
        }
    }

    // BORDER_RADIUS
    public get borderRadius(): AbstractControl {
        return this.base.get('borderRadius');
    }

    public setBorderRadius(value: number): void {
        this.borderRadius.setValue(value);
    }

    public get BORDER_WIDTH_MIN(): number {
        return CSS_RESTRICTIONS.BORDER_WIDTH_MIN;
    }

    public get BORDER_WIDTH_MAX(): number {
        return CSS_RESTRICTIONS.BORDER_WIDTH_MAX;
    }

    public get BORDER_RADIUS_MIN(): number {
        return CSS_RESTRICTIONS.BORDER_RADIUS_MIN;
    }

    public get BORDER_RADIUS_MAX(): number {
        return CSS_RESTRICTIONS.BORDER_RADIUS_MAX;
    }

    public get hover(): AbstractControl {
        return this.form.get('hover');
    }

    public get hoverType(): AbstractControl {
        return this.hover.get('hoverType');
    }

    public changeHoverOptions(event: Event): void {
        const selectedOption = (event.target as HTMLSelectElement).value;

        if (selectedOption === EButtonHoverType.opacity) {
            this.hover.patchValue({
                hoverType: EButtonHoverType.opacity,
                opacity: 80,
                shadow: 'none',
                color: this.color.value,
                background: this.background.value,
                borderColor: this.borderColor.value,
            });
            return;
        }

        if (selectedOption === EButtonHoverType.shadow) {
            this.hover.patchValue({
                hoverType: EButtonHoverType.shadow,
                opacity: 100,
                shadow: '0 0 10px 6px rgb(0 0 0 / 10%)',
                color: this.color.value,
                background: this.background.value,
                borderColor: this.borderColor.value,
            });
            return;
        }

        if (selectedOption === EButtonHoverType.color) {
            this.hover.patchValue({
                hoverType: EButtonHoverType.color,
                opacity: 100,
                shadow: 'none',
                color: ['rgb(255,255,255)'],
                background: ['rgb(22,117,242)'],
                borderColor: ['rgba(0,0,0,0)'],
            });
        }
    }

    public get opacity(): AbstractControl {
        return this.hover.get('opacity');
    }

    public get OPACITY_MIN(): number {
        return CSS_RESTRICTIONS.OPACITY_MIN;
    }

    public get OPACITY_MAX(): number {
        return CSS_RESTRICTIONS.OPACITY_MAX;
    }

    public setOpacity(value: number): void {
        this.opacity.setValue(value);
    }

    // HOVER COLOR
    public get hoverColor(): AbstractControl {
        return this.hover.get('color');
    }

    public get hoverColorValue(): string {
        if (this.buttonMode !== null) {
            return this.hoverColor.value[this.buttonMode] || this.hoverColor.value[0];
        }

        return this.hoverColor.value;
    }

    public setHoverColor({ value, presetName }: ColorPickerEventPayload): void {
        this.setHoverColorPickerPayload('color', value, presetName);
    }

    // HOVER BACKGROUND
    public get hoverBackground(): AbstractControl {
        return this.hover.get('background');
    }

    public get hoverBackgroundValue(): string {
        if (this.buttonMode !== null) {
            return this.hoverBackground.value[this.buttonMode] || this.hoverBackground.value[0];
        }

        return this.hoverBackground.value;
    }

    public setHoverBackground({ value, presetName }: ColorPickerEventPayload): void {
        this.setHoverColorPickerPayload('background', value, presetName);
    }

    // HOVER BORDER_COLOR
    public get hoverBorderColor(): AbstractControl {
        return this.hover.get('borderColor');
    }

    public get hoverBorderColorValue(): string {
        if (this.buttonMode !== null) {
            return this.hoverBorderColor.value[this.buttonMode] || this.hoverBorderColor.value[0];
        }

        return this.hoverBorderColor.value;
    }

    public setHoverBorderColor({ value, presetName }: ColorPickerEventPayload): void {
        this.setHoverColorPickerPayload('borderColor', value, presetName);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
