import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpHostDirective } from './host.directive';
import { SpClickOutsideDirective } from './click-outside.directive';
import { SpPanelDragDirective } from './panel-drag.directive';
import { SpPanelDragHandlerDirective } from './panel-drag-handler.directive';
import { SpFallbackImageDirective } from './fallback-image.directive';

const DIRECTIVES = [SpHostDirective, SpPanelDragDirective, SpPanelDragHandlerDirective, SpFallbackImageDirective];

const STANDALONE_DIRECTIVES = [SpClickOutsideDirective];
const MODULES = [CommonModule];

@NgModule({
    imports: [...MODULES, ...STANDALONE_DIRECTIVES],
    declarations: [...DIRECTIVES],
    exports: [...DIRECTIVES, ...STANDALONE_DIRECTIVES],
})
export class SpDirectivesModule {}
