import type { JssStyle } from 'jss';
import type { ThemeButton } from '@common/libs/themes/src/types/theme-button.type';

import { ChangeDetectionStrategy, Component, Input, type OnInit } from '@angular/core';
import { EButtonStyleType } from '@common/enums';

import { getStyles } from './website-button-preset.component.styles';

@Component({
    selector: 'sp-website-button-preset',
    templateUrl: './website-button-preset.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpWebsiteButtonPresetComponent implements OnInit {
    @Input() public type: EButtonStyleType.primary | EButtonStyleType.secondary | undefined;
    @Input() public button!: ThemeButton;
    @Input() public selected!: boolean;

    public styles!: JssStyle;
    public classes!: string;
    public readonly BUTTON_STYLE_TYPE_TRANSLATIONS: Readonly<Record<EButtonStyleType, string>> = {
        [EButtonStyleType.primary]: 'control_appearance_primary',
        [EButtonStyleType.secondary]: 'control_appearance_secondary',
        [EButtonStyleType.custom]: 'control_appearance_custom',
        [EButtonStyleType.classic]: 'control_appearance_classic',
    };
    public readonly BUTTON_TYPE_CLASS_MAP: Partial<Record<EButtonStyleType, string>> = {
        [EButtonStyleType.primary]: 'sp-ui-button-primary-light',
        [EButtonStyleType.secondary]: 'sp-ui-button-secondary-light',
    };

    public ngOnInit(): void {
        this.styles = getStyles(this.selected, this.type, this.button.base!);
        this.classes = this.getButtonClasses();
    }

    private getButtonClasses(): string {
        let classes = 'sp-ui-button sp-ui-button-sm';

        if (this.selected) {
            classes += ` ${this.BUTTON_TYPE_CLASS_MAP[this.button.styleType]}`;
        }

        return classes;
    }
}
