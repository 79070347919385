export enum EAttachmentOptions {
    fixed = 'fixed',
    scroll = 'scroll',
}

export interface IAttachmentOptions {
    label: string;
    value: EAttachmentOptions;
}

export const attachmentOptions: IAttachmentOptions[] = [
    {
        label: 'background_attachment_fixed',
        value: EAttachmentOptions.fixed,
    },
    {
        label: 'background_attachment_scroll',
        value: EAttachmentOptions.scroll,
    },
];
