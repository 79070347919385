import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { type Uuid } from '@common/types';
import { type SitePageEntity, type SitePageCopyRequest } from '@common/types/site-page.type';

type NavigateTo = { pageId?: string; siteId?: string; isNew?: boolean };

export const PagesActions = createActionGroup({
    source: 'Pages',
    events: {
        'Load pages success': props<{ pages: SitePageEntity[] }>(),
        'Load pages failure': props<{ error: string }>(),
        'Create page request': props<{ exit?: boolean; publish?: boolean; navigateTo?: NavigateTo; skipContentChange?: boolean }>(),
        'Create page success': props<{ page: SitePageEntity; oldPageId: string | number }>(),
        'Update page request': props<{ exit?: boolean; publish?: boolean; navigateTo?: NavigateTo; skipContentChange?: boolean }>(),
        'Update page success': props<{ page: SitePageEntity }>(),
        'Add new page': emptyProps(),
        'Create new page': emptyProps(),
        'Create new page success': props<{ page: SitePageEntity }>(),
        'Delete page request': props<{ siteId: number | string; pageId: number | string }>(),
        'Delete page success': props<{ pageId: number | string }>(),
        'Delete page failure': props<{ pageId: number | string; error: any }>(),
        'Activate page request': props<{ siteId: number | string; pageId: number | string; resetContent?: boolean }>(),
        'Activate page success': props<{ pageId: number | string; page: SitePageEntity }>(),
        'Activate page failure': props<{ pageId: number | string; error: any }>(),
        'Disable page request': props<{ siteId: number | string; pageId: number | string }>(),
        'Disable page success': props<{ pageId: number | string; page: SitePageEntity }>(),
        'Disable page failure': props<{ pageId: number | string; error: any }>(),
        'Copy page': props<{ siteId: number; page: SitePageEntity }>(),
        'Copy page request': props<{ siteId: number | string; pageId: number | string; copyRequest: SitePageCopyRequest }>(),
        'Copy page success': props<{ pageId: number | string; page: SitePageEntity }>(),
        'Copy page failure': props<{ pageId: number | string; error: any }>(),
        'Patch page': props<{ pageId: Uuid; data: Partial<SitePageEntity> }>(),
        'Set pages': props<{ pages: SitePageEntity[] }>(),
        'Navigate site page': props<{ siteId: string; pageId: string; skipContentChange?: boolean }>(),
        'Navigate site page from drawer': props<{ siteId: string; pageId: string }>(),
    },
});

export default PagesActions;
