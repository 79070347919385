<div *ngIf="!isTestGroupSiteId" [class]="classes?.contentFooter">
    <a class="powered-sendpulse" [href]="link" target="_blank">
        <img [src]="logoSrc" alt="SendPulse logo" />
        <span>{{ labelTranslationKey | translate }}</span>
    </a>
</div>

<div *ngIf="isTestGroupSiteId && !isOddSiteId" [class]="classes?.contentFooter">
    <a class="powered-sendpulse" [href]="linkTestBannerV1" target="_blank">
        <img [src]="logoSrc" alt="SendPulse logo" />
        <span>{{ labelTranslationKey | translate }}</span>
    </a>
</div>

<div *ngIf="isTestGroupSiteId && isOddSiteId" [class]="classes?.contentFooter" class="new-banner">
    <a class="powered-sendpulse" [href]="linkTestBannerV3" target="_blank">
        <img [src]="logoSrc" alt="SendPulse logo" />
        <span>{{ labelTranslationKeyTestBannerV1 | translate }}</span>
    </a>
</div>
