import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ESimpleThemeColorNames } from '@common/libs/themes/src/variants/simple/enums/color.enum';
import { SpThemeColorsSettingsComponent } from '@libs/themes/src/abstract/abstract-colors-settings.component';

@Component({
    selector: 'sp-simple-colors-settings',
    styleUrls: ['./simple-colors-settings.component.less'],
    templateUrl: './simple-colors-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSimpleColorsSettingsComponent extends SpThemeColorsSettingsComponent {
    public colorNames = Object.values(ESimpleThemeColorNames); // keep order
    public readonly COLOR_NAME_TRANSLATIONS: Readonly<Record<ESimpleThemeColorNames, string>> = {
        [ESimpleThemeColorNames.pageBackground]: 'theme_color_settings_page_background',
        [ESimpleThemeColorNames.contentBackground]: 'theme_color_settings_content_background',
        [ESimpleThemeColorNames.text]: 'theme_color_settings_text',
        [ESimpleThemeColorNames.primary]: 'theme_color_settings_primary',
        [ESimpleThemeColorNames.secondary]: 'theme_color_settings_secondary',
    };

    protected initForm(): void {
        this.form = this.fb.group({
            [ESimpleThemeColorNames.pageBackground]: null,
            [ESimpleThemeColorNames.contentBackground]: null,
            [ESimpleThemeColorNames.text]: null,
            [ESimpleThemeColorNames.primary]: null,
            [ESimpleThemeColorNames.secondary]: null,
        });
    }
}
