import { type EButtonStyleType } from '@common/enums';
import { type ESimpleThemeFontProperties } from '@common/libs/themes/src/variants/simple/enums/font.enum';
import { type EThemeFontTags } from '@common/libs/themes/src/enums/font-tags.enum';
import {
    SIMPLE_THEME_BUTTONS_VARIABLES,
    SIMPLE_THEME_COLORS_VARIABLES,
    SIMPLE_THEME_FONTS_VARIABLES,
} from '@common/libs/themes/src/variants/simple/constants/css-variables';

const varString = (value: string): string => `var(${value})`;

export function simpleColorCss(property: string): string {
    if (property?.includes('var')) {
        return property;
    }

    const value = SIMPLE_THEME_COLORS_VARIABLES[property];

    if (!value) {
        return property;
    }

    return varString(SIMPLE_THEME_COLORS_VARIABLES[property]);
}

export function simpleButtonCssProperty(type: EButtonStyleType, property: string) {
    return varString(SIMPLE_THEME_BUTTONS_VARIABLES[type][property]);
}

export function simplePrimaryButtonCss(property: string): string {
    return varString(SIMPLE_THEME_BUTTONS_VARIABLES.primary[property]);
}

export function simpleSecondaryButtonCss(property: string): string {
    return varString(SIMPLE_THEME_BUTTONS_VARIABLES.secondary[property]);
}

export function simpleFontCss(tag: EThemeFontTags, property: ESimpleThemeFontProperties): string {
    return varString(SIMPLE_THEME_FONTS_VARIABLES[tag][property]);
}

export function simpleFontButtonCss(property: ESimpleThemeFontProperties): string {
    return varString(SIMPLE_THEME_FONTS_VARIABLES.button[property]);
}
