import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
    name: 'spByteFormat',
})
export class SpByteFormat implements PipeTransform {
    public transform(size: number, b = 2): string {
        if (size === 0 || !size) {
            return '0 Bytes';
        }

        const c = b < 0 ? 0 : b;
        const d = Math.floor(Math.log(size) / Math.log(1024));
        return `${parseFloat((size / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`;
    }
}
