import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class BasePlatformService {
    constructor(@Inject(PLATFORM_ID) protected readonly platformId: string) {}

    public isPlatformServer(): boolean {
        return isPlatformServer(this.platformId);
    }

    public isPlatformBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }
}
