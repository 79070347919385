<a [class]="classes?.element" [id]="element.id + '-sp'" class="btn-hover" (click)="openModal()">
    <div [class]="classes?.wrapContent">
        <span [class]="classes?.label">{{ elementData.buttonText }}</span>
        <span
            *ngIf="!!elementData.buttonDescription"
            [class]="classes?.description">
            {{ elementData.buttonDescription }}
        </span>
    </div>
</a>
