// DISABLE FORMATTING FOR THIS FILE
/* eslint-disable prettier/prettier */

export const DEFAULT_WEBSITE_COLOR_PRESETS: string[][] = [
    // light
    // dark
    // primary
    // secondary
    // accent
    [
        'rgb(255, 255, 255)',
        'rgb(33, 36, 61)',
        'rgb(255, 124, 124)',
        'rgb(136, 225, 242)',
        'rgb(255, 208, 130)',
    ],
    [
        'rgb(255, 255, 255)',
        'rgb(10, 30, 46)',
        'rgb(255, 162, 26)',
        'rgb(0, 54, 80)',
        'rgb(238, 241, 242)',
    ],
    [
        'rgb(247,247,247)',
        'rgb(37,37,37)',
        'rgb(33,173,234)',
        'rgb(152,219,248)',
        'rgb(248,192,151)',
    ],

    [
        'rgb(255,255,255)',
        'rgb(34,34,34)',
        'rgb(49,57,139)',
        'rgb(211,158,220)',
        'rgb(158,185,220)',
    ],
    [
        'rgb(255,255,255)',
        'rgb(31,31,31)',
        'rgb(91,171,38)',
        'rgb(223,61,79)',
        'rgb(17,19,55)',
    ],
    [
        'rgb(248,241,242)',
        'rgb(33,36,33)',
        'rgb(130,204,245)',
        'rgb(234,247,254)',
        'rgb(245,130,178)',
    ],
    [
        'rgba(255,252,247,1)',
        'rgba(57,40,34,1)',
        'rgba(218,157,135,1)',
        'rgba(244,224,207,1)',
        'rgba(135,198,218,1)',
    ],
    [
        'rgba(253,248,245,1)',
        'rgba(62,40,22,1)',
        'rgba(244,220,141,1)',
        'rgba(227,229,209,1)',
        'rgba(235,211,188,1)',
    ],
    [
        'rgba(243,246,255,1)',
        'rgba(39,67,83,1)',
        'rgba(202,105,83,1)',
        'rgba(136,193,220,1)',
        'rgba(214,193,143,1)',
    ],
    [
        'rgba(255,255,255,1)',
        'rgba(10,12,24,1)',
        'rgba(241,210,91,1)',
        'rgba(255,247,218,1)',
        'rgba(208,214,143,1)',
    ],
    [
        'rgb(248,253,252)',
        'rgb(1,26,21)',
        'rgb(14,69,77)',
        'rgb(242, 192, 15)',
        'rgb(156,112,116)',
    ],
    [
        'rgba(255,255,255,1)',
        'rgba(25,48,70,1)',
        'rgba(186,60,55,1)',
        'rgba(236,192,104,1)',
        'rgba(250,239,216,1)',
    ],
    [
        'rgba(255,255,255,1)',
        'rgba(20,30,25,1)',
        'rgba(72,106,90,1)',
        'rgba(227,207,130,1)',
        'rgba(229,228,200,1)',
    ],
    [
        'rgba(247,249,243,1)',
        'rgba(65,46,41,1)',
        'rgba(205,119,88,1)',
        'rgba(241,213,203,1)',
        'rgba(241,203,203,1)',
    ],
    [
        'rgba(226,238,252,1)',
        'rgba(22,3,61,1)',
        'rgba(168,45,90,1)',
        'rgba(46,14,110,1)',
        'rgba(232,203,108,1)',
    ],
    [
        'rgba(239,247,252,1)',
        'rgba(38,37,37,1)',
        'rgba(61,79,139,1)',
        'rgba(225,174,130,1)',
        'rgba(178,74,87,1)',
    ],
    [
        'rgba(253,251,252,1)',
        'rgba(62,35,28,1)',
        'rgba(214,114,87,1)',
        'rgba(240,199,186,1)',
        'rgba(87,168,214,1)',
    ],
    [
        'rgba(247,238,254,1)',
        'rgba(38,59,94,1)',
        'rgba(148,146,243,1)',
        'rgba(235,183,135,1)',
        'rgba(231,206,248,1)',
    ],
    [
        'rgba(255,255,255,1)',
        'rgba(51,51,51,1)',
        'rgba(31,52,109,1)',
        'rgba(223,109,80,1)',
        'rgba(231,178,77,1)',
    ],
    [
        'rgba(255,255,255,1)',
        'rgba(49,49,49,1)',
        'rgba(113,82,198,1)',
        'rgba(229,233,253,1)',
        'rgba(202,72,75,1)',
    ],
    // light
    // dark
    // primary
    // secondary
    // accent
];
