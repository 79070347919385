import { EButtonStyleType, EButtonType, EButtonLinkType } from '@common/enums';
import { EGallerySliderSpeeds, EGalleryTypes } from '@app/features/gallery/enum/gallery.enum';

export const buttonStyleTypeTranslations: Readonly<Record<EButtonStyleType, string>> = {
    [EButtonStyleType.primary]: 'control_appearance_primary',
    [EButtonStyleType.secondary]: 'control_appearance_secondary',
    [EButtonStyleType.custom]: 'control_appearance_custom',
    [EButtonStyleType.classic]: 'control_appearance_classic',
};

export const buttonTypeTranslations: Readonly<Record<EButtonType, string>> = {
    [EButtonType.icon]: 'control_appearance_icon',
    [EButtonType.iconText]: 'control_appearance_icon_text',
} as any;

export const buttonLinkTypeTranslations: Readonly<Record<EButtonLinkType, string>> = {
    [EButtonLinkType.email]: 'control_email',
    [EButtonLinkType.phone]: 'control_phone',
    [EButtonLinkType.link]: 'control_link',
} as any;

export const buttonLinkTypeContentsTranslations: Readonly<Record<EButtonLinkType, string>> = {
    [EButtonLinkType.email]: 'control_write_message',
    [EButtonLinkType.phone]: 'control_call',
    [EButtonLinkType.link]: 'control_visit_site',
} as any;

export const buttonLinkTypeDescriptionTranslations: Readonly<Record<EButtonLinkType, string>> = {
    [EButtonLinkType.email]: 'control_response',
    [EButtonLinkType.phone]: 'control_from_to',
    [EButtonLinkType.link]: 'control_link_description',
} as any;

export const gallerySliderSpeedTranslations: Readonly<Record<EGallerySliderSpeeds, string>> = {
    [EGallerySliderSpeeds.manual]: 'control_swiping_speed_manually',
    [EGallerySliderSpeeds.slow]: 'control_swiping_speed_low',
    [EGallerySliderSpeeds.middle]: 'control_swiping_speed_average',
    [EGallerySliderSpeeds.high]: 'control_swiping_speed_high',
};

export const galleryTypesTranslations: Record<EGalleryTypes, string> = {
    [EGalleryTypes.image]: 'control_gallery_type_image',
    [EGalleryTypes.slider]: 'control_gallery_type_slider',
    [EGalleryTypes.video]: 'control_gallery_type_video',
};
