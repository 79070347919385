import { Input, Output, EventEmitter, forwardRef, OnDestroy, Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class SpInputAbstract<T> implements ControlValueAccessor, OnDestroy {
    // for disabling input when FormControl is not passed
    @Input() public disabled = false;
    // disables and adds "read only" formatting
    @Input() public readOnly = false;
    // for form validation when FormControl is not passed
    @Input() public required = false;
    // input value, if form contol is not used
    @Input() public value: T;
    /**
     * Floating label.
     * Will be hidden if omitted.
     */
    @Input() public label!: string;
    @Input() public control!: UntypedFormControl;
    @Input() public placeholder!: string;
    // for placeholder asterisk, will be hidden if true
    @Input() public noAsterisk = false;

    @Input() public debounceTime = 500;

    @Output() public blur = new EventEmitter<void>();
    @Output() public focus = new EventEmitter<void>();
    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    protected validatorsConfig: any[] = [];
    protected readonly unsubscribe$ = new Subject<void>();

    protected abstract getValidValue(value: T): T;

    // For NG_VALUE_ACCESSOR
    private onChange!: (value: T) => void;
    private onTouched!: () => void;

    public onBlur(event: MouseEvent): void {
        event.stopPropagation();
        this.blur.emit();
    }

    public onFocus(event: MouseEvent): void {
        event.stopPropagation();
        this.focus.emit();
    }

    public registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(val: T): void {
        if (val) {
            this.control.setValue(val);
        }
    }

    protected init(): void {
        if (!this.control) {
            const inputValue = {} as { value: any; disabled?: boolean };

            if (this.required) {
                this.validatorsConfig.push(Validators.required);
            }

            inputValue.value = this.value;
            inputValue.disabled = !!this.disabled;

            this.control = new UntypedFormControl(inputValue, this.validatorsConfig);
        }

        if (this.readOnly) {
            this.control.disable();
        }
    }

    protected subscribeOnFormControlValueChange(): void {
        this.control.valueChanges
            .pipe(takeUntil(this.unsubscribe$), debounceTime(this.debounceTime), distinctUntilChanged())
            .subscribe((value) => {
                if (this.onTouched) {
                    this.onTouched();
                }

                const validValue = this.getValidValue(value);
                if (validValue !== this.value) {
                    this.valueChange.emit(validValue);
                }

                if (this.onChange) {
                    this.onChange(value);
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

export function MakeProvider(type: any): any {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true,
    };
}
