import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';
import { ITeachersElementData } from '@app/features/edu-teachers/interface/ITeachers';
import { getStyles } from '@app/features/edu-teachers/components/edu-teachers-view/edu-teachers-view.component.styles';

@Component({
    selector: 'mls-edu-teachers-feature',
    templateUrl: './edu-teachers-feature.component.html',
    styleUrls: ['./edu-teachers-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class EduTeachersFeatureComponent extends BaseFeatureComponent<ITeachersElementData> {
    protected getStyles = getStyles;

    public teacherHasUploadedImage(teacher): boolean {
        return !!teacher.image?.startsWith('http');
    }

    public get getBlancImage(): string {
        return this.formatImgSrc(`./assets/img/elements/teacher-avatar-blank.svg`);
    }
}
