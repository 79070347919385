import type { PageSettings } from '@common/types/page-settings.type';

import { createImmerReducer } from 'ngrx-immer/store';
import { on } from '@ngrx/store';

import PageSettingsActions from './page-settings.actions';

export const PAGE_SETTINGS_FEATURE_KEY = 'page-settings';

export type PageSettingsState = {
    settings: PageSettings;
    error?: string;
};

export const initialPageSettingsState: PageSettingsState = {
    settings: null,
};

export const pageSettingsReducer = createImmerReducer<PageSettingsState>(
    initialPageSettingsState,
    on(PageSettingsActions.loadPageSettingsSuccess, (state: PageSettingsState, { settings }) => {
        state.settings = settings;
        return state;
    }),
    on(PageSettingsActions.loadPageSettingsFailure, (state: PageSettingsState, { error }) => {
        state.error = error;
        return state;
    }),
    on(PageSettingsActions.updatePageSettingsSuccess, (state: PageSettingsState, { pageSettings }) => {
        state.settings = pageSettings;
        return state;
    }),
);
