import { Inject, Injectable, Optional, TransferState, makeStateKey, type StateKey } from '@angular/core';
import { Request as ExpressRequest } from 'express';
import { of, type Observable } from 'rxjs';

import { REQUEST } from '@common/tokens';
import { ELanguages } from '@common/enums';
import { PlatformService } from '@common/services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class TranslationsService {
    private readonly LANGUAGE_KEY: StateKey<ELanguages>;
    private readonly TRANSLATIONS_KEY: StateKey<Record<string, string>>;

    constructor(
        private readonly transferState: TransferState,
        private readonly platformService: PlatformService,
        @Optional() @Inject(REQUEST) private readonly request: ExpressRequest,
    ) {
        this.LANGUAGE_KEY = makeStateKey<ELanguages>('language');
        this.TRANSLATIONS_KEY = makeStateKey<Record<string, string>>('translations');

        if (this.platformService.isPlatformServer()) {
            this.transferState.set(this.LANGUAGE_KEY, this.request.body.lang || ELanguages.EN);
        }
    }

    public getLanguageAsObservable(): Observable<ELanguages> {
        if (this.platformService.isPlatformServer()) {
            return of(this.request.body.lang);
        }

        return of(this.transferState.get(this.LANGUAGE_KEY, ELanguages.EN));
    }

    public getLanguageAsValue(): ELanguages {
        if (this.platformService.isPlatformServer()) {
            return this.request.body.lang;
        }

        return this.transferState.get(this.LANGUAGE_KEY, ELanguages.EN);
    }

    public setTranslations(translations: Record<string, string>): void {
        if (!this.platformService.isPlatformServer()) {
            return;
        }

        this.transferState.set(this.TRANSLATIONS_KEY, translations);
    }

    public getTranslations(): Observable<Record<string, string>> {
        if (this.platformService.isPlatformServer()) {
            return of({});
        }

        return of(this.transferState.get(this.TRANSLATIONS_KEY, {}));
    }
}
