import type { ThemeColor } from '@libs/themes';

import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'sp-color-picker-dropdown',
    templateUrl: './sp-color-picker-dropdown.component.html',
    styleUrls: ['./sp-color-picker-dropdown.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpColorPickerDropdownComponent {
    @Input() public color!: string;
    @Input() public colorsPreset: ThemeColor[];
    @Input() public hideGradient: boolean;

    @Output() public setColorEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() public setColorPresetEvent: EventEmitter<ThemeColor> = new EventEmitter<ThemeColor>();

    public showCustom = false;

    public setColorHandler(value: string): void {
        this.setColorEvent.emit(value);
    }

    public setColorPresetHandler(preset: ThemeColor): void {
        this.setColorPresetEvent.emit(preset);
    }
}
