import { type IElement } from '@app/features/common/interfaces/IElement';
import { type JssStyle } from 'jss';
import { type IUploadedFile } from '@app/features/common/interfaces/IFiles';
import { type IGallerySlider } from '@app/features/gallery/interface/IGalleryElement';
import { EHorizontalPosition, EVerticalPosition } from '@common/enums';
import { EGallerySliderHeightType, EGalleryTypes } from '@app/features/gallery/enum/gallery.enum';
import { commonFontSizeCoefficient, pStyles } from '@app/features/common/styles/text.styles';
import { DEFAULT_SLIDER_HEIGHT } from '@app/features/gallery/constants/gallery.consts';
import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';

const getDescriptionStyles = (file: IUploadedFile): JssStyle => {
    const commonDescription = {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        wordBreak: 'break-word',
        boxSizing: 'border-box',
        padding: file.description ? '25px' : '0',
        background: simpleColorCss(file.textBackground),
    };

    if (!file.description) {
        return { ...commonDescription, bottom: '0', left: '0' };
    }

    switch (file.textPosition) {
        case EVerticalPosition.B:
            return { ...commonDescription, bottom: '0', left: '0' };
        case EVerticalPosition.M:
            return {
                ...commonDescription,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            };
        case EVerticalPosition.T:
            return { ...commonDescription, top: '0', left: '0' };
    }
};
const getTextStyles = (file: IUploadedFile): JssStyle => {
    const fontSizeCoefficient = commonFontSizeCoefficient[file.textSize];
    const scaledFontSize = `calc(${pStyles.fontSize} * ${fontSizeCoefficient})`;
    const textStyles = { ...pStyles, fontSize: scaledFontSize, color: simpleColorCss(file.textColor) };
    const marginLeft = file.link ? '16px' : '0';
    const marginRight = file.link ? `calc(${pStyles.fontSize} * ${fontSizeCoefficient} + 15px)` : '0';

    return {
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
        marginRight,
        marginLeft,
        ...textStyles,
    };
};
const getWrapLinkIconStyles = (file: IUploadedFile): JssStyle => {
    if (!file.link) {
        return;
    }
    const fontSizeCoefficient = commonFontSizeCoefficient[file.textSize];
    const size = `calc(${pStyles.fontSize} * ${fontSizeCoefficient})`;

    if (file.description) {
        return { width: size, height: size };
    }

    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '15px',
        left: '15px',
        width: '28px',
        height: '28px',
        borderRadius: '6px',
        background: simpleColorCss(file.textBackground),
    };
};
const getLinkIconStyles = (file: IUploadedFile): JssStyle => {
    if (!file.link) {
        return { display: 'none' };
    }
    const fontSizeCoefficient = commonFontSizeCoefficient[file.textSize];
    const size = `calc(${pStyles.fontSize} * ${fontSizeCoefficient})`;
    return {
        width: size,
        height: size,
        fill: simpleColorCss(file.textColor),
    };
};
const getSliderHeight = (sliderData: IGallerySlider): string => {
    if (!sliderData.sliderHeightType) {
        return `${DEFAULT_SLIDER_HEIGHT}px`;
    }

    return sliderData.sliderHeightType === EGallerySliderHeightType.custom ? `${sliderData.height}px` : 'auto';
};

const getImageWrapperPosition = (file: IUploadedFile): string => {
    switch (file.imagePosition) {
        case EHorizontalPosition.L:
            return 'start';
        case EHorizontalPosition.M:
            return 'center';
        case EHorizontalPosition.R:
            return 'end';
        default:
            return 'center';
    }
};

export function getStyles(element: IElement): JssStyle {
    const sliderClasses: JssStyle = {};
    const imageClasses: Record<string, JssStyle> = {};
    let imagePosition: string;

    if (element.data.type === EGalleryTypes.slider && element.data.slider.uploadedFiles) {
        element.data.slider.uploadedFiles.forEach((file, index) => {
            sliderClasses[`description${index}`] = getDescriptionStyles(file);
            sliderClasses[`text${index}`] = getTextStyles(file);
            sliderClasses[`wrapLinkIcon${index}`] = getWrapLinkIconStyles(file);
            sliderClasses[`linkIcon${index}`] = getLinkIconStyles(file);
        });
    }

    if (element.data.type === EGalleryTypes.image && element.data.image.url) {
        const file = element.data.image;
        imagePosition = getImageWrapperPosition(file);
        imageClasses.description = getDescriptionStyles(file);
        imageClasses.text = getTextStyles(file);
        imageClasses.wrapLinkIcon = getWrapLinkIconStyles(file);
        imageClasses.linkIcon = getLinkIconStyles(file);
    }

    return {
        ...sliderClasses,
        ...imageClasses,
        iframe: {
            height: '315px', // TODO: FIX IFRAME VIDEO HEIGHT
            width: '100%',
            border: 'unset',
        },
        blankImageWrapper: {
            position: 'relative',
            height: '200px',
            background: '#F8FDFE',
        },
        blankImage: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        imgWrapper: {
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: imagePosition ? imagePosition : 'center',
            borderRadius: element.data.image.imageRound ? `${element.data.image.imageRound}px` : '0px',
        },
        image: {
            maxWidth: '100%',
            display: 'block',
            height: 'auto',
        },
        sliderImgWrapper: {
            height: getSliderHeight(element.data.slider),
            overflow: 'hidden',
            verticalAlign: 'middle!important',
            position: 'relative',
            borderRadius: element.data.slider.sliderRound ? `${element.data.slider.sliderRound}px` : '0px',
            display: 'block',
        },
        sliderImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    };
}
