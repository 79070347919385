import type { FontFamilyPresetPair } from '@libs/themes/src/types';
import { EThemeFontFamilies } from '@libs/themes/src/enums/font-families.enum';

export const DEFAULT_WEBSITE_FONT_FAMILY_PRESET_PAIRS: FontFamilyPresetPair[] = [
    {
        header: EThemeFontFamilies.montserrat,
        paragraph: EThemeFontFamilies.montserrat,
    },
    {
        header: EThemeFontFamilies.ptSans,
        paragraph: EThemeFontFamilies.ptSans,
    },
    {
        header: EThemeFontFamilies.firaSans,
        paragraph: EThemeFontFamilies.firaSans,
    },
    {
        header: EThemeFontFamilies.ibmPlexSans,
        paragraph: EThemeFontFamilies.ibmPlexSans,
    },
    {
        header: EThemeFontFamilies.openSans,
        paragraph: EThemeFontFamilies.openSans,
    },
    {
        header: EThemeFontFamilies.comfortaa,
        paragraph: EThemeFontFamilies.comfortaa,
    },
    {
        header: EThemeFontFamilies.manrope,
        paragraph: EThemeFontFamilies.manrope,
    },
    {
        header: EThemeFontFamilies.lora,
        paragraph: EThemeFontFamilies.lora,
    },
    {
        header: EThemeFontFamilies.merriweather,
        paragraph: EThemeFontFamilies.merriweather,
    },
    {
        header: EThemeFontFamilies.jost,
        paragraph: EThemeFontFamilies.jost,
    },
    {
        header: EThemeFontFamilies.unbounded,
        paragraph: EThemeFontFamilies.unbounded,
    },
    {
        header: EThemeFontFamilies.pacifico,
        paragraph: EThemeFontFamilies.openSans,
    },
    {
        header: EThemeFontFamilies.lora,
        paragraph: EThemeFontFamilies.roboto,
    },
    {
        header: EThemeFontFamilies.ptSerif,
        paragraph: EThemeFontFamilies.ptSans,
    },
    {
        header: EThemeFontFamilies.bitter,
        paragraph: EThemeFontFamilies.raleWay,
    },
    {
        header: EThemeFontFamilies.merriweather,
        paragraph: EThemeFontFamilies.firaSans,
    },
    {
        header: EThemeFontFamilies.openSans,
        paragraph: EThemeFontFamilies.lora,
    },
    {
        header: EThemeFontFamilies.ibmPlexSans,
        paragraph: EThemeFontFamilies.ibmPlexSerif,
    },
    {
        header: EThemeFontFamilies.oswald,
        paragraph: EThemeFontFamilies.playFairDisplay,
    },
    {
        header: EThemeFontFamilies.comfortaa,
        paragraph: EThemeFontFamilies.sourceCodeProp,
    },
    {
        header: EThemeFontFamilies.manrope,
        paragraph: EThemeFontFamilies.anonymousPro,
    },
    {
        header: EThemeFontFamilies.ibmPlexSerif,
        paragraph: EThemeFontFamilies.ibmPlexSans,
    },
    {
        header: EThemeFontFamilies.balsamiqSans,
        paragraph: EThemeFontFamilies.openSans,
    },
    {
        header: EThemeFontFamilies.jost,
        paragraph: EThemeFontFamilies.ubuntu,
    },
];
