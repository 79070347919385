<div [class]="classes?.wrap">
    <div [class]="classes?.messenger" *ngFor="let socialNetwork of elementData.socialNetworks">
        <a *ngIf="isSocialNetworkTypeIcon"
           [class]="classes?.element"
           [attr.href]="getSocialLink(socialNetwork)"
           target="_blank">
            <div [class]="classes?.wrapIcon">
                <img class="icon" [alt]="socialNetwork.name" [src]="getIconSrc(socialNetwork.name)">
            </div>
        </a>
        <a *ngIf="!isSocialNetworkTypeIcon"
           class="btn-hover"
           [class]="classes?.element"
           [attr.href]="getSocialLink(socialNetwork)"
           [ngStyle]="isButtonTypeClassic && { borderColor: getBrandColors(socialNetwork.name), background: getBrandColors(socialNetwork.name) }"
           target="_blank">
            <div [class]="classes?.wrapIcon">
                <div [innerHTML]="getIcon(socialNetwork.name)" class="icon"></div>
            </div>

            <div [class]="classes?.wrapContent">
                <span [class]="classes?.label"
                      [ngStyle]="isButtonTypeClassic && { color: chatTextColors(socialNetwork.name) }"
                >{{ socialNetwork.label }}</span>
            </div>
        </a>
    </div>
</div>
