import { HEX_COLOR } from '@common/regexp';

export function isHexColor(value: string): boolean {
    return HEX_COLOR.test(value);
}

export function rgbaToHex(rgba: string): string {
    if (rgba.startsWith('#')) {
        return rgba;
    }

    const [red, green, blue] = rgba.match(/(0?\.?\d{1,3})%?\b/g).map(Number);

    if (typeof red !== 'number' || typeof green !== 'number' || typeof blue !== 'number' || red > 255 || green > 255 || blue > 255) {
        throw new TypeError('Expected three numbers below 256');
    }

    return `#${(blue | (green << 8) | (red << 16) | (1 << 24)).toString(16).toUpperCase().slice(1)}`;
}
