import { IElement } from '@app/features/common/interfaces/IElement';
import { JssStyle } from 'jss';
import { h2Styles, pStyles } from '@app/features/common/styles/text.styles';

export function getStyles(element: IElement): JssStyle {
    return {
        teachersHeading: {
            marginBottom: '10px',
            textAlign: 'center',
            '& h2': h2Styles,
        },
        teachersList: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
        teacherProfile: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '30px',
            width: '30%',
            '& p': pStyles,
        },
        teacherAvatar: {
            borderRadius: '50%',
            marginBottom: '20px',
            margin: '0 auto',
            display: 'block',
        },
        teacherName: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        teacherDescription: {
            fontSize: '15px',
            fontStyle: 'italic',
        },
    };
}
