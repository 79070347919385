export enum EHorizontalPosition {
    L = 'L', // Left
    M = 'M', // Center
    R = 'R', // Right
}

export enum EVerticalPosition {
    T = 'T', // Top
    M = 'M', // Middle
    B = 'B', // Bottom
}
