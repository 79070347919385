export const TRANSPARENT_VALUE = 'rgba(255, 255, 255, 0)';

export const COLOR_PICKER_PRESETS: string[][] = [
    ['#BE3333', '#F5564A', '#FF968D', '#FF8DB3', '#EB3573', '#942661', '#FFFFFF'],
    ['#C54A24', '#FF6838', '#FFB39C', '#FFD18D', '#FFA21A', '#E8621A', '#EEF1F2'],
    ['#FF7D1A', '#FFC720', '#FFE38E', '#FFF6A7', '#FFED4F', '#F68C2E', '#B8C4CB'],
    ['#8E852E', '#D2DF4D', '#E8F0A6', '#CBE4AE', '#97C95C', '#477835', '#708A97'],
    ['#326E36', '#5EB762', '#AEDAB0', '#8DD0CA', '#1AA094', '#1A5F53', '#3C474C'],
    ['#1A7074', '#1AC3D8', '#8DE1EC', '#8ED8FA', '#1CB2F5', '#1A68A5', '#373737'],
    ['#2559AA', '#37A0F4', '#9BCFFA', '#A9B1DE', '#5262BC', '#31398B', '#513D39'],
    ['#46329D', '#764EBE', '#BBA7DF', '#D39EDC', '#A63DB8', '#5C2C97', '#86665A'],
];

export const COLOR_PICKER_GRADIENT_PRESETS: string[][] = [
    [
        'linear-gradient(115.36deg, #FDA949 0%, #DF1F6A 100%)',
        'linear-gradient(115.36deg, #FDBB81 0%, #FE3A75 100%)',
        'linear-gradient(115.36deg, #E45866 0%, #F8BE75 100%)',
        'linear-gradient(115.36deg, #E7B22A 0%, #C1D8CD 100%)',
        'linear-gradient(115.36deg, #FAD233 0%, #A2E8AF 100%)',
        'linear-gradient(115.36deg, #3DD5DF 0%, #67CA83 50.52%, #E9C56B 100%)',
        'linear-gradient(115.36deg, #BEE462 0%, #3D79EE 100%)',
    ],
    [
        'linear-gradient(115.36deg, #01ECDE 0%, #3D4FEE 100%)',
        'linear-gradient(115.36deg, #5676C2 0%, #A2E8AF 100%)',
        'linear-gradient(115.36deg, #21D5DC 0%, #B951D2 100%)',
        'linear-gradient(115.36deg, #48ECF7 0%, #F566A3 58.33%, #FD984F 100%)',
        'linear-gradient(115.36deg, #FE9D66 0%, #FA3D82 50%, #68369B 100%)',
        'linear-gradient(115.36deg, #7529C2 0%, #F44DA7 100%)',
        'linear-gradient(115.36deg, #E96BCC 0%, #045285 100%), #86665A',
    ],
];
