import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild, SimpleChanges, Type } from '@angular/core';

import { SpHostDirective } from '@common/directives/host.directive';
import { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';

import {
    SpSimpleButtonSettingsComponent,
    SpSimpleColorsSettingsComponent,
    SpSimpleFontSettingsComponent,
    SpSimplePresetSettingsComponent,
} from '@libs/themes/src/variants/simple';

type ThemeComponent = SpSimpleButtonSettingsComponent | SpSimpleColorsSettingsComponent | SpSimpleFontSettingsComponent | SpSimplePresetSettingsComponent;

@Component({
    selector: 'sp-simple-theme-control-resolver',
    template: '<ng-template spHost></ng-template>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSimpleThemeControlResolverComponent implements OnChanges {
    @Input() activeThemeSetting: EThemeSettings;
    @ViewChild(SpHostDirective, { static: true }) elementHost: SpHostDirective;

    private themeSettingsComponents: Record<any, any> = {
        [EThemeSettings.colorsSettings]: SpSimpleColorsSettingsComponent,
        [EThemeSettings.buttonsSettings]: SpSimpleButtonSettingsComponent,
        [EThemeSettings.fontsSettings]: SpSimpleFontSettingsComponent,
    };

    public ngOnChanges(changes: SimpleChanges) {
        const { activeThemeSetting } = changes;

        if (activeThemeSetting.previousValue === activeThemeSetting.currentValue) {
            return;
        }

        this.loadComponent();
    }

    private loadComponent(): void {
        const component: Type<ThemeComponent> = this.themeSettingsComponents[this.activeThemeSetting] || SpSimplePresetSettingsComponent;
        const { viewContainerRef } = this.elementHost;

        viewContainerRef.clear();
        viewContainerRef.createComponent<ThemeComponent>(component);
    }
}
