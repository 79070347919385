import type { Classes, Jss, JssStyle } from 'jss';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, type Renderer2, RendererFactory2, makeStateKey, TransferState } from '@angular/core';
import { create } from 'jss';

import preset from 'jss-preset-default';

import { PlatformService } from '@common/services/platform.service';

@Injectable({
    providedIn: 'root',
})
export class ServerStylesService {
    private readonly renderer!: Renderer2;
    private readonly styleElement!: HTMLStyleElement;
    private readonly jss!: Jss;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly platformService: PlatformService,
        private readonly transferState: TransferState,
        private readonly rendererFactory2: RendererFactory2,
    ) {
        if (this.platformService.isPlatformServer()) {
            this.renderer = this.rendererFactory2.createRenderer(null, null);
            this.styleElement = this.renderer.createElement('style');
            this.renderer.appendChild(this.document.querySelector('head'), this.styleElement);

            this.jss = create({
                ...preset(),
                id: { minify: true },
            });
        }
    }

    public getStyles(id: string, styles: JssStyle): Classes {
        const STYLE_KEY = makeStateKey<Classes>(`styles_${id}`);

        if (this.platformService.isPlatformBrowser()) {
            return this.transferState.get(STYLE_KEY, {});
        }

        const stylesheet = this.jss.createStyleSheet(styles as any);
        this.transferState.set(STYLE_KEY, stylesheet.classes);
        this.styleElement.innerHTML += stylesheet.toString();

        return stylesheet.classes;
    }
}
