import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'sp-synchronize-style-toggle',
    templateUrl: './sp-synchronize-style-toggle.component.html',
    styleUrls: ['./sp-synchronize-style-toggle.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSynchronizeStyleToggleComponent {
    @Input() public active: boolean;
}
