<div class="alert-box">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="sp-ui-text"
             [innerHTML]="subscriptionMessage | spSafeHtml"
        ></div>
    </div>
</div>
