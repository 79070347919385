import type { TimerData } from '@components/sp-timer';
import type { ITimerElementData } from '@app/features/timer/interface/ITimerElement';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { getStyles } from '@app/features/timer/components/timer-view/timer-view.component.styles';

import { BaseFeatureComponent } from '@web-builder/mls-widgets/common/abstract/base-feature.component';

@Component({
    selector: 'mls-timer-feature',
    templateUrl: './timer-feature.component.html',
    styleUrls: ['./timer-feature.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class TimerFeatureComponent extends BaseFeatureComponent<ITimerElementData> {
    protected getStyles = getStyles;

    public get timerData(): TimerData {
        return {
            untilDate: this.elementData.untilDate,
            columns: this.elementData.columns,
            customLabels: this.elementData.customLabels,
            expiredText: this.elementData.expiredText,
        };
    }
}
