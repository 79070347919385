export enum EThemeFontFamilies {
    pacifico = 'pacifico',
    openSans = 'openSans',
    lora = 'lora',
    roboto = 'roboto',
    ptSerif = 'ptSerif',
    ptSans = 'ptSans',
    bitter = 'bitter',
    raleWay = 'raleWay',
    merriweather = 'merriweather',
    firaSans = 'firaSans',
    jost = 'jost',
    ubuntu = 'ubuntu',
    ibmPlexSans = 'ibmPlexSans',
    ibmPlexSerif = 'ibmPlexSerif',
    oswald = 'oswald',
    playFairDisplay = 'playFairDisplay',
    comfortaa = 'comfortaa',
    manrope = 'manrope',
    sourceCodeProp = 'sourceCodeProp',
    anonymousPro = 'anonymousPro',
    balsamiqSans = 'balsamiqSans',
    lato = 'lato',
    montserrat = 'montserrat',
    inter = 'inter',
    commissioner = 'commissioner',
    mulish = 'mulish',
    yanoneKaffeesatz = 'yanoneKaffeesatz',
    philosopher = 'philosopher',
    jura = 'jura',
    caveat = 'caveat',
    literata = 'literata',
    unbounded = 'unbounded',
    cormorantGaramond = 'cormorantGaramond',
    tektur = 'tektur',
    exo2 = 'exo2',
}

export enum EThemeFontWeights {
    light = 'light',
    normal = 'normal',
    bold = 'bold',
}
