import type { SiteSettings } from '@common/types/site-settings.type';

import { createImmerReducer } from 'ngrx-immer/store';
import { on } from '@ngrx/store';

import SettingsActions from './settings.actions';

export const SETTINGS_FEATURE_KEY = 'settings';

export type SettingsState = {
    settings: SiteSettings;
    error?: string;
};

export const initialSettingsState: SettingsState = {
    settings: null,
};

export const settingsReducer = createImmerReducer<SettingsState>(
    initialSettingsState,
    on(SettingsActions.loadSiteSettingsSuccess, (state: SettingsState, { settings }) => {
        state.settings = settings;
        return state;
    }),
    on(SettingsActions.loadSiteSettingsFailure, (state: SettingsState, { error }) => {
        state.error = error;
        return state;
    }),
);
