export enum ESizeOptions {
    auto = 'auto',
    cover = 'cover',
    contain = 'contain',
}

export interface ISizeOptions {
    label: string;
    value: ESizeOptions;
}

export const sizeOptions: ISizeOptions[] = [
    {
        label: 'background_settings_size_default',
        value: ESizeOptions.auto,
    },
    {
        label: 'background_settings_size_stretch',
        value: ESizeOptions.cover,
    },
    {
        label: 'background_settings_size_fit',
        value: ESizeOptions.contain,
    },
];
