import type { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';

import { createReducer, on } from '@ngrx/store';

import ThemeActions from '@common/libs/themes/src/store/theme/theme.actions';
import ThemeConfigActions from '@common/libs/themes/src/store/theme-config/theme-config.actions';

export type ThemeConfigState = {
    loaded: boolean;
    activeThemeSetting: EThemeSettings;
};

export const THEME_CONFIG_STORE_KEY = 'config';

const initialThemeConfigState: ThemeConfigState = {
    loaded: false,
    activeThemeSetting: null,
};

export const themeConfigReducer = createReducer<ThemeConfigState>(
    initialThemeConfigState,
    on(ThemeActions.setTheme, (state) => ({ ...state, loaded: true })),

    on(ThemeConfigActions.setActiveThemeSetting, (state, { value }) => ({ ...state, activeThemeSetting: value })),
    on(ThemeConfigActions.unselectActiveThemeSetting, (state) => ({ ...state, activeThemeSetting: null })),
);
