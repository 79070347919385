export enum EPaymentType {
    yooMoney = 1,
    payPal = 2,
    fondy = 3,
    youKassa = 4,
    roboKassa = 5,
    stripe = 6,
    mercadopago = 7,
    liqpay = 8,
    wayforpay = 9,
    flutterwave = 10,
    monobank = 11,
    cryptomus = 12,
    whitepay = 13,
    klarna = 15,
}
