import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'img[spFallbackImage]',
})
export class SpFallbackImageDirective {
    @Input() public spFallbackImage!: string;

    private tries = 0;
    private readonly MAX_TRIES = 3;

    constructor(private readonly elementRef: ElementRef<HTMLImageElement>) {}

    @HostListener('error')
    public onError(): void {
        this.elementRef.nativeElement.style.visibility = 'hidden';
        const parent = this.elementRef.nativeElement.parentElement;
        if (parent.nodeName === 'PICTURE') {
            const sources = parent.querySelectorAll('source');
            Array.from(sources).forEach((s) => s.setAttribute('srcset', this.spFallbackImage));
        } else {
            if (this.tries < this.MAX_TRIES) {
                this.elementRef.nativeElement.src = this.spFallbackImage;
            }
            this.tries++;
        }

        this.elementRef.nativeElement.style.visibility = 'visible';
    }
}
